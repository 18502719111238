/**
 * @file   src\containers\organizations\Overview.tsx
 * @brief  Overview page.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, useIntlActionMessages, isUserAdmin } from '../../utils/helper';
import { Button, Col, Breadcrumb, Tab, Tabs, Table, Row, Badge } from 'react-bootstrap';
import Search from '../../components/MASearch';
import Pagination from '../../components/Pagination';
import ViewIcon from '../../assets/img/icon/View';
import UserImg from '../../assets/img/Userimg.jpg';
import { slide as Filter } from 'react-burger-menu';
import Select from '../../components/MASelect';
import Close from '../../assets/img/Close.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Sort from '../../assets/img/icon/Sort';
import Orgimg from "../../assets/img/Org-image.png";
import { RoleTypeIds } from '../../utils/enums';
import { useAppSelector } from '../../hooks';
import { RootState } from '../../store';

const Overview = () => {
    const navigate = useNavigate();
    // Location object
    const location = useLocation()
    const classlist = [
        { label: 'All  Class', value: '1' },
        { label: 'English', value: '2' },
        { label: 'Math', value: '3' },
        { label: 'Social Sciences', value: '3' },
        { label: 'Science', value: '4' },
        { label: 'Physical Education', value: '5' },
        { label: 'Information technology', value: '6' },
        { label: 'Arts (Drawing)', value: '7' },
        { label: 'Others', value: '8' },
        { label: 'N/A', value: '9' },
    ];
    const categorys = [
        { label: 'All Category', value: 0 },
        { label: 'Nutrition', value: 1 },
        { label: 'Education', value: 2 },
        { label: 'Fitness', value: 3 },
    ];
    const tags = [
        { label: 'Option1', value: 0 },
        { label: 'Option2', value: 1 },
        { label: 'Option3', value: 2 },
      ];

  // Accessing redux state variables
  const {
    rolesByOrgTypeData,
  } = useAppSelector((state: RootState) => state.organizationType);

    // Initialize component state variables.
    const [currentPage, setCurrentPage] = useState(1);
    const textProoftobeReviewed = useIntlMessages('text.ProoftobeReviewed');
    const textCompletionProof = useIntlMessages('text.CompletionProof');
    const textInProgressProof = useIntlMessages('text.InProgressProof');

    // get the label for tabs
  const getUserLabel = (userLevel: number) => {
    let tabLabel = '';
    if (rolesByOrgTypeData.length > 0) {
      const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
      tabLabel = element.length === 1 ? element[0].Name : '';
    } else {
      tabLabel = '';
    }
    return tabLabel;
  };
    return (
        <>
            <div className="page-title d-flex justify-content-between align-items-center">
                <h3><FormattedMessage id="Hd.Overview" /></h3>
                <Col md="auto">
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item onClick={() => navigate("/manageorganization")}>
                            <FormattedMessage id="Hd.ManageOrganizations" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate("/vieworganization", { state: { organizationId: location.state.organizationId } })}>
                            <FormattedMessage id="Hd.ViewOrganization" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            <FormattedMessage id="Hd.Overview" />
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </div>
            <div className='mb-4'><div className="org-selected">
                <img className="thumbnail-image"
                    src={Orgimg}
                    alt="user pic"
                />
                <div>
                    <h6 className='mb-1'>Modern American School</h6>
                    <small>5744 Forest Ln</small>
                </div>
            </div></div>
            {
                isUserAdmin() ? null :
                    <div className="content-sub">
                        <div className='content-area-padding d-flex justify-content-between align-items-center'>
                            <h6 className='mb-0'>{textProoftobeReviewed}</h6>
                            <div>
                                <Button variant="primary">{textCompletionProof}<Badge bg="danger">3</Badge></Button>
                                <Button variant="primary" className='ms-3'>{textInProgressProof}<Badge bg="danger">3</Badge></Button>
                            </div>
                        </div>
                    </div>
            }
            <div className="content-sub">
                <div className="content-sub-header header-light-bg d-flex align-items-center justify-content-between">
                    <h4>{getUserLabel(RoleTypeIds.APP_USER)}<FormattedMessage id="SubHd.StudentswithTO-DOItems" /></h4>
                    <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />} burgerButtonClassName="active">
                        <div id="filter-wrapper" className="filter-main">
                            <h4 className="mb-5"><FormattedMessage id="Hd.Filter" /></h4>
                            <Col className="form-sm">
                                <Row>
                                    <Col>
                                        <Select
                                            label={useIntlMessages('Label.Category')}
                                            options={categorys}
                                            // value={categorys[0]}
                                            placeholder={useIntlMessages('PH.Select')}
                                            onChange={(e: any) => e}
                                        // error="Error"
                                        />
                                    </Col>
                                    <Col>
                                        <Select
                                            label={useIntlMessages('Label.SubCategories')}
                                            options={classlist}
                                            // value={classlistValue}
                                            placeholder={useIntlMessages('PH.Select')}
                                            onChange={(e: any) => e}
                                        // error="Error"
                                        />
                                    </Col>
                                </Row>
                                <Col>
                            <Select
                              label="Tags"
                              options={tags}
                              placeholder="Select Tags"
                              onChange={(e: any) => e}
                              // error="Error"
                              isMulti
                            />
                          </Col>
                            </Col>
                            <Row className="mt-4">
                                <Col>
                                    <Button variant="outline-primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                        <FormattedMessage id="Button.ClearFilter" />
                                    </Button>
                                </Col>
                                <Col>
                                    <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                        <FormattedMessage id="Button.ApplyFilters" />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Filter>
                </div>
                <div className="content-area-padding">
                    <Tabs defaultActiveKey="Completed" transition={false} id="noanim-tab-example" className="mb-3">
                        <Tab eventKey="Completed" title={useIntlMessages('Status.Completed') + " (220)"}>  {/* `(${variable})` */}
                            <div className="d-flex justify-content-end mb-3">
                                <Col lg={3} className="form-sm">
                                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" />
                                </Col>
                            </div>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="Label.Name" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.SubCategories" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.Category" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.DateofJoining" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th className="w-50px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Pagination pageSize={5} totalitems={20} pageLimit={5}
                                setCurrentPage={(page: number) => setCurrentPage(page)}
                                currentPage={currentPage - 1}
                                prevPage={-1}
                                itemsDisplayed={5}
                            />
                        </Tab>
                        <Tab eventKey="INProgress" title={useIntlMessages('Status.INProgress') + " (280)"}>
                            <div className="d-flex justify-content-end mb-3">
                                <Col lg={3} className="form-sm">
                                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" />
                                </Col>
                                <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
                                    <div id="filter-wrapper" className="filter-main">
                                        <h4 className="mb-5"><FormattedMessage id="Hd.Filter" /></h4>
                                        <Col className="form-sm">
                                            <Row>
                                                <Col>
                                                    <Select
                                                        label={useIntlActionMessages('Label.Category')}
                                                        options={categorys}
                                                        // value={categorys[0]}
                                                        placeholder={useIntlMessages('PH.Select')}
                                                        onChange={(e: any) => e}
                                                    // error="Error"
                                                    />
                                                </Col>
                                                <Col>
                                                    <Select
                                                        label={useIntlActionMessages('Label.SubCategories')}
                                                        options={classlist}
                                                        // value={classlistValue}
                                                        placeholder={useIntlMessages('PH.Select')}
                                                        onChange={(e: any) => e}
                                                    // error="Error"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Row className="mt-4">
                                            <Col>
                                                <Button variant="outline-primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                    <FormattedMessage id="Button.ClearFilter" />
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                    <FormattedMessage id="Button.ApplyFilters" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Filter>
                            </div>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="Label.Name" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.SubCategories" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.Category" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.DateofJoining" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th className="w-50px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Pagination pageSize={5} totalitems={20} pageLimit={5}
                                setCurrentPage={(page: number) => setCurrentPage(page)}
                                currentPage={currentPage - 1}
                                prevPage={-1}
                                itemsDisplayed={5}
                            />
                        </Tab>
                        <Tab eventKey="NotStarted" title={useIntlMessages('Status.NotStarted') + " (200)"}>
                            <div className="d-flex justify-content-end mb-3">
                                <Col lg={3} className="form-sm">
                                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" />
                                </Col>
                                <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
                                    <div id="filter-wrapper" className="filter-main">
                                        <h4 className="mb-5"><FormattedMessage id="Hd.Filter" /></h4>
                                        <Col className="form-sm">
                                            <Row>
                                                <Col>
                                                    <Select
                                                        label={useIntlActionMessages('Label.Category')}
                                                        options={categorys}
                                                        // value={categorys[0]}
                                                        placeholder={useIntlMessages('PH.Select')}
                                                        onChange={(e: any) => e}
                                                    // error="Error"
                                                    />
                                                </Col>
                                                <Col>
                                                    <Select
                                                        label={useIntlActionMessages('Label.SubCategories')}
                                                        options={classlist}
                                                        // value={classlistValue}
                                                        placeholder={useIntlMessages('PH.Select')}
                                                        onChange={(e: any) => e}
                                                    // error="Error"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Row className="mt-4">
                                            <Col>
                                                <Button variant="outline-primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                    <FormattedMessage id="Button.ClearFilter" />
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                    <FormattedMessage id="Button.ApplyFilters" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Filter>
                            </div>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="Label.Name" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.SubCategories" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.Category" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.DateofJoining" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th className="w-50px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="dark" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Pagination pageSize={5} totalitems={20} pageLimit={5}
                                setCurrentPage={(page: number) => setCurrentPage(page)}
                                currentPage={currentPage - 1}
                                prevPage={-1}
                                itemsDisplayed={5}
                            />
                        </Tab>
                        <Tab eventKey="Overdue" title={useIntlMessages('Status.Overdue') + " (150)"}>
                            <div className="d-flex justify-content-end mb-3">
                                <Col lg={3} className="form-sm">
                                    <Search placeholder={useIntlMessages('PH.SearchName')} size="sm" />
                                </Col>
                                <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
                                    <div id="filter-wrapper" className="filter-main">
                                        <h4 className="mb-5"><FormattedMessage id="Hd.Filter" /></h4>
                                        <Col className="form-sm">
                                            <Row>
                                                <Col>
                                                    <Select
                                                        label={useIntlActionMessages('Label.Category')}
                                                        options={categorys}
                                                        // value={categorys[0]}
                                                        placeholder={useIntlMessages('PH.Select')}
                                                        onChange={(e: any) => e}
                                                    // error="Error"
                                                    />
                                                </Col>
                                                <Col>
                                                    <Select
                                                        label={useIntlActionMessages('Label.SubCategories')}
                                                        options={classlist}
                                                        // value={classlistValue}
                                                        placeholder={useIntlMessages('PH.Select')}
                                                        onChange={(e: any) => e}
                                                    // error="Error"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Row className="mt-4">
                                            <Col>
                                                <Button variant="outline-primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                    <FormattedMessage id="Button.ClearFilter" />
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                                                    <FormattedMessage id="Button.ApplyFilters" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Filter>
                            </div>
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="Label.Name" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.SubCategories" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.Category" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th>
                                            <FormattedMessage id="Label.DateofJoining" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                        </th>
                                        <th className="w-50px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                                <img src={UserImg} className="w-100" alt="user" />
                                            </span>
                                            <label>
                                                Vivan Franklin
                                            </label>
                                        </div></td>
                                        <td>Math, Science... +3</td>
                                        <td>Education, Fitness </td>
                                        <td>05/03/2023</td>
                                        <td>
                                            <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                                <ViewIcon />
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Pagination pageSize={5} totalitems={20} pageLimit={5}
                                setCurrentPage={(page: number) => setCurrentPage(page)}
                                currentPage={currentPage - 1}
                                prevPage={-1}
                                itemsDisplayed={5}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <div className="content-sub">
                <div className="content-sub-header header-light-bg d-flex align-items-center">
                    <h4>{getUserLabel(RoleTypeIds.APP_USER)}<FormattedMessage id="SubHd.StudentsRescheduled" /></h4>
                    <Col className="btn-container d-flex justify-content-end">
                        <Col lg={4} className="form-sm">
                            <Search placeholder={useIntlMessages('PH.SearchSurvey')} size="sm" />
                        </Col>
                    </Col>
                </div>
                <div className="content-area-padding">
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="Label.Name" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                </th>
                                <th>
                                    <FormattedMessage id="Label.SubCategories" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                </th>
                                <th>
                                    <FormattedMessage id="Label.Category" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                </th>
                                <th>
                                    <FormattedMessage id="Label.DateofJoining" /><Link to="" className='ms-1' onClick={() => window.alert('Not Implemented')}><Sort /></Link>
                                </th>
                                <th className="w-50px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> <div className="d-flex align-items-center">
                                    <span className="td-user-image">
                                        <img src={UserImg} className="w-100" alt="user" />
                                    </span>
                                    <label>
                                        Vivan Franklin
                                    </label>
                                </div></td>
                                <td>Math, Science... +3</td>
                                <td>Education, Fitness </td>
                                <td>05/03/2023</td>
                                <td>
                                    <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                        <ViewIcon />
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td> <div className="d-flex align-items-center">
                                    <span className="td-user-image">
                                        <img src={UserImg} className="w-100" alt="user" />
                                    </span>
                                    <label>
                                        Vivan Franklin
                                    </label>
                                </div></td>
                                <td>Math, Science... +3</td>
                                <td>Education, Fitness </td>
                                <td>05/03/2023</td>
                                <td>
                                    <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                        <ViewIcon />
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td> <div className="d-flex align-items-center">
                                    <span className="td-user-image">
                                        <img src={UserImg} className="w-100" alt="user" />
                                    </span>
                                    <label>
                                        Vivan Franklin
                                    </label>
                                </div></td>
                                <td>Math, Science... +3</td>
                                <td>Education, Fitness </td>
                                <td>05/03/2023</td>
                                <td>
                                    <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                        <ViewIcon />
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td> <div className="d-flex align-items-center">
                                    <span className="td-user-image">
                                        <img src={UserImg} className="w-100" alt="user" />
                                    </span>
                                    <label>
                                        Vivan Franklin
                                    </label>
                                </div></td>
                                <td>Math, Science... +3</td>
                                <td>Education, Fitness </td>
                                <td>05/03/2023</td>
                                <td>
                                    <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                        <ViewIcon />
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td> <div className="d-flex align-items-center">
                                    <span className="td-user-image">
                                        <img src={UserImg} className="w-100" alt="user" />
                                    </span>
                                    <label>
                                        Vivan Franklin
                                    </label>
                                </div></td>
                                <td>Math, Science... +3</td>
                                <td>Education, Fitness </td>
                                <td>05/03/2023</td>
                                <td>
                                    <Button variant="secondary" size="sm" className="icon-btn" onClick={() => window.alert('Not Implemented')}>
                                        <ViewIcon />
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Pagination pageSize={5} totalitems={20} pageLimit={5}
                        setCurrentPage={(page: number) => setCurrentPage(page)}
                        currentPage={currentPage - 1}
                        prevPage={-1}
                        itemsDisplayed={5}
                    />
                </div>
            </div>
        </>
    );
};
export default Overview;
