/**
 * @file   src\containers\organizations\GoalStep2.tsx
 * @brief  Goal add second step.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from 'react';
import '../../assets/css/GoalSurvay.scss';
import { FormattedMessage } from 'react-intl';
import { Button, Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../components/MACheck';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import Search from '../../components/MASearch';
import { Scrollbars } from 'react-custom-scrollbars';
import LinkIcon from '../../assets/img/icon/Link';
import AddIcon from '../../assets/img/icon/Add';
import AttachmentCard from '../../components/AttachmentCard';
import Doc from '../../assets/img/icon/Attachment';
import Image from '../../assets/img/icon/Image';
import Audio from '../../assets/img/icon/Audio';
import Video from '../../assets/img/icon/Video';
import plusicon from '../../assets/img/icon_plus_wt.svg';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IResourceForm, ILinkResource } from '../../interfaces/ResourceInterface';
import { validateForm } from '../../utils/formValidation';
import { MOTIVATIONAL_RESOURCE_GOAL_SCHEMA } from '../../validations/resourceSchema';
import { addResource } from '../../store/actions/resourceActions';
import { getFromLocalStorage, checkValidFile, bytesToSize, useIntlActionMessages, useIntlMessages, getFileTypeNumber } from '../../utils/helper';
import { resetResourceAdd, resetLinkResource, resetMotivationalViewAll } from '../../store/slices/resourceSlice';
import { MessageToaster } from '../../utils/ToastUtil';
import { NumberValues, ResourcetypeID, UploaderProps, IMAGE_TYPES_SUPPORTED, FILE_TYPES_SUPPORTED } from '../../utils/enums';
import { ISelectOptionsNumber, ISelectOption } from '../../interfaces/GeneralInterface';
import {
  getCategoryList,
  uploadFile,
  uploadFilePathUpdate,
  uploadFilePathUpdateMultiple,
  getGoalResource,
  deleteGoalResource,
  getGoalDetail,
} from '../../store/actions/organizationActions';
import ImageUpload from '../../components/ImageUpload';
import { getViewAllMotivationalResource, linkResource } from '../../store/actions/resourceActions';
import { IUploadFilePathUpdateParams, IuploadFilePathUpdateMultipleParams } from '../../interfaces/OrganisationInterface';
import Loader from '../../components/Loader';
import { resetUploadFile, resetaddGoalStepTwo, resetUploadFilePathUpdate } from '../../store/slices/organizationSlice';
import UploadNote from '../../components/UploadNote';
import useDebounce from '../../hooks/useDebounce';
import { DEFAULT_PAGE_INDEX, PAGE_SIZE_SORT_50 } from '../../utils/constants';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndMessage from '../../containers/EndMessage';
// Declare default params
const savedefaultRequestParams: IResourceForm = {
  ResourceName: '',
  ResourcetypeID: ResourcetypeID.MOTIVATIONAL_RESOURCE,
  CategoryID: 0,
  ClassID: [],
  CreatedUserRoleTypeID: 0,
  Description: '',
  OrganizationTypeId: 0,
  OrganizationID: '',
  IsGeneral: true,
  Visibility: [0],
  GoalID: '',
};
// Declare default params for resource list
const defaultListApiParams = {
  CategoryId: 0,
  Filter: [
    {
      OrganizationID: '',
      SubCategoryIDS: [0],
    },
  ],
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_SORT_50,
  PageSort: '',
  Search: '',
};
const GoalStep2 = (props: any) => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const resourceInputRef = useRef<any>();
  const imageRef = useRef<any>();
  const containerRef = useRef<any>();
  // Declare constants
  const fileType = [
    { label: 'Image', value: 'image' },
    { label: 'Video', value: 'video' },
    { label: 'Audio', value: 'audio' },
    { label: 'Document', value: 'document' },
  ];
  // Initialize language variables.
  const ImageUploadChooseImage = useIntlActionMessages('Image.Choose.Thumbnail');
  const ImageUploadFailed = useIntlActionMessages('Image.Upload.Failed');
  const ImageUploadChooseAttachment = useIntlActionMessages('Label.Upload.Attachment.type');
  const AddResourceError = useIntlActionMessages('AddResource.Error');
  const MotivationalResourcesText = useIntlMessages('PH.MotivationalResources');
  const CategoryText = useIntlActionMessages('Label.Category');
  const SelectText = useIntlActionMessages('PH.Select');
  const SubCategoryText = useIntlActionMessages('Label.SubCategory');
  const LoadingText = useIntlActionMessages('Label.Loading');
  const generalText = useIntlActionMessages('Label.Checkbox.GeneralMotivationalResourceAdmin');
  const storageData = getFromLocalStorage('MI_USR_DATA');
  // Access redux state variables
  const { getCatergoryListAPiData, getGoalDetailAPiData, getGoalDetailApiSuccess } = useAppSelector((state: RootState) => state.organization);
  const { addResourceApiLoading, addResourceApiSuccess, addResourceApiResponseCode, addResourceApiResponseMessage, addResourceApiData } = useAppSelector(
    (state: RootState) => state.resource,
  );
  const { motivationalViewAllData, motivationalViewAllApiLoading, linkResourceApiLoading, linkResourceApiResponseMessage, linkResourceApiSuccess } = useAppSelector(
    (state: RootState) => state.resource,
  );
  const {
    uploadFileApiLoading,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFileApiSuccess,
    uploadFileApiData,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseCode,
    uploadFilePathUpdateResponseMessage,
    getGoalResourceApiData,
    getGoalResourceApiSuccess,
    getGoalResourceApiLoading,
    deleteGoalResourceApiLoading,
    deleteGoalResourceApiSuccess,
    deleteGoalResourceApiErrorMessage,
    deleteGoalResourceApiErrorCode,
    uploadFilePathUpdateMultipleApiLoading,
    uploadFilePathUpdateMultipleApiSuccess,
    uploadFilePathUpdateMultipleResponseCode,
    uploadFilePathUpdateMultipleResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);
  // Initialize component state variables.
  const [fileTypeValue, setfileTypeValue] = useState<any>(fileType[0]);
  const [motivationalResourceForm, setMotivationalResourceForm] = useState<IResourceForm>(savedefaultRequestParams);
  const [errorFields, setErrorFields] = useState<any>({});
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [categoryList, setCategoryList] = useState<ISelectOption[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOption[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsNumber[]>([]);
  const [imgError, setImgError] = useState<string>('');
  const [upImageAttachment, setUpImageAttachment] = useState<string | null>(null);
  const [upImageAttachmentDetails, setUpImageAttachmentDetails] = useState<any>({});
  const [fileUploadBegin, setFileUploadBegin] = useState<boolean>(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState<any>([]);
  const [fileError, setFileError] = useState<string>('');
  const [goalID, setGoalID] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [apiParams, setApiParams] = useState<any>(defaultListApiParams);
  const [upImageThumbnailDetails, setUpImageThumbnailDetails] = useState<any>({});
  const [upImageThumbnail, setUpImageThumbnail] = useState<string | null>(null);
  const [uploadAttachment, setUploadAttachment] = useState<boolean>(false);
  const [uploadThumbnail, setUploadThumbnail] = useState<boolean>(false);
  const [startSubmit, setStartSubmit] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedCategoryResourceList, setSelectedCategoryResourceList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedSubCategoryResourceList, setSelectedSubCategoryResourceList] = useState<ISelectOptionsNumber[]>([]);
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [items, setItems] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchText, 500);
  // Set API params on bebounced search.
  useEffect(() => {
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Search: searchText,
      Page: DEFAULT_PAGE_INDEX,
      Filter: [
        {
          OrganizationID: props.orgId,
        },
      ],
    }));
  }, [debouncedSearch]);
  // showing link resource  success and error messages
  useEffect(() => {
    if (linkResourceApiSuccess) {
      toast.toastSuccess(linkResourceApiResponseMessage);
      setOpenFilter(false);
      dispatch(resetLinkResource());
      getResourceList();
      clearResourceDataFilter();
    }
  }, [linkResourceApiLoading]);
  // Api call to get goal details
  useEffect(() => {
    const goalDetailsAPIRequest = {
      goalId: props.activeGoalID,
    };
    dispatch(getGoalDetail(goalDetailsAPIRequest));
  }, [props.activeGoalID]);
  // Setting params
  useEffect(() => {
    if (apiParams.Search !== '' || selectedCategoryResourceList.length > 0 || selectedSubCategoryResourceList.length > 0) {
      setItems([]); // Clear items for new search or filter
      setApiParams((prevParams: any) => ({ ...prevParams, Page: 1 }));
    }
  }, [apiParams.Search, selectedCategoryResourceList, selectedSubCategoryResourceList]);

  // Fetch data when apiParams change
  useEffect(() => {
    if (apiParams.Search !== '' || selectedCategoryResourceList.length > 0 || selectedSubCategoryResourceList.length > 0) {
      dispatch(getViewAllMotivationalResource(apiParams));
    }
  }, [apiParams, dispatch]);
  // Setting items in overaly
  useEffect(() => {
    if (motivationalViewAllData?.Resources) {
      const newItems = motivationalViewAllData?.Resources;
      setItems((prevItems: any) => (apiParams.Page === 1 ? newItems : [...prevItems, ...newItems]));
      setHasMore(newItems.length > 0 && newItems.length === apiParams.PageSize);
    }
  }, [motivationalViewAllData]);

  // Works on unmounting. used to reset add goal step two
  useEffect(() => {
    setMotivationalResourceForm((prevData) => ({
      ...prevData,
      CreatedUserRoleTypeID: storageData?.RoleTypeId,
    }));
    setUploadedFileInfo([]);
    setUpImageAttachmentDetails([]);
    dispatch(resetResourceAdd());
    dispatch(resetUploadFile());
    dispatch(resetaddGoalStepTwo());
    setGoalID(props.activeGoalID);
    getResourceList();
  }, []);
  // resetting goal step 2 on return
  useEffect(() => {
    return () => {
      dispatch(resetaddGoalStepTwo());
    };
  }, []);
  // Upload file to bucket
  useEffect(() => {
    const submitUploadsAndSubsequentActions = async () => {
      if (uploadFileResponseCode > 0 && uploadFileApiSuccess && !submit && upImageAttachmentDetails) {
        uploadAttachmentFileToBucket();
        dispatch(resetUploadFile());
      } else if (submit && uploadFileResponseCode > 0 && uploadFileApiSuccess) {
        await uploadThumbnailToBucket();
        dispatch(resetUploadFile());
        dispatch(addResource(motivationalResourceForm));
      }
    };
    submitUploadsAndSubsequentActions();
  }, [uploadFileResponseCode, uploadFileResponseMessage]);
  // Handle Resource Success/Failure.
  useEffect(() => {
    if (submit && addResourceApiResponseCode > 0 && addResourceApiResponseMessage && !addResourceApiSuccess) {
      toast.toastError(addResourceApiResponseMessage);
      setStartSubmit(false);
    } else if (submit && addResourceApiResponseCode > 0 && addResourceApiSuccess) {
      setUploadThumbnail(true);
      setSubmit(false);
      // upload thumbnail
      const reqParams: IUploadFilePathUpdateParams = {
        FilePath: upImageThumbnailDetails.name,
        Type: 'resourceimage',
        TypeId: addResourceApiData?.ResourceId,
      };
      // Api request to update file info after bucket upload and save organization info
      dispatch(uploadFilePathUpdate(reqParams));
    }
  }, [addResourceApiResponseCode, addResourceApiResponseMessage]);
  // Show error messages upon save api failure
  useEffect(() => {
    if (uploadThumbnail && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateResponseMessage && !uploadFilePathUpdateApiSuccess) {
      toast.toastError(uploadFilePathUpdateResponseMessage);
      setStartSubmit(false);
    } else if (uploadThumbnail && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateApiSuccess) {
      setUploadAttachment(true);
      setUploadThumbnail(false);
      const uplodedFileInfo = uploadedFileInfo.map((file: any) => {
        return {
          FileName: file.name,
          DisplayName: file.displayName,
          FileSize: file.size,
          FileType: file.typeNumber ? file.typeNumber.toString() : '',
        };
      });
      const reqParams: IuploadFilePathUpdateMultipleParams = {
        Files: uplodedFileInfo,
        Type: 'resource_attachments',
        TypeId: addResourceApiData?.ResourceId,
      };
      // Api request to update file info after bucket upload and save organization info
      dispatch(uploadFilePathUpdateMultiple(reqParams));
    }
  }, [uploadFilePathUpdateResponseCode, uploadFilePathUpdateResponseMessage]);
  // Show message after form submit, success/failure
  useEffect(() => {
    if (uploadAttachment && uploadFilePathUpdateMultipleResponseCode > 0 && uploadFilePathUpdateMultipleApiSuccess) {
      setUploadAttachment(false);
      setStartSubmit(false);
      toast.toastSuccess(addResourceApiResponseMessage);
      dispatch(resetUploadFile());
      dispatch(resetUploadFilePathUpdate());
      setOpen(false);
      getResourceList();
      clearResourceData();
    }
  }, [uploadFilePathUpdateMultipleResponseCode, uploadFilePathUpdateMultipleResponseMessage]);
  // Show error messages upon save api failure unlink resource from goal
  useEffect(() => {
    if (deleteGoalResourceApiErrorCode > 0 && deleteGoalResourceApiSuccess) {
      // Unliked resource from goal
      toast.toastSuccess(deleteGoalResourceApiErrorMessage);
      clearResourceData();
      getResourceList();
      dispatch(resetaddGoalStepTwo());
    }
  }, [deleteGoalResourceApiErrorMessage, deleteGoalResourceApiErrorCode]);
  // Get category details by organization
  useEffect(() => {
    window.scrollTo(0, 0);
    setMotivationalResourceForm((motivationalResourceForm) => ({
      ...motivationalResourceForm,
      OrganizationID: props.orgId,
    }));
    dispatch(getCategoryList({ OrganizationId: props.orgId }));
  }, []);
  // Set image file type on page load
  useEffect(() => {
    handlefileTypeChange({ value: 'image' });
    setfileTypeValue(fileType.find((option) => option.value === 'image'));
  }, []);
  // Add event listener for Enter key press
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        if (isFormValid()) {
          event.preventDefault();
          onSubmit();
        }
      }
    };
    const isFormValid = () => {
      return Object.values(errorFields).every((error) => !error);
    };
    const currentContainer = containerRef?.current;
    if (currentContainer) {
      currentContainer.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [errorFields]);
  // Handle accepted value based file type
  useEffect(() => {
    handlefileTypeChange({ value: 'image' });
    setfileTypeValue(fileType.find((option) => option.value === 'image'));
  }, []);
  // Call to get motivational list API
  const getResourceList = () => {
    const resourceParams = {
      GoalId: props.activeGoalID,
      Page: NumberValues.NUM_1,
      PageSize: NumberValues.NUM_100,
      TypeID: ResourcetypeID.MOTIVATIONAL_RESOURCE,
    };
    dispatch(getGoalResource(resourceParams));
  };
  // Handle subcategory change
  const handleSubCategoryChange = async (event: any) => {
    if (event) {
      const subcategories = event.map((subcategory: any) => subcategory.value);
      setSelectedSubCategory(event);
      setMotivationalResourceForm((info: any) => ({
        ...info,
        ClassID: subcategories,
      }));
      const validateObj = {
        ...motivationalResourceForm,
        ClassID: subcategories?.length > 0 ? subcategories : undefined,
      };
      const errorResult = await validateForm(validateObj, MOTIVATIONAL_RESOURCE_GOAL_SCHEMA, errorFields);
      setErrorFields(errorResult);
    }
  };
  // Put file to bucket after getting the path
  const uploadThumbnailToBucket = async () => {
    const uploadURL = uploadFileApiData;
    if (uploadURL) {
      // PUT request: upload file to S3
      const result = await fetch(uploadURL, {
        method: 'PUT',
        body: upImageThumbnail,
      });
      if (result.status == 200) {
        const fileSize = bytesToSize(upImageThumbnailDetails.size);
        const fileInfo = { ...upImageThumbnailDetails, bucketURL: uploadURL, size: fileSize };
        setUpImageThumbnailDetails(fileInfo);
      } else {
        setStartSubmit(false);
        setFileError(ImageUploadFailed);
      }
    }
  };

  // Handle validation
  const CheckValidation = async (validateObj: any) => {
    const errorResult = await validateForm(validateObj, MOTIVATIONAL_RESOURCE_GOAL_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Handle file type
  const handlefileTypeChange = (event: { value: string }) => {
    setfileTypeValue(event);
    const fileType = event.value.toLowerCase();
    const acceptedFileExtensions = FILE_TYPES_SUPPORTED[fileType as keyof typeof FILE_TYPES_SUPPORTED] || [];
    const acceptString = acceptedFileExtensions.map((ext) => `.${ext}`).join(',');
    if (fileUploadRef?.current) {
      fileUploadRef.current.accept = acceptString;
    }
  };
  // Handle form fields validtaion
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name == 'Description') {
      setCharacterCount(event.target.value.length);
    }
    setMotivationalResourceForm((info: any) => ({
      ...info,
      GoalID: goalID?.toString(),
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    CheckValidation(validateObj);
  };
  // Function to upload attachments
  const uploadResourceFile = async (e: any) => {
    setFileUploadBegin(true);
    setFileError('');
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const lastDotIndex = file?.name?.lastIndexOf('.');
      const fileNameWithoutExtension = file?.name?.slice(0, lastDotIndex);
      const fileExtension = file?.name?.slice(lastDotIndex + 1);
      const modifiedFileName = fileNameWithoutExtension + new Date().getTime() + '.' + fileExtension;
      // replacing special characters with underscores
      const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
      const isValid = checkValidFile(modifiedFileName, fileTypeValue.value, file.size);
      if (isValid) {
        setUpImageAttachment(e.target.files[0]);
        const fileInfo = {
          name: formattedFilename,
          displayName: file.name,
          type: fileExtension,
          size: file.size,
          typeNumber: getFileTypeNumber(fileTypeValue),
        };
        setUpImageAttachmentDetails(fileInfo);
        const formData = new FormData();
        formData.append('UploadExtension', fileInfo.type);
        formData.append('UploadObjectKey', fileInfo.name);
        formData.append('UploadType', 'resource_attachments');
        dispatch(uploadFile(formData));
      } else {
        setFileError(ImageUploadChooseImage);
        setFileUploadBegin(false);
        if (fileUploadRef.current) {
          fileUploadRef.current.value = '';
        }
      }
    } else {
      setFileUploadBegin(false);
    }
  };
  // Put attachments to bucket after getting the path
  const uploadAttachmentFileToBucket = async () => {
    const uploadURL = uploadFileApiData;
    if (uploadURL) {
      // PUT request: upload file to S3
      const result = await fetch(uploadURL, {
        method: 'PUT',
        body: upImageAttachment,
      });
      if (result.status == 200) {
        const fileSize = bytesToSize(upImageAttachmentDetails.size);
        const fileInfo = { ...upImageAttachmentDetails, bucketURL: uploadURL, size: fileSize };
        setUpImageAttachmentDetails(fileInfo);
        setUploadedFileInfo([...uploadedFileInfo, fileInfo]);
        setFileUploadBegin(false);
        setFileError('');
      } else {
        setFileError(ImageUploadFailed);
        setFileUploadBegin(false);
        if (fileUploadRef.current) {
          fileUploadRef.current.value = '';
        }
        setStartSubmit(false);
      }
    }
  };
  // Handle form submit
  const onSubmit = async () => {
    let errorResult = {};
    errorResult = await validateForm(motivationalResourceForm, MOTIVATIONAL_RESOURCE_GOAL_SCHEMA, errorFields);
    if (Object.keys(errorResult).length === 0) {
      if (upImageThumbnail && uploadedFileInfo?.length > 0) {
        setImgError('');
        const formData = new FormData();
        formData.append('UploadExtension', upImageThumbnailDetails.type);
        formData.append('UploadObjectKey', upImageThumbnailDetails.name);
        formData.append('UploadType', 'resourceimage');
        dispatch(uploadFile(formData));
        setSubmit(true);
        setStartSubmit(true);
      } else {
        if (!upImageThumbnail) {
          setImgError(ImageUploadChooseImage);
          setStartSubmit(false);
        } else if (!upImageAttachment) {
          setFileError(ImageUploadChooseAttachment);
          setStartSubmit(false);
        }
        window.scrollTo(0, 0);
        if (imageRef?.current) {
          imageRef?.current?.focus();
        }
      }
    } else {
      setErrorFields(errorResult);
      setStartSubmit(false);
      // If there are validation errors, focus on the input field
      const firstErrorField = Object.keys(errorResult)[0];
      if (firstErrorField && resourceInputRef?.current) {
        resourceInputRef?.current?.focus();
      }
    }
  };
  // Set image selected
  const setPicture = (pic: any) => {
    setUpImageThumbnail(pic);
    const imgInfo = {
      name: pic.name,
      type: pic?.name?.split('.')[1],
    };
    setUpImageThumbnailDetails(imgInfo);
    setImgError('');
  };
  // Handle delete confirmation
  const ShowDeleteConfirmPopup = (resourceId: any) => {
    setIsShowDeleteConfirmPopup(true);
    setSelectedResourceId(resourceId);
  };
  // Api call to delete resource
  const handleDeleteResourcesClick = () => {
    if (selectedResourceId) {
      const deleteParams = {
        GoalID: props.activeGoalID,
        ResourceID: selectedResourceId,
      };
      dispatch(deleteGoalResource(deleteParams));
      setIsShowDeleteConfirmPopup(false);
    }
  };
  // Remove Attachment from listing
  const deleteAttachment = (deleteIndex: number) => {
    const newUploadedFileInfo = [...uploadedFileInfo];
    setUploadedFileInfo(newUploadedFileInfo.filter((item: any, index: number) => index != deleteIndex));
  };
  // Reset form
  const clearResourceData = () => {
    setOpen(false);
    setMotivationalResourceForm(savedefaultRequestParams);
    setUploadedFileInfo([]);
    setUpImageAttachmentDetails([]);
    setUpImageThumbnail('');
    setErrorFields([]);
    setImgError('');
    setFileError('');
    setUploadAttachment(false);
    setUploadThumbnail(false);
    if (getGoalDetailAPiData?.CategoryName != null && getGoalDetailAPiData?.CategoryId != null) {
      setSelectedCategory([{ label: getGoalDetailAPiData?.CategoryName, value: getGoalDetailAPiData?.CategoryId }]);
      const subcategoriesForSelection = getGoalDetailAPiData?.Class?.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setSelectedSubCategory(subcategoriesForSelection);
    } else {
      setSelectedCategory([]);
    }

    setMotivationalResourceForm((motivationalResourceForm) => ({
      ...motivationalResourceForm,
      // CategoryID: getGoalDetailAPiData?.CategoryId,
      CreatedUserRoleTypeID: storageData?.RoleTypeId,
      OrganizationID: props.orgId,
    }));

    if (fileUploadRef.current) {
      fileUploadRef.current.value = '';
    }
    const inputField = document.getElementById('resetUploadedFile');
    if (inputField) {
      inputField.click();
    }
  };
  // Handle next button
  const handleNextButton = () => {
    if (getGoalResourceApiSuccess && getGoalResourceApiData?.TotalCount === 0) {
      toast.toastError(AddResourceError);
    } else {
      props.onApiResponse(2);
    }
  };
  // Search field change event.
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  // Handle Search Click.
  const handleSearchClick = () => {
    setSearchText(searchText);
  };

  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSearchText(event.target.value);
    }
  };
  // Get sub categories based on category
  const handleCategoryChangeFilter = (event: any) => {
    const selectedCategoryId = event.value;
    setSelectedCategoryResourceList(event);
    const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
    if (category) {
      const subcategories = category.Subcategory.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setSubCategoryList(subcategories);
    } else {
      setSubCategoryList([]);
    }
    setApiParams((info: any) => ({
      ...info,
      CategoryId: selectedCategoryId,
      Page: DEFAULT_PAGE_INDEX,
    }));
  };
  // Sub Category change handler.
  const handleSubCategoryChangeFilter = (event: any) => {
    if (event) {
      const subCategoryIds = event.map((subcategory: any) => subcategory.value);
      setSelectedSubCategoryResourceList(event);
      setApiParams((info: any) => ({
        ...info,
        Filter: [
          {
            OrganizationID: props.orgId,
            SubCategoryIDS: subCategoryIds,
          },
        ],
        Page: DEFAULT_PAGE_INDEX,
      }));
    }
  };
  // Handle checkbox selection for link resource
  const toggleSelected = (key: number) => {
    const selectedIndex = selectedItems.indexOf(key);
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, key]);
    } else {
      const updatedSelection = selectedItems.filter((item) => item !== key);
      setSelectedItems(updatedSelection);
    }
  };

  // Api call to link resource report
  const handleLinkResource = (request: ILinkResource) => {
    dispatch(linkResource(request));
  };
  // Reset filter form
  const clearResourceDataFilter = () => {
    setOpenFilter(false);
    setHasMore(true);
    setApiParams(defaultListApiParams);
    setSearchText('');
    setSelectedCategoryResourceList([]);
    setSelectedSubCategoryResourceList([]);
    setSelectedItems([]);
    setItems([]);
    dispatch(resetMotivationalViewAll());
  };
  // Api call to reset goal details
  const resetGoalDetails = () => {
    if (getGoalDetailApiSuccess && getGoalDetailAPiData) {
      if (props.activeGoalID) {
        if (getGoalDetailAPiData?.CategoryName != null && getGoalDetailAPiData?.CategoryId != null) {
          setSelectedCategory([{ label: getGoalDetailAPiData?.CategoryName, value: getGoalDetailAPiData?.CategoryId }]);
          setCategoryList([{ label: getGoalDetailAPiData?.CategoryName, value: getGoalDetailAPiData?.CategoryId }]);
        } else {
          setSelectedCategory([]);
          setCategoryList([]);
        }
        const subcategoriesForSelection = getGoalDetailAPiData?.Class?.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSelectedSubCategory(subcategoriesForSelection);
        setSubCategoryList(subcategoriesForSelection);
        const subcategories = getGoalDetailAPiData?.Class?.map((subcategory: any) => subcategory.classid);
        setMotivationalResourceForm((resourceForm) => ({
          ...resourceForm,
          CategoryID: getGoalDetailAPiData?.CategoryId,
          ClassID: subcategories,
          IsGeneral: getGoalDetailAPiData?.IsGeneral,
          GoalID: props.activeGoalID,
        }));
      }
    }
  };
  // Fetching next page items
  const fetchMoreData = () => {
    if (motivationalViewAllApiLoading) return;
    setApiParams((prevParams: any) => ({
      ...prevParams,
      Page: prevParams.Page + 1,
    }));
  };

  return (
    <>
      <div className="mb-5">
        <div className="text-center" ref={containerRef}>
          <h3>
            <FormattedMessage id="Hd.AddMotivationalResources" />
          </h3>
          <p>
            <FormattedMessage id="SubHd.AddResources" />
          </p>
        </div>
        <div className="d-flex justify-content-center my-5">
          <Col xl={4}>
            <Row>
              <Col className="resource-button">
                <div className="custom-berger-menu">
                  <Filter
                    width={450}
                    right
                    isOpen={openFilter}
                    onOpen={() => {
                      setOpenFilter(true);
                      resetGoalDetails();
                    }}
                    onClose={() => clearResourceDataFilter()}
                    pageWrapId={'filter-wrapper'}
                    outerContainerId={'outer-container'}
                    customCrossIcon={<img src={Close} />}
                    customBurgerIcon={
                      <Button variant="secondary" className="btn-icon-top w-100">
                        <span>
                          <LinkIcon />
                        </span>
                        <FormattedMessage id="Button.LinkResource" />
                      </Button>
                    }
                  >
                    <div id="filter-wrapper" className="filter-main">
                      <h4>
                        <FormattedMessage id="Button.MotivationalResources" />
                      </h4>
                      {getGoalDetailAPiData?.IsGeneral === true ? (
                        <Col className="mt-4 form-sm">
                          <Search
                            value={searchText}
                            onChange={handleSearchChange}
                            onKeyDown={handleSearchKeyDown}
                            onClick={handleSearchClick}
                            placeholder={MotivationalResourcesText}
                            size="sm"
                          />
                        </Col>
                      ) : (
                        <div className="d-flex">
                          <Col className="pe-2 form-sm">
                            <Select
                              label={CategoryText}
                              options={categoryList}
                              value={selectedCategoryResourceList}
                              placeholder={SelectText}
                              onChange={(e: any) => handleCategoryChangeFilter(e)}
                            />
                          </Col>
                          <Col className="ps-2 form-sm">
                            <Select
                              label={SubCategoryText}
                              options={selectedSubCategory}
                              value={selectedSubCategoryResourceList}
                              placeholder={SelectText}
                              onChange={(e: any) => handleSubCategoryChangeFilter(e)}
                              isMulti
                            />
                          </Col>
                        </div>
                      )}
                      <InfiniteScroll
                        dataLength={items?.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={items?.length > 0 && <h4>{LoadingText}</h4>}
                        height={400}
                        endMessage={<EndMessage />}
                      >
                        {items &&
                          items?.length > 0 &&
                          items.map((resource: any) => {
                            const isSelected =
                              getGoalResourceApiData && getGoalResourceApiData.Resources && getGoalResourceApiData.Resources.some((gr: any) => gr.ResourceID === resource.Key);

                            return (
                              <div className="file-listing mb-1" key={resource.Key}>
                                <AttachmentCard
                                  File={resource?.File}
                                  filename={resource?.FileName}
                                  category={resource?.CategoryName}
                                  selected={isSelected || selectedItems.includes(resource?.Key)}
                                  handleRowClick={isSelected ? undefined : () => toggleSelected(resource?.Key)}
                                />
                              </div>
                            );
                          })}
                      </InfiniteScroll>
                      <Row className="mt-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearResourceDataFilter}>
                            <FormattedMessage id="Button.Cancel" />
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => {
                              const selectedKeys: string[] = selectedItems.map((item) => String(item));
                              handleLinkResource({
                                GoalID: props.activeGoalID,
                                IncentiveContents: selectedKeys,
                                TypeID: ResourcetypeID.MOTIVATIONAL_RESOURCE,
                              });
                            }}
                            disabled={items?.length === 0}
                          >
                            <FormattedMessage id="Button.Select" />
                            {selectedItems?.length > 0 && ` (${selectedItems?.length})`}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </div>
              </Col>
              <Col className="resource-button">
                <div className="custom-berger-menu">
                  <Filter
                    width={450}
                    right
                    isOpen={open}
                    onOpen={() => {
                      setOpen(true);
                      resetGoalDetails();
                    }}
                    onClose={() => clearResourceData()}
                    pageWrapId={'filter-wrapper'}
                    outerContainerId={'outer-container'}
                    customCrossIcon={<img src={Close} />}
                    customBurgerIcon={
                      <Button variant="secondary" className="btn-icon-top w-100" onClick={() => setOpen(true)}>
                        <span>
                          <AddIcon />
                        </span>
                        <FormattedMessage id="Button.AddNew" />
                      </Button>
                    }
                  >
                    <div id="filter-wrapper" className="filter-main">
                      <Scrollbars className="h-80vh">
                        <h4>
                          <FormattedMessage id="HD.AddMotivationalResources" />
                        </h4>
                        <p className="text-secondary">
                          <FormattedMessage id="SubHd.MotivationalResources" />
                        </p>
                        <Col className="form-sm">
                          <Select
                            label={useIntlActionMessages('Label.SelectFileType')}
                            options={fileType}
                            value={fileTypeValue}
                            placeholder={useIntlMessages('PH.Select')}
                            onChange={(e: any) => handlefileTypeChange(e)}
                          />
                        </Col>
                        <div className="justify-content-centers">
                          <div className="addMotiv-res">
                            <div className="fileupload-sec  mb-2">
                              <input
                                ref={fileUploadRef}
                                type="file"
                                name="file-3[]"
                                id="file-3"
                                className="inputfile inputfile-3"
                                data-multiple-caption="{count} files selected"
                                multiple={false}
                                accept="image/*"
                                onChange={(e: any) => uploadResourceFile(e)}
                              />
                              <label htmlFor="file-3">
                                <img src={plusicon} alt="" />
                                <span>{useIntlActionMessages('Button.AddFile')}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="error text-center pt-2" ref={imageRef}>
                          {' '}
                          {fileError}
                        </div>
                        <div className="upload-info">
                          <UploadNote
                            fileType={Object.entries(FILE_TYPES_SUPPORTED)
                              .map(([key, value]) => `${value.map((ext) => `.${ext}`).join(', ')}`)
                              .join(', ')}
                            // fileSize="250 MB"
                          />
                        </div>
                        {uploadedFileInfo?.length > 0 && (
                          <h6>
                            <FormattedMessage id="Label.Attachments" /> ({uploadedFileInfo?.length})
                          </h6>
                        )}
                        {uploadedFileInfo.map((filedetails: any, index: number) => (
                          <div className="attachment-list mb-1" key={index}>
                            <div className="att-list-b">
                              {filedetails?.type && FILE_TYPES_SUPPORTED.image.includes(filedetails.type.toLowerCase()) && (
                                <AttachmentCard
                                  fileType={<Image />}
                                  filename={filedetails.displayName}
                                  category={filedetails.size}
                                  deleteOption
                                  onDelete={() => deleteAttachment(index)}
                                />
                              )}
                              {filedetails?.type && FILE_TYPES_SUPPORTED.audio.includes(filedetails.type.toLowerCase()) && (
                                <AttachmentCard
                                  fileType={<Audio />}
                                  filename={filedetails.displayName}
                                  category={filedetails.size}
                                  deleteOption
                                  onDelete={() => deleteAttachment(index)}
                                />
                              )}
                              {filedetails?.type && FILE_TYPES_SUPPORTED.video.includes(filedetails.type.toLowerCase()) && (
                                <AttachmentCard
                                  fileType={<Video />}
                                  filename={filedetails.displayName}
                                  category={filedetails.size}
                                  deleteOption
                                  onDelete={() => deleteAttachment(index)}
                                />
                              )}
                              {filedetails?.type && FILE_TYPES_SUPPORTED.document.includes(filedetails.type.toLowerCase()) && (
                                <AttachmentCard
                                  fileType={<Doc />}
                                  filename={filedetails.displayName}
                                  category={filedetails.size}
                                  deleteOption
                                  onDelete={() => deleteAttachment(index)}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                        <div className="cover-photo mt-4 mb-3">
                          <Col xl="auto">
                            <ImageUpload
                              setError={(error: string) => setImgError(error)}
                              imageFile={''}
                              setPicture={setPicture}
                              maxSize={Number(UploaderProps.IMAGE_MAX_SIZE)}
                              acceptedFileTypes={IMAGE_TYPES_SUPPORTED}
                              invalidSizeMessage={useIntlActionMessages('Image.Upload.Size.Message')}
                              invalidTypeMessage={useIntlActionMessages('Image.Upload.AcceptedType.Error')}
                              mode="addresource"
                            />
                            <div className="error  text-center pt-2"> {imgError}</div>
                          </Col>
                        </div>
                        <div className="upload-info">
                          <UploadNote fileSize="50 MB" />
                        </div>
                        <Col className="form-sm">
                          <Input
                            ref={resourceInputRef}
                            label={useIntlActionMessages('Label.NameofResource')}
                            id="ResourceName"
                            name="ResourceName"
                            type="text"
                            placeholder={useIntlActionMessages('PH.MotivationalResource')}
                            maxLength={NumberValues.NUM_200}
                            onChange={onInputHandleChange}
                            errorMessage={errorFields?.ResourceName}
                            value={motivationalResourceForm.ResourceName}
                          />
                        </Col>
                        <Col className="form-sm position-relative ">
                          <span className="txt-count">
                            {characterCount}/{NumberValues.NUM_1000}
                          </span>
                          <Input
                            label={useIntlActionMessages('Label.Description')}
                            id="Description"
                            name="Description"
                            type="textarea"
                            placeholder={useIntlActionMessages('Label.ResourceDescription')}
                            maxLength={NumberValues.NUM_1000}
                            as="textarea"
                            onChange={onInputHandleChange}
                            rows={3}
                            errorMessage={errorFields?.Description}
                            value={motivationalResourceForm.Description}
                          />
                        </Col>
                        {getGoalDetailAPiData?.IsGeneral === true ? (
                          <div className="check-sm d-flex flex-row-reverse">
                            <Checkbox type="Checkbox" label={generalText} checked={true} disabled={true} />
                          </div>
                        ) : (
                          <>
                            <Col className="form-sm">
                              <Select
                                label={CategoryText}
                                id="CategoryID"
                                name="CategoryID"
                                options={categoryList}
                                value={selectedCategory}
                                placeholder={SelectText}
                                error={errorFields?.CategoryID}
                                isDisabled={true}
                              />
                            </Col>
                            <Col className="form-sm">
                              <Select
                                label={SubCategoryText}
                                id="ClassID"
                                name="ClassID"
                                options={subCategoryList}
                                value={selectedSubCategory}
                                placeholder={SelectText}
                                onChange={(e: any) => handleSubCategoryChange(e)}
                                error={errorFields?.ClassID}
                                isDisabled={selectedSubCategory?.length === 1}
                                isMulti
                              />
                            </Col>
                          </>
                        )}
                      </Scrollbars>
                      <Row className="mt-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearResourceData}>
                            <FormattedMessage id="Button.Cancel" />
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            className="w-100"
                            variant="primary"
                            onClick={onSubmit}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                onSubmit();
                              }
                            }}
                          >
                            <FormattedMessage id="Button.Save" />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
        {getGoalResourceApiData?.Resources?.length > 0 && (
          <h6 className="text-center">
            <FormattedMessage id="Label.Attachments" /> ({getGoalResourceApiData?.Resources ? getGoalResourceApiData?.Resources?.length : 0})
          </h6>
        )}
        <div className="attachment-list d-flex justify-content-center">
          <Col xl={10}>
            {getGoalResourceApiSuccess &&
              getGoalResourceApiData?.TotalCount > 0 &&
              getGoalResourceApiData?.Resources?.map((filedetails: any) => (
                <Row className="d-flex justify-content-center mb-3" key={filedetails.ResourceID}>
                  <Col xl={6}>
                    <AttachmentCard
                      File={filedetails?.ResourceThumbnail}
                      filename={filedetails.ResourceName}
                      category={filedetails.CategoryName}
                      deleteOption
                      onDelete={() => ShowDeleteConfirmPopup(filedetails.ResourceID)}
                    />
                  </Col>
                </Row>
              ))}
          </Col>
        </div>
        <div className="btn-container d-flex justify-content-end my-4">
          <Button variant="outline-primary" onClick={() => navigate('/activities')}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button variant="primary" onClick={() => handleNextButton()}>
            <FormattedMessage id="Button.Next" />
          </Button>
        </div>
        <DeleteConfirmPopup
          show={isShowDeleteConfirmPopup}
          handleClose={() => setIsShowDeleteConfirmPopup(false)}
          onCancel={() => setIsShowDeleteConfirmPopup(false)}
          onOkay={() => handleDeleteResourcesClick()}
          title={useIntlMessages('Text.DeleteConfirmation.Resource')}
          content={useIntlMessages('Text.DeleteConfirmation.Resource.Desc')}
        />
      </div>
      {(uploadFileApiLoading ||
        fileUploadBegin ||
        addResourceApiLoading ||
        uploadFilePathUpdateApiLoading ||
        uploadFilePathUpdateMultipleApiLoading ||
        deleteGoalResourceApiLoading ||
        getGoalResourceApiLoading ||
        motivationalViewAllApiLoading ||
        startSubmit) && <Loader />}
    </>
  );
};
export default GoalStep2;
