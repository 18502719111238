/**
 * @file   src\containers\organizations\Activities.tsx
 * @brief  Activities listing page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getFromLocalStorage, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { Button, Col, Breadcrumb, Tab, Tabs, Table, ProgressBar, Row } from 'react-bootstrap';
import Search from '../../components/MASearch';
import Pagination from '../../components/Pagination';
import ViewIcon from '../../assets/img/icon/View';
import UserImg from '../../assets/img/Userimg.jpg';
import { slide as Filter } from 'react-burger-menu';
import Select from '../../components/MASelect';
import Close from '../../assets/img/Close.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sort from '../../assets/img/icon/Sort';
import Checkbox from '../../components/MACheck';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getCategoryList, getGoalList } from '../../store/actions/organizationActions';
import { BUCKET_URL, DEFAULT_PAGE_INDEX, DEFAULT_SELECT_OPTION_NUMBER, PAGE_SIZE } from '../../utils/constants';
import { RootState } from '../../store';
import { GoalSortField, SortOrder, SortOrderType, SurveySortField } from '../../utils/enums';
import { IGoal } from '../../interfaces/OrganisationInterface';
import useDebounce from '../../hooks/useDebounce';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import { resetGoalStepOneApiData } from '../../store/slices/organizationSlice';
import Loader from '../../components/Loader';
import Orgimg from '../../assets/img/Org-image.png';
import { getSurveyList } from '../../store/actions/surveyAction';
import { ISurvey } from '../../interfaces/SurveyInterface';
import { resetCreateSurveyApiData } from '../../store/slices/surveySlice';
import { RoleTypeIds } from '../../utils/enums';
import { getCurrentOrgDetails, isAdminTeacherManager } from '../../utils/helper';
import Default from '../../assets/img/default.jpg';
// Values for goal status
const goalStatusList: Array<any> = [
  { label: 'Not Started', value: 'notstarted' },
  { label: 'In progress', value: 'inprogress' },
  { label: 'Completed', value: 'completed' },
];
const statusList: Array<any> = [
  { label: 'Not Started', value: 'NotStarted' },
  { label: 'In progress', value: 'InProgress' },
  { label: 'Completed', value: 'Completed' },
];
const goalListDefaultFilters = {
  IsGeneral: 0,
  Status: '',
  Category: 0,
  SubCategories: [],
};
const surveyListAPIDefaultParams = {
  IsGeneral: 0,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  Search: '',
  SortColumnName: '',
  SortOrder: '',
  Status: '',
  Category: 0,
  SubCategories: [],
  OrganizationID: '',
};
const surveyListDefaultFilters = {
  Status: '',
  Category: 0,
  SubCategories: [],
};
const Activities = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();

  // Access redux state variables.
  const { getGoalListAPiData, getCatergoryListAPiData, getCategoryListApiLoading, getGoalListApiLoading } = useAppSelector((state: RootState) => state.organization);
  const { listSurveyApiData } = useAppSelector((state: RootState) => state.survey);
  const orgDetail = getFromLocalStorage('ORG_DETAIL');
  const goalListAPIDefaultParams = {
    IsGeneral: 0,
    Page: DEFAULT_PAGE_INDEX,
    PageSize: PAGE_SIZE,
    Search: '',
    SortColumnName: '',
    SortOrder: '',
    Status: '',
    Category: 0,
    SubCategories: [],
    OrganizationID: getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : orgDetail?.OrganizationId,
  };

  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [searchText, setSearchText] = useState<string>('');
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [goalListApiParams, setGoalListApiParams] = useState<any>(goalListAPIDefaultParams);
  const [categoryList, setCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsNumber[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [goalListFilters, setGoalListFilters] = useState<any>(goalListDefaultFilters);
  const [isGeneral, setIsGeneral] = useState<number>(0);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [surveyListApiParams, setSurveyListApiParams] = useState<any>(surveyListAPIDefaultParams);
  const [sortFieldSurvey, setSortFieldSurvey] = useState<string>('');
  const [sortOrderSurvey, setSortOrderSurvey] = useState<string>('');
  const [currentPageSurvey, setCurrentPageSurvey] = useState(DEFAULT_PAGE_INDEX);
  const [searchTextSurvey, setSearchTextSurvey] = useState<string>('');
  const [subCategoryListSurvey, setSubCategoryListSurvey] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategorySurvey, setSelectedCategorySurvey] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [selectedSubCategorySurvey, setSelectedSubCategorySurvey] = useState<ISelectOptionsNumber[]>([]);
  const [selectedStatusSurvey, setSelectedStatusSurvey] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [surveyListFilters, setSurveyListFilters] = useState<any>(surveyListDefaultFilters);
  const [isFilterAppliedSurvey, setIsFilterAppliedSurvey] = useState<boolean>(false);
  const [isOpenGoalFilter, setIsOpenGoalFilter] = useState<boolean>(false);
  const [isOpenSurveyFilter, setIsOpenSurveyFilter] = useState<boolean>(false);
  const noData = useIntlActionMessages('Label.Nodata');

  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchText, 500);
  const debouncedSearchSurvey = useDebounce(searchTextSurvey, 500);
  // Call list apis on initial loading.
  useEffect(() => {
    const userData = getFromLocalStorage('MI_USR_DATA');
    if (userData.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN) {
      if (userData.IsPasswordChanged == false) navigate('/changepassword');
      else if (userData.IsProfileCreated == false) navigate('/profilecreate');
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
    dispatch(getCategoryList({ OrganizationId: getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : orgDetail?.OrganizationId }));
    if (location?.state?.tabId == 2) {
      setSurveyListApiParams((apiParams: any) => ({
        ...apiParams,
        OrganizationID: getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : orgDetail?.OrganizationId,
      }));
    } else {
      dispatch(getGoalList(goalListAPIDefaultParams));
    }
  }, []);

  // Get goal list on filter/search/sort/pagination change.
  useEffect(() => {
    dispatch(getGoalList(goalListApiParams));
  }, [goalListApiParams]);

  // Get survey list on filter/search/sort/pagination change.
  useEffect(() => {
    if (surveyListApiParams.OrganizationID != '') dispatch(getSurveyList(surveyListApiParams));
  }, [surveyListApiParams]);

  // Set API params on bebounced search goal list.
  useEffect(() => {
    setGoalListApiParams((apiParams: any) => ({
      ...apiParams,
      Search: searchText,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
  }, [debouncedSearch]);

  // Set API params on bebounced search survey list.
  useEffect(() => {
    setSurveyListApiParams((apiParams: any) => ({
      ...apiParams,
      Search: searchTextSurvey,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPageSurvey(DEFAULT_PAGE_INDEX);
  }, [debouncedSearchSurvey]);

  // Set API params on filter change goal list.
  useEffect(() => {
    setGoalListApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
      SortColumnName: sortField,
      SortOrder: sortOrder,
    }));
  }, [currentPage, sortField, sortOrder]);

  // Set API params on filter change survey list.
  useEffect(() => {
    setSurveyListApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPageSurvey,
      SortColumnName: sortFieldSurvey,
      SortOrder: sortOrderSurvey,
    }));
  }, [currentPageSurvey, sortFieldSurvey, sortOrderSurvey]);

  // Get category details by organization
  useEffect(() => {
    if (getCatergoryListAPiData && getCatergoryListAPiData.length > 0) {
      const categoryData = getCatergoryListAPiData.map(
        (category: any): ISelectOptionsNumber => ({
          label: category.CategoryName,
          value: +category.CategoryId,
        }),
      );
      setCategoryList(categoryData);
    } else {
      // If ResponseData is null, update category state with null
      setCategoryList([]);
    }
  }, [getCategoryListApiLoading]);

  // Sort header change event.
  const changeSortField = (field: string) => {
    let newSortOrder = SortOrderType.ASC;
    if (field === sortField) {
      newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
    }
    setSortField(field);
    setSortOrder(newSortOrder);
  };
  // Search field change event.
  const handleSearchChange = (event: any) => {
    setSearchText(event.target.value);
  };

  // Handle Search Click.
  const handleSearchClick = () => {
    setGoalListApiParams((apiParams: any) => ({
      ...apiParams,
      Search: searchText,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
  };

  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setGoalListApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPage(DEFAULT_PAGE_INDEX);
    } else {
      setSearchText(event.target.value);
    }
  };
  // Get sub categories based on category
  const handleCategoryChange = (event: any) => {
    const selectedCategoryId = event.value;
    setSelectedCategory(event);
    const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
    if (category) {
      const subcategories = category.Subcategory.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setSubCategoryList(subcategories);
      setSelectedSubCategory([]);
    } else {
      setSubCategoryList([]);
    }
    setGoalListFilters((info: any) => ({
      ...info,
      Category: selectedCategoryId,
    }));
  };
  const handleSubCategoryChange = (event: any) => {
    if (event) {
      const subcategories = event.map((subcategory: any) => subcategory.value);
      setSelectedSubCategory(event);
      setGoalListFilters((info: any) => ({
        ...info,
        SubCategories: subcategories,
      }));
    }
  };
  const handleStatusChange = (event: any) => {
    setSelectedStatus(event);
    setGoalListFilters((info: any) => ({
      ...info,
      Status: event.value,
    }));
  };
  // Handle General checkbox click
  const handleGeneralClick = (event: any) => {
    let isGeneralTemp = 0;
    if (event.target.checked) {
      isGeneralTemp = 1;
    }
    setIsGeneral(isGeneralTemp);
    setGoalListFilters((info: any) => ({
      ...info,
      IsGeneral: isGeneralTemp,
    }));
  };
  // handle apply filter
  const handleFilterChange = () => {
    setGoalListApiParams((apiParams: any) => ({
      ...apiParams,
      Page: DEFAULT_PAGE_INDEX,
      IsGeneral: goalListFilters.IsGeneral,
      Status: goalListFilters.Status,
      Category: goalListFilters.Category,
      SubCategories: goalListFilters.SubCategories,
    }));
    setCurrentPage(DEFAULT_PAGE_INDEX);
    setIsFilterApplied(true);
    setIsOpenGoalFilter(false);
  };

  // handle reset filter
  const handleFilterReset = () => {
    setGoalListFilters((info: any) => ({
      ...info,
      Status: '',
      Category: 0,
      SubCategories: [],
      Page: DEFAULT_PAGE_INDEX,
      IsGeneral: 0,
    }));
    setGoalListApiParams((apiParams: any) => ({
      ...apiParams,
      Status: '',
      Category: 0,
      SubCategories: [],
      Page: DEFAULT_PAGE_INDEX,
      IsGeneral: 0,
    }));
    setIsFilterApplied(false);
    setSelectedCategory(DEFAULT_SELECT_OPTION_NUMBER);
    setSelectedSubCategory([]);
    setSelectedStatus(DEFAULT_SELECT_OPTION_NUMBER);
    setIsGeneral(0);
    setIsOpenGoalFilter(false);
  };

  const handleAssignGoalClick = () => {
    dispatch(resetGoalStepOneApiData());
    navigate('/assigngoal', { state: { organizationId: orgDetail?.OrganizationId } });
  };

  // Reset values when tabs change
  const handleTabChange = (key: string) => {
    if (key == 'Surveys') {
      setSurveyListApiParams((apiParams: any) => ({
        ...apiParams,
        OrganizationID: orgDetail?.OrganizationId,
      }));
      // dispatch(getSurveyList(surveyListApiParams));
    } else if (key == 'Goals') {
      dispatch(getGoalList(goalListApiParams));
    }
  };
  // survey section
  // survey Sort header change event.
  const changeSortFieldSurvey = (field: string) => {
    let newSortOrder = SortOrder.ASC;
    if (field === sortFieldSurvey) {
      newSortOrder = sortOrderSurvey === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    setSortFieldSurvey(field);
    setSortOrderSurvey(newSortOrder);
  };
  // Search field change event survey.
  const handleSearchChangeSurvey = (event: any) => {
    setSearchTextSurvey(event.target.value);
  };

  // Handle Search Click survey.
  const handleSearchClickSurvey = () => {
    setSurveyListApiParams((apiParams: any) => ({
      ...apiParams,
      Search: searchTextSurvey,
      Page: DEFAULT_PAGE_INDEX,
    }));
    setCurrentPageSurvey(DEFAULT_PAGE_INDEX);
  };

  // Handle Search Field input key down survey.
  const handleSearchKeyDownSurvey = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setSurveyListApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchTextSurvey,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPageSurvey(DEFAULT_PAGE_INDEX);
    } else {
      setSearchTextSurvey(event.target.value);
    }
  };
  // Handle category change survey
  const handleCategoryChangeSurvey = (event: any) => {
    const selectedCategoryId = event.value;
    setSelectedCategorySurvey(event);
    const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
    if (category) {
      const subcategories = category.Subcategory.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setSubCategoryListSurvey(subcategories);
      setSelectedSubCategorySurvey([]);
    } else {
      setSubCategoryListSurvey([]);
    }
    setSurveyListFilters((info: any) => ({
      ...info,
      Category: selectedCategoryId,
      SubCategories: [],
    }));
  };
  const handleSubCategoryChangeSurvey = (event: any) => {
    if (event) {
      const subcategories = event.map((subcategory: any) => subcategory.value);
      setSelectedSubCategorySurvey(event);
      setSurveyListFilters((info: any) => ({
        ...info,
        SubCategories: subcategories,
      }));
    }
  };
  const handleStatusChangeSurvey = (event: any) => {
    setSelectedStatusSurvey(event);
    setSurveyListFilters((info: any) => ({
      ...info,
      Status: event.value,
    }));
  };
  // handle apply filter
  const handleApplyFilterSurvey = () => {
    setSurveyListApiParams((apiParams: any) => ({
      ...apiParams,
      Page: DEFAULT_PAGE_INDEX,
      Status: surveyListFilters.Status,
      Category: surveyListFilters.Category,
      SubCategories: surveyListFilters.SubCategories,
    }));
    setCurrentPageSurvey(DEFAULT_PAGE_INDEX);
    setIsFilterAppliedSurvey(true);
    setIsOpenSurveyFilter(false);
  };

  // handle reset filter
  const handleFilterResetSurvey = () => {
    setSurveyListFilters((info: any) => ({
      ...info,
      Status: '',
      Category: 0,
      SubCategories: [],
      Page: DEFAULT_PAGE_INDEX,
    }));
    setSurveyListApiParams((apiParams: any) => ({
      ...apiParams,
      Status: '',
      Category: 0,
      SubCategories: [],
      Page: DEFAULT_PAGE_INDEX,
    }));
    setIsFilterAppliedSurvey(false);
    setSelectedCategorySurvey(DEFAULT_SELECT_OPTION_NUMBER);
    setSelectedSubCategorySurvey([]);
    setSelectedStatusSurvey(DEFAULT_SELECT_OPTION_NUMBER);
    setIsOpenSurveyFilter(false);
  };
  const handleAssignSurveyClick = () => {
    dispatch(resetCreateSurveyApiData());
    navigate('/assignsurvey');
  };

  // Handle Goal Filter click.
  const handleGoalFilterClick = () => {
    setIsOpenGoalFilter(true);
  };
  // Handle Goal Filter close.
  const handleGoalFilterClose = () => {
    setIsOpenGoalFilter(false);
  };
  // Handle Survey Filter click.
  const handleSurveyFilterClick = () => {
    setIsOpenSurveyFilter(true);
  };
  // Handle Survey Filter close.
  const handleSurveyFilterClose = () => {
    setIsOpenSurveyFilter(false);
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.Activities" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.ManageOrganizations" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/vieworganization', { state: { organizationId: orgDetail?.OrganizationId } })}>
              <FormattedMessage id="Hd.ViewOrganization" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Hd.Activities" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="mb-4">
        {isAdminTeacherManager() ? null : (
          <div className="org-selected">
            <img className="thumbnail-image" src={orgDetail?.ImageURL ? BUCKET_URL + orgDetail?.ImageURL : Default} alt="user pic" />
            <div>
              <h6 className="mb-1">{orgDetail?.Name}</h6>
              <small>{orgDetail?.Address}</small>
            </div>
          </div>
        )}
      </div>
      <div className="content-sub">
        <div className="content-sub-header header-light-bg d-flex justify-content-end align-items-center">
          <Col md="auto" className="btn-container">
            <Button variant="primary" size="sm" onClick={() => handleAssignGoalClick()}>
              <FormattedMessage id="Button.AssignGoal" />
            </Button>
            <Button variant="primary" size="sm" onClick={() => handleAssignSurveyClick()}>
              <FormattedMessage id="Button.AssignSurvey" />
            </Button>
          </Col>
        </div>
        <div className="content-area-padding">
          <Tabs
            defaultActiveKey={location?.state?.tabId == 2 ? 'Surveys' : 'Goals'}
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            onSelect={(key: any) => handleTabChange(key)}
          >
            <Tab eventKey="Goals" title={useIntlMessages('Label.Goals')}>
              <div className="d-flex justify-content-end mb-3">
                <Col lg={3} className="form-sm">
                  <Search onChange={handleSearchChange} onKeyDown={handleSearchKeyDown} onClick={handleSearchClick} placeholder={useIntlMessages('PH.SearchGoal')} size="sm" />
                </Col>
                <Filter
                  onOpen={handleGoalFilterClick}
                  onClose={handleGoalFilterClose}
                  isOpen={isOpenGoalFilter}
                  width={450}
                  right
                  pageWrapId={'filter-wrapper'}
                  outerContainerId={'outer-container'}
                  customCrossIcon={<img src={Close} />}
                  burgerButtonClassName={isFilterApplied ? 'active' : ''}
                >
                  <div id="filter-wrapper" className="filter-main">
                    <h4 className="mb-5">Filter</h4>
                    <Col className="form-sm">
                      <Select
                        label="Todo Status"
                        options={goalStatusList}
                        value={selectedStatus}
                        placeholder="Select"
                        onChange={(e: any) => handleStatusChange(e)}
                        // error="Error"
                      />
                      <Col className="mb-3">
                        <Checkbox checked={isGeneral} type="Checkbox" label="General goals" onChange={handleGeneralClick} />
                      </Col>
                      <Row>
                        <Col>
                          <Select label="Category" options={categoryList} value={selectedCategory} placeholder="Select" onChange={(e: any) => handleCategoryChange(e)} />
                        </Col>
                        <Col>
                          <Select
                            label="Sub Category"
                            id="ClassID"
                            name="ClassID"
                            options={subCategoryList}
                            value={selectedSubCategory}
                            placeholder="Select"
                            onChange={(e: any) => handleSubCategoryChange(e)}
                            isMulti
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Row className="mt-4">
                      <Col>
                        <Button variant="outline-primary" className="w-100" onClick={handleFilterReset}>
                          Clear Filters
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="primary" className="w-100" onClick={handleFilterChange}>
                          Apply Filters
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Filter>
              </div>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="Label.GoalType" />
                      <Link to="" className="ms-1" onClick={() => changeSortField(GoalSortField.GOAL_TYPE)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.GoalName" />
                      <Link to="" className="ms-1" onClick={() => changeSortField(GoalSortField.GOAL_NAME)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.Category" />
                      <Link to="" className="ms-1" onClick={() => changeSortField(GoalSortField.CATEGORY)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.SubCategory" />
                      <Link to="" className="ms-1"></Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.CreatedBy" />
                      <Link to="" className="ms-1" onClick={() => changeSortField(GoalSortField.CREATED_BY)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.Status" />
                      <Link to="" className="ms-1"></Link>
                    </th>
                    <th className="w-50px"></th>
                  </tr>
                </thead>
                <tbody>
                  {getGoalListAPiData &&
                    getGoalListAPiData.Goals &&
                    getGoalListAPiData.Goals.length > 0 &&
                    getGoalListAPiData.Goals.map((goal: IGoal) => (
                      <tr key={goal.GoalID}>
                        <td>{goal.GoalType}</td>
                        <td>
                          {goal.GoalName}
                          {goal.IsGeneral == 1 && <span className="general-tag">General</span>}
                        </td>
                        <td>{goal.CategoryName}</td>
                        <td>
                          {goal.SubCategoryName?.map((subCategory: any, index: number) => (
                            <div key={index}>
                              {subCategory}
                              {index !== goal.SubCategoryName?.length - 1 && ', '}
                            </div>
                          ))}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="td-user-image">
                              <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            <label>
                              {goal.CreatedBy}
                              <span className="d-block font-xs">{goal.CreatedUserRole}</span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center multiple-user">
                            <ProgressBar now={goal.CompletionPercentage} />{' '}
                            <span className="ps-2">
                              <label className="td-progressbar">
                                {goal.CompletionPercentage}% <span className="inprogress">{goal.Status}</span>
                              </label>
                            </span>
                          </div>
                        </td>
                        <td>
                          <Button variant="secondary" size="sm" className="icon-btn" onClick={() => navigate(`/goaldetail/${goal.GoalID}`)}>
                            <ViewIcon />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  {!getGoalListAPiData ||
                    (getGoalListAPiData && !getGoalListAPiData.Goals && (
                      <tr>
                        <td colSpan={8}>
                          <div className="content-sub content-area-padding border-top text-center">
                            <h6>{noData}</h6>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {getGoalListAPiData && getGoalListAPiData.Goals && getGoalListAPiData.Goals.length > 0 && getGoalListAPiData.TotalCount > PAGE_SIZE && (
                <Pagination
                  pageSize={PAGE_SIZE}
                  totalitems={getGoalListAPiData.TotalCount}
                  pageLimit={PAGE_SIZE}
                  setCurrentPage={(page: number) => setCurrentPage(page)}
                  currentPage={currentPage - 1}
                  prevPage={-1}
                  itemsDisplayed={getGoalListAPiData?.Goals?.length}
                />
              )}
            </Tab>
            <Tab eventKey="Surveys" title={useIntlMessages('Label.Surveys')}>
              <div className="d-flex justify-content-end mb-3">
                <Col lg={3} className="form-sm">
                  <Search
                    onChange={handleSearchChangeSurvey}
                    onKeyDown={handleSearchKeyDownSurvey}
                    onClick={handleSearchClickSurvey}
                    placeholder={useIntlMessages('PH.SearchSurvey')}
                    size="sm"
                  />
                </Col>
                <Filter
                  onOpen={handleSurveyFilterClick}
                  onClose={handleSurveyFilterClose}
                  isOpen={isOpenSurveyFilter}
                  width={450}
                  right
                  pageWrapId={'filter-wrapper'}
                  outerContainerId={'outer-container'}
                  customCrossIcon={<img src={Close} />}
                  burgerButtonClassName={isFilterAppliedSurvey ? 'active' : ''}
                >
                  <div id="filter-wrapper" className="filter-main">
                    <h4 className="mb-5">Filter</h4>
                    <Col className="form-sm">
                      <Select label="Todo Status" options={statusList} value={selectedStatusSurvey} placeholder="Select" onChange={(e: any) => handleStatusChangeSurvey(e)} />
                      <Row>
                        <Col>
                          <Select
                            label="Category"
                            options={categoryList}
                            value={selectedCategorySurvey}
                            placeholder="Select"
                            onChange={(e: any) => handleCategoryChangeSurvey(e)}
                          />
                        </Col>
                        <Col>
                          <Select
                            label="Sub Category"
                            options={subCategoryListSurvey}
                            value={selectedSubCategorySurvey}
                            placeholder="Select"
                            onChange={(e: any) => handleSubCategoryChangeSurvey(e)}
                            isMulti
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Row className="mt-4">
                      <Col>
                        <Button variant="outline-primary" className="w-100" onClick={handleFilterResetSurvey}>
                          Clear Filters
                        </Button>
                      </Col>
                      <Col>
                        <Button variant="primary" className="w-100" onClick={handleApplyFilterSurvey}>
                          Apply Filters
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Filter>
              </div>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th className="fix-width">
                      <FormattedMessage id="Label.SurveyName" />
                      <Link to="" className="ms-1" onClick={() => changeSortFieldSurvey(SurveySortField.SURVEY_NAME)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.Category" />
                      <Link to="" className="ms-1" onClick={() => changeSortFieldSurvey(SurveySortField.CATEGORY)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.SubCategories" />
                      <Link to="" className="ms-1"></Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.CreatedBy" />
                      <Link to="" className="ms-1" onClick={() => changeSortFieldSurvey(SurveySortField.CREATED_BY)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.Status" />
                      <Link to="" className="ms-1"></Link>
                    </th>
                    <th className="w-50px"></th>
                  </tr>
                </thead>
                <tbody>
                  {listSurveyApiData &&
                    listSurveyApiData.SurveyList &&
                    listSurveyApiData.SurveyList.length > 0 &&
                    listSurveyApiData.SurveyList.map((survey: ISurvey) => (
                      <tr>
                        <td className="fix-width">{survey.SurveyName}</td>
                        <td>{survey.Category}</td>
                        <td>
                          {survey.SubCategory?.map((subCategory: any, index: number) => (
                            <>
                              {subCategory}
                              {index !== survey.SubCategory?.length - 1 && ', '}
                            </>
                          ))}
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="td-user-image">
                              <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            <label>
                              {survey.CreatedBy}
                              <span className="d-block font-xs">{survey.CreatedUserRole}</span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center multiple-user">
                            <ProgressBar now={survey.CompletetionPercentage} />{' '}
                            <span className="ps-2">
                              <label className="td-progressbar">
                                {survey.CompletetionPercentage}% <span className="inprogress">{survey.Status}</span>
                              </label>
                            </span>
                          </div>
                        </td>
                        <td>
                          <Button variant="secondary" size="sm" className="icon-btn" onClick={() => navigate(`/surveydetails/${survey.SurveyId}`)}>
                            <ViewIcon />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  {!listSurveyApiData ||
                    (listSurveyApiData && !listSurveyApiData.SurveyList && (
                      <tr>
                        <td colSpan={8}>
                          <div className="content-sub content-area-padding border-top text-center">
                            <h6>{noData}</h6>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {listSurveyApiData && listSurveyApiData.SurveyList && listSurveyApiData.SurveyList.length > 0 && listSurveyApiData.TotalCount > PAGE_SIZE && (
                <Pagination
                  pageSize={PAGE_SIZE}
                  totalitems={listSurveyApiData.TotalCount}
                  pageLimit={PAGE_SIZE}
                  setCurrentPage={(page: number) => setCurrentPageSurvey(page)}
                  currentPage={currentPage - 1}
                  prevPage={-1}
                  itemsDisplayed={listSurveyApiData?.SurveyList?.length}
                />
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
      {getGoalListApiLoading && <Loader />}
    </>
  );
};
export default Activities;
