/**
 * @file   src\containers\subscriptions\Plans.tsx
 * @brief  Subscription plans list page.
 * @date    July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useState, useEffect } from 'react';
import '../../assets/css/Home.scss';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useIntlActionMessages } from '../../utils/helper';
import SubscriptionCard from '../../components/SubscriptionCard';
import { PAGE_SIZE_12, DEFAULT_PAGE_INDEX } from '../../utils/constants';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import { getSubscriptionPlanList } from '../../store/actions/subscriptionPlanActions';
// Declare default params  for listing
const defaultApiParams = {
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_12,
};
const SubscriptionPlans = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();

  // Access redux state variables.
  const { subscriptionPlanListData, subscriptionPlanListApiLoading, subscriptionPlanListApiSuccess } = useAppSelector((state: RootState) => state.subscriptionPlan);

  // Initialize component state variables.
  const NodataText = useIntlActionMessages('Label.Nodata');
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [apiParams, setApiParams] = useState<any>(defaultApiParams);

  // Works on initial rendering
  useEffect(() => {
    dispatch(getSubscriptionPlanList(apiParams));
  }, [apiParams]);
  // Handle pagination
  useEffect(() => {
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
    }));
  }, [currentPage]);
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.ManageSubscriptionPlans" />
        </h3>
        <Button variant="primary" size="sm" onClick={() => navigate('/addsubscriptionplan')}>
          <FormattedMessage id="Hd.AddPlan" />
        </Button>
      </div>
      <div className="content-sub">
        <div className="content-area-padding">
          <Row>
            {subscriptionPlanListApiSuccess && subscriptionPlanListData?.Plans?.length > 0 ? (
              subscriptionPlanListData.Plans.map((plan: any, index: any) => (
                <Col className="mb-3" xl={3} lg={4} md={6} key={index}>
                  <SubscriptionCard
                    price={plan?.Amount}
                    duration={plan?.Duration}
                    name={plan?.PlanName}
                    websiteuser={plan?.WebUserCount}
                    mobileuser={plan?.APPUserCount}
                    limited={plan?.Visibility}
                    PlanId={plan.PlanId}
                  />
                </Col>
              ))
            ) : (
              <div className="content-sub content-area-padding border-top text-center p-4">
                <h6>{NodataText}</h6>
              </div>
            )}
          </Row>
        </div>
      </div>
      {subscriptionPlanListApiSuccess && subscriptionPlanListData?.Plans?.length > 0 && subscriptionPlanListData.TotalCount > PAGE_SIZE_12 && (
        <Pagination
          pageSize={PAGE_SIZE_12}
          totalitems={subscriptionPlanListData?.TotalCount}
          pageLimit={PAGE_SIZE_12}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          currentPage={currentPage - 1}
          prevPage={-1}
          itemsDisplayed={subscriptionPlanListData?.Plans?.length}
        />
      )}
      {subscriptionPlanListApiLoading && <Loader />}
    </>
  );
};
export default SubscriptionPlans;
