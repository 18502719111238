/**
 * @file   src\store\actions\CompetencyActions.ts
 * @brief  This file is responsible for creating asynchronous resorces based api call.
 * @date   FEB, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { CompetencyApis } from '../../utils/apiUrls';
import { ICompetencyForm,ICompetencyApiParams,ITopCompetencyApiParams,IDeleteCompetencyApiParams } from '../../interfaces/CompetencyInterface';

// Api call to add  Competency
export const addCompetency = createAsyncThunk('/addcompetencies', async (request: ICompetencyForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CompetencyApis.ADD_COMPETENCY}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get competencies list.
export const getCompetenciesList = createAsyncThunk('/listCompetencies', async (request: ICompetencyApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CompetencyApis.LIST_COMPETENCIES}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to view competencies detail.
export const viewCompetency = createAsyncThunk('/competency/view', async (request: any, { rejectWithValue }) => {
  try {
      const { data } = await axios.post(`${CompetencyApis.VIEW_COMPETENCY}`,request);
      return data;
  } catch (error: any) {
      return rejectWithValue(error?.data);
  }
});
// API call to update competencies.
export const updateCompetency = createAsyncThunk('/competency/update', async (request: ICompetencyForm, { rejectWithValue }) => {
  try {
      const { data } = await axios.put(`${CompetencyApis.UPDATE_COMPETENCY}`,request);
      return data;
  } catch (error: any) {
      return rejectWithValue(error?.data);
  }
});
// API call to get top competencies list.
export const getTopCompetenciesList = createAsyncThunk('/getTopCompetencies', async (request: ITopCompetencyApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CompetencyApis.LIST_TOP_COMPETENCIES}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API to delete competencies
export const deleteCompetency = createAsyncThunk('/deleteCompetency', async (request: IDeleteCompetencyApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CompetencyApis.DELETE_COMPETENCY}`,request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});