/**
 * @file   src\store\slices\goalSlice.ts
 * @brief  This file is responsible for creating goal based slices to call actions and state management.
 * @date   MAY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import {
  listGoalCompetencies,
  listGoalTasksEvents,
  getAssignmentStudentsList,
  getAssignmentProofs,
  getAssignmentStatusCount,
  approveAssignment,
  rejectAssignment,
  sendAssignmentMessage,
  listUsersWithSurveyGoalAssignmentDetails,
} from '../actions/goalAction';
import { IGoalSlice } from '../../interfaces/GoalInterface';
// Initial state
const goalDefaultState: IGoalSlice = {
  listGoalCompetenciesApiLoading: false,
  listGoalCompetenciesApiSuccess: false,
  listGoalCompetenciesApiData: {},
  listGoalCompetenciesApiResponseCode: 0,
  listGoalCompetenciesApiResponseMessage: '',
  listGoalTasksEventsApiLoading: false,
  listGoalTasksEventsApiSuccess: false,
  listGoalTasksEventsApiData: {},
  listGoalTasksEventsApiResponseCode: 0,
  listGoalTasksEventsApiResponseMessage: '',
  getAssignmentStudentsListApiLoading: false,
  getAssignmentStudentsListApiSuccess: true,
  getAssignmentStudentsListApiResponseCode: 0,
  getAssignmentStudentsListAPiData: {},
  getAssignmentStudentsListApiResponseMessage: '',
  getAssignmentProofsApiLoading: false,
  getAssignmentProofsApiSuccess: true,
  getAssignmentProofsApiResponseCode: 0,
  getAssignmentProofsAPiData: {},
  getAssignmentProofsApiResponseMessage: '',
  getAssignmentStatusCountApiLoading: false,
  getAssignmentStatusCountApiSuccess: true,
  getAssignmentStatusCountApiResponseCode: 0,
  getAssignmentStatusCountAPiData: {},
  getAssignmentStatusCountApiResponseMessage: '',
  approveAssignmentApiLoading: false,
  approveAssignmentApiSuccess: true,
  approveAssignmentApiResponseCode: 0,
  approveAssignmentAPiData: {},
  approveAssignmentApiResponseMessage: '',
  rejectAssignmentApiLoading: false,
  rejectAssignmentApiSuccess: true,
  rejectAssignmentApiResponseCode: 0,
  rejectAssignmentAPiData: {},
  rejectAssignmentApiResponseMessage: '',
  sendAssignmentMessageApiLoading: false,
  sendAssignmentMessageApiSuccess: true,
  sendAssignmentMessageApiResponseCode: 0,
  sendAssignmentMessageAPiData: {},
  sendAssignmentMessageApiResponseMessage: '',
  listUsersWithSurveyGoalAssignmentDetailsApiLoading: false,
  listUsersWithSurveyGoalAssignmentDetailsApiSuccess: true,
  listUsersWithSurveyGoalAssignmentDetailsApiResponseCode: 0,
  listUsersWithSurveyGoalAssignmentDetailsAPiData: {},
  listUsersWithSurveyGoalAssignmentDetailsApiResponseMessage: '',
};
// Goal slice
const goalSlice = createSlice({
  name: 'goal',
  initialState: goalDefaultState,
  reducers: {
    resetAssignmentStudentsList: (state) => {
      state.getAssignmentStudentsListAPiData = {};
      state.getAssignmentStudentsListApiResponseCode = 0;
      state.getAssignmentStudentsListApiLoading = false;
      state.getAssignmentStudentsListApiSuccess = false;
      state.getAssignmentStudentsListApiResponseMessage = '';
    },
    resetAssignmentProofs: (state) => {
      state.getAssignmentProofsAPiData = {};
      state.getAssignmentProofsApiResponseCode = 0;
      state.getAssignmentProofsApiLoading = false;
      state.getAssignmentProofsApiSuccess = false;
      state.getAssignmentProofsApiResponseMessage = '';
    },
    resetAssignmentStatusCount: (state) => {
      state.getAssignmentStatusCountAPiData = {};
      state.getAssignmentStatusCountApiResponseCode = 0;
      state.getAssignmentStatusCountApiLoading = false;
      state.getAssignmentStatusCountApiSuccess = false;
      state.getAssignmentStatusCountApiResponseMessage = '';
    },
    resetApproveAssignment: (state) => {
      state.approveAssignmentAPiData = {};
      state.approveAssignmentApiResponseCode = 0;
      state.approveAssignmentApiLoading = false;
      state.approveAssignmentApiSuccess = false;
      state.approveAssignmentApiResponseMessage = '';
    },
    resetRejectAssignment: (state) => {
      state.rejectAssignmentAPiData = {};
      state.rejectAssignmentApiResponseCode = 0;
      state.rejectAssignmentApiLoading = false;
      state.rejectAssignmentApiSuccess = false;
      state.rejectAssignmentApiResponseMessage = '';
    },
    resetListGoalCompetencies: (state) => {
      state.listGoalCompetenciesApiLoading = false;
      state.listGoalCompetenciesApiSuccess = false;
      state.listGoalCompetenciesApiData = {};
      state.listGoalCompetenciesApiResponseCode = 0;
      state.listGoalCompetenciesApiResponseMessage = '';
    },
    resetListGoalTasksEvents: (state) => {
      state.listGoalTasksEventsApiLoading = false;
      state.listGoalTasksEventsApiSuccess = false;
      state.listGoalTasksEventsApiData = {};
      state.listGoalTasksEventsApiResponseCode = 0;
      state.listGoalTasksEventsApiResponseMessage = '';
    },
    resetAssignmentMessage: (state) => {
      state.sendAssignmentMessageApiLoading = false;
      state.sendAssignmentMessageApiSuccess = false;
      state.sendAssignmentMessageAPiData = {};
      state.sendAssignmentMessageApiResponseCode = 0;
      state.sendAssignmentMessageApiResponseMessage = '';
    },
    resetListUsersWithSurveyGoalAssignmentDetailsApi:(state)=>{
      state.listUsersWithSurveyGoalAssignmentDetailsApiLoading = false;
      state.listUsersWithSurveyGoalAssignmentDetailsApiSuccess = false;
      state.listUsersWithSurveyGoalAssignmentDetailsApiResponseCode = 0;
      state.listUsersWithSurveyGoalAssignmentDetailsAPiData = {};
      state.listUsersWithSurveyGoalAssignmentDetailsApiResponseMessage = '';
    }
  },
  extraReducers(builder) {
    // list goal competencies api starts
    builder.addCase(listGoalCompetencies.pending, (state) => {
      state.listGoalCompetenciesApiLoading = true;
      state.listGoalCompetenciesApiSuccess = false;
      state.listGoalCompetenciesApiResponseCode = 0;
    });
    // list goal competencies api success
    builder.addCase(listGoalCompetencies.fulfilled, (state, action) => {
      state.listGoalCompetenciesApiLoading = false;
      state.listGoalCompetenciesApiSuccess = true;
      state.listGoalCompetenciesApiResponseCode = action.payload.ResponseCode;
      state.listGoalCompetenciesApiData = action.payload.ResponseData;
    });
    // list goal competencies api failure
    builder.addCase(listGoalCompetencies.rejected, (state, action: any) => {
      state.listGoalCompetenciesApiLoading = false;
      state.listGoalCompetenciesApiSuccess = false;
      state.listGoalCompetenciesApiResponseCode = action.payload.ResponseCode;
      state.listGoalCompetenciesApiResponseMessage = action.payload.ResponseMessage;
    });
    // list goal tasks/events api starts
    builder.addCase(listGoalTasksEvents.pending, (state) => {
      state.listGoalTasksEventsApiLoading = true;
      state.listGoalTasksEventsApiSuccess = false;
      state.listGoalTasksEventsApiResponseCode = 0;
    });
    // list goal tasks/events api success
    builder.addCase(listGoalTasksEvents.fulfilled, (state, action) => {
      state.listGoalTasksEventsApiLoading = false;
      state.listGoalTasksEventsApiSuccess = true;
      state.listGoalTasksEventsApiResponseCode = action.payload.ResponseCode;
      state.listGoalTasksEventsApiData = action.payload.ResponseData;
    });
    // list goal tasks/events api failure
    builder.addCase(listGoalTasksEvents.rejected, (state, action: any) => {
      state.listGoalTasksEventsApiLoading = false;
      state.listGoalTasksEventsApiSuccess = false;
      state.listGoalTasksEventsApiResponseCode = action.payload.ResponseCode;
      state.listGoalTasksEventsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get users by goal id api starts
    builder.addCase(getAssignmentStudentsList.pending, (state) => {
      state.getAssignmentStudentsListApiLoading = true;
      state.getAssignmentStudentsListApiSuccess = false;
      state.getAssignmentStudentsListApiResponseCode = 0;
    });
    // get users by goal id api success
    builder.addCase(getAssignmentStudentsList.fulfilled, (state, action) => {
      state.getAssignmentStudentsListApiLoading = false;
      state.getAssignmentStudentsListApiSuccess = true;
      state.getAssignmentStudentsListApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentStudentsListAPiData = action.payload.ResponseData;
      state.getAssignmentStudentsListApiResponseMessage = action.payload.ResponseMessage;
    });
    // get users by goal id api failure
    builder.addCase(getAssignmentStudentsList.rejected, (state, action: any) => {
      state.getAssignmentStudentsListApiLoading = false;
      state.getAssignmentStudentsListApiSuccess = false;
      state.getAssignmentStudentsListApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentStudentsListApiResponseMessage = action.payload.ResponseMessage;
    });
    // get proofs and notes by users for task/event api starts
    builder.addCase(getAssignmentProofs.pending, (state) => {
      state.getAssignmentProofsApiLoading = true;
      state.getAssignmentProofsApiSuccess = false;
      state.getAssignmentProofsApiResponseCode = 0;
    });
    // get users by goal id api success
    builder.addCase(getAssignmentProofs.fulfilled, (state, action) => {
      state.getAssignmentProofsApiLoading = false;
      state.getAssignmentProofsApiSuccess = true;
      state.getAssignmentProofsApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentProofsAPiData = action.payload.ResponseData;
      state.getAssignmentProofsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get users by goal id api failure
    builder.addCase(getAssignmentProofs.rejected, (state, action: any) => {
      state.getAssignmentProofsApiLoading = false;
      state.getAssignmentProofsApiSuccess = false;
      state.getAssignmentProofsApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentProofsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get count of students for each status in an assignment api start
    builder.addCase(getAssignmentStatusCount.pending, (state) => {
      state.getAssignmentStatusCountApiLoading = true;
      state.getAssignmentStatusCountApiSuccess = false;
      state.getAssignmentStatusCountApiResponseCode = 0;
    });
    // get count of students for each status in an assignment api success
    builder.addCase(getAssignmentStatusCount.fulfilled, (state, action) => {
      state.getAssignmentStatusCountApiLoading = false;
      state.getAssignmentStatusCountApiSuccess = true;
      state.getAssignmentStatusCountApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentStatusCountAPiData = action.payload.ResponseData;
      state.getAssignmentStatusCountApiResponseMessage = action.payload.ResponseMessage;
    });
    // get count of students for each status in an assignment api failed
    builder.addCase(getAssignmentStatusCount.rejected, (state, action: any) => {
      state.getAssignmentStatusCountApiLoading = false;
      state.getAssignmentStatusCountApiSuccess = false;
      state.getAssignmentStatusCountApiResponseCode = action.payload.ResponseCode;
      state.getAssignmentStatusCountApiResponseMessage = action.payload.ResponseMessage;
    });
    // approve assignment api start
    builder.addCase(approveAssignment.pending, (state) => {
      state.approveAssignmentApiLoading = true;
      state.approveAssignmentApiSuccess = false;
      state.approveAssignmentApiResponseCode = 0;
    });
    // approve assignment api success
    builder.addCase(approveAssignment.fulfilled, (state, action) => {
      state.approveAssignmentApiLoading = false;
      state.approveAssignmentApiSuccess = true;
      state.approveAssignmentApiResponseCode = action.payload.ResponseCode;
      state.approveAssignmentAPiData = action.payload.ResponseData;
      state.approveAssignmentApiResponseMessage = action.payload.ResponseMessage;
    });
    // approve assignment api failure
    builder.addCase(approveAssignment.rejected, (state, action: any) => {
      state.approveAssignmentApiLoading = false;
      state.approveAssignmentApiSuccess = false;
      state.approveAssignmentApiResponseCode = action.payload.ResponseCode;
      state.approveAssignmentApiResponseMessage = action.payload.ResponseMessage;
    });
    // reject assignment api start
    builder.addCase(rejectAssignment.pending, (state) => {
      state.rejectAssignmentApiLoading = true;
      state.rejectAssignmentApiSuccess = false;
      state.rejectAssignmentApiResponseCode = 0;
    });
    // reject assignment api success
    builder.addCase(rejectAssignment.fulfilled, (state, action) => {
      state.rejectAssignmentApiLoading = false;
      state.rejectAssignmentApiSuccess = true;
      state.rejectAssignmentApiResponseCode = action.payload.ResponseCode;
      state.rejectAssignmentAPiData = action.payload.ResponseData;
      state.rejectAssignmentApiResponseMessage = action.payload.ResponseMessage;
    });
    // reject assignment api failure
    builder.addCase(rejectAssignment.rejected, (state, action: any) => {
      state.rejectAssignmentApiLoading = false;
      state.rejectAssignmentApiSuccess = false;
      state.rejectAssignmentApiResponseCode = action.payload.ResponseCode;
      state.rejectAssignmentApiResponseMessage = action.payload.ResponseMessage;
    });
    // send message assignment api start
    builder.addCase(sendAssignmentMessage.pending, (state) => {
      state.sendAssignmentMessageApiLoading = true;
      state.sendAssignmentMessageApiSuccess = false;
      state.sendAssignmentMessageApiResponseCode = 0;
    });
    // send message assignment api success
    builder.addCase(sendAssignmentMessage.fulfilled, (state, action) => {
      state.sendAssignmentMessageApiLoading = false;
      state.sendAssignmentMessageApiSuccess = true;
      state.sendAssignmentMessageApiResponseCode = action.payload.ResponseCode;
      state.sendAssignmentMessageAPiData = action.payload.ResponseData;
      state.sendAssignmentMessageApiResponseMessage = action.payload.ResponseMessage;
    });
    // send message assignment api failure
    builder.addCase(sendAssignmentMessage.rejected, (state, action: any) => {
      state.sendAssignmentMessageApiLoading = false;
      state.sendAssignmentMessageApiSuccess = false;
      state.sendAssignmentMessageApiResponseCode = action.payload.ResponseCode;
      state.sendAssignmentMessageApiResponseMessage = action.payload.ResponseMessage;
    });
    // list users with goal/survey assignment api start
    builder.addCase(listUsersWithSurveyGoalAssignmentDetails.pending, (state) => {
      state.listUsersWithSurveyGoalAssignmentDetailsApiLoading = true;
      state.listUsersWithSurveyGoalAssignmentDetailsApiSuccess = false;
      state.listUsersWithSurveyGoalAssignmentDetailsApiResponseCode = 0;
    });
    // list users with goal/survey assignment api success
    builder.addCase(listUsersWithSurveyGoalAssignmentDetails.fulfilled, (state, action) => {
      state.listUsersWithSurveyGoalAssignmentDetailsApiLoading = false;
      state.listUsersWithSurveyGoalAssignmentDetailsApiSuccess = true;
      state.listUsersWithSurveyGoalAssignmentDetailsApiResponseCode = action.payload.ResponseCode;
      state.listUsersWithSurveyGoalAssignmentDetailsAPiData = action.payload.ResponseData;
      state.listUsersWithSurveyGoalAssignmentDetailsApiResponseMessage = action.payload.ResponseMessage;
    });
    // list users with goal/survey assignment api failure
    builder.addCase(listUsersWithSurveyGoalAssignmentDetails.rejected, (state, action: any) => {
      state.listUsersWithSurveyGoalAssignmentDetailsApiLoading = false;
      state.listUsersWithSurveyGoalAssignmentDetailsApiSuccess = false;
      state.listUsersWithSurveyGoalAssignmentDetailsApiResponseCode = action.payload.ResponseCode;
      state.listUsersWithSurveyGoalAssignmentDetailsApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export actions
export const {
  resetAssignmentStudentsList,
  resetAssignmentStatusCount,
  resetApproveAssignment,
  resetAssignmentProofs,
  resetRejectAssignment,
  resetListGoalCompetencies,
  resetListGoalTasksEvents,
  resetAssignmentMessage,
  resetListUsersWithSurveyGoalAssignmentDetailsApi
} = goalSlice.actions;
// Export reducer.
export default goalSlice.reducer;
