/**
 * @file   src\store\slices\organizationSlice.ts
 * @brief  This file is responsible for creating organization based slices to call actions and state management.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import {
  saveOrganization,
  updateOrganization,
  getOrganizationList,
  deleteOrganization,
  deleteCategory,
  getOrganizationInfo,
  getStates,
  getCities,
  uploadFile,
  uploadFilePathUpdate,
  uploadFilePathUpdateMultiple,
  subscriptionPlans,
  getCategoryList,
  addGoalStepOne,
  addGoalEvent,
  addGoalTask,
  getGoalList,
  getEventsTasks,
  getSubCategoryList,
  deleteEventsTasks,
  addGoalFinish,
  getGoalResource,
  deleteGoalResource,
  getGoalDetail,
  updateGoal,
  getUsersByGoalId,
  getEventDetails,
  getTaskDetails,
  editEventDetails,
  editTaskDetails,
  deleteGoal,
} from '../actions/organizationActions';
import { IOrganizationSlice } from '../../interfaces/OrganisationInterface';

// Initial state
const organizationDefaultState: IOrganizationSlice = {
  saveOrganizationApiData: {},
  saveOrganizationResponseCode: 0,
  saveOrganizationApiLoading: false,
  saveOrganizationApiSuccess: false,
  saveOrganizationResponseMessage: '',
  updateOrganizationApiData: {},
  updateOrganizationResponseCode: 0,
  updateOrganizationApiLoading: false,
  updateOrganizationApiSuccess: false,
  updateOrganizationResponseMessage: '',
  organizationListApiData: {},
  organizationListApiResponseCode: 0,
  organizationListApiLoading: false,
  organizationListApiSuccess: false,
  organizationListApiResponseMessage: '',
  deleteOrganizationApiData: {},
  deleteOrganizationResponseCode: 0,
  deleteOrganizationApiLoading: false,
  deleteOrganizationApiSuccess: false,
  deleteOrganizationResponseMessage: '',
  deleteCategoryApiData: {},
  deleteCategoryResponseCode: 0,
  deleteCategoryApiLoading: false,
  deleteCategoryApiSuccess: false,
  deleteCategoryResponseMessage: '',
  organizationDetailApiData: {},
  organizationDetailApiResponseCode: 0,
  organizationDetailApiLoading: false,
  organizationDetailApiSuccess: false,
  organizationDetailApiResponseMessage: '',
  listStatesApiData: {},
  listStatesApiResponseCode: 0,
  listStatesApiLoading: false,
  listStatesApiSuccess: false,
  listStatesApiResponseMessage: '',
  listCitiesApiData: {},
  listCitiesApiResponseCode: 0,
  listCitiesApiLoading: false,
  listCitiesApiSuccess: false,
  listCitiesApiResponseMessage: '',
  uploadFileApiData: {},
  uploadFileResponseCode: 0,
  uploadFileApiLoading: false,
  uploadFileApiSuccess: false,
  uploadFileResponseMessage: '',
  uploadFilePathUpdateApiData: {},
  uploadFilePathUpdateResponseCode: 0,
  uploadFilePathUpdateApiLoading: false,
  uploadFilePathUpdateApiSuccess: false,
  uploadFilePathUpdateResponseMessage: '',
  uploadFilePathUpdateMultipleApiData: {},
  uploadFilePathUpdateMultipleResponseCode: 0,
  uploadFilePathUpdateMultipleApiLoading: false,
  uploadFilePathUpdateMultipleApiSuccess: false,
  uploadFilePathUpdateMultipleResponseMessage: '',
  subscriptionPlansApiData: {},
  subscriptionPlansResponseCode: 0,
  subscriptionPlansApiLoading: false,
  subscriptionPlansApiSuccess: false,
  subscriptionPlansResponseMessage: '',
  getCategoryListApiLoading: false,
  getCategoryListApiSuccess: false,
  getCategoryListApiResponseCode: 0,
  getCategoryListApiResponseMessage: '',
  getCatergoryListAPiData: [],
  addGoalStepOneApiResponseCode: 0,
  addGoalStepOneApiLoading: false,
  addGoalStepOneApiSuccess: false,
  addGoalStepOneApiResponseMessage: '',
  addGoalStepOneAPiData: {},
  addEventApiLoading: false,
  addEventApiSuccess: false,
  addEventApiResponseCode: 0,
  addEventApiData: {},
  addEventApiResponseMessage: '',
  addTaskApiLoading: false,
  addTaskApiSuccess: false,
  addTaskApiResponseCode: 0,
  addTaskApiData: {},
  addTaskApiResponseMessage: '',
  getGoalListApiLoading: false,
  getGoalListApiSuccess: false,
  getGoalListApiResponseCode: 0,
  getGoalListApiResponseMessage: '',
  getGoalListAPiData: { Goals: [], TotalCount: 0 },
  getSubCategoryListApiLoading: false,
  getSubCategoryListApiSuccess: false,
  getSubCategoryListApiResponseCode: 0,
  getSubCategoryListApiResponseMessage: '',
  getSubCategoryListAPiData: [],
  getTaskEventsApiLoading: false,
  getTaskEventsApiSuccess: false,
  getTaskEventsApiData: {},
  getTaskEventsApiErrorCode: 0,
  getTaskEventsApiErrorMessage: '',
  deleteTaskEventsApiLoading: false,
  deleteTaskEventsApiSuccess: false,
  deleteTaskEventsApiData: {},
  deleteTaskEventsApiErrorCode: 0,
  deleteTaskEventsApiErrorMessage: '',
  addGoalFinishApiResponseCode: 0,
  addGoalFinishApiLoading: false,
  addGoalFinishApiSuccess: false,
  addGoalFinishApiResponseMessage: '',
  addGoalFinishAPiData: {},
  getGoalResourceApiLoading: false,
  getGoalResourceApiSuccess: false,
  getGoalResourceApiData: {},
  getGoalResourcesApiErrorCode: 0,
  getGoalResourceApiErrorMessage: '',
  deleteGoalResourceApiLoading: false,
  deleteGoalResourceApiSuccess: false,
  deleteGoalResourceApiData: {},
  deleteGoalResourceApiErrorCode: 0,
  deleteGoalResourceApiErrorMessage: '',
  getGoalDetailApiLoading: false,
  getGoalDetailApiSuccess: false,
  getGoalDetailApiResponseCode: 0,
  getGoalDetailApiResponseMessage: '',
  getGoalDetailAPiData: {},
  updateGoalApiLoading: false,
  updateGoalApiSuccess: false,
  updateGoalApiResponseCode: 0,
  updateGoalApiResponseMessage: '',
  updateGoalAPiData: {},
  getUsersByGoalIdApiLoading: false,
  getUsersByGoalIdApiSuccess: false,
  getUsersByGoalIdApiResponseCode: 0,
  getUsersByGoalIdApiResponseMessage: '',
  getUsersByGoalIdAPiData: {},
  getEventDetailsApiData: {},
  getEventDetailsApiLoading: false,
  getEventDetailsApiResponseCode: 0,
  getEventDetailsApiSuccess: false,
  getEventDetailsApiResponseMessage: '',
  getTaskDetailsApiData: {},
  getTaskDetailsApiLoading: false,
  getTaskDetailsApiResponseCode: 0,
  getTaskDetailsApiResponseMessage: '',
  getTaskDetailsApiSuccess: false,
  editEventApiData: {},
  editEventApiLoading: false,
  editEventApiResponseCode: 0,
  editEventApiResponseMessage: '',
  editEventApiSuccess: false,
  editTaskApiData: {},
  editTaskApiLoading: false,
  editTaskApiResponseCode: 0,
  editTaskApiResponseMessage: '',
  editTaskApiSuccess: false,
  deleteGoalApiLoading: false,
  deleteGoalApiSuccess: false,
  deleteGoalApiData: {},
  deleteGoalApiResponseCode: 0,
  deleteGoalApiResponseMessage: '',
};
// Authentication slice
const organizationSlice = createSlice({
  name: 'organization',
  initialState: organizationDefaultState,
  reducers: {
    resetSaveOrganization: (state) => {
      state.saveOrganizationApiData = {};
      state.saveOrganizationResponseCode = 0;
      state.saveOrganizationApiLoading = false;
      state.saveOrganizationApiSuccess = false;
      state.saveOrganizationResponseMessage = '';
    },
    resetUpdateOrganization: (state) => {
      state.updateOrganizationApiData = {};
      state.updateOrganizationResponseCode = 0;
      state.updateOrganizationApiLoading = false;
      state.updateOrganizationApiSuccess = false;
      state.updateOrganizationResponseMessage = '';
    },
    resetOrganizationList: (state) => {
      state.organizationListApiData = {};
      state.organizationListApiResponseCode = 0;
      state.organizationListApiLoading = false;
      state.organizationListApiSuccess = false;
      state.organizationListApiResponseMessage = '';
    },
    resetDeleteOrganization: (state) => {
      state.deleteOrganizationApiData = {};
      state.deleteOrganizationResponseCode = 0;
      state.deleteOrganizationApiLoading = false;
      state.deleteOrganizationApiSuccess = false;
      state.deleteOrganizationResponseMessage = '';
    },
    resetdeleteCategory: (state) => {
      state.deleteCategoryApiData = {};
      state.deleteCategoryResponseCode = 0;
      state.deleteCategoryApiLoading = false;
      state.deleteCategoryApiSuccess = false;
      state.deleteCategoryResponseMessage = '';
    },
    resetOrganizationInfo: (state) => {
      state.organizationDetailApiData = {};
      state.organizationDetailApiResponseCode = 0;
      state.organizationDetailApiLoading = false;
      state.organizationDetailApiSuccess = false;
      state.organizationDetailApiResponseMessage = '';
    },
    resetUploadFile: (state) => {
      state.uploadFileApiData = {};
      state.uploadFileResponseCode = 0;
      state.uploadFileApiLoading = false;
      state.uploadFileApiSuccess = false;
      state.uploadFileResponseMessage = '';
    },
    resetUploadFilePathUpdate: (state) => {
      state.uploadFilePathUpdateApiData = {};
      state.uploadFilePathUpdateResponseCode = 0;
      state.uploadFilePathUpdateApiLoading = false;
      state.uploadFilePathUpdateApiSuccess = false;
      state.uploadFilePathUpdateResponseMessage = '';
    },
    resetUploadFilePathUpdateMultiple: (state) => {
      state.uploadFilePathUpdateMultipleApiData = {};
      state.uploadFilePathUpdateMultipleResponseCode = 0;
      state.uploadFilePathUpdateMultipleApiLoading = false;
      state.uploadFilePathUpdateMultipleApiSuccess = false;
      state.uploadFilePathUpdateMultipleResponseMessage = '';
    },
    resetSubscriptionPlans: (state) => {
      state.subscriptionPlansApiData = {};
      state.subscriptionPlansResponseCode = 0;
      state.subscriptionPlansApiLoading = false;
      state.subscriptionPlansApiSuccess = false;
      state.subscriptionPlansResponseMessage = '';
    },
    resetaddGoalStepOne: (state) => {
      state.addGoalStepOneApiLoading = false;
      state.addGoalStepOneApiSuccess = false;
      state.addGoalStepOneApiResponseCode = 0;
      state.addGoalStepOneApiResponseMessage = '';
      state.uploadFileApiLoading = false;
      state.uploadFileApiSuccess = false;
      state.uploadFileResponseCode = 0;
      state.uploadFileResponseMessage = '';
      state.uploadFilePathUpdateApiLoading = false;
      state.uploadFilePathUpdateApiSuccess = false;
      state.uploadFilePathUpdateResponseCode = 0;
      state.uploadFilePathUpdateResponseMessage = '';
    },
    resetaddGoalStepThree: (state) => {
      state.addEventApiLoading = false;
      state.addEventApiSuccess = false;
      state.addEventApiResponseCode = 0;
      state.addEventApiData = {};
      state.addEventApiResponseMessage = '';
      state.addTaskApiLoading = false;
      state.addTaskApiSuccess = false;
      state.addTaskApiResponseCode = 0;
      state.addTaskApiResponseMessage = '';
      state.addTaskApiData = {};
      state.getTaskEventsApiLoading = false;
      state.getTaskEventsApiSuccess = false;
      state.getTaskEventsApiData = {};
      state.getTaskEventsApiErrorCode = 0;
      state.getTaskEventsApiErrorMessage = '';
      state.getTaskDetailsApiResponseMessage = '';
      state.getTaskDetailsApiResponseCode = 0;
      state.getTaskDetailsApiData = {};
      state.getTaskDetailsApiLoading = false;
      state.getTaskDetailsApiSuccess = false;
      state.deleteTaskEventsApiLoading = false;
      state.deleteTaskEventsApiSuccess = false;
      state.deleteTaskEventsApiData = {};
      state.deleteTaskEventsApiErrorCode = 0;
      state.deleteTaskEventsApiErrorMessage = '';
      state.editEventApiData = {};
      state.editEventApiLoading = false;
      state.editEventApiResponseCode = 0;
      state.editEventApiResponseMessage = '';
      state.editEventApiSuccess = false;
      state.editTaskApiData = {};
      state.editTaskApiLoading = false;
      state.editTaskApiResponseMessage = '';
      state.editTaskApiResponseCode = 0;
      state.editTaskApiSuccess = false;
      state.getEventDetailsApiData = {};
      state.getEventDetailsApiLoading = false;
      state.getEventDetailsApiResponseCode = 0;
      state.getEventDetailsApiResponseMessage = '';
      state.getEventDetailsApiSuccess = false;
    },
    resetAssignmentDelete: (state) => {
      state.deleteTaskEventsApiLoading = false;
      state.deleteTaskEventsApiSuccess = false;
      state.deleteTaskEventsApiData = {};
      state.deleteTaskEventsApiErrorCode = 0;
      state.deleteTaskEventsApiErrorMessage = '';
    },
    resetaddGoalFinish: (state) => {
      state.addGoalFinishApiLoading = false;
      state.addGoalFinishApiSuccess = false;
      state.addGoalFinishApiResponseCode = 0;
      state.addGoalFinishApiResponseMessage = '';
    },
    resetDeleteGoal: (state) => {
      state.deleteGoalResourceApiLoading = false;
      state.deleteGoalResourceApiSuccess = false;
      state.deleteGoalResourceApiErrorCode = 0;
      state.deleteGoalResourceApiErrorMessage = '';
    },
    resetaddGoalStepTwo: (state) => {
      state.getGoalResourceApiLoading = false;
      state.getGoalResourceApiSuccess = false;
      state.getGoalResourceApiData = {};
      state.getGoalResourcesApiErrorCode = 0;
      state.getGoalResourceApiErrorMessage = '';
      state.deleteGoalResourceApiLoading = false;
      state.deleteGoalResourceApiSuccess = false;
      state.deleteGoalResourceApiData = {};
      state.deleteGoalResourceApiErrorCode = 0;
      state.deleteGoalResourceApiErrorMessage = '';
    },
    resetUpdateGoalStepOne: (state) => {
      state.updateGoalApiLoading = false;
      state.updateGoalApiSuccess = false;
      state.updateGoalApiResponseCode = 0;
      state.updateGoalApiResponseMessage = '';
      state.uploadFileApiLoading = false;
      state.uploadFileApiSuccess = false;
      state.uploadFileResponseCode = 0;
      state.uploadFileResponseMessage = '';
      state.uploadFilePathUpdateApiLoading = false;
      state.uploadFilePathUpdateApiSuccess = false;
      state.uploadFilePathUpdateResponseCode = 0;
      state.uploadFilePathUpdateResponseMessage = '';
    },
    resetGoalStepOneApiData: (state) => {
      state.addGoalStepOneAPiData = {};
    },
    resetGoalListApiData: (state) => {
      state.getGoalListAPiData = { Goals: [], TotalCount: 0 };
    },
    resetDeleteGoalDetail: (state) => {
      state.deleteGoalApiLoading = false;
      state.deleteGoalApiSuccess = false;
      state.deleteGoalApiData = {};
      state.deleteGoalApiResponseCode = 0;
      state.deleteGoalApiResponseMessage = '';
    },
    resetAddTaskEventGoalDetail: (state) => {
      state.addEventApiLoading = false;
      state.addEventApiSuccess = false;
      state.addEventApiResponseCode = 0;
      state.addEventApiData = {};
      state.addEventApiResponseMessage = '';
      state.addTaskApiLoading = false;
      state.addTaskApiSuccess = false;
      state.addTaskApiResponseCode = 0;
      state.addTaskApiData = {};
      state.addTaskApiResponseMessage = '';
    },
    resetEditTaskEventGoalDetail: (state) => {
      state.editEventApiLoading = false;
      state.editEventApiSuccess = false;
      state.editEventApiResponseCode = 0;
      state.editEventApiData = {};
      state.editEventApiResponseMessage = '';
      state.editTaskApiLoading = false;
      state.editTaskApiSuccess = false;
      state.editTaskApiResponseCode = 0;
      state.editTaskApiData = {};
      state.editTaskApiResponseMessage = '';
    },
    resetGoalDetail: (state) => {
      state.getGoalDetailApiLoading = false;
      state.getGoalDetailApiSuccess = false;
      state.getGoalDetailApiResponseCode = 0;
      state.getGoalDetailApiResponseMessage = '';
      state.getGoalDetailAPiData = {};
    },
    resetGoalResource: (state) => {
      state.getGoalResourceApiLoading = true;
      state.getGoalResourceApiSuccess = false;
      state.getGoalResourcesApiErrorCode = 0;
    },
    resetGetUsersByGoalIdApi: (state) => {
      state.getUsersByGoalIdApiLoading = false;
      state.getUsersByGoalIdApiSuccess = false;
      state.getUsersByGoalIdApiResponseCode = 0;
      state.getUsersByGoalIdAPiData = {};
      state.getUsersByGoalIdApiResponseMessage = '';
    },
  },
  extraReducers(builder) {
    // saveOrganization api starts
    builder.addCase(saveOrganization.pending, (state) => {
      state.saveOrganizationApiLoading = true;
      state.saveOrganizationApiSuccess = false;
      state.saveOrganizationResponseCode = 0;
    });
    // saveOrganization api success
    builder.addCase(saveOrganization.fulfilled, (state, action) => {
      state.saveOrganizationApiLoading = false;
      state.saveOrganizationApiSuccess = true;
      state.saveOrganizationResponseCode = action.payload.ResponseCode;
      state.saveOrganizationApiData = action.payload.ResponseData;
      state.saveOrganizationResponseMessage = action.payload.ResponseMessage;
    });
    // saveOrganization api failure
    builder.addCase(saveOrganization.rejected, (state, action: any) => {
      state.saveOrganizationApiLoading = false;
      state.saveOrganizationApiSuccess = false;
      state.saveOrganizationResponseCode = action.payload.ResponseCode;
      state.saveOrganizationResponseMessage = action.payload.ResponseMessage;
    });
    // updateOrganization api starts
    builder.addCase(updateOrganization.pending, (state) => {
      state.updateOrganizationApiLoading = true;
      state.updateOrganizationApiSuccess = false;
      state.updateOrganizationResponseCode = 0;
    });
    // updateOrganization api success
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.updateOrganizationApiLoading = false;
      state.updateOrganizationApiSuccess = true;
      state.updateOrganizationResponseCode = action.payload.ResponseCode;
      state.updateOrganizationApiData = action.payload.ResponseData;
      state.updateOrganizationResponseMessage = action.payload.ResponseMessage;
    });
    // updateOrganization api failure
    builder.addCase(updateOrganization.rejected, (state, action: any) => {
      state.updateOrganizationApiLoading = false;
      state.updateOrganizationApiSuccess = false;
      state.updateOrganizationResponseCode = action.payload.ResponseCode;
      state.updateOrganizationResponseMessage = action.payload.ResponseMessage;
    });

    // listOrganization api starts
    builder.addCase(getOrganizationList.pending, (state) => {
      state.organizationListApiLoading = true;
      state.organizationListApiSuccess = false;
      state.organizationListApiResponseCode = 0;
    });
    // listOrganization api success
    builder.addCase(getOrganizationList.fulfilled, (state, action) => {
      state.organizationListApiLoading = false;
      state.organizationListApiSuccess = true;
      state.organizationListApiResponseCode = action.payload.ResponseCode;
      state.organizationListApiData = action.payload.ResponseData;
    });
    // listOrganization api failure
    builder.addCase(getOrganizationList.rejected, (state, action: any) => {
      state.organizationListApiLoading = false;
      state.organizationListApiSuccess = false;
      state.organizationListApiResponseCode = action.payload.ResponseCode;
      state.organizationListApiResponseMessage = action.payload.ResponseMessage;
    });

    // deleteOrganization api starts
    builder.addCase(deleteOrganization.pending, (state) => {
      state.deleteOrganizationApiLoading = true;
      state.deleteOrganizationApiSuccess = false;
      state.deleteOrganizationResponseCode = 0;
    });
    // deleteOrganization api success
    builder.addCase(deleteOrganization.fulfilled, (state, action) => {
      state.deleteOrganizationApiLoading = false;
      state.deleteOrganizationApiSuccess = true;
      state.deleteOrganizationResponseCode = action.payload.ResponseCode;
      state.deleteOrganizationApiData = action.payload.ResponseData;
      state.deleteOrganizationResponseMessage = action.payload.ResponseMessage;
    });
    // deleteOrganization api failure
    builder.addCase(deleteOrganization.rejected, (state, action: any) => {
      state.deleteOrganizationApiLoading = false;
      state.deleteOrganizationApiSuccess = false;
      state.deleteOrganizationResponseCode = action.payload.ResponseCode;
      state.deleteOrganizationResponseMessage = action.payload.ResponseMessage;
    });
    // deleteCategory api starts
    builder.addCase(deleteCategory.pending, (state) => {
      state.deleteCategoryApiLoading = true;
      state.deleteCategoryApiSuccess = false;
      state.deleteCategoryResponseCode = 0;
    });
    // deleteCategory api success
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.deleteCategoryApiLoading = false;
      state.deleteCategoryApiSuccess = true;
      state.deleteCategoryResponseCode = action.payload.ResponseCode;
      state.deleteCategoryApiData = action.payload.ResponseData;
      state.deleteCategoryResponseMessage = action.payload.ResponseMessage;
    });
    // deleteCategory api failure
    builder.addCase(deleteCategory.rejected, (state, action: any) => {
      state.deleteCategoryApiLoading = false;
      state.deleteCategoryApiSuccess = false;
      state.deleteCategoryResponseCode = action.payload.ResponseCode;
      state.deleteCategoryResponseMessage = action.payload.ResponseMessage;
    });
    // View Organization api starts
    builder.addCase(getOrganizationInfo.pending, (state) => {
      state.organizationDetailApiLoading = true;
      state.organizationDetailApiSuccess = false;
      state.organizationDetailApiResponseCode = 0;
    });
    // View Organization api success
    builder.addCase(getOrganizationInfo.fulfilled, (state, action) => {
      state.organizationDetailApiLoading = false;
      state.organizationDetailApiSuccess = true;
      state.organizationDetailApiResponseCode = action.payload.ResponseCode;
      state.organizationDetailApiData = action.payload.ResponseData;
    });
    // View Organization api failure
    builder.addCase(getOrganizationInfo.rejected, (state, action: any) => {
      state.organizationDetailApiLoading = false;
      state.organizationDetailApiSuccess = false;
      state.organizationDetailApiResponseCode = action.payload.ResponseCode;
      state.organizationDetailApiResponseMessage = action.payload.ResponseMessage;
    });

    // get states api starts
    builder.addCase(getStates.pending, (state) => {
      state.listStatesApiLoading = true;
      state.listStatesApiSuccess = false;
      state.listStatesApiResponseCode = 0;
    });
    // get states api success
    builder.addCase(getStates.fulfilled, (state, action) => {
      state.listStatesApiLoading = false;
      state.listStatesApiSuccess = true;
      state.listStatesApiResponseCode = action.payload.ResponseCode;
      state.listStatesApiData = action.payload.ResponseData;
    });
    // get states api failure
    builder.addCase(getStates.rejected, (state, action: any) => {
      state.listStatesApiLoading = false;
      state.listStatesApiSuccess = false;
      state.listStatesApiResponseCode = action.payload.ResponseCode;
      state.listStatesApiResponseMessage = action.payload.ResponseMessage;
    });

    // get cities api starts
    builder.addCase(getCities.pending, (state) => {
      state.listCitiesApiLoading = true;
      state.listCitiesApiSuccess = false;
      state.listCitiesApiResponseCode = 0;
    });
    // get cities api success
    builder.addCase(getCities.fulfilled, (state, action) => {
      state.listCitiesApiLoading = false;
      state.listCitiesApiSuccess = true;
      state.listCitiesApiResponseCode = action.payload.ResponseCode;
      state.listCitiesApiData = action.payload.ResponseData;
    });
    // get cities api failure
    builder.addCase(getCities.rejected, (state, action: any) => {
      state.listCitiesApiLoading = false;
      state.listCitiesApiSuccess = false;
      state.listCitiesApiResponseCode = action.payload.ResponseCode;
      state.listCitiesApiResponseMessage = action.payload.ResponseMessage;
    });
    // uploadFile api success
    builder.addCase(uploadFile.pending, (state) => {
      state.uploadFileApiLoading = true;
      state.uploadFileApiSuccess = false;
      state.uploadFileResponseCode = 0;
    });
    // uploadFile api success
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.uploadFileApiLoading = false;
      state.uploadFileApiSuccess = true;
      state.uploadFileResponseCode = action.payload.ResponseCode;
      state.uploadFileApiData = action.payload.ResponseData;
      state.uploadFileResponseMessage = action.payload.ResponseMessage;
    });
    // uploadFile api failure
    builder.addCase(uploadFile.rejected, (state, action: any) => {
      state.uploadFileApiLoading = false;
      state.uploadFileApiSuccess = false;
      state.uploadFileResponseCode = action.payload.ResponseCode;
      state.uploadFileResponseMessage = action.payload.ResponseMessage;
    });
    // uploadFilePathUpdate api success
    builder.addCase(uploadFilePathUpdate.pending, (state) => {
      state.uploadFilePathUpdateApiLoading = true;
      state.uploadFilePathUpdateApiSuccess = false;
      state.uploadFilePathUpdateResponseCode = 0;
    });
    // uploadFilePathUpdate api success
    builder.addCase(uploadFilePathUpdate.fulfilled, (state, action) => {
      state.uploadFilePathUpdateApiLoading = false;
      state.uploadFilePathUpdateApiSuccess = true;
      state.uploadFilePathUpdateResponseCode = action.payload.ResponseCode;
      state.uploadFilePathUpdateApiData = action.payload.ResponseData;
      state.uploadFilePathUpdateResponseMessage = action.payload.ResponseMessage;
    });
    // uploadFilePathUpdate api failure
    builder.addCase(uploadFilePathUpdate.rejected, (state, action: any) => {
      state.uploadFilePathUpdateApiLoading = false;
      state.uploadFilePathUpdateApiSuccess = false;
      state.uploadFilePathUpdateResponseCode = action.payload.ResponseCode;
      state.uploadFilePathUpdateResponseMessage = action.payload.ResponseMessage;
    });
    // uploadFilePathUpdateMultiple api success
    builder.addCase(uploadFilePathUpdateMultiple.pending, (state) => {
      state.uploadFilePathUpdateMultipleApiLoading = true;
      state.uploadFilePathUpdateMultipleApiSuccess = false;
      state.uploadFilePathUpdateMultipleResponseCode = 0;
    });
    // uploadFilePathUpdateMultiple api success
    builder.addCase(uploadFilePathUpdateMultiple.fulfilled, (state, action) => {
      state.uploadFilePathUpdateMultipleApiLoading = false;
      state.uploadFilePathUpdateMultipleApiSuccess = true;
      state.uploadFilePathUpdateMultipleResponseCode = action.payload.ResponseCode;
      state.uploadFilePathUpdateMultipleApiData = action.payload.ResponseData;
      state.uploadFilePathUpdateMultipleResponseMessage = action.payload.ResponseMessage;
    });
    // uploadFilePathUpdateMultiple api failure
    builder.addCase(uploadFilePathUpdateMultiple.rejected, (state, action: any) => {
      state.uploadFilePathUpdateMultipleApiLoading = false;
      state.uploadFilePathUpdateMultipleApiSuccess = false;
      state.uploadFilePathUpdateMultipleResponseCode = action.payload.ResponseCode;
      state.uploadFilePathUpdateMultipleResponseMessage = action.payload.ResponseMessage;
    });
    // subscriptionPlans api success
    builder.addCase(subscriptionPlans.pending, (state) => {
      state.subscriptionPlansApiLoading = true;
      state.subscriptionPlansApiSuccess = false;
      state.subscriptionPlansResponseCode = 0;
    });
    // subscriptionPlans api success
    builder.addCase(subscriptionPlans.fulfilled, (state, action) => {
      state.subscriptionPlansApiLoading = false;
      state.subscriptionPlansApiSuccess = true;
      state.subscriptionPlansResponseCode = action.payload.ResponseCode;
      state.subscriptionPlansApiData = action.payload.ResponseData;
      state.subscriptionPlansResponseMessage = action.payload.ResponseMessage;
    });
    // subscriptionPlans api failure
    builder.addCase(subscriptionPlans.rejected, (state, action: any) => {
      state.subscriptionPlansApiLoading = false;
      state.subscriptionPlansApiSuccess = false;
      state.subscriptionPlansResponseCode = action.payload.ResponseCode;
      state.subscriptionPlansResponseMessage = action.payload.ResponseMessage;
    });
    // category list api starts
    builder.addCase(getCategoryList.pending, (state) => {
      state.getCategoryListApiLoading = true;
      state.getCategoryListApiSuccess = false;
      state.getCategoryListApiResponseCode = 0;
    });
    // category list api success
    builder.addCase(getCategoryList.fulfilled, (state, action) => {
      state.getCategoryListApiLoading = false;
      state.getCategoryListApiSuccess = true;
      state.getCategoryListApiResponseCode = action.payload.ResponseCode;
      state.getCatergoryListAPiData = action.payload.ResponseData;
      state.getCategoryListApiResponseMessage = action.payload.ResponseMessage;
    });
    // category list api failure
    builder.addCase(getCategoryList.rejected, (state, action: any) => {
      state.getCategoryListApiLoading = false;
      state.getCategoryListApiSuccess = false;
      state.getCategoryListApiResponseCode = action.payload.ResponseCode;
      state.getCategoryListApiResponseMessage = action.payload.ResponseMessage;
    });
    // add goal step one api starts
    builder.addCase(addGoalStepOne.pending, (state) => {
      state.addGoalStepOneApiLoading = true;
      state.addGoalStepOneApiSuccess = false;
      state.addGoalStepOneApiResponseCode = 0;
    });
    // add goal step one api success
    builder.addCase(addGoalStepOne.fulfilled, (state, action) => {
      state.addGoalStepOneApiLoading = false;
      state.addGoalStepOneApiSuccess = true;
      state.addGoalStepOneApiResponseCode = action.payload.ResponseCode;
      state.addGoalStepOneAPiData = action.payload.ResponseData;
      state.addGoalStepOneApiResponseMessage = action.payload.ResponseMessage;
    });
    // add goal step one api failure
    builder.addCase(addGoalStepOne.rejected, (state, action: any) => {
      state.addGoalStepOneApiLoading = false;
      state.addGoalStepOneApiSuccess = false;
      state.addGoalStepOneApiResponseCode = action.payload.ResponseCode;
      state.addGoalStepOneApiResponseMessage = action.payload.ResponseMessage;
    });
    // add event api starts
    builder.addCase(addGoalEvent.pending, (state) => {
      state.addEventApiLoading = true;
      state.addEventApiSuccess = false;
      state.addEventApiResponseCode = 0;
    });
    // add event api success
    builder.addCase(addGoalEvent.fulfilled, (state, action) => {
      state.addEventApiLoading = false;
      state.addEventApiSuccess = true;
      state.addEventApiResponseCode = action.payload.ResponseCode;
      state.addEventApiResponseMessage = action.payload.ResponseMessage;
      state.addEventApiData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    // add event api failure
    builder.addCase(addGoalEvent.rejected, (state, action: any) => {
      state.addEventApiLoading = false;
      state.addEventApiSuccess = false;
      state.addEventApiResponseCode = action.payload.ResponseCode;
      state.addEventApiResponseMessage = action.payload.ResponseMessage;
    });
    // add task api starts
    builder.addCase(addGoalTask.pending, (state) => {
      state.addTaskApiLoading = true;
      state.addTaskApiSuccess = false;
      state.addTaskApiResponseCode = 0;
    });
    // add task api success
    builder.addCase(addGoalTask.fulfilled, (state, action) => {
      state.addTaskApiLoading = false;
      state.addTaskApiSuccess = true;
      state.addTaskApiResponseCode = action.payload.ResponseCode;
      state.addTaskApiResponseMessage = action.payload.ResponseMessage;
      state.addTaskApiData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    // add task api failure
    builder.addCase(addGoalTask.rejected, (state, action: any) => {
      state.addTaskApiLoading = false;
      state.addTaskApiSuccess = false;
      state.addTaskApiResponseCode = action.payload.ResponseCode;
      state.addTaskApiResponseMessage = action.payload.ResponseMessage;
    });
    // get goal list api starts
    builder.addCase(getGoalList.pending, (state) => {
      state.getGoalListApiLoading = true;
      state.getGoalListApiSuccess = false;
      state.getGoalListApiResponseCode = 0;
    });
    // get goal list api success
    builder.addCase(getGoalList.fulfilled, (state, action) => {
      state.getGoalListApiLoading = false;
      state.getGoalListApiSuccess = true;
      state.getGoalListApiResponseCode = action.payload.ResponseCode;
      state.getGoalListAPiData = action.payload.ResponseData;
      state.getGoalListApiResponseMessage = action.payload.ResponseMessage;
    });
    // get goal list api failure
    builder.addCase(getGoalList.rejected, (state, action: any) => {
      state.getGoalListApiLoading = false;
      state.getGoalListApiSuccess = false;
      state.getGoalListApiResponseCode = action.payload.ResponseCode;
      state.getGoalListApiResponseMessage = action.payload.ResponseMessage;
    });
    // get events tasks api starts
    builder.addCase(getEventsTasks.pending, (state) => {
      state.getTaskEventsApiLoading = true;
      state.getTaskEventsApiSuccess = false;
      state.getTaskEventsApiErrorCode = 0;
    });
    // get events tasksgoal list api success
    builder.addCase(getEventsTasks.fulfilled, (state, action) => {
      state.getTaskEventsApiLoading = false;
      state.getTaskEventsApiSuccess = true;
      state.getTaskEventsApiErrorCode = action.payload.ResponseCode;
      state.getTaskEventsApiData = action.payload.ResponseData;
      state.getTaskEventsApiErrorMessage = action.payload.ResponseMessage;
    });
    // get events tasks api failure
    builder.addCase(getEventsTasks.rejected, (state, action: any) => {
      state.getTaskEventsApiLoading = false;
      state.getTaskEventsApiSuccess = false;
      state.getTaskEventsApiErrorCode = action.payload.ResponseCode;
      state.getTaskEventsApiErrorMessage = action.payload.ResponseMessage;
    });
    // Subcategory list api starts
    builder.addCase(getSubCategoryList.pending, (state) => {
      state.getSubCategoryListApiLoading = true;
      state.getSubCategoryListApiSuccess = false;
      state.getSubCategoryListApiResponseCode = 0;
    });
    // Subcategory list api success
    builder.addCase(getSubCategoryList.fulfilled, (state, action) => {
      state.getSubCategoryListApiLoading = false;
      state.getSubCategoryListApiSuccess = true;
      state.getSubCategoryListApiResponseCode = action.payload.ResponseCode;
      state.getSubCategoryListAPiData = action.payload.ResponseData;
      state.getSubCategoryListApiResponseMessage = action.payload.ResponseMessage;
    });
    // Subcategory list api failure
    builder.addCase(getSubCategoryList.rejected, (state, action: any) => {
      state.getSubCategoryListApiLoading = false;
      state.getSubCategoryListApiSuccess = false;
      state.getSubCategoryListApiResponseCode = action.payload.ResponseCode;
      state.getSubCategoryListApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete events tasks api starts
    builder.addCase(deleteEventsTasks.pending, (state) => {
      state.deleteTaskEventsApiLoading = true;
      state.deleteTaskEventsApiSuccess = false;
      state.deleteTaskEventsApiErrorCode = 0;
    });
    // delete events tasksgoal list api success
    builder.addCase(deleteEventsTasks.fulfilled, (state, action) => {
      state.deleteTaskEventsApiLoading = false;
      state.deleteTaskEventsApiSuccess = true;
      state.deleteTaskEventsApiErrorCode = action.payload.ResponseCode;
      state.deleteTaskEventsApiData = action.payload.ResponseData;
      state.deleteTaskEventsApiErrorMessage = action.payload.ResponseMessage;
    });
    // delete events tasks api failure
    builder.addCase(deleteEventsTasks.rejected, (state, action: any) => {
      state.deleteTaskEventsApiLoading = false;
      state.deleteTaskEventsApiSuccess = false;
      state.deleteTaskEventsApiErrorCode = action.payload.ResponseCode;
      state.deleteTaskEventsApiErrorMessage = action.payload.ResponseMessage;
    });
    // add goal finish api starts
    builder.addCase(addGoalFinish.pending, (state) => {
      state.addGoalFinishApiLoading = true;
      state.addGoalFinishApiSuccess = false;
      state.addGoalFinishApiResponseCode = 0;
    });
    // add goal finish api success
    builder.addCase(addGoalFinish.fulfilled, (state, action) => {
      state.addGoalFinishApiLoading = false;
      state.addGoalFinishApiSuccess = true;
      state.addGoalFinishApiResponseCode = action.payload.ResponseCode;
      state.addGoalFinishAPiData = action.payload.ResponseData;
      state.addGoalFinishApiResponseMessage = action.payload.ResponseMessage;
    });
    // add goal finish api failure
    builder.addCase(addGoalFinish.rejected, (state, action: any) => {
      state.addGoalFinishApiLoading = false;
      state.addGoalFinishApiSuccess = false;
      state.addGoalFinishApiResponseCode = action.payload.ResponseCode;
      state.addGoalFinishApiResponseMessage = action.payload.ResponseMessage;
    });
    // get goal resource api starts
    builder.addCase(getGoalResource.pending, (state) => {
      state.getGoalResourceApiLoading = true;
      state.getGoalResourceApiSuccess = false;
      state.getGoalResourcesApiErrorCode = 0;
    });
    // get  goal resource list api success
    builder.addCase(getGoalResource.fulfilled, (state, action) => {
      state.getGoalResourceApiLoading = false;
      state.getGoalResourceApiSuccess = true;
      state.getGoalResourcesApiErrorCode = action.payload.ResponseCode;
      state.getGoalResourceApiData = action.payload.ResponseData;
      state.getGoalResourceApiErrorMessage = action.payload.ResponseMessage;
    });
    // get  goal resource api failure
    builder.addCase(getGoalResource.rejected, (state, action: any) => {
      state.getGoalResourceApiLoading = false;
      state.getGoalResourceApiSuccess = false;
      state.getGoalResourcesApiErrorCode = action.payload.ResponseCode;
      state.getGoalResourceApiErrorMessage = action.payload.ResponseMessage;
    });
    // delete goal resource api starts
    builder.addCase(deleteGoalResource.pending, (state) => {
      state.deleteGoalResourceApiLoading = true;
      state.deleteGoalResourceApiSuccess = false;
      state.deleteGoalResourceApiErrorCode = 0;
    });
    // delete goal resource api success
    builder.addCase(deleteGoalResource.fulfilled, (state, action) => {
      state.deleteGoalResourceApiLoading = false;
      state.deleteGoalResourceApiSuccess = true;
      state.deleteGoalResourceApiErrorCode = action.payload.ResponseCode;
      state.deleteGoalResourceApiData = action.payload.ResponseData;
      state.deleteGoalResourceApiErrorMessage = action.payload.ResponseMessage;
    });
    // delete goal resource api failure
    builder.addCase(deleteGoalResource.rejected, (state, action: any) => {
      state.deleteGoalResourceApiLoading = false;
      state.deleteGoalResourceApiSuccess = false;
      state.deleteGoalResourceApiErrorCode = action.payload.ResponseCode;
      state.deleteGoalResourceApiErrorMessage = action.payload.ResponseMessage;
    });
    // get goal detail api starts
    builder.addCase(getGoalDetail.pending, (state) => {
      state.getGoalDetailApiLoading = true;
      state.getGoalDetailApiSuccess = false;
      state.getGoalDetailApiResponseCode = 0;
    });
    // get goal detail api success
    builder.addCase(getGoalDetail.fulfilled, (state, action) => {
      state.getGoalDetailApiLoading = false;
      state.getGoalDetailApiSuccess = true;
      state.getGoalDetailApiResponseCode = action.payload.ResponseCode;
      state.getGoalDetailAPiData = action.payload.ResponseData;
      state.getGoalDetailApiResponseMessage = action.payload.ResponseMessage;
    });
    // get goal detail api failure
    builder.addCase(getGoalDetail.rejected, (state, action: any) => {
      state.getGoalDetailApiLoading = false;
      state.getGoalDetailApiSuccess = false;
      state.getGoalDetailApiResponseCode = action.payload.ResponseCode;
      state.getGoalDetailApiResponseMessage = action.payload.ResponseMessage;
    });
    // update goal api starts
    builder.addCase(updateGoal.pending, (state) => {
      state.updateGoalApiLoading = true;
      state.updateGoalApiSuccess = false;
      state.updateGoalApiResponseCode = 0;
    });
    // update goal api success
    builder.addCase(updateGoal.fulfilled, (state, action) => {
      state.updateGoalApiLoading = false;
      state.updateGoalApiSuccess = true;
      state.updateGoalApiResponseCode = action.payload.ResponseCode;
      state.updateGoalAPiData = action.payload.ResponseData;
      state.updateGoalApiResponseMessage = action.payload.ResponseMessage;
    });
    // update goal api failure
    builder.addCase(updateGoal.rejected, (state, action: any) => {
      state.updateGoalApiLoading = false;
      state.updateGoalApiSuccess = false;
      state.updateGoalApiResponseCode = action.payload.ResponseCode;
      state.updateGoalApiResponseMessage = action.payload.ResponseMessage;
    });
    // get users by goal id api starts
    builder.addCase(getUsersByGoalId.pending, (state) => {
      state.getUsersByGoalIdApiLoading = true;
      state.getUsersByGoalIdApiSuccess = false;
      state.getUsersByGoalIdApiResponseCode = 0;
    });
    // get users by goal id api success
    builder.addCase(getUsersByGoalId.fulfilled, (state, action) => {
      state.getUsersByGoalIdApiLoading = false;
      state.getUsersByGoalIdApiSuccess = true;
      state.getUsersByGoalIdApiResponseCode = action.payload.ResponseCode;
      state.getUsersByGoalIdAPiData = action.payload.ResponseData;
      state.getUsersByGoalIdApiResponseMessage = action.payload.ResponseMessage;
    });
    // get event details api starts
    builder.addCase(getEventDetails.pending, (state) => {
      state.getEventDetailsApiLoading = true;
      state.getEventDetailsApiSuccess = false;
      state.getEventDetailsApiResponseCode = 0;
    });
    // get event details api success
    builder.addCase(getEventDetails.fulfilled, (state, action) => {
      state.getEventDetailsApiLoading = false;
      state.getEventDetailsApiSuccess = true;
      state.getEventDetailsApiResponseCode = action.payload.ResponseCode;
      state.getEventDetailsApiData = action.payload.ResponseData;
      state.getEventDetailsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get event details api failure
    builder.addCase(getEventDetails.rejected, (state, action: any) => {
      state.getEventDetailsApiLoading = false;
      state.getEventDetailsApiSuccess = false;
      state.getEventDetailsApiResponseCode = action.payload.ResponseCode;
      state.getEventDetailsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get task details api starts
    builder.addCase(getTaskDetails.pending, (state) => {
      state.getTaskDetailsApiLoading = true;
      state.getTaskDetailsApiSuccess = false;
      state.getTaskDetailsApiResponseCode = 0;
    });
    // get task details api success
    builder.addCase(getTaskDetails.fulfilled, (state, action) => {
      state.getTaskDetailsApiLoading = false;
      state.getTaskDetailsApiSuccess = true;
      state.getTaskDetailsApiResponseCode = action.payload.ResponseCode;
      state.getTaskDetailsApiData = action.payload.ResponseData;
      state.getTaskDetailsApiResponseMessage = action.payload.ResponseMessage;
    });
    // get task details api failure
    builder.addCase(getTaskDetails.rejected, (state, action: any) => {
      state.getTaskDetailsApiLoading = false;
      state.getTaskDetailsApiSuccess = false;
      state.getTaskDetailsApiResponseCode = action.payload.ResponseCode;
      state.getTaskDetailsApiResponseMessage = action.payload.ResponseMessage;
    });
    // edit task details api starts
    builder.addCase(editTaskDetails.pending, (state) => {
      state.editTaskApiLoading = true;
      state.editTaskApiSuccess = false;
      state.editTaskApiResponseCode = 0;
    });
    // edit task details api success
    builder.addCase(editTaskDetails.fulfilled, (state, action) => {
      state.editTaskApiLoading = false;
      state.editTaskApiSuccess = true;
      state.editTaskApiResponseCode = action.payload.ResponseCode;
      state.editTaskApiData = action.payload.ResponseData;
      state.editTaskApiResponseMessage = action.payload.ResponseMessage;
    });
    // edit task details api failure
    builder.addCase(editTaskDetails.rejected, (state, action: any) => {
      state.editTaskApiLoading = false;
      state.editTaskApiSuccess = false;
      state.editEventApiResponseCode = action.payload.ResponseCode;
      state.editTaskApiResponseMessage = action.payload.ResponseMessage;
    });
    // edit event details api starts
    builder.addCase(editEventDetails.pending, (state) => {
      state.editEventApiLoading = true;
      state.editEventApiSuccess = false;
      state.editEventApiResponseCode = 0;
    });
    // edit event details api success
    builder.addCase(editEventDetails.fulfilled, (state, action) => {
      state.editEventApiLoading = false;
      state.editEventApiSuccess = true;
      state.editEventApiResponseCode = action.payload.ResponseCode;
      state.editEventApiData = action.payload.ResponseData;
      state.editEventApiResponseMessage = action.payload.ResponseMessage;
    });
    // edit event details api failure
    builder.addCase(editEventDetails.rejected, (state, action: any) => {
      state.editEventApiLoading = false;
      state.editEventApiSuccess = false;
      state.editEventApiResponseCode = action.payload.ResponseCode;
      state.editEventApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete goal api starts
    builder.addCase(deleteGoal.pending, (state) => {
      state.deleteGoalApiLoading = true;
      state.deleteGoalApiSuccess = false;
      state.deleteGoalApiResponseCode = 0;
    });
    // delete goal api success
    builder.addCase(deleteGoal.fulfilled, (state, action) => {
      state.deleteGoalApiLoading = false;
      state.deleteGoalApiSuccess = true;
      state.deleteGoalApiResponseCode = action.payload.ResponseCode;
      state.deleteGoalApiData = action.payload.ResponseData;
      state.deleteGoalApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete goal api failure
    builder.addCase(deleteGoal.rejected, (state, action: any) => {
      state.deleteGoalApiLoading = false;
      state.deleteGoalApiSuccess = false;
      state.deleteGoalApiResponseCode = action.payload.ResponseCode;
      state.deleteGoalApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export actions
export const {
  resetSaveOrganization,
  resetOrganizationList,
  resetUpdateOrganization,
  resetDeleteOrganization,
  resetdeleteCategory,
  resetOrganizationInfo,
  resetUploadFile,
  resetUploadFilePathUpdate,
  resetUploadFilePathUpdateMultiple,
  resetaddGoalStepOne,
  resetSubscriptionPlans,
  resetaddGoalStepThree,
  resetaddGoalFinish,
  resetDeleteGoal,
  resetaddGoalStepTwo,
  resetUpdateGoalStepOne,
  resetGoalStepOneApiData,
  resetGoalListApiData,
  resetDeleteGoalDetail,
  resetAddTaskEventGoalDetail,
  resetEditTaskEventGoalDetail,
  resetAssignmentDelete,
  resetGoalDetail,
  resetGoalResource,
  resetGetUsersByGoalIdApi
} = organizationSlice.actions;
// Export reducer.
export default organizationSlice.reducer;
