/**
 * @file   src\store\slices\resourceSlice.ts
 * @brief  This file is responsible for creating resources based slices to call actions and state management.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  addResource,
  editResource,
  resourcedetails,
  getMotivationalList,
  getIncentiveResourceList,
  getGoalIncentiveResourceList,
  getReportedResourceList,
  getViewAllMotivationalResource,
  resolveResourceReport,
  linkResource,
  deleteResource,
  deleteResourceAttachment,
} from '../actions/resourceActions';
import { IResourceSlice } from '../../interfaces/ResourceInterface';

// Initial state of resource slice
const resourceDefaultState: IResourceSlice = {
  addResourceApiData: {},
  addResourceApiLoading: false,
  addResourceApiResponseCode: 0,
  addResourceApiResponseMessage: '',
  addResourceApiSuccess: false,
  editResourceApiData: {},
  editResourceApiLoading: false,
  editResourceApiResponseCode: 0,
  editResourceApiResponseMessage: '',
  editResourceApiSuccess: false,
  resourceDetailApiLoading: false,
  resourceDetailViewApiSuccess: false,
  resourceDetailViewApiResponseCode: 0,
  resourceDetailViewApiResponseMessage: '',
  resourceDetail: null,
  motivationalList: [],
  motivationalListApiResponseCode: 0,
  motivationalListApiLoading: false,
  motivationalListApiSuccess: false,
  motivationalListApiResponseMessage: '',
  incentiveResourceList: [],
  incentiveResourceListApiResponseCode: 0,
  incentiveResourceListApiLoading: false,
  incentiveResourceListApiSuccess: false,
  incentiveResourceListApiResponseMessage: '',
  incentiveResourceListGoal: [],
  incentiveResourceListGoalApiResponseCode: 0,
  incentiveResourceListGoalApiLoading: false,
  incentiveResourceListGoalApiSuccess: false,
  incentiveResourceListGoalApiResponseMessage: '',
  reportedResourceList: [],
  reportedResourceListApiResponseCode: 0,
  reportedResourceListApiLoading: false,
  reportedResourceListApiSuccess: false,
  reportedResourceListApiResponseMessage: '',
  motivationalViewAllData: [],
  motivationalViewAllApiResponseCode: 0,
  motivationalViewAllApiLoading: false,
  motivationalViewAllApiSuccess: false,
  motivationalViewAllApiResponseMessage: '',
  resolveReportApiData: {},
  resolveReportApiErrorCode: 0,
  resolveReportApiErrorMessage: '',
  resolveReportApiLoading: false,
  resolveReportApiSuccess: false,
  linkResourceApiData: {},
  linkResourceApiResponseCode: 0,
  linkResourceApiResponseMessage: '',
  linkResourceApiLoading: false,
  linkResourceApiSuccess: false,
  deleteResourceApiData: {},
  deleteResourceApiResponseCode: 0,
  deleteResourceApiLoading: false,
  deleteResourceApiSuccess: false,
  deleteResourceApiResponseMessage: '',
  deleteResourceAttachmentApiData: {},
  deleteResourceAttachmentApiResponseCode: 0,
  deleteResourceAttachmentApiLoading: false,
  deleteResourceAttachmentApiSuccess: false,
  deleteResourceAttachmentApiResponseMessage: '',
};

// Resource slice
const resourceSlice = createSlice({
  name: 'resource',
  initialState: resourceDefaultState,
  reducers: {
    resetResourceAdd: (state) => {
      state.addResourceApiData = {};
      state.addResourceApiLoading = false;
      state.addResourceApiResponseCode = 0;
      state.addResourceApiResponseMessage = '';
      state.addResourceApiSuccess = false;
    },
    resetResourceEdit: (state) => {
      state.editResourceApiData = {};
      state.editResourceApiLoading = false;
      state.editResourceApiResponseCode = 0;
      state.editResourceApiResponseMessage = '';
      state.editResourceApiSuccess = false;
    },
    resetResourceDetail: (state) => {
      state.resourceDetail = {};
      state.resourceDetailApiLoading = false;
      state.resourceDetailViewApiResponseCode = 0;
      state.resourceDetailViewApiResponseMessage = '';
      state.resourceDetailViewApiSuccess = false;
    },
    resetMotivationalList: (state) => {
      state.motivationalList = [];
      state.motivationalListApiResponseCode = 0;
      state.motivationalListApiLoading = false;
      state.motivationalListApiSuccess = false;
      state.motivationalListApiResponseMessage = '';
    },
    resetIncentiveResourceList: (state) => {
      state.incentiveResourceList = [];
      state.incentiveResourceListApiResponseCode = 0;
      state.incentiveResourceListApiLoading = false;
      state.incentiveResourceListApiSuccess = false;
      state.incentiveResourceListApiResponseMessage = '';
    },
    resetIncentiveResourceListGoal: (state) => {
      state.incentiveResourceListGoal = [];
      state.incentiveResourceListGoalApiResponseCode = 0;
      state.incentiveResourceListGoalApiLoading = false;
      state.incentiveResourceListGoalApiSuccess = false;
      state.incentiveResourceListGoalApiResponseMessage = '';
    },
    resetReportedResourceList: (state) => {
      state.reportedResourceList = [];
      state.reportedResourceListApiResponseCode = 0;
      state.reportedResourceListApiLoading = false;
      state.reportedResourceListApiSuccess = false;
      state.reportedResourceListApiResponseMessage = '';
    },
    resetMotivationalViewAll: (state) => {
      state.motivationalViewAllData = [];
      state.motivationalViewAllApiResponseCode = 0;
      state.motivationalViewAllApiLoading = false;
      state.motivationalViewAllApiSuccess = false;
      state.motivationalViewAllApiResponseMessage = '';
    },
    resetResolveReport: (state) => {
      state.resolveReportApiData = {};
      state.resolveReportApiErrorCode = 0;
      state.resolveReportApiErrorMessage = '';
      state.resolveReportApiLoading = false;
      state.resolveReportApiSuccess = false;
    },
    resetLinkResource: (state) => {
      state.linkResourceApiData = {};
      state.linkResourceApiResponseCode = 0;
      state.linkResourceApiResponseMessage = '';
      state.linkResourceApiLoading = false;
      state.linkResourceApiSuccess = false;
    },
    resetDeleteResource: (state) => {
      state.deleteResourceApiData = {};
      state.deleteResourceApiResponseCode = 0;
      state.deleteResourceApiResponseMessage = '';
      state.deleteResourceApiLoading = false;
      state.deleteResourceApiSuccess = false;
    },
    resetDeleteResourceAttachment: (state) => {
      state.deleteResourceAttachmentApiData = {};
      state.deleteResourceApiResponseCode = 0;
      state.deleteResourceAttachmentApiResponseMessage = '';
      state.deleteResourceAttachmentApiLoading = false;
      state.deleteResourceAttachmentApiSuccess = false;
    },
  },
  extraReducers(builder) {
    // resource add  api starts
    builder.addCase(addResource.pending, (state) => {
      state.addResourceApiLoading = true;
      state.addResourceApiSuccess = false;
      state.addResourceApiResponseCode = 0;
    });
    // resource add api success
    builder.addCase(addResource.fulfilled, (state, action) => {
      state.addResourceApiLoading = false;
      state.addResourceApiSuccess = true;
      state.addResourceApiResponseCode = action.payload.ResponseCode;
      state.addResourceApiData = action.payload.ResponseData;
      state.addResourceApiResponseMessage = action.payload.ResponseMessage;
    });
    // resource add api failure
    builder.addCase(addResource.rejected, (state, action: any) => {
      state.addResourceApiLoading = false;
      state.addResourceApiSuccess = false;
      state.addResourceApiResponseCode = action.payload.ResponseCode;
      state.addResourceApiResponseMessage = action.payload.ResponseMessage;
    });
    // resource edit  api starts
    builder.addCase(editResource.pending, (state) => {
      state.editResourceApiLoading = true;
      state.editResourceApiSuccess = false;
      state.editResourceApiResponseCode = 0;
    });
    // resource edit api success
    builder.addCase(editResource.fulfilled, (state, action) => {
      state.editResourceApiLoading = false;
      state.editResourceApiSuccess = true;
      state.editResourceApiResponseCode = action.payload.ResponseCode;
      state.editResourceApiData = action.payload.ResponseData;
      state.editResourceApiResponseMessage = action.payload.ResponseMessage;
    });
    // resource edit api failure
    builder.addCase(editResource.rejected, (state, action: any) => {
      state.editResourceApiLoading = false;
      state.editResourceApiSuccess = false;
      state.editResourceApiResponseCode = action.payload.ResponseCode;
      state.editResourceApiResponseMessage = action.payload.ResponseMessage;
    });
    // resource details api starts
    builder.addCase(resourcedetails.pending, (state) => {
      state.resourceDetailApiLoading = true;
      state.resourceDetailViewApiSuccess = false;
      state.resourceDetailViewApiResponseCode = 0;
    });
    // resource details api success
    builder.addCase(resourcedetails.fulfilled, (state, action) => {
      state.resourceDetailApiLoading = false;
      state.resourceDetailViewApiSuccess = true;
      state.resourceDetailViewApiResponseCode = action.payload.ResponseCode;
      state.resourceDetail = action.payload.ResponseData;
    });
    // resource details api failure
    builder.addCase(resourcedetails.rejected, (state, action: any) => {
      state.resourceDetailApiLoading = false;
      state.resourceDetailViewApiSuccess = false;
      state.resourceDetailViewApiResponseCode = action.payload.ResponseCode;
      state.resourceDetailViewApiResponseMessage = action.payload.ResponseMessage;
    });
    // motivational list api starts
    builder.addCase(getMotivationalList.pending, (state) => {
      state.motivationalListApiLoading = true;
      state.motivationalListApiSuccess = false;
      state.motivationalListApiResponseCode = 0;
    });
    //  motivational list  api success
    builder.addCase(getMotivationalList.fulfilled, (state, action) => {
      state.motivationalListApiLoading = false;
      state.motivationalListApiSuccess = true;
      state.motivationalListApiResponseCode = action.payload.ResponseCode;
      state.motivationalList = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    //  motivational list failure
    builder.addCase(getMotivationalList.rejected, (state, action: any) => {
      state.motivationalListApiLoading = false;
      state.motivationalListApiSuccess = false;
      state.motivationalListApiResponseCode = action.payload.ResponseCode;
      state.motivationalListApiResponseMessage = action.payload.ResponseMessage;
    });
    // IncentiveResource list api starts
    builder.addCase(getGoalIncentiveResourceList.pending, (state) => {
      state.incentiveResourceListGoalApiLoading = true;
      state.incentiveResourceListGoalApiSuccess = false;
      state.incentiveResourceListGoalApiResponseCode = 0;
    });
    //  Incentive Resource list  api success
    builder.addCase(getGoalIncentiveResourceList.fulfilled, (state, action) => {
      state.incentiveResourceListGoalApiLoading = false;
      state.incentiveResourceListGoalApiSuccess = true;
      state.incentiveResourceListGoalApiResponseCode = action.payload.ResponseCode;
      state.incentiveResourceListGoal = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    //  Incentive Resource list failure
    builder.addCase(getGoalIncentiveResourceList.rejected, (state, action: any) => {
      state.incentiveResourceListGoalApiLoading = false;
      state.incentiveResourceListGoalApiSuccess = false;
      state.incentiveResourceListGoalApiResponseCode = action.payload.ResponseCode;
      state.incentiveResourceListGoalApiResponseMessage = action.payload.ResponseMessage;
    });
    // IncentiveResource list api starts
    builder.addCase(getIncentiveResourceList.pending, (state) => {
      state.incentiveResourceListApiLoading = true;
      state.incentiveResourceListApiSuccess = false;
      state.incentiveResourceListApiResponseCode = 0;
    });
    //  Incentive Resource list  api success
    builder.addCase(getIncentiveResourceList.fulfilled, (state, action) => {
      state.incentiveResourceListApiLoading = false;
      state.incentiveResourceListApiSuccess = true;
      state.incentiveResourceListApiResponseCode = action.payload.ResponseCode;
      state.incentiveResourceList = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    //  Incentive Resource list failure
    builder.addCase(getIncentiveResourceList.rejected, (state, action: any) => {
      state.incentiveResourceListApiLoading = false;
      state.incentiveResourceListApiSuccess = false;
      state.incentiveResourceListApiResponseCode = action.payload.ResponseCode;
      state.incentiveResourceListApiResponseMessage = action.payload.ResponseMessage;
    });
    // ReportedResource list api starts
    builder.addCase(getReportedResourceList.pending, (state) => {
      state.reportedResourceListApiLoading = true;
      state.reportedResourceListApiSuccess = false;
      state.reportedResourceListApiResponseCode = 0;
    });
    //  Reported Resource list  api success
    builder.addCase(getReportedResourceList.fulfilled, (state, action) => {
      state.reportedResourceListApiLoading = false;
      state.reportedResourceListApiSuccess = true;
      state.reportedResourceListApiResponseCode = action.payload.ResponseCode;
      state.reportedResourceList = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    //  Reported Resource list failure
    builder.addCase(getReportedResourceList.rejected, (state, action: any) => {
      state.reportedResourceListApiLoading = false;
      state.reportedResourceListApiSuccess = false;
      state.reportedResourceListApiResponseCode = action.payload.ResponseCode;
      state.reportedResourceListApiResponseMessage = action.payload.ResponseMessage;
    });
    // Motivational Resource view all api starts
    builder.addCase(getViewAllMotivationalResource.pending, (state) => {
      state.motivationalViewAllApiLoading = true;
      state.motivationalViewAllApiSuccess = false;
      state.motivationalViewAllApiResponseCode = 0;
    });
    // Motivational Resource view all  api success
    builder.addCase(getViewAllMotivationalResource.fulfilled, (state, action) => {
      state.motivationalViewAllApiLoading = false;
      state.motivationalViewAllApiSuccess = true;
      state.motivationalViewAllApiResponseCode = action.payload.ResponseCode;
      state.motivationalViewAllData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    //  Motivational Resource view all failure
    builder.addCase(getViewAllMotivationalResource.rejected, (state, action: any) => {
      state.motivationalViewAllApiLoading = false;
      state.motivationalViewAllApiSuccess = false;
      state.motivationalViewAllApiResponseCode = action.payload.ResponseCode;
      state.motivationalViewAllApiResponseMessage = action.payload.ResponseMessage;
    });
    // resolve resource report api starts
    builder.addCase(resolveResourceReport.pending, (state) => {
      state.resolveReportApiLoading = true;
      state.resolveReportApiSuccess = false;
      state.resolveReportApiErrorCode = 0;
    });
    // resolve resource report  api success
    builder.addCase(resolveResourceReport.fulfilled, (state, action) => {
      state.resolveReportApiLoading = false;
      state.resolveReportApiSuccess = true;
      state.resolveReportApiErrorCode = action.payload.ResponseCode;
      state.resolveReportApiData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
      state.resolveReportApiErrorMessage = action.payload.ResponseMessage;
    });
    //  resolve resource report failure
    builder.addCase(resolveResourceReport.rejected, (state, action: any) => {
      state.resolveReportApiLoading = false;
      state.resolveReportApiSuccess = false;
      state.resolveReportApiErrorCode = action.payload.ResponseCode;
      state.resolveReportApiErrorMessage = action.payload.ResponseMessage;
    });
    // link resource report api starts
    builder.addCase(linkResource.pending, (state) => {
      state.linkResourceApiLoading = true;
      state.linkResourceApiSuccess = false;
      state.linkResourceApiResponseCode = 0;
    });
    // link resource report  api success
    builder.addCase(linkResource.fulfilled, (state, action) => {
      state.linkResourceApiLoading = false;
      state.linkResourceApiSuccess = true;
      state.linkResourceApiResponseCode = action.payload.ResponseCode;
      state.linkResourceApiData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
      state.linkResourceApiResponseMessage = action.payload.ResponseMessage;
    });
    //  link resource report failure
    builder.addCase(linkResource.rejected, (state, action: any) => {
      state.linkResourceApiLoading = false;
      state.linkResourceApiSuccess = false;
      state.linkResourceApiResponseCode = action.payload.ResponseCode;
      state.linkResourceApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete resource api starts
    builder.addCase(deleteResource.pending, (state) => {
      state.deleteResourceApiLoading = true;
      state.deleteResourceApiSuccess = false;
      state.deleteResourceApiResponseCode = 0;
    });
    // delete resource api success
    builder.addCase(deleteResource.fulfilled, (state, action) => {
      state.deleteResourceApiLoading = false;
      state.deleteResourceApiSuccess = true;
      state.deleteResourceApiResponseCode = action.payload.ResponseCode;
      state.deleteResourceApiData = action.payload.ResponseData;
      state.deleteResourceApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete resource api failure
    builder.addCase(deleteResource.rejected, (state, action: any) => {
      state.deleteResourceApiLoading = false;
      state.deleteResourceApiSuccess = false;
      state.deleteResourceApiResponseCode = action.payload.ResponseCode;
      state.deleteResourceApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete resource attachment api starts
    builder.addCase(deleteResourceAttachment.pending, (state) => {
      state.deleteResourceAttachmentApiLoading = true;
      state.deleteResourceAttachmentApiSuccess = false;
      state.deleteResourceAttachmentApiResponseCode = 0;
    });
    // delete resource attachment api success
    builder.addCase(deleteResourceAttachment.fulfilled, (state, action) => {
      state.deleteResourceAttachmentApiLoading = false;
      state.deleteResourceAttachmentApiSuccess = true;
      state.deleteResourceAttachmentApiResponseCode = action.payload.ResponseCode;
      state.deleteResourceAttachmentApiData = action.payload.ResponseData;
      state.deleteResourceAttachmentApiResponseMessage = action.payload.ResponseMessage;
    });
    // delete resource attachment api failure
    builder.addCase(deleteResourceAttachment.rejected, (state, action: any) => {
      state.deleteResourceAttachmentApiLoading = false;
      state.deleteResourceAttachmentApiSuccess = false;
      state.deleteResourceAttachmentApiResponseCode = action.payload.ResponseCode;
      state.deleteResourceAttachmentApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});
// Export actions
export const {
  resetResourceAdd,
  resetMotivationalList,
  resetIncentiveResourceList,
  resetIncentiveResourceListGoal,
  resetReportedResourceList,
  resetMotivationalViewAll,
  resetResolveReport,
  resetLinkResource,
  resetDeleteResource,
  resetDeleteResourceAttachment,
  resetResourceEdit,
  resetResourceDetail,
} = resourceSlice.actions;
// Export reducer.
export default resourceSlice.reducer;
