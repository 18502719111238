/**
 * @file   src\store\actions\userActions.ts
 * @brief  This file is responsible for creating asynchronous users based api call.
 * @date   FEB, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { UserApis, OrganizationApi } from '../../utils/apiUrls';
import { IUserForm, ISystemAdminForm, IAssignOrgsRequest } from '../../interfaces/UserInterface';
// Api call to get user list
export const fetchUsersList = createAsyncThunk('/listusers', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.LIST_USERS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to add single user
export const addSingleUser = createAsyncThunk('/adduser', async (request: IUserForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.ADD_SINGLE_USER}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get user details
export const userdetails = createAsyncThunk('/userdetails/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.VIEW_USERS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to add system admin.
export const addSystemAdmin = createAsyncThunk('/addsystemadmin', async (request: ISystemAdminForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.ADD_SYSTEM_ADMIN}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

export const getProfileSettings = createAsyncThunk('/getProfileSettings/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.GET_PROFILE_SETTINGS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to user bulk upload.
export const usersBulkUpload = createAsyncThunk('/usersBulkUpload', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.USER_BULK_UPLOAD}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get accountability partners related to students
export const usersHierarchyStudents = createAsyncThunk('/usershierarchystudents/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.ACC_PARTNERS_FOR_STUDENTS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get list of organizations assigned to admin
export const orgListForAdmins = createAsyncThunk('/orglistforadmins', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApis.ORG_LIST_FOR_ADMINS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to assign organizations to org admins
export const assignOrganizations = createAsyncThunk('/assignorgsforadmin', async (request: IAssignOrgsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApis.ASSIGN_ORGS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get students related to accountability partners
export const usersHierarchyAcPartners = createAsyncThunk('/usershierarchyaccpartners/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.STUDENTS_FOR_ACPARTNERS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
