import '../assets/css/Header.scss';
import { Nav, NavDropdown } from 'react-bootstrap';
import ProfilePic from '../assets/img/ProfilePic.svg';
import { end } from '@popperjs/core';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { resetLogin } from '../store/slices/authSlice';
import { slide as Filter } from 'react-burger-menu';
import Close from '../assets/img/Close.svg';
import Orgimg from '../assets/img/Org-image.png';
import GlobUni from '../assets/img/glo-uni.png';
import ListCard from '../components/OrgListCard';
import AmeUni from '../assets/img/ame-uni.png';
import ChildAmer from '../assets/img/chi-amer-sc.png';
import EmpAcad from '../assets/img/emp-acad.png';
import IntAcada from '../assets/img/int-acad.png';
import { isUserAdmin, removeItemLocalStorage, getFromLocalStorage, capitalizeFirstLetters, setItemLocalStorage } from '../utils/helper';
import { BUCKET_URL } from '../utils/constants';
import LogoutIcon from '../assets/img/icon/LogoutIcon';
import MyProfileIcon from '../assets/img/icon/MyProfile';
import OrganizationIcon from '../assets/img/icon/Organization';
import HelpIcon from '../assets/img/icon/Help';
import { RoleTypeIds } from '../utils/enums';
import { orgListForAdmins } from '../store/actions/userActions';
import { RootState } from '../store';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useEffect, useState } from 'react';
import Loader from './Loader';
import ConfirmationPopUp from './DeleteConfirmation';
import { resetOrgListForAdmins } from '../store/slices/userSlice';
import { IOrgForAdminInfo } from '../interfaces/OrganisationInterface';
import { getCurrentOrgDetails, isOrgAdmin } from '../utils/helper';

const Header = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  //  Action dispatch object creation
  const dispatch = useAppDispatch();

  const userData = getFromLocalStorage('MI_USR_DATA');
  const profilePicData = getFromLocalStorage('profile_image_url');
  const teacherRoles = [RoleTypeIds.APP_USER_MANAGER, RoleTypeIds.ORGANIZATION_ADMIN, RoleTypeIds.ORGANIZATION_MANGER];

  // Accessing redux state variables
  const { orgListForAdminsApiLoading, orgListForAdminsApiData, orgListForAdminsApiSuccess } = useAppSelector((state: RootState) => state.user);
  // component state variables
  const [isOpen, setOpen] = useState<boolean>(false);
  const [switchConfirm, setSwitchConfirm] = useState<boolean>(false);
  const [orgInfo, setOrgInfo] = useState<IOrgForAdminInfo>({
    OrganizationID: '',
    OrganizationName: '',
    LogoImage: '',
    Address: '',
  });
  // open side menu to show list of organizations for switching
  useEffect(() => {
    if (isOrgAdmin()) {
      dispatch(
        orgListForAdmins({
          OrgAdminID: userData.UserId,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (orgListForAdminsApiSuccess && orgListForAdminsApiData.length !== 0) {
      const currentOrg = orgListForAdminsApiData.filter((org: IOrgForAdminInfo) => org.OrganizationID == userData.OrganizationId);
      if (currentOrg[0]) {
        setOrgInfo(currentOrg[0]);
        setItemLocalStorage('CURRENT_ORG', currentOrg[0]);
      }
    }
  }, [orgListForAdminsApiLoading]);
  // handle user logout
  const handleLogout = () => {
    dispatch(resetLogin());
    dispatch(resetOrgListForAdmins());
    removeItemLocalStorage('MI_USR_DATA');
    removeItemLocalStorage('ORG_DETAIL');
    removeItemLocalStorage('CURRENT_ORG');
    removeItemLocalStorage('profile_image_url');
    removeItemLocalStorage('org_admin_org_id');
    navigate('/signin');
  };
  // get url to display profile iamge in header
  const getProfileImage = () => {
    let url = '';
    if (userData && userData?.ProfileImage) {
      url = BUCKET_URL + userData.ProfileImage;
    } else if (profilePicData && profilePicData?.url) {
      if (profilePicData?.url.startsWith('user')) {
        url = BUCKET_URL + profilePicData.url;
      } else {
        url = BUCKET_URL + 'user/profile/' + profilePicData.url;
      }
    } else {
      url = ProfilePic;
    }
    return url;
  };
  // get full name of logged in user for display
  const getFullName = () => {
    let fullName;
    if (userData?.Firstname || userData?.Lastname) {
      const name = userData?.Firstname + ' ' + userData?.Lastname;
      fullName = capitalizeFirstLetters(name);
    } else if (profilePicData?.first_name || profilePicData?.last_name) {
      const name = profilePicData?.first_name + ' ' + profilePicData?.last_name;
      fullName = capitalizeFirstLetters(name);
    }
    return fullName;
  };
  // Get list of organisations assigned to org admin
  const getOrgListOfAdmin = () => {
    if (orgListForAdminsApiData.length == 0) {
      dispatch(
        orgListForAdmins({
          OrgAdminID: userData.UserId,
        }),
      );
    } else if (orgListForAdminsApiData.length > 0) {
      setOpen(true);
    }
  };
  // show switch organization
  const handleSwitchOrganisation = (orgInfo: any) => {
    setOrgInfo(orgInfo);
    setOpen(false);
    setSwitchConfirm(true);
  };
  // redirect to home page after switching organisation
  const redirectAfterSwitch = () => {
    setSwitchConfirm(false);
    setItemLocalStorage('CURRENT_ORG', orgInfo);
    navigate('/vieworganization');
  };
  // get top org details
  const getTopOrg = () => {
    let details;
    const orgDetails = getFromLocalStorage('CURRENT_ORG');
    if (orgDetails !== null) {
      details = orgDetails;
    } else {
      const currentOrg = orgListForAdminsApiData.filter((org: IOrgForAdminInfo) => org.OrganizationID == userData.OrganizationId);
      details = currentOrg[0];
    }
    return details;
  };
  // get header drop down menus for roles org admin ,org manager and teacher
  const getHeaderMenusForTeacher = () => {
    return (
      <>
        <NavDropdown.Item onClick={() => navigate('/myprofile')}>
          <MyProfileIcon /> My Profile
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate('/myorganization')}>
          <OrganizationIcon /> My Organization
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => window.alert('Not Implemented')}>
          <HelpIcon /> Help and Support
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => handleLogout()}>
          <LogoutIcon /> Sign Out
        </NavDropdown.Item>
      </>
    );
  };
  return (
    <div className="header-main position-fixed d-flex justify-content-between">
      {orgListForAdminsApiLoading && <Loader />}
      <ConfirmationPopUp
        title="Switch Organisation ?"
        content={`Are you sure you want to switch to this organization? Data related to the ${orgInfo.OrganizationName} organization will be shown further.`}
        show={switchConfirm}
        handleClose={() => setSwitchConfirm(false)}
        onOkay={redirectAfterSwitch}
        onCancel={() => setSwitchConfirm(false)}
      />
      <div>
        <div className="custom-berger-menu header-org me-3 ">
          {isOrgAdmin() && orgListForAdminsApiSuccess && orgListForAdminsApiData.length !== 0 && (
            <Filter
              width={450}
              isOpen={isOpen}
              right
              pageWrapId={'filter-wrapper'}
              outerContainerId={'outer-container'}
              customCrossIcon={<img src={Close} />}
              onOpen={() => getOrgListOfAdmin()}
              onClose={() => setOpen(false)}
              customBurgerIcon={
                <div className="org-selected">
                  <img className="thumbnail-image" src={BUCKET_URL + getTopOrg().LogoImage} alt="user pic" />
                  <div className="pt-1">
                    <h6 className="mb-1">{getTopOrg().OrganizationName}</h6>
                    <small>{getTopOrg().Address}</small>
                  </div>
                </div>
              }
            >
              <div id="filter-wrapper" className="filter-main">
                <h4 className="mb-5">
                  <FormattedMessage id="Hd.Organization" />
                </h4>
                {orgListForAdminsApiSuccess &&
                  orgListForAdminsApiData &&
                  orgListForAdminsApiData.map((organisation: IOrgForAdminInfo) => (
                    <ListCard
                      image={organisation.LogoImage}
                      label={organisation.OrganizationName}
                      sublabel={organisation.Address}
                      handleClick={() => handleSwitchOrganisation(organisation)}
                      selected={orgInfo.OrganizationID === organisation.OrganizationID}
                    />
                  ))}
              </div>
            </Filter>
          )}
        </div>
      </div>
      <Nav>
        <NavDropdown
          align={end}
          className="profile-menu"
          id="nav-dropdown-dark-example"
          title={
            <div className="profile-dropdown">
              <img className="header-user-image" src={getProfileImage()} alt="user pic" />
              <div>
                <p>{getFullName()}</p>
                <span>{userData && userData?.RoleName}</span>
              </div>
            </div>
          }
        >
          {teacherRoles.includes(userData.RoleTypeId) ? (
            getHeaderMenusForTeacher()
          ) : (
            <>
              <NavDropdown.Item onClick={() => navigate('/myprofile')}>
                <FormattedMessage id="Link.Profile" />
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleLogout()}>Sign out</NavDropdown.Item>
            </>
          )}
        </NavDropdown>
      </Nav>
    </div>
  );
};
export default Header;
