/**
 * @file   src\containers\ProfileCreation.tsx
 * @brief  Profile Creation page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { Row, Col, Button } from 'react-bootstrap';
import '../../assets/css/myprof.scss';
import '../../assets/css/ProfileCreation.scss';
import { slide as Filter } from 'react-burger-menu';
import usrAvatar from '../../assets/img/icn-avatar.svg';
import UserImg from '../../assets/img/Userimg.jpg';
import radioCheck from '../../assets/img/radio-checked.svg';
import Close from '../../assets/img/Close.svg';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import DatePicker from 'react-datepicker';
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { getStates, getCities } from '../../store/actions/organizationActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { ISelectOptionsNumber, ISelectOption } from '../../interfaces/GeneralInterface';
import * as yup from 'yup';
import { IProfileForm, IAvatar } from '../../interfaces/ProfileInterface';
import { validateForm } from '../../utils/formValidation';
import { getAvatarsList, createTeacherProfile, sendOtpToPhone } from '../../store/actions/profileActions';
import { DATE_FORMAT1, BUCKET_URL } from '../../utils/constants';
import moment from 'moment';
import { MessageToaster } from '../../utils/ToastUtil';
import { uploadFile, uploadFilePathUpdate } from '../../store/actions/organizationActions';
import { getFromLocalStorage, useIntlMessages, setItemLocalStorage } from '../../utils/helper';
import Loader from '../../components/Loader';
import { resetProfileCreation } from '../../store/slices/profileSlice';
import { resetaddGoalStepOne } from '../../store/slices/organizationSlice';
import { userdetails, getProfileSettings } from '../../store/actions/userActions';
import { CountryCodes, GenderValues } from '../../utils/enums';
import { PHONE_NUMBER_REGEX, ZIP_REGEX_NEW, USERNAME_REGEX } from '../../utils/constants';
import { useIntlActionMessages } from '../../utils/helper';
import { ProfileColumn } from '../../utils/enums';
import { Breadcrumb } from 'react-bootstrap';
import { RoleTypeIds } from '../../utils/enums';
import { addProfileForm, addProfileImgDatas } from '../../store/slices/profileSlice';
import { IUploadFilePathUpdateParams } from '../../interfaces/OrganisationInterface';
import CreatableSelect from 'react-select/creatable';

const genderOptions = [
  { label: 'Male', value: GenderValues.MALE },
  { label: 'Female', value: GenderValues.FEMALE },
];
const countryCodeOptions = [
  { label: '+1 - US', value: CountryCodes.US },
  { label: '+91 - IND', value: CountryCodes.IND },
];
const Profilecreation = () => {
  // Route navigation object creation
  const navigate = useNavigate();
  // location info object
  const location = useLocation();
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Message toaster object creation
  const toastObj = new MessageToaster();

  // Accessing redux state variables
  const {
    listStatesApiData,
    listStatesApiLoading,
    listStatesApiSuccess,
    listCitiesApiData,
    listCitiesApiLoading,
    listCitiesApiSuccess,
    uploadFileApiData,
    uploadFileApiLoading,
    uploadFileApiSuccess,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);
  const {
    createProfileApiLoading,
    createProfileApiSuccess,
    getAvatarsApiData,
    getAvatarsApiSuccess,
    validateTokenApiData,
    phoneOtpApiLoading,
    phoneOtpApiResponseCode,
    phoneOtpApiResponseMessage,
    phoneOtpApiSuccess,
  } = useAppSelector((state: RootState) => state.profile);

  const { userDetail, userDetailApiLoading, userDetailViewApiSuccess, profileSettingsApiLoading, profileSettingsApiSuccess, profileSettingsApiData } = useAppSelector(
    (state: RootState) => state.user,
  );

  // messages from assets file
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  const profileAddedMessage = useIntlMessages('ProfileCreate.Success.Message');
  const fnameRequired = useIntlMessages('ProfileForm.FirstName.Required');
  const lnameRequired = useIntlMessages('ProfileForm.SecondName.Required');
  const usernameRequired = useIntlMessages('ProfileForm.Username.Required');
  const stateRequired = useIntlMessages('ProfileForm.Select.State');
  const cityRequired = useIntlMessages('ProfileForm.Select.City');
  const dobRequired = useIntlMessages('ProfileForm.Select.Dob');
  const genderRequired = useIntlMessages('ProfileForm.Select.Gender');
  const addressRequired = useIntlMessages('ProfileForm.Select.Address');
  const zipRequired = useIntlMessages('ProfileForm.Select.Zip');
  const phoneRequired = useIntlMessages('ProfileForm.Phone.Required');
  const imageRequired = useIntlMessages('ProfileForm.Image.Required');
  const ccodeRequired = useIntlMessages('ProfileForm.CountryCode.Required');
  const highestEducationRequired = useIntlMessages('ProfileForm.HighestEducation.Required');
  // Component state variables
  const [dob, setDob] = useState<Date | null>(null);
  const [stateOptions, setStateOptions] = useState<ISelectOptionsNumber[]>([]);
  const [selectedTags, setSelectedTags] = useState<ISelectOption[]>([]);
  const [profileForm, setProfileForm] = useState<IProfileForm>({
    Firstname: '',
    Lastname: '',
    Username: '',
    PhoneNumber: '',
    Gender: '',
    DateOfBirth: '',
    CityId: 0,
    CityName: '',
    StateId: 0,
    Address: '',
    Zip: '',
    CountryCode: '',
    HighestEducation: '',
  });

  const [errorFields, setErrorFields] = useState<any>({
    Firstname: fnameRequired,
    Lastname: lnameRequired,
    Username: usernameRequired,
    CountryCode: ccodeRequired,
    PhoneNumber: phoneRequired,
    Gender: '',
    DateOfBirth: '',
    CityId: '',
    StateId: '',
    Address: '',
    Zip: '',
    HighestEducation: '',
  });
  const [stateValue, setStateValue] = useState<ISelectOptionsNumber[]>([]);
  const [genderValue, setGenderValue] = useState<ISelectOption[]>([]);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<string>('');
  const [avatarIndex, setAvatarIndex] = useState<number>(15);
  const [avatarUrl, setAvatarUrl] = useState<string>('');
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [profileImgName, setProfileImgName] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<ISelectOption[]>([]);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const userData = getFromLocalStorage('MI_USR_DATA');
  const textFirstNameInputRef = useRef<HTMLInputElement>(null);
  const CREATE_PROFILE_SCHEMA = yup.object({
    Firstname: yup.string().trim().required().min(3).max(100),
    Lastname: yup.string().trim().required().min(3).max(100),
    Username: yup.string().trim().required().matches(USERNAME_REGEX, useIntlActionMessages('Form.Username.Valid')).min(3).max(100),
    PhoneNumber: yup.string().required(useIntlActionMessages('Form.Phoneno.Required')).matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Form.Phoneno.Required')),
    Address: yup
      .string()
      .max(200)
      .test('validator-address-required', function (value) {
        const validation = IsMandatoryColumn(ProfileColumn.Address);
        if (validation == true) {
          if (value?.length === 0) {
            return this.createError({
              path: this.path,
              message: useIntlActionMessages('Form.Address.Required'),
            });
          } else {
            if (value !== null && value !== undefined && value.length < 5) {
              return this.createError({
                path: this.path,
                message: useIntlActionMessages('Form.Address.Min'),
              });
            } else {
              return true;
            }
          }
        } else {
          if (value !== null && value !== undefined && value.length > 0 && value.length < 5) {
            return this.createError({
              path: this.path,
              message: useIntlActionMessages('Form.Address.Min'),
            });
          } else {
            return true;
          }
        }
      }),
    CityId: yup.number().test('validator-city-required', function (value) {
      const validation = IsMandatoryColumn(ProfileColumn.City);
      if (validation == true) {
        if (value !== null && value !== undefined && value > 0) {
          return true;
        } else {
          return this.createError({
            path: this.path,
            message: useIntlActionMessages('ProfileForm.Select.City'),
          });
        }
      } else return true;
    }),
    StateId: yup.number().test('validator-state-required', function (value) {
      const validation = IsMandatoryColumn(ProfileColumn.State);
      if (validation == true) {
        if (value !== null && value !== undefined && value > 0) {
          return true;
        } else {
          return this.createError({
            path: this.path,
            message: useIntlActionMessages('ProfileForm.Select.State'),
          });
        }
      } else return true;
    }),
    DateOfBirth: yup.string().test('validator-dob-required', function (value) {
      const validation = IsMandatoryColumn(ProfileColumn.DOB);
      if (validation == true) {
        if (value?.length === 0) {
          return this.createError({
            path: this.path,
            message: useIntlActionMessages('ProfileForm.Select.Dob'),
          });
        } else return true;
      } else return true;
    }),
    Zip: yup
      .string()
      .matches(ZIP_REGEX_NEW, useIntlActionMessages('Form.Zip.Invalid'))
      .test('validator-zip-required', function (value) {
        const validation = IsMandatoryColumn(ProfileColumn.Zip);
        if (validation == true && value?.length === 0) {
          return this.createError({
            path: this.path,
            message: useIntlActionMessages('Form.Zip.Required'),
          });
        } else return true;
      }),
    Gender: yup.string().test('validator-gender-required', function (value) {
      const validation = IsMandatoryColumn(ProfileColumn.Gender);
      if (validation == true) {
        if (value?.length === 0) {
          return this.createError({
            path: this.path,
            message: useIntlActionMessages('ProfileForm.Select.Gender'),
          });
        } else return true;
      } else return true;
    }),
    CountryCode: yup.string().required(),
    HighestEducation: yup.string().test('validator-highestEducation-required', function (value) {
      const validation = IsMandatoryColumn(ProfileColumn.HighestEducation);
      if (validation == true) {
        if (value?.length === 0) {
          return this.createError({
            path: this.path,
            message: useIntlActionMessages('ProfileForm.HighestEducation.Required'),
          });
        } else return true;
      } else return true;
    }),
  });

  // Initial loading - calling states list and avatars list apis
  useEffect(() => {
    try {
      setTimeout(() => {
        textFirstNameInputRef.current?.focus();
      }, 1000);
      const userData = getFromLocalStorage('MI_USR_DATA');
      if (userData.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN) {
        if (userData.IsPasswordChanged == false) navigate('/changepassword');
        else if (userData.IsProfileCreated == false) navigate('/profilecreate');
      }
      dispatch(getStates({}));
      dispatch(getAvatarsList({}));
      if (location.pathname === '/editprofile') {
        dispatch(userdetails({ UserId: userData.UserId }));
      }
      dispatch(
        getProfileSettings({
          OrganizationID: userData.OrganizationId,
          RoleId: userData.RoleId,
        }),
      );

      window.scrollTo(0, 0);
    } catch (e) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  }, []);

  // reset profile create on component unmount
  useEffect(() => {
    return () => {
      dispatch(resetProfileCreation());
      dispatch(resetaddGoalStepOne());
    };
  }, []);

  useEffect(() => {
    try {
      if (profileSettingsApiSuccess && profileSettingsApiData !== null) {
        setErrorFields({
          Firstname: profileForm.Firstname == '' ? fnameRequired : '',
          Lastname: profileForm.Lastname == '' ? lnameRequired : '',
          Username: profileForm.Username == '' ? usernameRequired : '',
          CountryCode: profileForm.CountryCode == '' ? ccodeRequired : '',
          PhoneNumber: profileForm.PhoneNumber == '' ? phoneRequired : '',
          Gender: (profileForm.Gender === '' && IsMandatoryColumn(ProfileColumn.Gender)) === true ? genderRequired : '',
          DateOfBirth: (profileForm.DateOfBirth === '' && IsMandatoryColumn(ProfileColumn.DOB)) === true ? dobRequired : '',
          CityId: (profileForm.CityId === 0 && IsMandatoryColumn(ProfileColumn.City)) === true ? cityRequired : '',
          StateId: (profileForm.StateId === 0 && IsMandatoryColumn(ProfileColumn.State)) === true ? stateRequired : '',
          Address: (profileForm.Address === '' && IsMandatoryColumn(ProfileColumn.Address)) === true ? addressRequired : '',
          Zip: (profileForm.Zip === '' && IsMandatoryColumn(ProfileColumn.Zip)) === true ? zipRequired : '',
          HighestEducation: (profileForm.HighestEducation === '' && IsMandatoryColumn(ProfileColumn.HighestEducation)) === true ? highestEducationRequired : '',
        });
      }
    } catch (e) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  }, [profileSettingsApiLoading]);

  // Initial loading - call to list states api
  useEffect(() => {
    if (listStatesApiSuccess && listStatesApiData?.length > 0) {
      try {
        // set data to state dropdown
        const stateOptions = listStatesApiData.map(
          (state: any): ISelectOptionsNumber => ({
            label: state.StateName,
            value: state.Id,
          }),
        );
        setStateOptions(stateOptions);
      } catch (e) {
        toastObj.toastError(unexpectedErrorMessage);
      }
    }
  }, [listStatesApiLoading]);

  // Initial loading - call to list cities api
  // Get cities
  useEffect(() => {
    if (listCitiesApiSuccess && listCitiesApiData && listCitiesApiData?.length > 0) {
      const options = listCitiesApiData.map(
        (city: any): ISelectOption => ({
          value: city.Id,
          label: city.CityName,
        }),
      );
      setTagOptions(options);
    } else {
      setTagOptions([]);
    }
  }, [listCitiesApiLoading, listCitiesApiData]);

  // navigate to otp verification after sending otp
  useEffect(() => {
    try {
      if (phoneOtpApiSuccess) {
        const filePath = profileImage !== null ? profileImgName : avatarUrl;
        setItemLocalStorage('profile_image_url', { url: filePath, first_name: profileForm.Firstname, last_name: profileForm.Lastname });
        navigate('/otpverification', {
          state: { phoneNo: profileForm.PhoneNumber, countryCode: profileForm.CountryCode },
        });
      } else if (phoneOtpApiResponseCode !== 3036 && !phoneOtpApiSuccess && phoneOtpApiResponseMessage.length > 0) {
        toastObj.toastError(phoneOtpApiResponseMessage);
      }
    } catch (e) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  }, [phoneOtpApiLoading]);

  // handle image upload and avatar url upload
  useEffect(() => {
    if (createProfileApiSuccess && location.pathname === '/editprofile') {
      if (profileImage !== null && profileImageUrl.length > 0) {
        createFileUrlForImage();
      } else if (profileImage === null && avatarUrl.length > 0) {
        updateAssetPathForProfile();
      } else {
        navigate('/myprofile');
      }
    }
  }, [createProfileApiLoading]);
  // redirect to myprofile after updating path
  useEffect(() => {
    if (uploadFilePathUpdateApiSuccess && createProfileApiSuccess) {
      toastObj.toastSuccess(profileAddedMessage);
      navigate('/myprofile');
    } else if (!uploadFilePathUpdateApiSuccess && !uploadFilePathUpdateApiLoading && uploadFilePathUpdateResponseMessage.length > 0) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  }, [uploadFilePathUpdateApiLoading]);

  // Handle the upload path api response
  useEffect(() => {
    if (uploadFileResponseCode > 0 && uploadFileApiSuccess) {
      uploadPicToBucket();
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);
  // set up data in profile form while editing profile info
  useEffect(() => {
    try {
      if (userDetailViewApiSuccess && userDetail) {
        setProfileForm((prev: IProfileForm) => ({
          ...prev,
          Firstname: userDetail.FirstName,
          Lastname: userDetail.LastName,
          Username: userDetail.UserName,
          CountryCode: `${userDetail.PhonenumCountryCode}`,
          PhoneNumber: userDetail.PhoneNumberDisplay,
          Gender: IsShowColumn(ProfileColumn.Gender) === true ? userDetail?.Gender ?? '' : '',
          DateOfBirth: IsShowColumn(ProfileColumn.DOB) === true ? (userDetail?.Dob === null ? '' : `${moment(new Date(userDetail.Dob)).local().format(DATE_FORMAT1)}`) : '',
          Address: IsShowColumn(ProfileColumn.Address) === true ? userDetail?.Address ?? '' : '',
          HighestEducation: IsShowColumn(ProfileColumn.HighestEducation) === true ? userDetail?.HighestEducation ?? '' : '',
          StateId: IsShowColumn(ProfileColumn.State) === true ? userDetail?.StateId ?? 0 : 0,
          CityId: IsShowColumn(ProfileColumn.City) === true ? userDetail?.CityId ?? 0 : 0,
          CityName: userDetail.CityName,
          Zip: IsShowColumn(ProfileColumn.Zip) === true ? userDetail?.ZipName ?? '' : '',
        }));
        const countryCodeValue = countryCodeOptions.filter((item) => item.value === userDetail.PhonenumCountryCode);
        setCountryCode(countryCodeValue);
        if (userDetail?.Gender !== null) {
          const genderValue = genderOptions.filter((item) => item.value === userDetail.Gender);
          setGenderValue(genderValue);
        }
        if (userDetail?.Dob !== null) {
          setDob(new Date(userDetail.Dob));
        }
        if (userDetail?.StateId !== null) {
          setStateValue([{ label: userDetail.StateName, value: userDetail.StateId }]);
        }
        if (userDetail?.CityId !== null) {
          // setCityValue([{ label: userDetail.CityName, value: userDetail.CityId }]);
          setSelectedTags([{ label: userDetail.CityName, value: userDetail.CityId }]);
        }
        const reqParams = {
          StateId: userDetail.StateId,
        };
        dispatch(getCities(reqParams));
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  }, [userDetailApiLoading]);
  // calculate mandatory form fields
  const IsMandatoryColumn = (col: any) => {
    try {
      if (
        profileSettingsApiData !== null &&
        profileSettingsApiData.length > 0 &&
        profileSettingsApiData[0].OnBoardingInfo.find((i: any) => i.columnid === col).onboardingstatusid === 1
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
      return false;
    }
  };

  const uploadPicToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: profileImage,
        });
        if (result.status == 200) {
          updateAssetPathForProfile();
        }
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };

  // create pre signed url for profile image
  const createFileUrlForImage = () => {
    try {
      const formData = new FormData();
      formData.append('UploadExtension', profileImage.type);
      formData.append('UploadObjectKey', profileImgName);
      formData.append('UploadType', 'profile');
      dispatch(uploadFile(formData));
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // add uploaded image url in profile
  const updateAssetPathForProfile = () => {
    try {
      const filePath = profileImage !== null ? profileImgName : avatarUrl;
      const reqParams: IUploadFilePathUpdateParams = {
        FilePath: filePath,
        Type: 'profile',
        TypeId: userData.UserId,
      };
      // Api request to update file info after bucket upload and save profile info
      dispatch(uploadFilePathUpdate(reqParams));
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // get value of email to pre-fill email field
  const getEmailValue = () => {
    try {
      const userData = getFromLocalStorage('MI_USR_DATA');
      let email = '';
      if (location.pathname === '/profilecreate') {
        email = validateTokenApiData?.Email == null ? userData.Email : validateTokenApiData?.Email;
      } else if (location.pathname === '/editprofile' && userDetailViewApiSuccess) {
        email = userDetail.EmailId;
      }
      return email;
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
      return '';
    }
  };

  // handle state select box change
  const handleStateChange = (event: any) => {
    try {
      setStateValue(event);
      // setCityValue([]);
      setProfileForm((prevData: IProfileForm) => ({
        ...prevData,
        StateId: event.value,
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        StateId: '',
      }));
      const reqParams = {
        StateId: event.value,
      };
      dispatch(getCities(reqParams));
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };

  const handleCityTagChange = (tag: any) => {
    try {
      // setCityValue(tag);
      setSelectedTags(tag);
      setProfileForm((prevData: IProfileForm) => ({
        ...prevData,
        CityId: isNaN(Number(tag?.value)) ? 0 : tag?.value,
        CityName: tag?.label,
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        CityId: '',
      }));
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };

  // handle gender select box change
  const handleGenderChange = (event: any) => {
    try {
      setGenderValue(event);
      setProfileForm((prevData: IProfileForm) => ({
        ...prevData,
        Gender: event.value,
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        Gender: '',
      }));
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // handle dob select box change
  const handleDobChange = (date: any) => {
    try {
      setDob(date);
      setProfileForm((prevData: IProfileForm) => ({
        ...prevData,
        DateOfBirth: date !== null ? `${moment(date).local().format(DATE_FORMAT1)}` : '',
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        DateOfBirth: '',
      }));
    } catch (e) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // get list of avatars to display
  const getAvatarsListMapped = () => {
    try {
      if (getAvatarsApiSuccess) {
        const avatarsList = getAvatarsApiData.Avatars.map((avatar: IAvatar, index: number) => (
          <Col className="d-flex justify-content-center mb-4">
            <div
              className="avat-wrp"
              onClick={() => {
                setAvatarUrl(avatar.id);
                setAvatarIndex(index);
                setProfileImageUrl(avatar.url);
                setOpen(false);
              }}
            >
              <img src={avatar.url} alt="Avatar" className="avatar-image" />
              <span>
                {avatarIndex === index && <img src={radioCheck} />}
                Avatar {index + 1}
              </span>
            </div>
          </Col>
        ));
        return avatarsList;
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setProfileForm((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorresult = await validateForm(validateObj, CREATE_PROFILE_SCHEMA, errorFields);
      setErrorFields(errorresult);
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  const onSubmit = async () => {
    try {
      const errorresult = await validateForm(profileForm, CREATE_PROFILE_SCHEMA, errorFields);
      if (IsMandatoryColumn(ProfileColumn.Gender) === true && profileForm.Gender === '') {
        errorresult.Gener = genderRequired;
      }
      // setErrorFields(errorresult);
      if (profileImage === null && avatarUrl.length === 0) {
        toastObj.toastError(imageRequired);
      }
      if (Object.keys(errorresult).length > 0) {
        setErrorVisible(true);
      } else if (profileImage !== null || avatarUrl.length > 0) {
        dispatch(addProfileForm(profileForm));
        dispatch(
          addProfileImgDatas({
            profileImage: profileImage,
            profileImageUrl: profileImageUrl,
            avatarUrl: avatarUrl,
            profileImgName: profileImgName,
          }),
        );
        dispatch(
          sendOtpToPhone({
            CountryCode: profileForm.CountryCode,
            PhoneNumber: profileForm.PhoneNumber,
          }),
        );
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // handle form submit during edit profile
  const onSubmitEdit = async () => {
    try {
      const errorresult = await validateForm(profileForm, CREATE_PROFILE_SCHEMA, errorFields);
      setErrorFields(errorresult);
      if (Object.keys(errorresult).length > 0) {
        setErrorVisible(true);
      } else if (profileForm.PhoneNumber !== userDetail.PhoneNumberDisplay) {
        dispatch(addProfileForm(profileForm));
        dispatch(
          addProfileImgDatas({
            profileImage: profileImage,
            profileImageUrl: profileImageUrl,
            avatarUrl: avatarUrl,
            profileImgName: profileImgName,
          }),
        );
        dispatch(
          sendOtpToPhone({
            CountryCode: profileForm.CountryCode,
            PhoneNumber: profileForm.PhoneNumber,
          }),
        );
      } else {
        dispatch(createTeacherProfile(profileForm));
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // show selected profile image
  const showSelectedImage = (event: any) => {
    try {
      if (event.target.files[0]) {
        const file = event.target.files[0];
        setProfileImage(file);
        setProfileImgName(`${file?.name?.split('.')[0]}_${+new Date().getTime()}.${file?.name?.split('.')[1]}`);
        setProfileImageUrl(URL.createObjectURL(file));
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // handle country code select box
  const handleCountryCodeChange = (event: any) => {
    try {
      setCountryCode(event);
      setErrorFields((prev: any) => ({
        ...prev,
        CountryCode: '',
      }));
      setProfileForm((prev: IProfileForm) => ({
        ...prev,
        CountryCode: event.value,
      }));
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
    }
  };
  // get url of selected avatar for display
  const getAvatarUrl = () => {
    try {
      let imgUrl = '';
      if (userDetail && userDetail.LogoImage) {
        imgUrl = BUCKET_URL + userDetail.LogoImage;
      }
      if (profileImageUrl.length > 0) {
        imgUrl = profileImageUrl;
      }
      if (imgUrl === '') {
        imgUrl = UserImg;
      }
      return imgUrl;
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
      return '';
    }
  };

  const IsShowColumn = (col: any) => {
    try {
      if (
        profileSettingsApiData !== null &&
        profileSettingsApiData.length > 0 &&
        profileSettingsApiData[0].OnBoardingInfo.find((i: any) => i.columnid === col).onboardingstatusid === 3
      ) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
      return true;
    }
  };
  // subtract years from a date and calculate new
  const subtractYears = (date: any, years: any) => {
    date.setFullYear(date.getFullYear() - years);
    return date;
  };
  return (
    <>
      <div className="profileCreation addMotiv-res">
        <Row className="g-0">
          <Col xl={2} lg={3} md={3}>
            <div className="col-left d-flex justify-content-center text-center">
              <div className="prof-details">
                {location.pathname === '/profilecreate' && <img src={profileImageUrl === '' ? UserImg : profileImageUrl} alt="" className="prof-image" />}
                {location.pathname === '/editprofile' && <img src={getAvatarUrl()} alt="" className="prof-image" />}
                <h6 className="mt-3">{userData && userData.OrganizationName}</h6>
                <p>{userData && userData?.RoleName && userData.RoleName} </p>
                <div className="fileupload-sec">
                  <input
                    type="file"
                    name="file-3[]"
                    id="file-3"
                    className="inputfile inputfile-3"
                    data-multiple-caption="{count} files selected"
                    multiple={false}
                    accept="image/png, image/gif, image/jpeg"
                    onChange={(event) => showSelectedImage(event)}
                  />
                  <label className="hand-icon" htmlFor="file-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17.427" viewBox="0 0 24 17.427">
                      <path
                        id="upload_icon"
                        d="M51.182,274.427H45.755A5.749,5.749,0,0,1,44.827,263a7.393,7.393,0,0,1,2.564-4.322A7.083,7.083,0,0,1,52.055,257a6.816,6.816,0,0,1,5.155,2.223,7.489,7.489,0,0,1,2.1,5.331v.655a4.411,4.411,0,0,1,3.327,1.268,4.657,4.657,0,0,1-3.245,7.95H52.818v-8.673l2.264,2.263,1.173-1.172L52,262.591l-4.255,4.255,1.173,1.172,2.264-2.263Z"
                        transform="translate(-40 -257)"
                        fill="#fff"
                      />
                    </svg>
                    <span className="hand-icon">Change Pic</span>
                  </label>
                </div>
                <div className="myprof-cont mt-4">
                  <div className="custom-berger-menu custom-berger-menu-chose-avat">
                    <Filter
                      width={450}
                      isOpen={open}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      right
                      pageWrapId={'filter-wrapper'}
                      outerContainerId={'outer-container'}
                      customCrossIcon={<img src={Close} />}
                      customBurgerIcon={
                        <div className="avt-selected">
                          <img className="btn-chose-avatar" src={usrAvatar} alt="user pic" />
                          <div>
                            <h6>Choose Avatar</h6>
                          </div>
                        </div>
                      }
                    >
                      <div id="filter-wrapper" className="filter-main">
                        <h4 className="mb-5">Choose Avatar</h4>
                        <Row>{getAvatarsListMapped()}</Row>
                      </div>
                    </Filter>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={10} g={9} md={9}>
            <Row className="profile-inn-cont g-0">
              <Col className="d-flex justify-content-between">
                <h3> {location.pathname === '/editprofile' ? 'Edit profile' : "Let's create your profile"}</h3>
                {location.pathname === '/editprofile' && (
                  <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item onClick={() => navigate('/myprofile')}>My Profile</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Profile</Breadcrumb.Item>
                  </Breadcrumb>
                )}
              </Col>
              <Row className="mt-5">
                <Col lg={6}>
                  <Input
                    label={useIntlMessages('First Name')}
                    id="Name"
                    name="Firstname"
                    type="text"
                    placeholder={useIntlMessages('First Name')}
                    maxLength={200}
                    value={profileForm.Firstname}
                    onChange={onInputHandleChange}
                    errorMessage={errorVisible ? errorFields?.Firstname : ''}
                    autoFocus={true}
                    ref={textFirstNameInputRef}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    label={useIntlMessages('Last Name')}
                    id="Name"
                    name="Lastname"
                    type="text"
                    placeholder={useIntlMessages('Last Name')}
                    maxLength={200}
                    value={profileForm.Lastname}
                    onChange={onInputHandleChange}
                    errorMessage={errorVisible ? errorFields?.Lastname : ''}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    label={useIntlMessages('Username')}
                    id="Name"
                    name="Username"
                    type="text"
                    placeholder={useIntlMessages('Username')}
                    maxLength={200}
                    value={profileForm.Username}
                    onChange={onInputHandleChange}
                    errorMessage={errorVisible ? errorFields?.Username : ''}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    label={useIntlMessages('Email')}
                    id="Name"
                    name="email"
                    type="text"
                    placeholder={useIntlMessages('userfullname@example.com')}
                    maxLength={200}
                    value={getEmailValue()}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.email}
                    status={true}
                  />
                </Col>
                <Col lg={2}>
                  <Select
                    options={countryCodeOptions}
                    label="Country Code"
                    value={countryCode}
                    error={errorVisible ? errorFields?.CountryCode : ''}
                    onChange={(e: any) => handleCountryCodeChange(e)}
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    label={useIntlMessages('Label.PhoneNum')}
                    id="PhoneNo"
                    name="PhoneNumber"
                    type="text"
                    placeholder={useIntlMessages('PH.PhoneNum')}
                    maxLength={200}
                    value={profileForm.PhoneNumber}
                    onChange={onInputHandleChange}
                    errorMessage={errorVisible ? errorFields?.PhoneNumber : ''}
                  />
                </Col>
                {IsShowColumn(ProfileColumn.Gender) === true && (
                  <Col lg={6}>
                    <Select
                      label="Gender"
                      options={genderOptions}
                      value={genderValue}
                      placeholder="Select"
                      onChange={(e: any) => handleGenderChange(e)}
                      error={errorVisible ? errorFields?.Gender : ''}
                    />
                  </Col>
                )}

                {IsShowColumn(ProfileColumn.DOB) === true && (
                  <Col lg={6}>
                    <div className="custom-calendar mb-4">
                      <label className="form-label">Date of Birth</label>
                      <DatePicker
                        selected={dob}
                        onChange={(date) => handleDobChange(date)}
                        maxDate={subtractYears(new Date(), 10)}
                        placeholderText="MM/DD/YYYY"
                        showYearDropdown={true}
                        scrollableYearDropdown={true}
                        yearDropdownItemNumber={100}
                      />
                      {errorVisible && errorFields?.DateOfBirth && errorFields?.DateOfBirth.length > 0 && <label className="error">{errorFields?.DateOfBirth}</label>}
                    </div>
                  </Col>
                )}

                {IsShowColumn(ProfileColumn.HighestEducation) === true && (
                  <Col lg={6}>
                    <Input
                      label={useIntlMessages('Label.HighestEducation')}
                      id="HighestEducation"
                      name="HighestEducation"
                      type="text"
                      placeholder={useIntlMessages('PH.HighestEducation')}
                      maxLength={200}
                      value={profileForm.HighestEducation}
                      onChange={onInputHandleChange}
                      errorMessage={errorVisible ? errorFields?.HighestEducation : ''}
                    />
                  </Col>
                )}

                {IsShowColumn(ProfileColumn.Address) === true && (
                  <Col lg={12} className="addOrg-textarea">
                    <Input
                      label={useIntlMessages('Label.address')}
                      id="Address"
                      name="Address"
                      type="textarea"
                      placeholder={useIntlMessages('PH.addr')}
                      maxLength={100}
                      value={profileForm?.Address}
                      onChange={onInputHandleChange}
                      errorMessage={errorVisible ? errorFields?.Address : ''}
                      as="textarea"
                      rows={3}
                    />
                    <span className="txt-count">{profileForm.Address.length}/100</span>
                  </Col>
                )}
                {IsShowColumn(ProfileColumn.State) === true && (
                  <Col lg={6}>
                    <Select
                      label="State"
                      options={stateOptions}
                      placeholder="Select"
                      onChange={(e: any) => handleStateChange(e)}
                      error={errorVisible ? errorFields?.StateId : ''}
                      value={stateValue}
                    />
                  </Col>
                )}
                {IsShowColumn(ProfileColumn.City) === true && (
                  <Col lg={6}>
                    <label className="form-label">{useIntlMessages('Label.City')}</label>
                    <CreatableSelect options={tagOptions} name="Tags" id="Tags" className="tags-selector" onChange={handleCityTagChange} value={selectedTags} />
                    <small>{useIntlActionMessages('Text.HelpTextCity')}</small>
                  </Col>
                )}

                {IsShowColumn(ProfileColumn.Zip) === true && (
                  <Col lg={6}>
                    <Input
                      label={useIntlMessages('Label.Zip')}
                      id="PhoneNo"
                      name="Zip"
                      type="text"
                      placeholder={useIntlMessages('PH.ZipC')}
                      maxLength={5}
                      value={profileForm.Zip}
                      onChange={onInputHandleChange}
                      errorMessage={errorVisible ? errorFields?.Zip : ''}
                    />
                  </Col>
                )}
              </Row>

              <Row>
                <Col lg={6} className="d-flex justify-content-center ms-0">
                  {location.pathname === '/editprofile' && (
                    <Button className="mb-4 btn-cancel w-100 ms-0" variant="outline-primary" onClick={() => navigate('/myprofile')}>
                      <FormattedMessage id="Button.Cancel" />
                    </Button>
                  )}
                </Col>
                <Col lg={6} className="d-flex justify-content-center ">
                  <Button className="mb-4 btn-Done w-100 " variant="primary" onClick={location.pathname === '/profilecreate' ? onSubmit : onSubmitEdit}>
                    <FormattedMessage id="Button.Done" />
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
      {(createProfileApiLoading || uploadFileApiLoading || uploadFilePathUpdateApiLoading || phoneOtpApiLoading || listCitiesApiLoading || profileSettingsApiLoading) && <Loader />}
    </>
  );
};
export default Profilecreation;
