/**
 * @file   src\utils\helper.ts
 * @brief  This is a general helper file.
 * @date   NOV, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useIntl, createIntl, createIntlCache } from 'react-intl';
import { RoleTypeIds, FILE_TYPES_SUPPORTED, UploaderProps } from './enums';
// import { ResourcetypeID, NumberValues, IMAGE_TYPES_SUPPORTED, UploaderProps, FILE_TYPES_SUPPORTED } from '../../utils/enums';

import LocaleMessages from '../assets/strings/English.json';

// Localization/Internationalization of messages.
export const useIntlMessages = (id: string) => {
  const intl = useIntl();
  if (id !== '' && id !== undefined) {
    const message = intl.formatMessage({ id });
    return message;
  }
  return '';
};

// Localization/Internationalization of action messages.
export const useIntlActionMessages = (id: string) => {
  const locale = 'en';
  const messages = LocaleMessages;
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale,
      messages,
    },
    cache,
  );
  if (id !== '' && id !== undefined) {
    const message = intl.formatMessage({ id });
    return message;
  }
  return '';
};

// function to identify if logged in user is admin
export const isUserAdmin = () => {
  const userData: any = localStorage.getItem('MI_USR_DATA');
  const userObject = JSON.parse(userData);
  const roleTypeId = userObject.RoleTypeId;
  if ([RoleTypeIds.SUPER_ADMIN, RoleTypeIds.SYSTEM_ADMIN].includes(roleTypeId)) {
    return true;
  }
  return false;
};
// function to identify if logged in user is org admin
export const isOrgAdmin = () => {
  const userData: any = localStorage.getItem('MI_USR_DATA');
  const userObject = JSON.parse(userData);
  const roleTypeId = userObject.RoleTypeId;
  if (roleTypeId === RoleTypeIds.ORGANIZATION_ADMIN) {
    return true;
  }
  return false;
};

export const getFromLocalStorage = (key: string) => {
  const data: any = localStorage.getItem(key);
  if (data !== 'undefined') {
    return JSON.parse(data);
  }
};

export const setItemLocalStorage = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeItemLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
export const checkValidFile = (filename: string, type: string, size: any) => {
  if (filename) {
    const fileExtension: string = filename?.split('.').pop()?.toLowerCase() || '';

    const fileSize = size;
    switch (type) {
      case 'image':
        if (+fileSize <= UploaderProps.IMAGE_MAX_SIZE && FILE_TYPES_SUPPORTED?.image?.includes(fileExtension)) {
          return true;
        } else {
          return false;
        }
      case 'video':
        if (+fileSize <= UploaderProps.VIDEO_MAX_SIZE && FILE_TYPES_SUPPORTED?.video?.includes(fileExtension)) {
          return true;
        } else {
          return false;
        }
      case 'audio':
        if (+fileSize <= UploaderProps.AUDIO_MAX_SIZE && FILE_TYPES_SUPPORTED?.audio?.includes(fileExtension.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      case 'document':
        if (+fileSize <= UploaderProps.DOCUMENT_MAX_SIZE && FILE_TYPES_SUPPORTED?.document?.includes(fileExtension)) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }
  return false;
};

// Convert bytes to human-readable format (MB, KB, GB) with rounded values
export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const roundedSize = Math.round(bytes / Math.pow(1024, i));
  return roundedSize + ' ' + sizes[i];
};
// calculate difference of two dates in hours
export const dateDiffInHours = (date1: any, date2: any) => {
  let diff = (date1.getTime() - date2.getTime()) / 1000;
  diff /= 60 * 60;
  const finalResult = Math.abs(Math.round(diff));
  return finalResult;
};
// check valid image or not
export const isValidImageUrl = (url: any) => {
  const img = new Image();
  img.src = url;
  return img.complete || img.width + img.height > 0;
};
export const getFileTypeNumber = (filetypedetails: any) => {
  switch (filetypedetails?.value?.toLowerCase()) {
    case 'image':
      return 1;
    case 'audio':
      return 2;
    case 'video':
      return 3;
    case 'document':
      return 4;
    default:
      return 1;
  }
};

export const capitalizeFirstLetters = (sentance: string) => {
  const words = sentance.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (words[i][0] !== undefined) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
  }
  return words.join(' ');
};

export const getFileTypeByExtension = (extension: string) => {
  const extensionLowerCase = extension.toLowerCase();
  for (const [type, extensions] of Object.entries(FILE_TYPES_SUPPORTED)) {
    if (extensions.includes(extensionLowerCase)) {
      return type;
    }
  }
  return '';
};

export const preventWhitespaceInput = (e: any) => {
  if (e.key === ' ') {
    e.preventDefault();
  }
};
// Validate date1 is before date2
export const isBeforeOrEqual = (date1: any, date2: any) => {
  let isBefore = false;
  if (date1 <= date2) {
    isBefore = true;
  }
  return isBefore;
};
// get current org details
export const getCurrentOrgDetails = () => {
  const orgDetails = getFromLocalStorage('CURRENT_ORG');
  return orgDetails;
};
export const getRandomString = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const length = 10; // Set the desired length of the random string

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const isTeacherWebUser = () => {
  const userData: any = localStorage.getItem('MI_USR_DATA');
  const userObject = JSON.parse(userData);
  const roleTypeId = userObject.RoleTypeId;
  if ([RoleTypeIds.APP_USER_MANAGER].includes(roleTypeId)) {
    return true;
  }
  return false;
};

export const isAdminTeacherManager = () => {
  const userData: any = localStorage.getItem('MI_USR_DATA');
  const userObject = JSON.parse(userData);
  const roleTypeId = userObject.RoleTypeId;
  if ([RoleTypeIds.ORGANIZATION_ADMIN, RoleTypeIds.APP_USER_MANAGER, RoleTypeIds.ORGANIZATION_MANGER].includes(roleTypeId)) {
    return true;
  }
  return false;
};

export const isOrgAdminManager = () => {
  const userData: any = localStorage.getItem('MI_USR_DATA');
  const userObject = JSON.parse(userData);
  const roleTypeId = userObject.RoleTypeId;
  if ([RoleTypeIds.ORGANIZATION_ADMIN,RoleTypeIds.ORGANIZATION_MANGER].includes(roleTypeId)) {
    return true;
  }
  return false;
};
