/**
 * @file   src\store\index.ts
 * @brief  This file is responsible for configuring the redux store.
 * @date   April, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './slices/dashboardSlice';
import authSlice from './slices/authSlice';
import userSlice from './slices/userSlice';
import organizationSlice from './slices/organizationSlice';
import notificationSlice from './slices/notificationSlice';
import organizationTypeSlice from './slices/organizationTypeSlice';
import resourceSlice from './slices/resourceSlice';
import profileSlice from './slices/profileSlice';
import competencySlice from './slices/competencySlice';
import goalSlice from './slices/goalSlice';
import subscriptionPlanSlice from './slices/subscriptionPlanSlice';
import surveySlice from './slices/surveySlice';
import settingsSlice from './slices/settingsSlice';

export const store = configureStore({
  reducer: {
    // Add reducer slices.
    dashboard: dashboardReducer,
    authentication: authSlice,
    user: userSlice,
    organization: organizationSlice,
    organizationType: organizationTypeSlice,
    resource: resourceSlice,
    profile: profileSlice,
    competency: competencySlice,
    goal: goalSlice,
    subscriptionPlan: subscriptionPlanSlice,
    notification: notificationSlice,
    survey: surveySlice,
    settings: settingsSlice
  },
  // Adding the api middlewares enables caching , invalidation, polling and other useful features of rtk query.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
