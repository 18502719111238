/**
 * @file   src\containers\Home.tsx
 * @brief  Dashboard.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react';
import '../assets/css/Home.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, useIntlActionMessages, getFromLocalStorage } from '../utils/helper';
import { Col, Row } from 'react-bootstrap';
import Select from '../components/MASelect';
import PerfomanceCard from '../components/PerfomanceCard';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { ISelectOptionsNumber } from '../interfaces/GeneralInterface';
import { getOrganizationTypeDropDownList } from '../store/actions/organizationTypeActions';
import Loader from '../components/Loader';
import { getUserCountByOrganizationType, getOverallPerformanceByOrganizationType } from '../store/actions/dashboardActions';
import { resestUserCountList, resetOverallPerformanceList } from '../store/slices/dashboardSlice';
import { RoleTypeIds } from '../utils/enums';
import { useNavigate } from 'react-router-dom';
// Declare default params for listing users count
const defaultUserCountListApiParams = {
  OrganizationTypeId: 0,
};
// Declare default params for overall performance
const defaultOverallPerformanceApiParams = {
  OrganizationTypeId: 0,
  DateRange: 'Last_7_days',
};
const Home = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Access redux state variables
  const { userCountListData, userCountListApiSuccess, userCountListApiLoading, overallPerformanceListApiSuccess, overallPerformanceListData, overallPerformanceListApiLoading } =
    useAppSelector((state: RootState) => state.dashboard);
  // Declare constants
  const selectDays = [
    { label: 'Last 7 days', value: 'Last_7_days' },
    { label: 'Last 30 days', value: 'Last_30_days' },
    { label: 'Last 6 months', value: 'Last_6_month' },
    { label: 'Last 1 year', value: 'Last_1_year' },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Access redux state variables
  const { organizationTypeDropDownData, organizationTypeDropDownSuccess, organizationTypeDropDownLoading } = useAppSelector((state: RootState) => state.organizationType);
  // component state variabless
  const [sliderKey, setSliderKey] = useState(0);
  const [dateFilter, setDateFilter] = useState<any>(selectDays[0]);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber>();
  const [apiUserCountListParams, setUserCountListApiParams] = useState<any>(defaultUserCountListApiParams);
  const [apiOverallPerformanceApiParams, setOverallPerformanceApiParams] = useState<any>(defaultOverallPerformanceApiParams);
  const NodataText = useIntlActionMessages('Label.Nodata');
  // Handle top 6 tile style
  const tileClasses = ['dash-tile students', 'dash-tile accountability', 'dash-tile teachers', 'dash-tile managers', 'dash-tile admins'];
  // Resetting user list  works on unmounting
  useEffect(() => {
    const storageData = getFromLocalStorage('MI_USR_DATA');
    const userData = storageData ? storageData : {};
    if (userData.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN && userData.IsPasswordChanged !== null) {
      if (userData.IsPasswordChanged == false) navigate('/changepassword');
      else if (userData.IsProfileCreated == false) navigate('/profilecreate');
    }
    dispatch(getOrganizationTypeDropDownList({}));
    dispatch(resestUserCountList());
    dispatch(resetOverallPerformanceList());
  }, []);

  // Get organization type
  useEffect(() => {
    if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
      const orgTypeOptions = organizationTypeDropDownData.map(
        (orgtype: any): ISelectOptionsNumber => ({
          label: orgtype.orgTypeName,
          value: orgtype.orgTypeId,
        }),
      );
      setOrganizationTypeOptions([...orgTypeOptions]);
      const defaultOrgType = orgTypeOptions[0];
      setOrganizationTypeValue(defaultOrgType);
      const updatedParams = {
        ...apiUserCountListParams,
        OrganizationTypeId: defaultOrgType.value,
      };
      const updatedPerformanceParams = {
        ...apiOverallPerformanceApiParams,
        OrganizationTypeId: defaultOrgType.value,
      };
      setUserCountListApiParams(updatedParams);
      setOverallPerformanceApiParams(updatedPerformanceParams);
      dispatch(getUserCountByOrganizationType(updatedParams));
      dispatch(getOverallPerformanceByOrganizationType(updatedPerformanceParams));
    }
  }, [organizationTypeDropDownLoading]);
  // Get user count on organization type change
  const handleOrgTypeChange = (event: any) => {
    const selectedOrgTypeId = parseInt(event.value);
    // Reset slider to first slide by changing the key
    setSliderKey((prevKey) => prevKey + 1);
    const updatedParams = {
      ...apiUserCountListParams,
      OrganizationTypeId: selectedOrgTypeId,
    };
    const updatedPerformanceParams = {
      ...apiOverallPerformanceApiParams,
      OrganizationTypeId: selectedOrgTypeId,
    };
    setOverallPerformanceApiParams({
      ...apiOverallPerformanceApiParams,
      OrganizationTypeId: selectedOrgTypeId,
    });
    setUserCountListApiParams(updatedParams);
    dispatch(getUserCountByOrganizationType(updatedParams));
    dispatch(getOverallPerformanceByOrganizationType(updatedPerformanceParams));
    setOrganizationTypeValue(event);
  };
  // Handle DateRange dropdown change
  const handleDateFilter = (event: any) => {
    const updatedDateRange = event.value;
    const updatedParams = {
      ...apiOverallPerformanceApiParams,
      DateRange: updatedDateRange,
    };
    setDateFilter(event);
    setOverallPerformanceApiParams(updatedParams);
    dispatch(getOverallPerformanceByOrganizationType(updatedParams));
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.Dashboard" />
        </h3>
        <Col xl={2} lg={4} md={4} className="form-sm">
          <Select
            label={useIntlMessages('Label.OrganizationType')}
            options={organizationTypeOptions}
            value={organizationTypeValue}
            name="OrganizationTypeId"
            placeholder={useIntlMessages('PH.Select')}
            onChange={(e: any) => handleOrgTypeChange(e)}
          />
        </Col>
      </div>
      <div className="content-sub">
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <div>
            <h4>
              <FormattedMessage id="SubHD.OverallPerformance" />
            </h4>
            <p>
              <FormattedMessage id="SubHD.OverallPerformance.details" />
            </p>
          </div>
          <Col xl={2} lg={4} md={4} className="form-sm max-w-140">
            <Select options={selectDays} value={dateFilter} placeholder={useIntlMessages('PH.Select')} onChange={(event: any) => handleDateFilter(event)} />
          </Col>
        </div>
        <div className="perfomance-main">
          <Slider key={sliderKey} {...settings}>
            {overallPerformanceListApiSuccess && overallPerformanceListData?.Organizations?.length > 0 ? (
              overallPerformanceListData?.Organizations?.map((performance: any, index: number) => (
                <PerfomanceCard percentage={performance.PerformancePercentage} label={performance.OrganizationName} key={index} />
              ))
            ) : (
              <div className="content-sub content-area-padding border-top text-center p-4">
                <h6>{NodataText}</h6>
              </div>
            )}
          </Slider>
        </div>
      </div>
      <div className="dashboard-tiles-main">
        <Row>
          {userCountListApiSuccess && userCountListData?.length > 0 ? (
            userCountListData.map((user: any, index: number) => (
              <Col xl lg={4} md={4}>
                <div className={tileClasses[index % tileClasses?.length]}>
                  <div className="dash-tile-bg">
                    <h2>{user?.RoleWiseCount}</h2>
                    <div className="d-flex">
                      <span>{user?.RoleWiseCount === 1 ? user?.RoleName : `${user?.RoleName}s`}</span>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="content-sub content-area-padding border-top text-center p-4">
              <h6>{NodataText}</h6>
            </div>
          )}
        </Row>
      </div>
      {(organizationTypeDropDownLoading || userCountListApiLoading || overallPerformanceListApiLoading) && <Loader />}
    </>
  );
};
export default Home;
