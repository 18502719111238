/**
 * @file   src\components\Layout.tsx
 * @brief  This component used as a layout for the content which can be replaced.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useMemo } from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import '../assets/css/Layout.scss';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Col, Container, Row } from 'react-bootstrap';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Logo from '../assets/img/Logo.svg';
import IcnDashboard from '../assets/img/icon/Menu_Dashboard';
import IcnOrg from '../assets/img/icon/Menu_Org';
import IcnNotification from '../assets/img/icon/Menu_Notification';
import IcnUser from '../assets/img/icon/Menu_Users';
import IcnResource from '../assets/img/icon/Menu_Resource';
import IcnCompetencies from '../assets/img/icon/Menu_Competencies';
import IcnSettings from '../assets/img/icon/Menu_Settings';
import IcnSubscription from '../assets/img/icon/Menu_Subscription';
import ActivitiesIcon from '../assets/img/icon/Activities';
import MenuBtn from '../assets/img/Menu.svg';
import { FormattedMessage } from 'react-intl';
import { Paths } from '../utils/enums';
import { isUserAdmin } from '../utils/helper';

// Layout is common for all pages. Decides layout of the web pages
const Layout = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  const location = useLocation();

  // Manage states
  const [collapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);

  // Creating path arrays
  const organisationPaths = useMemo(
    () => [
      '/manageorganization',
      '/addorganization',
      '/listorganizationtypes',
      '/vieworganization',
      '/overview',
      '/surveyform',
      '/activities',
      '/addorganizationtype',
      '/assigngoal',
      '/assignsurvey',
      '/goalsdetail',
      '/taskdetails',
      '/orgtypedetails',
      '/editorganization',
      '/goaldetail',
      '/surveydetails',
    ],
    [],
  );
  const usersPaths = useMemo(() => ['/adduser', '/manageuser', '/userDetails', '/managepermission', '/userdetails'], []);
  const subscriptionPlansPaths = useMemo(() => ['/subscriptionplans', '/subscriptiondetails', '/addsubscriptionplan', '/editsubscriptionplan'], []);
  const resourcePaths = useMemo(() => ['/resources', '/resourceDetails', '/addmotivation', '/addincentivecontent', '/resourcesViewall'], []);
  const competencyPaths = useMemo(() => ['/competencies', '/addcompetencies', '/editcompetency'], []);
  const activitiesPaths = useMemo(() => ['/activities','/assigngoal', '/assignsurvey', '/goaldetail', '/taskdetails', '/surveydetails'], []);

  return (
    <Container fluid>
      <Row>
        <Sidebar collapsed={collapsed} toggled={toggled} onBackdropClick={() => setToggled(false)} customBreakPoint="950px" className="main-menu">
          <div className="logo-container">
            <Link to="/home">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <Menu>
            {/* Showing navigation menus for teacher level role and admin role */}
            {isUserAdmin() ? (
              <>
                <MenuItem icon={<IcnDashboard />} active={location.pathname === '/home'} onClick={() => navigate(Paths.ADMIN_HOME)}>
                  <FormattedMessage id="Hd.Dashboard" />
                </MenuItem>
                <MenuItem
                  icon={<IcnOrg />}
                  active={organisationPaths.includes(location.pathname) || organisationPaths.includes(location.pathname.slice(0, location.pathname.lastIndexOf('/')))}
                  onClick={() => navigate(Paths.MANAGE_ORGANISATIONS)}
                >
                  <FormattedMessage id="Hd.ManageOrganizations" />
                </MenuItem>
                <MenuItem icon={<IcnNotification />} active={location.pathname === '/notification'} onClick={() => navigate(Paths.NOTIFICATIONS)}>
                  <FormattedMessage id="Hd.Notifications" />
                </MenuItem>
                <MenuItem icon={<IcnUser />} onClick={() => navigate(Paths.USERS)} active={usersPaths.some((path) => location.pathname.startsWith(path))}>
                  <FormattedMessage id="Hd.ManageUsers" />
                </MenuItem>
                <MenuItem icon={<IcnResource />} onClick={() => navigate(Paths.RESOURCES)} active={resourcePaths.some((path) => location.pathname.startsWith(path))}>
                  <FormattedMessage id="Hd.Resources" />
                </MenuItem>
                <MenuItem icon={<IcnCompetencies />} onClick={() => navigate(Paths.COMPETENCIES)} active={competencyPaths.includes(location.pathname)}>
                  <FormattedMessage id="Hd.Competencies" />
                </MenuItem>
                <MenuItem icon={<IcnSettings />} onClick={() => navigate(Paths.MANAGE_SETTINGS)} active={location.pathname === '/managesettings'}>
                  <FormattedMessage id="Hd.ManageSettings" />
                </MenuItem>
                <MenuItem icon={<IcnSubscription />} onClick={() => navigate(Paths.SUBSCRIPTION_PLANS)} active={subscriptionPlansPaths.includes(location.pathname)}>
                  <FormattedMessage id="Hd.ManageSubscriptionPlans" />
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem icon={<IcnDashboard />} active={location.pathname === '/vieworganization'} onClick={() => navigate('/vieworganization')}>
                  <FormattedMessage id="Hd.Dashboard" />
                </MenuItem>
                <MenuItem icon={<IcnNotification />} active={location.pathname === '/notification'} onClick={() => window.alert('Not implemented')}>
                  <FormattedMessage id="Hd.Notifications" />
                </MenuItem>
                <MenuItem icon={<ActivitiesIcon />} onClick={() => navigate('/activities')} active={activitiesPaths.some((path) => location.pathname.startsWith(path))}>
                  <FormattedMessage id="Hd.Activities" />
                </MenuItem>
                <MenuItem icon={<IcnUser />} onClick={() => navigate(Paths.USERS)} active={usersPaths.some((path) => location.pathname.startsWith(path))}>
                  <FormattedMessage id="Hd.Users" />
                </MenuItem>
                <MenuItem icon={<IcnResource />} onClick={() => window.alert('Not implemented')} active={resourcePaths.includes(location.pathname)}>
                  <FormattedMessage id="Hd.Resources" />
                </MenuItem>
                <MenuItem icon={<IcnCompetencies />} onClick={() => window.alert('Not implemented')} active={competencyPaths.includes(location.pathname)}>
                  <FormattedMessage id="Hd.Competencies" />
                </MenuItem>
              </>
            )}
          </Menu>
        </Sidebar>

        <Col className="main-container">
          <button className="sb-button" onClick={() => setToggled(!toggled)}>
            <img src={MenuBtn} alt="" />
          </button>
          <Header />
          <div className="content-area-main">
            <Outlet />
          </div>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
