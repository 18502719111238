import { useState } from "react";
import Chart from "react-apexcharts";

const ProductivityGraph = (props:any) => {
  const yaxisLabel=props.yaxisLabel;
  const [chartOptions, setChartOptions] = useState({
    series: [{
      name: yaxisLabel,
      data: [44, 55, 41, 67, 22, 43]
    }],
    options: {
      chart: {
        height: 350,
        foreColor: 'white',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          columnWidth: '12px',
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#A53FE6'],
      xaxis: {
        title: {
          text: 'Date'
        },
        categories: ['5/25', '5/26', '5/27', '5/28', '5/29', '5/30'
        ],
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: yaxisLabel,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "vertical",
          shadeIntensity: 0.50,
          gradientToColors: ['#8AC2F6'],
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [40, 70, 100]
        },
      }
    },
  })

  return (
    <div >
      <Chart
        options={chartOptions.options}
        series={chartOptions.series}
        type="bar"
        width="400"
      />
    </div>
  );
}


export default ProductivityGraph;