/**
 * @file   src\containers\competencies\Add.tsx
 * @brief  Competencies Add.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, useIntlActionMessages } from '../../utils/helper';
import { Button, Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Plusicon from '../../assets/img/plus_icn.svg';
import minusicon from '../../assets/img/icn-minus.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { COMPETENCY_SCHEMA } from '../../validations/competencySchema';
import { validateForm } from '../../utils/formValidation';
import { addCompetency, viewCompetency, updateCompetency } from '../../store/actions/competencyActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ICompetencyForm, ICompetencyViewAPIRequest } from '../../interfaces/CompetencyInterface';
import { resetCompetencyAdd, resetCompetenciesUpdate, resetCompetenciesView } from '../../store/slices/competencySlice';
import Loader from '../../components/Loader';
import { NumberValues } from '../../utils/enums';
import { MessageToaster } from '../../utils/ToastUtil';

// Declare default params
const savedefaultRequestParams: ICompetencyForm = {
  Name: '',
  Options: [],
  OrganizationID: '',
};

const CompetenciesAdd = () => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();

  // Access redux state variables
  const {
    addCompetencyApiLoading,
    addCompetencyApiSuccess,
    addCompetencyApiResponseCode,
    addCompetencyApiResponseMessage,
    competencyDetail,
    competencyViewApiLoading,
    competencyViewApiSuccess,
    competencyUpdateApiLoading,
    competencyUpdateApiSuccess,
    competencyUpdateApiResponseCode,
    competencyUpdateApiResponseMessage,
  } = useAppSelector((state: RootState) => state.competency);
  // Initialize component state variables.
  const [competencyForm, setCompetencyForm] = useState<ICompetencyForm>(savedefaultRequestParams);
  const [errorFields, setErrorFields] = useState<any>({});
  const [options, setOptions] = useState<string[]>(competencyDetail?.Options || ['']);
  const OptionsMin = useIntlActionMessages('Form.Options.Min');
  const Duplicateoptions = useIntlActionMessages('Form.Duplicate.options');
  // Function to get competency details
  useEffect(() => {
    if (location?.state?.CompetencyID) {
      const competencyViewAPIRequest: ICompetencyViewAPIRequest = {
        CompetencyID: location?.state?.CompetencyID,
      };
      dispatch(viewCompetency(competencyViewAPIRequest));
    }
  }, []);
  // Show message after form submit, success/failure
  useEffect(() => {
    if (addCompetencyApiResponseCode > 0 && addCompetencyApiSuccess) {
      toast.toastSuccess(addCompetencyApiResponseMessage);
      navigate('/competencies');
      dispatch(resetCompetencyAdd());
    } else if (addCompetencyApiResponseCode > 0 && addCompetencyApiResponseMessage && !addCompetencyApiSuccess) {
      toast.toastError(addCompetencyApiResponseMessage);
      dispatch(resetCompetencyAdd());
    }
  }, [addCompetencyApiLoading, addCompetencyApiResponseCode, addCompetencyApiSuccess, addCompetencyApiResponseMessage]);
  // Reset the form after creating competency
  useEffect(() => {
    return () => {
      dispatch(resetCompetencyAdd());
      dispatch(resetCompetenciesUpdate());
      dispatch(resetCompetenciesView());
    };
  }, []);
  // set data for competency edit
  useEffect(() => {
    if (competencyViewApiSuccess && competencyDetail) {
      if (location.pathname === '/editcompetency') {
        setCompetencyForm((competencyForm: any) => ({
          ...competencyForm,
          CompetencyID: competencyDetail?.CompetencyID.toString(),
          Name: competencyDetail?.Title,
        }));
        // setOptions(competencyDetail?.Options || []);
        const optionsArray = competencyDetail?.Options.map((opt: any) => opt.competencyoption) || [];
        setOptions(optionsArray);
      }
    }
  }, [competencyViewApiLoading, competencyViewApiSuccess, competencyDetail]);
  // Handle Org Type Update Success/Failure.
  useEffect(() => {
    if (competencyUpdateApiSuccess && competencyUpdateApiResponseCode > 0) {
      navigate('/competencies');
      toast.toastSuccess(competencyUpdateApiResponseMessage);
    } else if (!competencyUpdateApiSuccess && competencyUpdateApiResponseCode > 0) {
      toast.toastError(competencyUpdateApiResponseMessage);
    }
  }, [competencyUpdateApiLoading]);
  // Add event listener for Enter key press
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        // Check if all required fields are filled
        if (isFormValid()) {
          event.preventDefault();
          onSubmit();
        }
      }
    };
    const isFormValid = () => {
      return Object.values(errorFields).every((error) => !error);
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [errorFields]);
  // Handle options add
  const handleAddOption = () => {
    setOptions([...options, '']);
  };
  // Handle options remove
  const handleRemoveOption = (index: any) => {
    if (options.length === 1) {
      return; // Do not remove if there's only one option
    }
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };
  // Handle input field
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompetencyForm((info: any) => ({
      ...info,
      [name]: value,
    }));
    // Validate other fields
    const validateObj = { [name]: value };
    const errorResult = await validateForm(validateObj, COMPETENCY_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Handle form submit
  const onSubmit = async () => {
    // Validate the Options field
    const optionsErrorResult = validateOptions(options);
    // Validate other fields
    const otherFieldsErrorResult = await validateForm(competencyForm, COMPETENCY_SCHEMA, errorFields);
    if (optionsErrorResult) {
      if (optionsErrorResult.Options === Duplicateoptions) {
        toast.toastError(optionsErrorResult.Options);
      } else {
        setErrorFields({ ...otherFieldsErrorResult, ...optionsErrorResult });
      }
    } else if (Object.keys(otherFieldsErrorResult).length !== 0) {
      setErrorFields(otherFieldsErrorResult);
      return;
    } else {
      // If there are no validation errors, proceed with form submission
      const optionValues = options.map((option) => option.trim()).filter((option) => option !== '');
      setCompetencyForm((info: any) => ({
        ...info,
        Options: optionValues,
      }));
      if (location?.state?.CompetencyID) {
        dispatch(
          updateCompetency({
            ...competencyForm,
            Options: optionValues,
          }),
        );
      } else {
        dispatch(
          addCompetency({
            ...competencyForm,
            Options: optionValues,
          }),
        );
      }
    }
  };
  // Function to validate the Options field
  const validateOptions = (options: string[]): { Options?: string } | null => {
    const nonEmptyOptions = options.filter((option) => option.trim() !== '');
    if (nonEmptyOptions.length === 0) {
      return { Options: OptionsMin };
    }
    const uniqueOptions = new Set(nonEmptyOptions);
    if (uniqueOptions.size !== nonEmptyOptions.length) {
      return { Options: Duplicateoptions };
    }
    return null; // No validation errors
  };
  // Function to handle the Options field
  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
    // Clear options error message if options are modified
    setErrorFields((prevErrorFields: any) => {
      const { Options, ...rest } = prevErrorFields; // Remove the Options error
      return rest;
    });
  };

  return (
    <>
      <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
        <h3>{location.pathname === '/editcompetency' ? <FormattedMessage id="Button.EditCompetency" /> : <FormattedMessage id="Button.AddCompetency" />}</h3>
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item onClick={() => navigate('/competencies')}>
            <FormattedMessage id="Hd.Competencies" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {location.pathname === '/editcompetency' ? <FormattedMessage id="Button.EditCompetency" /> : <FormattedMessage id="Button.AddCompetency" />}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-sub py-5">
        <div className="content-area-padding">
          <div className="d-flex justify-content-center">
            <Col xl={6} lg={7} className="tab-secondary">
              <Input
                autoFocus
                label={useIntlMessages('Label.NameofCompetency')}
                id="Name"
                name="Name"
                type="text"
                placeholder={useIntlMessages('PH.NameCompetency')}
                maxLength={NumberValues.NUM_100}
                onChange={onInputHandleChange}
                value={competencyForm.Name}
                errorMessage={errorFields?.Name}
              />
              <Row className="d-flex- align-items-center">
                <Col>
                  <label className="form-label mb-0">
                    <FormattedMessage id="Label.Options" />{' '}
                    <span>
                      (<FormattedMessage id="SubLabel.Options" />)
                    </span>
                  </label>
                </Col>
                <Col sm="auto">
                  <Button variant="dark" className="icon-btn" onClick={handleAddOption}>
                    <img src={Plusicon} alt="Add Option" />
                  </Button>
                </Col>
              </Row>
              {options.map((option, index) => (
                <Row key={index} className="d-flex align-items-center mt-3">
                  <Col className="no-margin">
                    <Input
                      id={`Option${index}`}
                      name={`Option${index}`}
                      type="text"
                      onChange={(e: any) => handleOptionChange(index, e.target.value)}
                      value={option}
                      placeholder={`Option${index + 1}`}
                      maxLength={NumberValues.NUM_100}
                      errorMessage={errorFields?.Options}
                    />
                  </Col>
                  {options.length > 1 && (
                    <Col sm="auto">
                      <Button variant="dark" className="icon-btn" onClick={() => handleRemoveOption(index)}>
                        <img src={minusicon} alt="Remove Option" />
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              <Row className="mt-5 mb-5">
                <Col>
                  <Button variant="outline-primary" className="w-100" onClick={() => navigate('/competencies')}>
                    <FormattedMessage id="Button.Cancel" />
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={onSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                  >
                    <FormattedMessage id="Button.Save" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      </div>
      {addCompetencyApiLoading||competencyViewApiLoading && <Loader />}
    </>
  );
};

export default CompetenciesAdd;
