/**
 * @file   src/containers/Myprofile.tsx
 * @brief  My profile page.
 * @date   Dec, 2023
 * @author ZCO
 * @copyright (c) 2023, ZCO */

import React, { useEffect, useState } from 'react';
import '../../assets/css/myprof.scss';
import '../../assets/css/org.scss';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Nav, NavDropdown, Button } from 'react-bootstrap';
import More from '../../assets/img/More.svg';
import { end } from '@popperjs/core';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import usrAvatar from '../../assets/img/icn-avatar.svg';
import radioCheck from '../../assets/img/radio-checked.svg';
import { useNavigate } from 'react-router-dom';
import { useIntlActionMessages, useIntlMessages, capitalizeFirstLetters } from '../../utils/helper';
import { userdetails } from '../../store/actions/userActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getFromLocalStorage } from '../../utils/helper';
import { RootState } from '../../store';
import { BUCKET_URL,DATE_TIME_FORMAT_DISPLAY } from '../../utils/constants';
import UserImg from '../../assets/img/Userimg.jpg';
import moment from 'moment';
import Loader from '../../components/Loader';
import { resetUserDetails } from '../../store/slices/userSlice';
import proImg from '../../assets/img/pro_img.png';
import addUser from '../../assets/img/add-usr-icn.svg';
import Select from '../../components/MASelect';
import UserHierarchyList from '../manageusers/UserHierarchyList';
import { RoleTypeIds } from '../../utils/enums';

const Myprofile = () => {
  // Route navigation object creation
  const navigate = useNavigate();
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Accessing redux variable
  const { userDetail, userDetailApiLoading } = useAppSelector((state: RootState) => state.user);
  const [open, setOpen] = useState(false);
  const MessageNill = useIntlActionMessages('Label.Nill');

  const userData = getFromLocalStorage('MI_USR_DATA');
  const organization: any = [];
  const organizationValue: any = [];

  // Getting user details on initial loading
  useEffect(() => {
    const userData = getFromLocalStorage('MI_USR_DATA');
    if (userData.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN) {
      if (userData.IsPasswordChanged == false) navigate('/changepassword');
      else if (userData.IsProfileCreated == false) navigate('/profilecreate');
    }

    dispatch(userdetails({ UserId: userData.UserId }));
  }, []);
  // reset user details on component unmounting
  useEffect(() => {
    return () => {
      dispatch(resetUserDetails());
    };
  }, []);

  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Label.Myprofile" />
        </h3>
      </div>
      <div className="myprof-cont">
        <Row>
          <Col xl={4} lg={5} className="mypro-sec-L">
            <div>
              <div className="pro-bg "></div>
              <img src={userDetail && userDetail?.LogoImage ? BUCKET_URL + userDetail.LogoImage : UserImg} className="prof-wrp" alt="" />
              <p className="d-flex justify-content-center">{userDetail && userDetail?.FullName && capitalizeFirstLetters(userDetail.FullName)}</p>
              {userDetail?.LastLoginTime != null && (
                <span className='text-center d-block'>
                  {useIntlMessages('Label.LastLogin')} : {moment(userDetail.LastLoginTime).local().format(DATE_TIME_FORMAT_DISPLAY)}
                </span>
              )}
            </div>
          </Col>
          <Col className="content-sub profileContent">
            <div>
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <div>
                  <h4>
                    <FormattedMessage id="Hd.PersInfo" />
                  </h4>
                </div>
                <Col xs="auto">
                  <Nav>
                    <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                      <NavDropdown.Item onClick={() => navigate('/editprofile')}>
                        <FormattedMessage id="Link.EditProfile" />
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={() => navigate('/changepassword')}>
                        <FormattedMessage id="Link.Change Password" />
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Col>
              </div>
              <div className="content-area-padding">
                <Row className="label-with-content mb-5">
                  <Col xl lg={4} md={4} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Role" />
                    </label>
                    <p>{userDetail && userDetail?.RoleName}</p>
                  </Col>
                  <Col xl lg={4} md={4} sm={6}>
                    <label>
                      <FormattedMessage id="Label.DateofBirth" />
                    </label>
                    <p>{userDetail && userDetail?.Dob ? moment(userDetail.Dob).local().format('MM/DD/YYYY') : MessageNill}</p>
                  </Col>
                  <Col xl lg={4} md={4} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Gender" />
                    </label>
                    <p>
                      {userDetail && userDetail.Gender
                        ? userDetail.Gender === 'F'
                          ? 'Female'
                          : userDetail.Gender === 'M'
                          ? 'Male'
                          : MessageNill
                        : MessageNill}
                    </p>
                  </Col>
                </Row>
                <Row className="label-with-content mb-5">
                  <Col xl lg={4} md={4} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Email" />
                    </label>
                    <p>{userDetail && userDetail?.EmailId}</p>
                  </Col>
                  <Col xl lg={4} md={4} sm={6}>
                    <label>
                      <FormattedMessage id="Label.PhoneNum" />
                    </label>
                    <p>
                      {userDetail && userDetail?.PhonenumCountryCode} {userDetail && userDetail?.PhoneNumberDisplay}
                    </p>
                  </Col>
                  <Col xl lg={4} md={4} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Address" />
                    </label>
                    <p>{userDetail && userDetail?.Address}</p>
                  </Col>
                </Row>
                <Row className="label-with-content mb-4">
                  <Col xl lg={4} md={4} sm={6}>
                    <label>
                      <FormattedMessage id="Label.City" />
                    </label>
                    <p>{userDetail && userDetail?.CityName}</p>
                  </Col>
                  <Col xl lg={4} md={4} sm={6} className="d-flex">
                    <div>
                      <label>
                        <FormattedMessage id="Label.State" />
                      </label>
                      <p> {userDetail && userDetail?.StateName}</p>
                    </div>
                  </Col>
                  <Col xl lg={4} md={4} sm={6} className="d-flex">
                    <div className="zip-col">
                      <label>
                        <FormattedMessage id="Label.Zip" />
                      </label>
                      <p> {userDetail && userDetail?.ZipName}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <div className="content-sub">
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <div>
                  <h4>
                    <FormattedMessage id="Hd.UserHierarchy" />
                  </h4>
                </div>
              </div>
              <div className="content-area-padding content-prof-user">
                <h6>
                  <FormattedMessage id="Hd.SystemAdmin" />
                </h6>
                <Col lg={4}>
                  <div className="user-wrap d-flex">
                    <div className="usr-image">
                      <img src={proImg} alt="" />
                    </div>
                    <div className="usr-name">
                      <p>John Doe</p>
                      <span className="usr-status-admin small-text">Active</span>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </Col>
        </Row> */}
        {/* <Row>
          <Col>
            <UserHierarchyList roleName="Organization Admins/Managers" count={15} usersList={[]} />
            <UserHierarchyList roleName="Teaching staff" count={350} usersList={[]} />
            <UserHierarchyList roleName="Accountability partners" count={1100} usersList={[]} />
            <UserHierarchyList roleName="Students" count={1850} usersList={[]} />
          </Col>
        </Row> */}
      </div>
      {userDetailApiLoading && <Loader />}
    </>
  );
};
export default Myprofile;
