/**
 * @file   src\containers\manageusers\UserTable.tsx
 * @brief  Users List Table Component
 * @date   April, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage } from 'react-intl';
import { IUserTableProps, IUserTableRow } from '../../interfaces/UserInterface';
import { Button } from 'react-bootstrap';
import Default from '../../assets/img/Userimg.jpg';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DATE_FORMAT, BUCKET_URL } from '../../utils/constants';
import ViewIcon from '../../assets/img/icon/View';

const UserTableRows: React.FC<IUserTableProps> = ({ userListApiSuccess, userListApiData, roleLevel, roleTypeId }) => {
  // Navigation object creation
  const navigate = useNavigate();

  // get level one user list
  const getLevelOneTableRows = () => {
    if (userListApiSuccess && userListApiData.length === 0) {
      return (
        <tr>
          <td colSpan={5} className="text-center">
            <FormattedMessage id="NoResults.Found" />
          </td>
        </tr>
      );
    } else if (userListApiSuccess && userListApiData.length > 0) {
      const userListRows = userListApiData.map((user: IUserTableRow) => (
        <tr key={user.UserId}>
          <td>
            <div className="d-flex align-items-center">
              <span className="td-user-image">
                <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : Default} className="w-100" alt="user" />
              </span>
              {user.Name}
            </div>
          </td>
          <td>{user.UserName}</td>
          <td>{user.PhoneNumber}</td>
          <td>{moment(user.DOJ).local().format(DATE_FORMAT)}</td>
          <td>
            <Button
              variant="secondary"
              size="sm"
              className="icon-btn"
              onClick={() =>
                navigate(`/userDetails/${user.UserId}/${roleTypeId}`, {
                  state: {
                    tabName: 'System Admin',
                  },
                })
              }
            >
              <ViewIcon />
            </Button>
          </td>
        </tr>
      ));
      return userListRows;
    }
  };

  // Get table rows for level 6 user - eg.student (app users)
  const getLevel6UsersTableRows = () => {
    if (userListApiSuccess && userListApiData.length === 0) {
      return (
        <tr>
          <td colSpan={7} className="text-center">
            <FormattedMessage id="NoResults.Found" />
          </td>
        </tr>
      );
    } else if (userListApiSuccess && userListApiData.length > 0) {
      const userListRows = userListApiData.map((user: IUserTableRow) => (
        <tr key={user.UserId}>
          <td>
            <div className="d-flex align-items-center">
              <span className="td-user-image">
                <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : Default} className="w-100" alt="user" />
              </span>
              {user.Name}
            </div>
          </td>
          <td>{user.UserName}</td>
          <td>{user.Categories !== null ? user.Categories.join(', ') : null}</td>
          <td>{user.SubCategories !== null ? user.SubCategories.join(', ') : null}</td>
          <td>{moment(user.DOJ).local().format(DATE_FORMAT)}</td>
          <td>
            <Button
              variant="secondary"
              size="sm"
              className="icon-btn"
              onClick={() =>
                navigate(`/userDetails/${user.UserId}/${roleTypeId}`, {
                  state: {
                    tabName: 'Student',
                  },
                })
              }
            >
              <ViewIcon />
            </Button>
          </td>
        </tr>
      ));
      return userListRows;
    }
  };
  // get table rows for level 5 to 3 users
  const getLevelFiveToThreeTableRows = () => {
    if (userListApiSuccess && userListApiData.length === 0) {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            <FormattedMessage id="NoResults.Found" />
          </td>
        </tr>
      );
    } else if (userListApiSuccess && userListApiData.length > 0) {
      const userListRows = userListApiData.map((user: IUserTableRow) => (
        <tr key={user.UserId}>
          <td>
            <div className="d-flex align-items-center">
              <span className="td-user-image">
                <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : Default} className="w-100" alt="user" />
              </span>
              {user.Name}
            </div>
          </td>
          <td>{user.UserName}</td>
          <td>{user.NoStudentAssigned}</td>
          <td>{moment(user.DOJ).local().format(DATE_FORMAT)}</td>
          <td>
            <Button
              variant="secondary"
              size="sm"
              className="icon-btn"
              onClick={() =>
                navigate(`/userDetails/${user.UserId}/${roleTypeId}`, {
                  state: {
                    tabName: 'Accountability Partner',
                  },
                })
              }
            >
              <ViewIcon />
            </Button>
          </td>
        </tr>
      ));
      return userListRows;
    }
  };
  const getSystemAdminTableRows = () => {
    if (userListApiSuccess && userListApiData.length === 0) {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            <FormattedMessage id="NoResults.Found" />
          </td>
        </tr>
      );
    } else if (userListApiSuccess && userListApiData.length > 0) {
      const userListRows = userListApiData.map((user: IUserTableRow) => (
        <tr key={user.UserId}>
          <td>
            <div className="d-flex align-items-center">
              <span className="td-user-image">
                <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : Default} className="w-100" alt="user" />
              </span>
              {user.Name}
            </div>
          </td>
          <td>{user.Email}</td>
          <td>{user.PhoneNumber}</td>
          <td>{moment(user.DOJ).local().format(DATE_FORMAT)}</td>
          <td>
            <Button
              variant="secondary"
              size="sm"
              className="icon-btn"
              onClick={() =>
                navigate(`/userDetails/${user.UserId}/${roleTypeId}`, {
                  state: {
                    tabName: 'Accountability Partner',
                  },
                })
              }
            >
              <ViewIcon />
            </Button>
          </td>
        </tr>
      ));
      return userListRows;
    }
  };

  return (
    <>
      {roleLevel === 'six' && getLevel6UsersTableRows()}
      {roleLevel === '5-3' && getLevelFiveToThreeTableRows()}
      {roleLevel === 'one' && getLevelOneTableRows()}
      {roleLevel === '7' && getSystemAdminTableRows()}
    </>
  );
};

export default UserTableRows;
