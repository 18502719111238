/**
 * @file   src\containers\organizations\TaskDetails.tsx
 * @brief  Task details page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import StudentCard from '../../components/StudentCard';
import UserImg from '../../assets/img/Userimg.jpg';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndMessage from '../EndMessage';
import { useIntlActionMessages } from '../../utils/helper';

const AssignmentStudentList = (props: any) => {
  return (
    <>
      <InfiniteScroll
        dataLength={props?.totalCount > 0 ? props?.StudentList?.length:0}
        next={props.fetchMoreData}
        hasMore={props.hasMore}
        loader={props?.totalCount > 0 && props?.StudentList?.length > 0 && <h3>{useIntlActionMessages('Label.Loading')}</h3>}
        height={300}
        endMessage={props?.totalCount > 0 && props?.StudentList?.length > 0 &&<EndMessage />}
      >
        {((props?.StudentList && props?.StudentList?.length == 0) || !(props?.StudentList) || props?.StudentList?.length===undefined)? (
          <div className="text-center mt-5">No results found.</div>
        ) : (
          props?.StudentList &&
          props?.StudentList?.length > 0 &&
          props?.StudentList?.map((student: any, index: number) => (
            <div key={student.UserID} onClick={() => props?.handlestudentDetail(student)}>
              <StudentCard
                image={UserImg}
                name={student.UserName}
                attachment={student?.Attachments?.length}
                status={student.Status}
                reviewStatus={student.ReviewStatus}
                selected={props?.activeStudentDetails?.UserID == student.UserID ? true : false}
              />
            </div>
          ))
        )}
      </InfiniteScroll>
    </>
  );
};
export default AssignmentStudentList;
