/**
 * @file   src\containers\organizations\Add.tsx
 * @brief  Page to add new organization under organization type.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from 'react';
import '../../assets/css/addOrgType.scss';
import '../../assets/css/org.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, useIntlActionMessages } from '../../utils/helper';
import { Button, Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { ORGANISATION_SCHEMA } from '../../validations/organisationSchema';
import { validateForm } from '../../utils/formValidation';
import { IOrganisationForm, IUploadFilePathUpdateParams, IViewOrganizationInfoApiParams } from '../../interfaces/OrganisationInterface';
import { useNavigate, useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import CreatableSelect from 'react-select/creatable';
import {
  getStates,
  getCities,
  saveOrganization,
  uploadFile,
  uploadFilePathUpdate,
  getOrganizationInfo,
  updateOrganization,
  deleteCategory,
} from '../../store/actions/organizationActions';
import { getOrganizationTypeDropDownList } from '../../store/actions/organizationTypeActions';
import { ISelectOptionsNumber, ISelectOption } from '../../interfaces/GeneralInterface';
import Loader from '../../components/Loader';
import plusicon from '../../assets/img/plus_icn.svg';
import minusicon from '../../assets/img/icn-minus.svg';
import Delete from '../../assets/img/icon/Delete';
import { MessageToaster } from '../../utils/ToastUtil';
import ImageUpload from '../../components/ImageUpload';
import { UploaderProps, IMAGE_TYPES_SUPPORTED, NumberValues } from '../../utils/enums';
import { resetdeleteCategory } from '../../store/slices/organizationSlice';
import Edit from '../../assets/img/icon/Edit';

// Declare constants
const statusValues = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

// Declare default params
const savedefaultRequestParams: IOrganisationForm = {
  Name: '',
  AdminEmail: '',
  CountryCode: '',
  PhoneNo: '',
  Address: '',
  State: 0,
  City: 0,
  CityName: '',
  zip: '',
  Status: 'active',
  Categories: [
    {
      CategoryId: 0,
      CategoryName: '',
      SubCategories: [
        {
          SubCategoryId: 0,
          SubCategoryName: '',
        },
      ],
    },
  ],
  ImageURL: 'string',
  OrganizationTypeId: 0,
};
const OrganizationAdd = () => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();
  // Ref object creation.
  const catRef = useRef<any>(null);
  const inputRef = useRef<any>();
  const imageRef = useRef<any>();
  const containerRef = useRef<any>();
  // Declare constants
  const uploadFailed = useIntlActionMessages('Image.Upload.Failed');
  const LabelValidationAddTerminology = useIntlActionMessages('Label.validation.Add.Terminology');
  const LabelValidationSubCategory = useIntlActionMessages('Label.validation.SubCategory');
  const Empty = useIntlActionMessages('');
  const ImageUploadChooseImage = useIntlActionMessages('Image.Upload.ChooseImage');
  const LabelvalidationCategory = useIntlActionMessages('Label.validation.Category');
  const LabelTerminologiesCategoryPlaceholder = useIntlActionMessages('Label.Terminologies.Category.Placeholder');
  const LabelTerminologiesSubCategoryPlaceholder = useIntlActionMessages('Label.Terminologies.SubCategory.Placeholder');
  const TextAdd = useIntlActionMessages('text.Add');
  const LabelSubCategories = useIntlMessages('Label.SubCategories');
  const DeleteCategoryMessage = useIntlActionMessages('Organization.DeleteCategory');
  const updateBtn = useIntlActionMessages('text.Update');
  const LabelEditOrganization = useIntlActionMessages('Label.EditOrganization');
  const LabelAddOrganization = useIntlActionMessages('Label.AddOrganization');

  // Access redux state variables
  const { organizationTypeDropDownData, organizationTypeDropDownLoading, organizationTypeDropDownSuccess } = useAppSelector((state: RootState) => state.organizationType);
  const {
    saveOrganizationApiLoading,
    saveOrganizationApiSuccess,
    saveOrganizationResponseCode,
    saveOrganizationResponseMessage,
    saveOrganizationApiData,
    updateOrganizationApiLoading,
    updateOrganizationApiSuccess,
    updateOrganizationResponseCode,
    updateOrganizationResponseMessage,
    listStatesApiData,
    listStatesApiLoading,
    listStatesApiSuccess,
    listCitiesApiData,
    listCitiesApiLoading,
    listCitiesApiSuccess,
    uploadFileApiData,
    uploadFileApiLoading,
    uploadFileApiSuccess,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseCode,
    uploadFilePathUpdateResponseMessage,
    deleteCategoryApiLoading,
    deleteCategoryApiSuccess,
    deleteCategoryResponseCode,
    deleteCategoryResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);
  const { organizationDetailApiData, organizationDetailApiSuccess, organizationDetailApiLoading } = useAppSelector((state: RootState) => state.organization);

  // Component state values
  const [stateDropdownOptions, setStateDropdownOptions] = useState<Array<any>>([]);
  const [stateDropdownValue, setStateDropdownValue] = useState<ISelectOptionsNumber | []>([]);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber | []>([]);
  const [organisationForm, setOrganisationForm] = useState<IOrganisationForm>(savedefaultRequestParams);
  const [errorFields, setErrorFields] = useState<any>({});
  const [statusValue, setStatusValue] = useState<any>(statusValues[0]);
  const [categories, setCategories] = useState<any>([{ CategoryId: 0, CategoryName: '', SubCategories: [{ SubCategoryId: 0, SubCategoryName: '' }] }]);
  const [confirmedCategories, setConfirmedCategories] = useState<any>([]);
  const [categoryError, setCategoryError] = useState<string>('');
  const [subCategoryError, setSubCategoryError] = useState<string>('');
  const [submitForm, setSubmitForm] = useState<boolean>(false);
  const [imgError, setImgError] = useState<string>('');
  const [upImage, setUpImage] = useState<string | null>(null);
  const [upImageDetails, setUpImageDetails] = useState<any>({});
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [beginUpdateOrganization, setBeginUpdateOrganization] = useState<boolean>(false);
  const [removeCategory, setRemoveCategory] = useState<number>();
  const [removeCategoryFlag, setRemoveCategoryFlag] = useState<boolean>(false);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const [selectedTags, setSelectedTags] = useState<ISelectOption[]>([]);

  useEffect(() => {
    // Prevent enterkey press
    const listener = (event: any) => {
      if (event.keyCode === NumberValues.NUM_13 && event.target.nodeName != 'TEXTAREA') {
        event.preventDefault();
      }
    };
    const currentContainer = containerRef?.current;
    if (currentContainer) {
      currentContainer.addEventListener('keydown', listener);
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('keydown', listener);
      }
    };
  }, []);
  useEffect(() => {
    dispatch(resetdeleteCategory());
    // Api request to load data initially
    dispatch(getOrganizationTypeDropDownList({}));
    dispatch(getStates({}));
    if (location?.state?.organizationId) {
      const viewOrganizationInfoParams: IViewOrganizationInfoApiParams = {
        OrganizationId: location?.state?.organizationId,
      };
      dispatch(getOrganizationInfo(viewOrganizationInfoParams));
    }
  }, []);

  useEffect(() => {
    if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
      // set data to org type dropdown
      const orgTypeOptions = organizationTypeDropDownData.map(
        (orgtype: any): ISelectOptionsNumber => ({
          label: orgtype.orgTypeName,
          value: orgtype.orgTypeId,
        }),
      );
      setOrganizationTypeOptions(orgTypeOptions);
    }
  }, [organizationTypeDropDownLoading]);
  // Get states
  useEffect(() => {
    if (listStatesApiSuccess && listStatesApiData?.length > 0) {
      // set data to state dropdown
      const stateOptions = listStatesApiData.map(
        (state: any): ISelectOptionsNumber => ({
          label: state.StateName,
          value: state.Id,
        }),
      );
      setStateDropdownOptions(stateOptions);
    }
  }, [listStatesApiLoading]);
  // Get cities
  useEffect(() => {
    if (listCitiesApiSuccess && listCitiesApiData && listCitiesApiData?.length > 0) {
      const options = listCitiesApiData.map(
        (city: any): ISelectOption => ({
          value: city.Id,
          label: city.CityName,
        }),
      );
      setTagOptions(options);
    } else {
      setTagOptions([]);
    }
  }, [listCitiesApiLoading, listCitiesApiData]);

  // set data for organization type dropdown
  useEffect(() => {
    if (organizationDetailApiSuccess && organizationDetailApiData) {
      if (location.pathname === '/editorganization') {
        setPreviewImage(organizationDetailApiData.ImageURL);
        const imgInfo = {
          name: organizationDetailApiData?.ImageURL?.split('.')[0],
          type: organizationDetailApiData?.ImageURL?.split('.')[1],
        };
        setPicture(imgInfo);
        const categories = organizationDetailApiData?.CategoriesAndSubCategories?.map((category: any) => ({
          CategoryId: category.CategoryId,
          CategoryName: category.CategoryName,
          SubCategories: category?.SubCategory?.map((subcategory: any) => ({ SubCategoryId: subcategory.SubCategoryId, SubCategoryName: subcategory.SubCategoryName })),
          // SubCategoryName: category.SubCategory.map((subcategory: any) => subcategory.SubCategoryName),
        }));
        setConfirmedCategories(categories);
        setOrganisationForm((organisationForm: any) => ({
          ...organisationForm,
          Name: organizationDetailApiData.Name,
          AdminEmail: organizationDetailApiData.AdminEmail,
          CountryCode: organizationDetailApiData.CountryCode,
          PhoneNo: organizationDetailApiData.PhoneNo,
          Address: organizationDetailApiData.Address,
          zip: organizationDetailApiData.Zip,
          OrganizationTypeId: organizationDetailApiData.OrganizationTypeId,
          OrganizationID: organizationDetailApiData.OrganizationId,
          ImageURL: organizationDetailApiData.ImageURL,
          State: organizationDetailApiData.StateID,
          City: organizationDetailApiData.CityID,
          CityName: organizationDetailApiData.City,
          Status: organizationDetailApiData.Status.toLowerCase(),
          Categories: categories,
        }));
        setOrganizationTypeValue({
          label: organizationDetailApiData.OrganizationType,
          value: organizationDetailApiData.OrganizationTypeId,
        });
        setStateDropdownValue({
          label: organizationDetailApiData.State,
          value: organizationDetailApiData.StateID,
        });
        setSelectedTags([{ label: organizationDetailApiData?.City, value: organizationDetailApiData?.CityID }]);
        setStatusValue({
          label: organizationDetailApiData.Status,
          value: organizationDetailApiData.Status.toLowerCase(),
        });
        setCharacterCount(organizationDetailApiData.Address.length);
      }
    }
  }, [organizationDetailApiLoading]);

  // Show  messages upon delet category api
  useEffect(() => {
    if (removeCategoryFlag && deleteCategoryResponseCode > 0 && deleteCategoryResponseMessage && !deleteCategoryApiSuccess) {
      toast.toastError(deleteCategoryResponseMessage);
    } else if (removeCategoryFlag && deleteCategoryResponseCode > 0 && deleteCategoryApiSuccess) {
      toast.toastSuccess(deleteCategoryResponseMessage);
      let newCategories = [...confirmedCategories];
      newCategories = newCategories.filter((item: any, index: number) => index !== removeCategory);
      setConfirmedCategories(newCategories);
      setOrganisationForm((info: any) => ({
        ...info,
        Categories: newCategories,
      }));
    }
  }, [deleteCategoryResponseCode, deleteCategoryResponseMessage]);

  // Show error messages upon save api failure
  useEffect(() => {
    if (submitForm && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateResponseMessage && !uploadFilePathUpdateApiSuccess) {
      toast.toastError(uploadFilePathUpdateResponseMessage);
    } else if (submitForm && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateApiSuccess) {
      // Redirect to org listing after updating file status
      toast.toastSuccess(location?.state?.organizationId ? updateOrganizationResponseMessage : saveOrganizationResponseMessage);
      navigate('/manageorganization');
    }
  }, [uploadFilePathUpdateResponseCode, uploadFilePathUpdateResponseMessage]);

  useEffect(() => {
    if (submitForm && saveOrganizationResponseCode > 0 && saveOrganizationResponseMessage && !saveOrganizationApiSuccess) {
      toast.toastError(saveOrganizationResponseMessage);
    } else if (saveOrganizationResponseCode > 0 && saveOrganizationApiSuccess) {
      const reqParams: IUploadFilePathUpdateParams = {
        FilePath: upImageDetails.name,
        Type: 'organization_image',
        TypeId: saveOrganizationApiData?.OrganizationDetails?.OrganizationId,
      };
      // Api request to update file info after bucket upload and save organization info
      dispatch(uploadFilePathUpdate(reqParams));
    }
  }, [saveOrganizationResponseCode, saveOrganizationResponseMessage]);

  useEffect(() => {
    if (submitForm && updateOrganizationResponseCode > 0 && updateOrganizationResponseMessage && !updateOrganizationApiSuccess) {
      toast.toastError(updateOrganizationResponseMessage);
    } else if (submitForm && updateOrganizationResponseCode > 0 && updateOrganizationApiSuccess) {
      if (upImageDetails.name != organizationDetailApiData?.ImageURL?.split('.')[0]) {
        const reqParams: IUploadFilePathUpdateParams = {
          FilePath: upImageDetails.name,
          Type: 'organization_image',
          TypeId: location?.state?.organizationId,
        };
        // Api request to update file info after bucket upload and update organization info
        dispatch(uploadFilePathUpdate(reqParams));
      } else {
        toast.toastSuccess(updateOrganizationResponseMessage);
        navigate('/manageorganization');
      }
    }
  }, [updateOrganizationResponseCode, updateOrganizationResponseMessage]);

  // Handle the upload path api response
  useEffect(() => {
    if (submitForm && uploadFileResponseCode > 0 && uploadFileApiSuccess) {
      uploadPic();
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  // api request to the update organization
  useEffect(() => {
    if (beginUpdateOrganization) {
      dispatch(updateOrganization(organisationForm));
    }
  }, [beginUpdateOrganization]);
  // Add event listener for Enter key press
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        // Check if all required fields are filled
        if (isFormValid()) {
          event.preventDefault();
          onSubmit();
        }
      }
    };
    const isFormValid = () => {
      return Object.values(errorFields).every((error) => !error);
    };
    const currentContainer = containerRef?.current;
    if (currentContainer) {
      currentContainer.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [errorFields]);
  // Put file to bucket after getting the path
  const uploadPic = async () => {
    setBeginUpdateOrganization(false);
    const uploadURL = uploadFileApiData;
    if (uploadURL) {
      // PUT request: upload file to S3
      const result = await fetch(uploadURL, {
        method: 'PUT',
        body: upImage,
      });
      if (result.status == 200) {
        if (location?.state?.organizationId) {
          setBeginUpdateOrganization(true);
        } else {
          dispatch(saveOrganization(organisationForm));
        }
      } else {
        setFileUploading(false);
        setImgError(uploadFailed);
      }
    }
  };
  // handle form fields validtaion
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name == 'Address') {
      setCharacterCount(event.target.value.length);
    }
    setOrganisationForm((info: any) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    CheckValidation(validateObj);
  };
  // handle city changes
  const handleTagChange = (tag: any) => {
    setSelectedTags(tag);
    setOrganisationForm((info) => ({
      ...info,
      City: isNaN(Number(tag?.value)) ? 0 : tag?.value,
      CityName: tag?.label,
    }));
    const validateObj = {
      CityName: tag.label,
    };
    CheckValidation(validateObj);
  };
  // handle form fields validtaion
  const onInputHandleChangeZip = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganisationForm((info: any) => ({
      ...info,
      zip: event.target.value,
    }));
    const validateObj = {
      zip: event.target.value,
    };
    CheckValidation(validateObj);
  };

  // handle form submit
  const onSubmit = async () => {
    setBeginUpdateOrganization(false);
    const errorResult = await validateForm(organisationForm, ORGANISATION_SCHEMA, errorFields);
    if (Object.keys(errorResult).length === 0) {
      setErrorFields({});
      if (confirmedCategories.length == 0 || !confirmedCategories[0].CategoryName.trim()) {
        setCategoryError(LabelValidationAddTerminology);
      } else {
        if (confirmedCategories[0] && confirmedCategories[0]?.SubCategories?.length == 0) {
          setCategoryError('');
          setSubCategoryError(LabelValidationSubCategory);
        } else {
          // Upload file
          setFileUploading(true);
          setCategoryError('');
          setSubCategoryError(Empty);
          setOrganisationForm((info: any) => ({
            ...info,
            Categories: confirmedCategories,
          }));
          setSubmitForm(true);
          // APi request to get the fileupload path
          if (upImage) {
            setImgError('');
            if (location?.state?.organizationId && upImageDetails?.name === organizationDetailApiData?.ImageURL?.split('.')[0]) {
              setBeginUpdateOrganization(true);
            } else {
              const formData = new FormData();
              formData.append('UploadExtension', upImageDetails.type);
              formData.append('UploadObjectKey', upImageDetails.name);
              formData.append('UploadType', 'organization_image');
              dispatch(uploadFile(formData));
            }
          } else {
            setImgError(ImageUploadChooseImage);
            window.scrollTo(0, 0);
            if (imageRef?.current) {
              imageRef?.current?.focus();
            }
          }
          setFileUploading(false);
        }
      }
    } else {
      setErrorFields(errorResult);
      const firstErrorField = Object.keys(errorResult)[0];
      if (firstErrorField && inputRef?.current) {
        inputRef?.current?.focus();
      }
      window.scrollTo(0, 0);
    }
  };

  // handle organization type select box change
  const handleOrgTypeChange = (event: any) => {
    setOrganisationForm((info: any) => ({
      ...info,
      OrganizationTypeId: event.value,
    }));
    setOrganizationTypeValue(event);
    const validateObj = {
      ['OrganizationTypeId']: event.value,
    };
    CheckValidation(validateObj);
  };
  // handle state select box change
  const handleStateChange = (event: any) => {
    setOrganisationForm((info: any) => ({
      ...info,
      State: event.value,
    }));
    setStateDropdownValue(event);
    const reqParams = {
      StateId: event.value,
    };
    dispatch(getCities(reqParams));
    const validateObj = {
      ['State']: event.value,
    };
    CheckValidation(validateObj);
  };
  // handle status select box change
  const handleStatusChange = (event: any) => {
    setOrganisationForm((info: any) => ({
      ...info,
      Status: event.value,
    }));
    setStatusValue([event]);
  };
  // After upating field, check for validation
  const CheckValidation = async (validateObj: any) => {
    const errorResult = await validateForm(validateObj, ORGANISATION_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Add sub-category
  const addSubcategory = (subcategoryIndex: number) => {
    setCategoryError('');
    setSubCategoryError('');
    const newCategories = [...categories];
    newCategories[subcategoryIndex].SubCategories.push({ SubCategoryId: 0, SubCategoryName: '' });
    setCategories(newCategories);
  };
  // Add category
  const addCategory = () => {
    if (!categories[0].CategoryName.trim()) {
      setCategoryError(LabelvalidationCategory);
    } else {
      const emptyItems = categories[0].SubCategories.filter((item: any) => item.SubCategoryName.trim() === '');
      if (emptyItems.length > 0) {
        setSubCategoryError(LabelValidationSubCategory);
      } else {
        setCategoryError('');
        setSubCategoryError('');
        const newItem = {
          CategoryId: categories[0].CategoryId,
          CategoryName: categories[0].CategoryName,
          SubCategories: categories[0].SubCategories,
        };
        if (isUpdate) {
          if (confirmedCategories) {
            // const updated = categories[0].SubCategories.filter((item: any) => item.SubCategoryName.trim() === '');
            setConfirmedCategories(
              confirmedCategories.map((item: any) => {
                if (item.CategoryId === categories[0].CategoryId) {
                  return { ...item, SubCategories: categories[0].SubCategories };
                }
                return item;
              }),
            );
            // setConfirmedCategories([...confirmedCategories, newItem]);
          } else {
            setConfirmedCategories([newItem]);
          }
          setIsUpdate(false);
        } else {
          if (confirmedCategories) {
            setConfirmedCategories([...confirmedCategories, newItem]);
          } else {
            setConfirmedCategories([newItem]);
          }
        }
        setCategories([{ CategoryId: 0, CategoryName: '', SubCategories: [{ SubCategoryId: 0, SubCategoryName: '' }] }]);
      }
    }
  };
  // Delete category
  const deleteCategoryDetails = (categoryName: string, categoryIndex: number, categoryId: number) => {
    let newCategories = [...confirmedCategories];
    newCategories = newCategories.filter((item: any, index: number) => index !== categoryIndex);
    if (categoryId == 0) {
      setConfirmedCategories(newCategories);
      setOrganisationForm((info: any) => ({
        ...info,
        Categories: newCategories,
      }));
      toast.toastSuccess(DeleteCategoryMessage);
    } else {
      setRemoveCategory(categoryIndex);
      setRemoveCategoryFlag(true);
      dispatch(deleteCategory({ CategoryId: categoryId }));
    }
  };

  // Update category
  const onUpdateCategory = (index: number) => {
    const categoryToUpdate = confirmedCategories[index];
    // Set the selected category and classes
    setCategories([categoryToUpdate]);
    catRef?.current?.focus();
    setIsUpdate(true);
  };

  // Remove sub-category
  const removeSubcategory = (subcategoryIndex: number) => {
    const newCategories = [...categories];
    newCategories[0].SubCategories = newCategories[0].SubCategories.filter((item: any, index: number) => index != subcategoryIndex);
    setCategories(newCategories);
  };
  // Function to handle change category
  const onChangeCategory = async (categoryname: string) => {
    const newCategories = [...categories];
    newCategories[0].CategoryName = categoryname;
    setCategories(newCategories);
    if (!categoryname.trim()) {
      setCategoryError(LabelvalidationCategory);
    } else {
      setCategoryError('');
    }
  };
  // Function to handle change sub-category
  const onChangeSubCategory = async (subcategory: string, subcategoryIndex: number) => {
    const newCategories = [...categories];
    newCategories[0].SubCategories[subcategoryIndex].SubCategoryName = subcategory;
    setCategories(newCategories);
    if (!subcategory.trim()) {
      setSubCategoryError(LabelValidationSubCategory);
    } else {
      setSubCategoryError('');
    }
  };
  // set image selected
  const setPicture = (pic: any) => {
    setImgError('');
    setUpImage(pic);
    const imgInfo = {
      name: pic.name,
      type: pic.type,
    };
    setUpImageDetails(imgInfo);
  };
  // Function to handle cancel on tab key press
  const handleCancel: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === 'Enter') {
      navigate('/manageorganization');
    }
  };
  return (
    <>
      <div className="OrganizationAdd">
        <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
          <h3>{location.pathname === '/editorganization' ? <FormattedMessage id="Hd.EditOrg" /> : <FormattedMessage id="Hd.AddOrg" />}</h3>
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>{useIntlMessages('Label.Organization')} </Breadcrumb.Item>
            <Breadcrumb.Item active>{location?.state?.organizationId ? LabelEditOrganization : LabelAddOrganization}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="content-sub content-sub-org add-Org-type " ref={containerRef}>
          <Row className="justify-content-center mb-2">
            <Col xl="auto">
              <ImageUpload
                setError={(error: string) => setImgError(error)}
                imageFile={previewImage ? previewImage : ''}
                setPicture={setPicture}
                maxSize={Number(UploaderProps.IMAGE_MAX_SIZE)}
                acceptedFileTypes={IMAGE_TYPES_SUPPORTED}
                invalidSizeMessage={useIntlActionMessages('Image.Upload.Size.Message')}
                invalidTypeMessage={useIntlActionMessages('Image.Upload.AcceptedType.Error')}
                mode={location?.state?.organizationId ? 'edit' : 'add'}
              />
              <div className="error" ref={imageRef}>
                {' '}
                {imgError}
              </div>
            </Col>
            <div className="upload-info mt-2">
              <ul>
                <li>Upload image files with .png, .jpg or .jpeg extension with size below 5MB.</li>
              </ul>
            </div>
          </Row>
          <Row>
            <Col lg={6}>
              <Input
                autoFocus
                ref={inputRef}
                label={useIntlMessages('Label.OrgName')}
                id="Name"
                name="Name"
                type="text"
                placeholder={useIntlMessages('PH.OrgName')}
                maxLength={NumberValues.NUM_150}
                value={organisationForm.Name}
                onChange={onInputHandleChange}
                errorMessage={errorFields?.Name}
              />
            </Col>
            <Col lg={6}>
              <Select
                id="OrganizationTypeId"
                name="OrganizationTypeId"
                label={useIntlMessages('Label.OrganizationType')}
                options={organizationTypeOptions}
                value={organizationTypeValue}
                placeholder={useIntlMessages('PH.Select')}
                onChange={(e: any) => handleOrgTypeChange(e)}
                error={errorFields?.OrganizationTypeId}
              />
            </Col>
            <Col lg={6}>
              <Input
                label={useIntlMessages('Label.admiEmail')}
                id="AdminEmail"
                name="AdminEmail"
                type="text"
                placeholder={useIntlMessages('PH.OrgEmail')}
                maxLength={NumberValues.NUM_100}
                value={organisationForm.AdminEmail}
                onChange={onInputHandleChange}
                errorMessage={errorFields?.AdminEmail}
              />
            </Col>
            <Col lg={6}>
              <Row>
                <Col xl={4}>
                  <Input
                    label={useIntlMessages('Label.CountryCode')}
                    id="CountryCode"
                    name="CountryCode"
                    type="text"
                    placeholder={useIntlMessages('PH.CountryCode')}
                    maxLength={NumberValues.NUM_5}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.CountryCode}
                    value={organisationForm.CountryCode}
                  />
                </Col>
                <Col>
                  <Input
                    label={useIntlMessages('Label.PhoneNum')}
                    id="PhoneNo"
                    name="PhoneNo"
                    type="text"
                    placeholder={useIntlMessages('PH.PhoneNum')}
                    maxLength={NumberValues.NUM_20}
                    value={organisationForm.PhoneNo}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.PhoneNo}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={12} className="addOrg-textarea">
              <Input
                label={useIntlMessages('Label.address')}
                id="Address"
                name="Address"
                type="textarea"
                placeholder={useIntlMessages('PH.addr')}
                maxLength={NumberValues.NUM_1000}
                value={organisationForm.Address}
                onChange={onInputHandleChange}
                errorMessage={errorFields?.Address}
                as="textarea"
                rows={3}
              />
              <span className="txt-count">
                {characterCount}/{NumberValues.NUM_1000}
              </span>
            </Col>
            <Col lg={6}>
              <Select
                id="State"
                name="State"
                label="State"
                options={stateDropdownOptions}
                value={stateDropdownValue}
                placeholder={useIntlMessages('PH.Select')}
                onChange={(e: any) => handleStateChange(e)}
                error={errorFields?.State}
              />
            </Col>
            <Col xl={6}>
              <label className="form-label">{useIntlMessages('Label.City')}</label>
              <CreatableSelect options={tagOptions} name="Tags" id="Tags" className="tags-selector" onChange={handleTagChange} value={selectedTags} />
              {errorFields?.CityName && (
                <div className="error-message">
                  <small className="text-danger">{errorFields.CityName}</small>
                </div>
              )}
              <small>{useIntlActionMessages('Text.HelpTextCity')}</small>
            </Col>
            <Col lg={6}>
              <Input
                label={useIntlMessages('Label.Zip')}
                id="zip"
                name="zip"
                type="text"
                placeholder={useIntlMessages('PH.ZipC')}
                maxLength={NumberValues.NUM_10}
                value={organisationForm.zip}
                onChange={onInputHandleChangeZip}
                errorMessage={errorFields?.zip}
              />
            </Col>
            <Col lg={6}>
              <Select
                id="OrganizationStatus"
                name="OrganizationStatus"
                label={useIntlMessages('Label.OrganizationStatus')}
                options={statusValues}
                value={statusValue}
                placeholder={useIntlMessages('PH.Select')}
                onChange={(e: any) => handleStatusChange(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center mt-5">
              <h4>{useIntlMessages('Label.Terminologies.Head')}</h4>
              <p className="small-text">{useIntlMessages('Label.Terminologies.Text')}</p>
            </Col>
          </Row>
          <Row className="org-cat mb-3">
            <Col lg={6}>
              <div className="org-cat-col">
                <h6>{useIntlMessages('Label.Categories')}</h6>
                <p className="small-text">{useIntlMessages('Label.Terminologies.Category.Text')}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="org-cat-col">
                <h6>{useIntlMessages('Label.SubCategories')}</h6>
                <p className="small-text">{useIntlMessages('Label.Terminologies.SubCategory.Text')} </p>
              </div>
            </Col>
            {categories?.map((category: any) => (
              <>
                <Col lg={6} className="mb-3">
                  <div className="org-cat-col">
                    <Input
                      id="categoryName"
                      name="categoryName"
                      type="text"
                      onChange={(e: any) => onChangeCategory(e.target.value)}
                      placeholder={LabelTerminologiesCategoryPlaceholder}
                      maxLength={NumberValues.NUM_100}
                      value={category.CategoryName}
                      errorMessage={categoryError ? categoryError : ''}
                      autoFocus={isUpdate ? true : false}
                      txtRef={catRef}
                      // readOnly={isUpdate?true:false}
                    />
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <div className="org-cat-col">
                    {category.SubCategories.map((subcategory: any, subcategoryIndex: number) => (
                      <>
                        <Row>
                          <Col lg={10} md={10} sm={10}>
                            <Input
                              id="subCategoryName"
                              name="subCategoryName"
                              type="text"
                              onChange={(e: any) => onChangeSubCategory(e.target.value, subcategoryIndex)}
                              placeholder={LabelTerminologiesSubCategoryPlaceholder}
                              maxLength={NumberValues.NUM_100}
                              value={subcategory.SubCategoryName}
                            />
                          </Col>
                          <Col lg={2} md={2} sm={2}>
                            {subcategoryIndex == 0 ? (
                              <Button className="Plus-btn" variant="outline-secondary" id="button-addon2" onClick={() => addSubcategory(subcategoryIndex)}>
                                <img src={plusicon} />
                              </Button>
                            ) : (
                              <Button className="Plus-btn" variant="outline-secondary" id="button-addon2" onClick={() => removeSubcategory(subcategoryIndex)}>
                                <img src={minusicon} />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </>
                    ))}
                    <label className="error">{subCategoryError ? subCategoryError : ''}</label>
                    <Button className="Plus-btn Add-btn" variant="outline-secondary" id="button-addon2" onClick={addCategory}>
                      <img src={plusicon} />
                      {isUpdate ? updateBtn : TextAdd}
                    </Button>
                  </div>
                </Col>
              </>
            ))}
          </Row>
          {confirmedCategories && confirmedCategories?.length > 0 && (
            <Row>
              <div className="content-sub org-term p-0">
                <div className="content-sub-header d-flex justify-content-between align-items-center">
                  <Col lg={5}>
                    <h6>
                      <FormattedMessage id="Hd.Categories" />
                    </h6>
                  </Col>
                  <Col lg={5}>
                    <h6>{LabelSubCategories}</h6>
                  </Col>
                  <Col lg={2}></Col>
                </div>
                {confirmedCategories?.map((listCategory: any, categoryIndex: number) => (
                  <Row>
                    <Col lg={5} className="categ-l">
                      <div className="p-3">{listCategory.CategoryName}</div>
                    </Col>
                    <Col lg={5} className="class-l">
                      <div className="p-3">
                        {listCategory?.SubCategories?.map((listSubcategory: any, listSubcategoryIndex: number) => (
                          <>
                            {listSubcategory.SubCategoryName}
                            {listSubcategoryIndex !== listCategory.SubCategories.length - 1 && ', '}
                          </>
                        ))}
                      </div>
                    </Col>
                    <Col lg={2} className="p-3 d-flex justify-content-end">
                      <div>
                        <Button variant="dark" size="sm" className="icon-btn me-2" onClick={() => onUpdateCategory(categoryIndex)}>
                          <Edit />
                        </Button>
                        <Button
                          variant="dark"
                          size="sm"
                          className="me-3 icon-btn "
                          onClick={() => deleteCategoryDetails(listCategory.CategoryName, categoryIndex, listCategory.CategoryId)}
                        >
                          {' '}
                          <Delete />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ))}
              </div>
            </Row>
          )}
          <Row>
            <Col lg={12} className="d-flex justify-content-end ">
              <Button className="mb-4 btn-cancel" variant="outline-primary" onClick={() => navigate('/manageorganization')} onKeyDown={handleCancel}>
                <FormattedMessage id="Button.Cancel" />
              </Button>
              <Button
                className="mb-4 btn-Done"
                variant="primary"
                onClick={onSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              >
                <FormattedMessage id="Button.Done" />
              </Button>
            </Col>
          </Row>
        </div>
        {(organizationTypeDropDownLoading ||
          listStatesApiLoading ||
          listCitiesApiLoading ||
          uploadFileApiLoading ||
          uploadFilePathUpdateApiLoading ||
          saveOrganizationApiLoading ||
          updateOrganizationApiLoading ||
          fileUploading ||
          deleteCategoryApiLoading ||
          organizationDetailApiLoading) && <Loader />}
      </div>
    </>
  );
};
export default OrganizationAdd;
