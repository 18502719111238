/**
 * @file   src\store\slices\userSlice.ts
 * @brief  This file is responsible for creating user based slices to call actions and state management.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchUsersList,
  addSingleUser,
  userdetails,
  addSystemAdmin,
  getProfileSettings,
  usersHierarchyStudents,
  orgListForAdmins,
  usersBulkUpload,
  usersHierarchyAcPartners,
  assignOrganizations,
} from '../actions/userActions';
import { IUserSlice } from '../../interfaces/UserInterface';

// Initial state of user slice
const userDefaultState: IUserSlice = {
  userListApiData: [],
  userListApiLoading: false,
  userListApiResponseCode: 0,
  userListApiResponseMessage: '',
  userListApiSuccess: false,
  addUserApiData: {},
  addUserApiLoading: false,
  addUserApiResponseCode: 0,
  addUserApiResponseMessage: '',
  addUserApiSuccess: false,
  userDetailApiLoading: false,
  userDetailViewApiSuccess: false,
  userDetailViewApiResponseCode: 0,
  userDetailViewApiResponseMessage: '',
  userDetail: null,
  addSystemAdminApiData: {},
  addSystemAdminApiLoading: false,
  addSystemAdminApiResponseCode: 0,
  addSystemAdminApiResponseMessage: '',
  addSystemAdminApiSuccess: false,
  profileSettingsApiLoading: false,
  profileSettingsApiSuccess: false,
  profileSettingsApiData: {},
  profileSettingsApiResponseCode: 0,
  profileSettingsApiResponseMessage: '',
  userBulkUploadApiData: {},
  userBulkUploadApiLoading: false,
  userBulkUploadApiResponseCode: 0,
  userBulkUploadApiResponseMessage: '',
  userBulkUploadApiSuccess: false,
  userHierarchyStudentsApiLoading: false,
  userHierarchyStudentsApiSuccess: false,
  userHierarchyStudentsApiData: {},
  userHierarchyStudentsApiResponseCode: 0,
  userHierarchyStudentsApiResponseMessage: '',
  orgListForAdminsApiLoading: false,
  orgListForAdminsApiSuccess: false,
  orgListForAdminsApiData: [],
  orgListForAdminsApiResponseCode: 0,
  orgListForAdminsApiResponseMessage: '',
  userHierarchyAcPartnersApiLoading: false,
  userHierarchyAcPartnersApiSuccess: false,
  userHierarchyAcPartnersApiData: [],
  userHierarchyAcPartnersApiResponseCode: 0,
  userHierarchyAcPartnersApiResponseMessage: '',
  assignOrgsApiLoading: false,
  assignOrgsApiData: {},
  assignOrgsApiSuccess: false,
  assignOrgsApiResponseCode: 0,
  assignOrgsApiResponseMessage: '',
};

// Authentication slice
const userSlice = createSlice({
  name: 'users',
  initialState: userDefaultState,
  reducers: {
    resetUsersList: (state) => {
      state.userListApiData = {};
      state.userListApiResponseCode = 0;
      state.userListApiLoading = false;
      state.userListApiSuccess = false;
      state.userListApiResponseMessage = '';
    },
    resetUserAdd: (state) => {
      state.addUserApiData = {};
      state.addUserApiLoading = false;
      state.addUserApiResponseCode = 0;
      state.addUserApiResponseMessage = '';
      state.addUserApiSuccess = false;
    },
    resetUserDetails: (state) => {
      state.userDetailApiLoading = false;
      state.userDetailViewApiSuccess = false;
      state.userDetailViewApiResponseCode = 0;
      state.userDetailViewApiResponseMessage = '';
      state.userDetail = null;
    },
    resetSystemAdminAdd: (state) => {
      state.addSystemAdminApiData = {};
      state.addSystemAdminApiLoading = false;
      state.addSystemAdminApiResponseCode = 0;
      state.addSystemAdminApiResponseMessage = '';
      state.addSystemAdminApiSuccess = false;
    },
    resetProfileSettings: (state) => {
      state.profileSettingsApiData = {};
      state.profileSettingsApiLoading = false;
      state.profileSettingsApiResponseCode = 0;
      state.profileSettingsApiResponseMessage = '';
      state.profileSettingsApiSuccess = false;
    },
    resetUserBulkUpload: (state) => {
      state.userBulkUploadApiData = {};
      state.userBulkUploadApiLoading = false;
      state.userBulkUploadApiResponseCode = 0;
      state.userBulkUploadApiResponseMessage = '';
      state.userBulkUploadApiSuccess = false;
    },
    resetUserHierarchyStudents: (state) => {
      state.userHierarchyStudentsApiLoading = false;
      state.userHierarchyStudentsApiSuccess = false;
      state.userHierarchyStudentsApiData = {};
      state.userHierarchyStudentsApiResponseCode = 0;
      state.userHierarchyStudentsApiResponseMessage = '';
    },
    resetUserHierarchyAcPartners: (state) => {
      state.userHierarchyAcPartnersApiLoading = false;
      state.userHierarchyAcPartnersApiSuccess = false;
      state.userHierarchyAcPartnersApiData = [];
      state.userHierarchyAcPartnersApiResponseCode = 0;
      state.userHierarchyAcPartnersApiResponseMessage = '';
    },
    resetAssignOrg: (state) => {
      state.assignOrgsApiLoading = false;
      state.assignOrgsApiData = {};
      state.assignOrgsApiSuccess = false;
      state.assignOrgsApiResponseCode = 0;
      state.assignOrgsApiResponseMessage = '';
    },
    resetOrgListForAdmins: (state) => {
      state.orgListForAdminsApiLoading = false;
      state.orgListForAdminsApiSuccess = false;
      state.orgListForAdminsApiData = [];
      state.orgListForAdminsApiResponseCode = 0;
      state.orgListForAdminsApiResponseMessage = '';
    },
  },
  extraReducers(builder) {
    // user list api starts
    builder.addCase(fetchUsersList.pending, (state) => {
      state.userListApiLoading = true;
      state.userListApiSuccess = false;
      state.userListApiResponseCode = 0;
    });
    // user list api success
    builder.addCase(fetchUsersList.fulfilled, (state, action) => {
      state.userListApiLoading = false;
      state.userListApiSuccess = true;
      state.userListApiResponseCode = action.payload.ResponseCode;
      state.userListApiData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
    });
    // user list api failure
    builder.addCase(fetchUsersList.rejected, (state, action: any) => {
      state.userListApiLoading = false;
      state.userListApiSuccess = false;
      state.userListApiResponseCode = action.payload.ResponseCode;
      state.userListApiResponseMessage = action.payload.ResponseMessage;
    });
    // user details api starts
    builder.addCase(userdetails.pending, (state) => {
      state.userDetailApiLoading = true;
      state.userDetailViewApiSuccess = false;
      state.userDetailViewApiResponseCode = 0;
    });
    // user details api success
    builder.addCase(userdetails.fulfilled, (state, action) => {
      state.userDetailApiLoading = false;
      state.userDetailViewApiSuccess = true;
      state.userDetailViewApiResponseCode = action.payload.ResponseCode;
      state.userDetail = action.payload.ResponseData;
    });
    // user details api failure
    builder.addCase(userdetails.rejected, (state, action: any) => {
      state.userDetailApiLoading = false;
      state.userDetailViewApiSuccess = false;
      state.userDetailViewApiResponseCode = action.payload.ResponseCode;
      state.userDetailViewApiResponseMessage = action.payload.ResponseMessage;
    });
    // user add  api starts
    builder.addCase(addSingleUser.pending, (state) => {
      state.addUserApiLoading = true;
      state.addUserApiSuccess = false;
      state.addUserApiResponseCode = 0;
    });
    // user add api success
    builder.addCase(addSingleUser.fulfilled, (state, action) => {
      state.addUserApiLoading = false;
      state.addUserApiSuccess = true;
      state.addUserApiResponseCode = action.payload.ResponseCode;
      state.addUserApiData = action.payload.ResponseData;
      state.addUserApiResponseMessage = action.payload.ResponseMessage;
    });
    // user add api failure
    builder.addCase(addSingleUser.rejected, (state, action: any) => {
      state.addUserApiLoading = false;
      state.addUserApiSuccess = false;
      state.addUserApiResponseCode = action.payload.ResponseCode;
      state.addUserApiResponseMessage = action.payload.ResponseMessage;
    });

    // system admin add  api starts
    builder.addCase(addSystemAdmin.pending, (state) => {
      state.addSystemAdminApiLoading = true;
      state.addSystemAdminApiSuccess = false;
      state.addSystemAdminApiResponseCode = 0;
    });
    // system admin add api success
    builder.addCase(addSystemAdmin.fulfilled, (state, action) => {
      state.addSystemAdminApiLoading = false;
      state.addSystemAdminApiSuccess = true;
      state.addSystemAdminApiResponseCode = action.payload.ResponseCode;
      state.addSystemAdminApiData = action.payload.ResponseData;
      state.addSystemAdminApiResponseMessage = action.payload.ResponseMessage;
    });
    // system admin add api failure
    builder.addCase(addSystemAdmin.rejected, (state, action: any) => {
      state.addSystemAdminApiLoading = false;
      state.addSystemAdminApiSuccess = false;
      state.addSystemAdminApiResponseCode = action.payload.ResponseCode;
      state.addSystemAdminApiResponseMessage = action.payload.ResponseMessage;
    });
    // Profile settings api starts
    builder.addCase(getProfileSettings.pending, (state) => {
      state.profileSettingsApiLoading = true;
      state.profileSettingsApiSuccess = false;
      state.profileSettingsApiResponseCode = 0;
    });
    // Profile settings api success
    builder.addCase(getProfileSettings.fulfilled, (state, action) => {
      state.profileSettingsApiLoading = false;
      state.profileSettingsApiSuccess = true;
      state.profileSettingsApiResponseCode = action.payload.ResponseCode;
      state.profileSettingsApiData = action.payload.ResponseData;
      state.profileSettingsApiResponseMessage = action.payload.ResponseMessage;
    });
    // Profile settings api failure
    builder.addCase(getProfileSettings.rejected, (state, action: any) => {
      state.profileSettingsApiLoading = false;
      state.profileSettingsApiSuccess = false;
      state.profileSettingsApiResponseCode = action.payload.ResponseCode;
      state.profileSettingsApiResponseMessage = action.payload.ResponseMessage;
    });
    // user bulk upload  api starts
    builder.addCase(usersBulkUpload.pending, (state) => {
      state.userBulkUploadApiLoading = true;
      state.userBulkUploadApiSuccess = false;
      state.userBulkUploadApiResponseCode = 0;
    });
    // user bulk upload  api success
    builder.addCase(usersBulkUpload.fulfilled, (state, action) => {
      state.userBulkUploadApiLoading = false;
      state.userBulkUploadApiSuccess = true;
      state.userBulkUploadApiResponseCode = action.payload.ResponseCode;
      state.userBulkUploadApiData = action.payload.ResponseData;
      state.userBulkUploadApiResponseMessage = action.payload.ResponseMessage;
    });
    // user bulk upload  api failure
    builder.addCase(usersBulkUpload.rejected, (state, action: any) => {
      state.userBulkUploadApiLoading = false;
      state.userBulkUploadApiSuccess = false;
      state.userBulkUploadApiResponseCode = action.payload.ResponseCode;
      state.userBulkUploadApiResponseMessage = action.payload.ResponseMessage;
    });
    // Profile settings api starts
    builder.addCase(usersHierarchyStudents.pending, (state) => {
      state.userHierarchyStudentsApiLoading = true;
      state.userHierarchyStudentsApiSuccess = false;
      state.userHierarchyStudentsApiResponseCode = 0;
    });
    // Profile settings api success
    builder.addCase(usersHierarchyStudents.fulfilled, (state, action) => {
      state.userHierarchyStudentsApiLoading = false;
      state.userHierarchyStudentsApiSuccess = true;
      state.userHierarchyStudentsApiResponseCode = action.payload.ResponseCode;
      state.userHierarchyStudentsApiData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
      state.userHierarchyStudentsApiResponseMessage = action.payload.ResponseMessage;
    });
    // Profile settings api failure
    builder.addCase(usersHierarchyStudents.rejected, (state, action: any) => {
      state.userHierarchyStudentsApiLoading = false;
      state.userHierarchyStudentsApiSuccess = false;
      state.userHierarchyStudentsApiResponseCode = action.payload.ResponseCode;
      state.userHierarchyStudentsApiResponseMessage = action.payload.ResponseMessage;
    });
    // organization list for admins api starts
    builder.addCase(orgListForAdmins.pending, (state) => {
      state.orgListForAdminsApiLoading = true;
      state.orgListForAdminsApiSuccess = false;
      state.orgListForAdminsApiResponseCode = 0;
    });
    // organization list for admins api success
    builder.addCase(orgListForAdmins.fulfilled, (state, action) => {
      state.orgListForAdminsApiLoading = false;
      state.orgListForAdminsApiSuccess = true;
      state.orgListForAdminsApiResponseCode = action.payload.ResponseCode;
      state.orgListForAdminsApiData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
      state.orgListForAdminsApiResponseMessage = action.payload.ResponseMessage;
    });
    // organization list for admins api failure
    builder.addCase(orgListForAdmins.rejected, (state, action: any) => {
      state.orgListForAdminsApiLoading = false;
      state.orgListForAdminsApiSuccess = false;
      state.orgListForAdminsApiResponseCode = action.payload.ResponseCode;
      state.orgListForAdminsApiResponseMessage = action.payload.ResponseMessage;
    });
    // user hierarchy accountability partners api starts
    builder.addCase(usersHierarchyAcPartners.pending, (state) => {
      state.userHierarchyAcPartnersApiLoading = true;
      state.userHierarchyAcPartnersApiSuccess = false;
      state.userHierarchyAcPartnersApiResponseCode = 0;
    });
    // user hierarchy accountability partners api success
    builder.addCase(usersHierarchyAcPartners.fulfilled, (state, action) => {
      state.userHierarchyAcPartnersApiLoading = false;
      state.userHierarchyAcPartnersApiSuccess = true;
      state.userHierarchyAcPartnersApiResponseCode = action.payload.ResponseCode;
      state.userHierarchyAcPartnersApiData = action.payload.ResponseData === null ? [] : action.payload.ResponseData;
      state.userHierarchyAcPartnersApiResponseMessage = action.payload.ResponseMessage;
    });
    // user hierarchy accountability partners api failure
    builder.addCase(usersHierarchyAcPartners.rejected, (state, action: any) => {
      state.userHierarchyAcPartnersApiLoading = false;
      state.userHierarchyAcPartnersApiSuccess = false;
      state.userHierarchyAcPartnersApiResponseCode = action.payload.ResponseCode;
      state.userHierarchyAcPartnersApiResponseMessage = action.payload.ResponseMessage;
    });
    // assign orgs to org admin api starts
    builder.addCase(assignOrganizations.pending, (state) => {
      state.assignOrgsApiLoading = true;
      state.assignOrgsApiSuccess = false;
      state.assignOrgsApiResponseCode = 0;
    });
    // assign orgs to org admin api success
    builder.addCase(assignOrganizations.fulfilled, (state, action) => {
      state.assignOrgsApiLoading = false;
      state.assignOrgsApiSuccess = true;
      state.assignOrgsApiResponseCode = action.payload.ResponseCode;
      state.assignOrgsApiData = action.payload.ResponseData;
      state.assignOrgsApiResponseMessage = action.payload.ResponseMessage;
    });
    // assign orgs to org admin api failure
    builder.addCase(assignOrganizations.rejected, (state, action: any) => {
      state.assignOrgsApiLoading = false;
      state.assignOrgsApiSuccess = false;
      state.assignOrgsApiResponseCode = action.payload.ResponseCode;
      state.assignOrgsApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export actions
export const {
  resetUsersList,
  resetUserAdd,
  resetUserDetails,
  resetSystemAdminAdd,
  resetProfileSettings,
  resetUserBulkUpload,
  resetUserHierarchyStudents,
  resetAssignOrg,
  resetUserHierarchyAcPartners,
  resetOrgListForAdmins,
} = userSlice.actions;
// Export reducer.
export default userSlice.reducer;
