/**
 * @file   src\containers\organizations\ReviewProof.tsx
 * @brief  Review Proof page.
 * @date   March, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React from 'react';
import '../../assets/css/Home.scss'
import { FormattedMessage } from 'react-intl';
import { Col, Row, Breadcrumb, Button } from 'react-bootstrap';
import Select from '../../components/MASelect';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserImg from '../../assets/img/Userimg.jpg';
import RightIcon from '../../assets/img/icon/RightArrow';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';

const ReviewProof = () => {
    const showBy = [
        { label: "Show by Name", value: "1" },
        { label: "Show by Task", value: "2" }
    ];
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (

        <>
            <div className='page-title d-flex justify-content-between align-items-center'>
                <h3>Review Proof</h3>
                <Col md="auto">
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item>
                            <FormattedMessage id="Hd.Dashboard" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <FormattedMessage id="Hd.Overview" />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            Review Proof
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                {/* <Col xl={2} lg={4} md={4} className='form-sm'>
          <Select
            label={useIntlMessages("Label.OrganizationType")}
            options={organization}
            value={organizationValue}
            placeholder="Select"
            onChange={() => console.log('todo')}
          // error="Error"
          />
        </Col> */}
            </div>
            <div className='page-title d-flex justify-content-between align-items-center'>
                <h5 className='mb-0'>Completion Proof</h5>
                <Col xl={2} lg={3} md={4} className='form-sm'>
                    <Select
                        options={showBy}
                        placeholder="Select"
                        onChange={() => console.log('todo')}
                    />
                </Col>
            </div>
            <div className='content-sub'>
                <div className='content-sub-header d-flex justify-content-between align-items-center'>
                    <div>
                        <div className="d-flex align-items-center userthumnail">
                            <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            <label>
                                Student Name X<span className="d-block font-xs">Org. Name</span>
                            </label>
                        </div>
                    </div>
                    <Col sm="auto">
                        <Button variant="dark" size="sm" className="icon-btn">
                            <RightIcon />
                        </Button>
                    </Col>
                </div>
                <div className='content-area-padding'>
                    <Row>
                        <Col xl={6}>
                            <div className='content-sub content-sub-secondary'>
                                <div className='content-sub-header d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className="d-flex align-items-center userthumnail">
                                            <label>
                                            Name of Task 1
                                            <span className="d-block font-xs">Jan 20, 2022</span>
                                            </label>
                                        </div>
                                    </div>
                                    <Col sm="auto">
                                        <Button variant="dark" size="sm" className="icon-btn">
                                            <RightIcon />
                                        </Button>
                                    </Col>
                                </div>
                                <div className='content-area-padding'>
                                    <h5 className='text-center'>Attachments (2)</h5>
                                    <div className="reviewproof-main">
                                        <Slider {...settings}>
                                            <div>
                                                <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' width="100%" />
                                                <p>filename_name.mp4</p>
                                            </div>
                                            <div><ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' width="100%" />
                                                <p>filename_name.mp4</p></div>
                                            <div><ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' width="100%" />
                                                <p>filename_name.mp4</p></div>
                                        </Slider>
                                    </div>
                                    <Row className="mt-2">
                                        <Col>
                                            <Button variant="outline-primary" className="w-100">Add Message</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="outline-primary" className="w-100">Reject</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="primary" className="w-100">Approve</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className='content-sub content-sub-secondary'>
                                <div className='content-sub-header d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className="d-flex align-items-center userthumnail">
                                            <label>
                                            Name of Task 2<span className="d-block font-xs">Jan 20, 2022</span>
                                            </label>
                                        </div>
                                    </div>
                                    <Col sm="auto">
                                        <Button variant="dark" size="sm" className="icon-btn">
                                            <RightIcon />
                                        </Button>
                                    </Col>
                                </div>
                                <div className='content-area-padding'>
                                    <h5 className='text-center'>Attachments (2)</h5>
                                    <div className="reviewproof-main">
                                        <Slider {...settings}>
                                            <div>
                                                <ReactAudioPlayer
                                                    src="my_audio_file.ogg"
                                                    autoPlay
                                                    controls
                                                />
                                                <p>filename_name.mp4</p>
                                            </div>
                                            <div><ReactAudioPlayer
                                                    src="my_audio_file.ogg"
                                                    autoPlay
                                                    controls
                                                />
                                                <p>filename_name.mp4</p></div>
                                            <div><ReactAudioPlayer
                                                    src="my_audio_file.ogg"
                                                    autoPlay
                                                    controls
                                                />
                                                <p>filename_name.mp4</p></div>
                                        </Slider>
                                    </div>
                                    <Row className="mt-2">
                                        <Col>
                                            <Button variant="outline-primary" className="w-100">Add Message</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="outline-primary" className="w-100">Reject</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="primary" className="w-100">Approve</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='content-sub'>
                <div className='content-sub-header d-flex justify-content-between align-items-center'>
                    <div>
                        <div className="d-flex align-items-center userthumnail">
                            <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            <label>
                                Student Name Y<span className="d-block font-xs">Org. Name</span>
                            </label>
                        </div>
                    </div>
                    <Col sm="auto">
                        <Button variant="dark" size="sm" className="icon-btn">
                            <RightIcon />
                        </Button>
                    </Col>
                </div>
                <div className='content-area-padding'>
                    <Row>
                        <Col xl={6}>
                            <div className='content-sub content-sub-secondary'>
                                <div className='content-sub-header d-flex justify-content-between align-items-center'>
                                    <div>
                                        <div className="d-flex align-items-center userthumnail">
                                            <label>
                                            Name of Task 1
                                            <span className="d-block font-xs">Jan 20, 2022</span>
                                            </label>
                                        </div>
                                    </div>
                                    <Col sm="auto">
                                        <Button variant="dark" size="sm" className="icon-btn">
                                            <RightIcon />
                                        </Button>
                                    </Col>
                                </div>
                                <div className='content-area-padding'>
                                    <h5 className='text-center'>Attachments (2)</h5>
                                    <div className="reviewproof-main">
                                        <Slider {...settings}>
                                            <div>
                                                <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' width="100%" />
                                                <p>filename_name.mp4</p>
                                            </div>
                                            <div><ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' width="100%" />
                                                <p>filename_name.mp4</p></div>
                                            <div><ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' width="100%" />
                                                <p>filename_name.mp4</p></div>
                                        </Slider>
                                    </div>
                                    <Row className="mt-2">
                                        <Col>
                                            <Button variant="outline-primary" className="w-100">Add Message</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="outline-primary" className="w-100">Reject</Button>
                                        </Col>
                                        <Col>
                                            <Button variant="primary" className="w-100">Approve</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
export default ReviewProof;