/**
 * @file   src/containers/profile/TeacherPlanListCard.tsx
 * @brief  Subscription plan card element
 * @date   August, 2024
 * @author ZCO
 * @copyright (c) 2024, ZCO */

import { useState } from 'react';
import '../../assets/css/TeacherPlanListCard.scss';
import { Button } from 'react-bootstrap';
import { PlanTypes } from '../../utils/enums';
import { IPlanCardProps } from '../../interfaces/ProfileInterface';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentPopUp from './PaymentPopUp';
import { useLocation } from 'react-router-dom';

// stripe key
const stripePromise = loadStripe('pk_test_51PL5J12MewYdLBpvyz9pNUA7jmNkuzBHD77nw0xcAqfV2govTcf0ju857qo8rs5mMTWmlkM5tNNtn08xRp8sbhuw00p9ISbzoN');

const TeacherPlanList = ({ price, plantype, duration, webusersno, appusersno, planid, discount, context ,planname }: IPlanCardProps) => {
  // component state variables
  const [show, setShow] = useState(false);
  const [planPrice, setPlanPrice] = useState<number>(0);
  // show payment popup.set chosen price for subscription
  const showPaymentPopUp = (price: number) => {
    setShow(true);
    setPlanPrice(price);
  };
  // convert plantype to plantype text
  const planTypeText = (plantype: string) => {
    let plantext = '';
    switch (plantype) {
      case PlanTypes.MONTHLY:
        plantext = 'per month';
        break;
      case PlanTypes.HALF_YEARLY:
        plantext = 'per six months';
        break;
      case PlanTypes.QUARTERLY:
        plantext = 'per three months';
        break;
      case PlanTypes.YEARLY:
        plantext = 'per year';
        break;
    }
    return plantext;
  };
  return (
    <div className="teacherplanlist-main">
      <div>
        {discount > 0 ? <div className="discount-label">Save ${discount}</div> : ' '}
        <h4>{planname}</h4>
        <p>Subscription</p>
        <div className="d-flex align-items-end mb-4 mt-4">
          <h1>${price}</h1>
          <small>/ {planTypeText(plantype)}</small>
        </div>
        <h6>Web Users: {webusersno}</h6>
        <h6>App Users: {appusersno}</h6>
        <h6>
          ${price} Billed {plantype}
        </h6>
      </div>
      <div className="d-grid mt-3">
        <Button
          variant="primary"
          className="btn-block"
          onClick={() => {
            if (context === 'Cancel') {
              window.alert('Not Implemented');
            } else {
              showPaymentPopUp(price);
            }
          }}
        >
          {context == 'Cancel' ? 'Cancel subscription' : 'Subscribe'}
        </Button>
      </div>
      <Elements stripe={stripePromise}>
        <PaymentPopUp show={show} setShow={setShow} price={planPrice} planid={planid} plantype={plantype} />
      </Elements>
    </div>
  );
};
export default TeacherPlanList;
