/**
 * @file   src\containers\competencies\List.tsx
 * @brief  List Competencies.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect } from 'react';
import '../../assets/css/Competencies.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { Col, Row, Button, Accordion } from 'react-bootstrap';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Select from '../../components/MASelect';
import CompetenciesCard from '../../components/CompetenciesCard';
import ListCard from '../../components/OrgListCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from '../../components/Loader';
import Edit from '../../assets/img/icon/Edit';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { getCompetenciesList, getTopCompetenciesList, deleteCompetency } from '../../store/actions/competencyActions';
import { PAGE_SIZE, DEFAULT_PAGE_INDEX, BUCKET_URL } from '../../utils/constants';
import Pagination from '../../components/Pagination';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import Default from '../../assets/img/default.jpg';
import DatePicker from 'react-datepicker';
import Delete from '../../assets/img/icon/Delete';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { getOrganizationTypeDropDownList, getOrganizationNameByOrgType } from '../../store/actions/organizationTypeActions';
import { IDeleteCompetencyApiParams } from '../../interfaces/CompetencyInterface';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetDeleteCompetency,resetCompetenciesList,resetTopCompetenciesList } from '../../store/slices/competencySlice';
import { RoleTypeIds } from '../../utils/enums';

// Declare default params  for listing
const defaultApiParams = {
  DateRange: '',
  OrganizationID: '',
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  StartDate: '',
  EndDate: '',
};
// Declare default params for listing top 6 competencies
const defaultTopListApiParams = {
  DateRange: '',
  OrganizationID: '',
  TagOptions: [],
  StartDate: '',
  EndDate: '',
};

const CompetenciesList = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Message toaster object creation
  const toastObj = new MessageToaster();
  // Access redux state variables.

  const {
    competenciesListData,
    competenciesListApiLoading,
    competenciesListApiSuccess,
    competenciesTopListData,
    competenciesTopListApiSuccess,
    deleteCompetencyApiSuccess,
    deleteCompetencyApiResponseMessage,
    deleteCompetencyApiLoading,
  } = useAppSelector((state: RootState) => state.competency);

  const { organizationTypeDropDownData, organizationTypeDropDownSuccess, organizationTypeDropDownLoading, orgnameByOrgTypeData, orgnameByOrgTypeLoading,rolesByOrgTypeData } = useAppSelector(
    (state: RootState) => state.organizationType,
  );

  // Declare constants for tags
  const tags = [
    { label: 'Option1', value: 0 },
    { label: 'Option2', value: 1 },
    { label: 'Option3', value: 2 },
  ];
  // Declare constants for date filter
  const selectDays = [
    { label: 'Last 7 days', value: 'Last_7_days' },
    { label: 'Last 30 days', value: 'Last_30_days' },
    { label: 'Last 6 months', value: 'Last_6_month' },
    { label: 'Last 1 year', value: 'Last_1_year' },
    { label: 'Custom Range', value: 'Custom_Range' },
  ];
  // Handle top 6 tile style
  const tileClasses = ['dash-tile students', 'dash-tile accountability', 'dash-tile teachers', 'dash-tile managers', 'dash-tile admins', 'dash-tile greentile'];

  // Initialize component state variables.
  const NodataText = useIntlActionMessages('Label.Nodata');
  const [dateFilter, setDateFilter] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [apiParams, setApiParams] = useState<any>(defaultApiParams);
  const [apiTopListParams, setTopListApiParams] = useState<any>(defaultTopListApiParams);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber | null>();
  const [organisations, setOrganisations] = useState(orgnameByOrgTypeData);
  const [selectedOrganization, setSelectedOrganization] = useState<Array<any>>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [selectedCompetencyId, setSelectedCompetencyId] = useState<string | null>(null);
  const [selectedOrganizationID, setSelectedOrganizationID] = useState<any>(null);
  const DeleteCompetencyError = useIntlActionMessages('Text.DeleteCompetencyError');
  const EditCompetencyError = useIntlActionMessages('Text.EditCompetencyError');
   // Reset the form after listing competency
   useEffect(() => {
    return () => {
      dispatch(resetCompetenciesList());
      dispatch(resetTopCompetenciesList());
    };
  }, []);
  // Populate organization details when coming from organization page
  useEffect(() => {
    if (location?.state) {
      const { organizationId, OrganizationTypeId, OrganizationType } = location.state;
      if (OrganizationTypeId) {
        setOrganizationTypeValue({ label: OrganizationType, value: OrganizationTypeId });
      }
      if (organizationId) {
        const selectedOrgTypeId = parseInt(OrganizationTypeId);
        dispatch(
          getOrganizationNameByOrgType({
            OrganizationTypeId: selectedOrgTypeId,
          }),
        );
        const matchedOrganization = orgnameByOrgTypeData.find((org: any) => org.ID === organizationId);
        if (matchedOrganization) {
          setSelectedOrganization([matchedOrganization]);
        }
      }
      setTopListApiParams({
        ...apiTopListParams,
        OrganizationID: organizationId.toString(),
      });
      setApiParams({
        ...apiParams,
        OrganizationID: organizationId.toString(),
        Page: DEFAULT_PAGE_INDEX,
      });
      dispatch(getCompetenciesList(apiParams));
      dispatch(getTopCompetenciesList(apiTopListParams));
    }
  }, [location.state]);

  // Works on initial rendering
  useEffect(() => {
    dispatch(getOrganizationTypeDropDownList({}));
    dispatch(getCompetenciesList(apiParams));
    dispatch(getTopCompetenciesList(apiTopListParams));
  }, [apiParams]);
  // Handle pagination
  useEffect(() => {
    setApiParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
    }));
  }, [currentPage]);
  // Set default organization
  useEffect(() => {
    if (orgnameByOrgTypeData && orgnameByOrgTypeData?.length > 0) {
      setSelectedOrganization([orgnameByOrgTypeData[0]]);
      setTopListApiParams({
        ...apiTopListParams,
        OrganizationID: orgnameByOrgTypeData[0].ID.toString(),
      });
      setApiParams({
        ...apiParams,
        OrganizationID: orgnameByOrgTypeData[0].ID.toString(),
        Page: DEFAULT_PAGE_INDEX,
      });
      dispatch(getCompetenciesList(apiParams));
      dispatch(getTopCompetenciesList(apiTopListParams));
    }
  }, [orgnameByOrgTypeData]);
  // Fetch organizations type details
  useEffect(() => {
    if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
      const orgTypeOptions = organizationTypeDropDownData.map(
        (orgtype: any): ISelectOptionsNumber => ({
          label: orgtype.orgTypeName,
          value: orgtype.orgTypeId,
        }),
      );
      setOrganizationTypeOptions([...orgTypeOptions]);
    }
  }, [organizationTypeDropDownLoading]);
  // Fetch organizations based on org type id
  useEffect(() => {
    if (orgnameByOrgTypeData && orgnameByOrgTypeData?.length > 0) {
      const updatedOrgs = orgnameByOrgTypeData.map((org: any, index: any) => ({
        ...org,
        selected: index === 0,
      }));
      setOrganisations(updatedOrgs);
    }
  }, [orgnameByOrgTypeData]);
  // Reset selected values when the route changes
  useEffect(() => {
    setOrganizationTypeValue(null);
    setSelectedOrganization([]);
    setDateFilter([]);
    setApiParams({
      ...apiParams,
      DateRange: '',
      OrganizationID: '',
      Page: DEFAULT_PAGE_INDEX,
    });
    setTopListApiParams({
      ...apiParams,
      DateRange: '',
      OrganizationID: '',
    });
  }, [location]);
  // Handle delete confirmation
  const ShowDeleteConfirmPopup = (CompetencyID: string, OrganizationID: string, OptionTotCount: number) => {
    if (OptionTotCount > 0) {
      toastObj.toastInfo(DeleteCompetencyError);
    } else {
      setIsShowDeleteConfirmPopup(true);
      setSelectedCompetencyId(CompetencyID);
      setSelectedOrganizationID(OrganizationID);
    }
  };
  // Handle edit confirmation
  const ShowEditConfirmPopup = (CompetencyID: string, OptionTotCount: number) => {
    if (OptionTotCount > 0) {
      toastObj.toastInfo(EditCompetencyError);
    } else {
      navigate('/editcompetency', {
        state: {
          CompetencyID: CompetencyID,
        },
      });
    }
  };
  // Api call to delete competency
  const handleDeleteCompetencyClick = () => {
    if (selectedCompetencyId) {
      const deleteParams: IDeleteCompetencyApiParams = {
        CompetencyID: selectedCompetencyId,
        OrganizationID: selectedOrganizationID,
      };
      dispatch(deleteCompetency(deleteParams));
    }
  };
  // show  delete competency api success message and error message
  useEffect(() => {
    if (deleteCompetencyApiSuccess) {
      setIsShowDeleteConfirmPopup(false);
      toastObj.toastSuccess(deleteCompetencyApiResponseMessage);
      navigate('/competencies');
      dispatch(resetDeleteCompetency());
    } else if (!deleteCompetencyApiSuccess && deleteCompetencyApiResponseMessage.length > 0) {
      toastObj.toastError(deleteCompetencyApiResponseMessage);
      dispatch(resetDeleteCompetency());
    }
  }, [deleteCompetencyApiLoading]);
  // Handle date  filter
  const handleDateFilter = (event: any) => {
    setDateFilter(event);
    setDateRange([null, null]);
    if (event.value === 'Custom_Range') {
      return;
    }
    const updatedApiParams = {
      ...apiParams,
      DateRange: event.value,
      Page: DEFAULT_PAGE_INDEX,
      StartDate: '',
      EndDate: '',
    };
    const updatedTopListApiParams = {
      ...apiTopListParams,
      DateRange: event.value,
      StartDate: '',
      EndDate: '',
    };
    setApiParams(updatedApiParams);
    setTopListApiParams(updatedTopListApiParams);
    dispatch(getCompetenciesList(updatedApiParams));
    dispatch(getTopCompetenciesList(updatedTopListApiParams));
  };
  // Handle organization type change
  const handleOrgTypeChange = async (event: any) => {
    dispatch(resetCompetenciesList());
    dispatch(resetTopCompetenciesList());
    const selectedOrgTypeId = parseInt(event.value);
    setOrganizationTypeValue(event);
    dispatch(
      getOrganizationNameByOrgType({
        OrganizationTypeId: selectedOrgTypeId,
      }),
    );
  };
  // Handle organization selection
  const handleOrganisationSelect = (index: number) => {
    dispatch(resetCompetenciesList());
    dispatch(resetTopCompetenciesList());
    const updatedOrganisations = organisations.map((org: any, i: any) => ({
      ...org,
      selected: i === index,
    }));
    setOrganisations(updatedOrganisations);
    const selectedOrg = organisations[index];
    setSelectedOrganization([selectedOrg]);
    const organizationID = selectedOrg.ID;
    setTopListApiParams({
      ...apiTopListParams,
      OrganizationID: organizationID,
    });
    setApiParams({
      ...apiParams,
      OrganizationID: organizationID,
      Page: DEFAULT_PAGE_INDEX,
    });
    dispatch(getCompetenciesList(apiParams));
    dispatch(getTopCompetenciesList(apiTopListParams));
    setOpen(false);
  };
  // convert tpo date format
  const formatDateToISO = (date: Date) => {
    const timezoneOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    const adjustedDate = new Date(date.getTime() - timezoneOffset);
    return adjustedDate.toISOString().split('T')[0];
  };
  // Handle custom range
  const handleCustomDateRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setDateRange(dates);
    if (start && end) {
      const formattedStartDate = formatDateToISO(start);
      const formattedEndDate = formatDateToISO(end);
      const updatedApiParams = {
        ...apiParams,
        DateRange: 'Custom_Range',
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
        Page: DEFAULT_PAGE_INDEX,
      };
      const updatedTopListApiParams = {
        ...apiTopListParams,
        DateRange: 'Custom_Range',
        StartDate: formattedStartDate,
        EndDate: formattedEndDate,
      };
      setApiParams(updatedApiParams);
      setTopListApiParams(updatedTopListApiParams);
      dispatch(getCompetenciesList(updatedApiParams));
      dispatch(getTopCompetenciesList(updatedTopListApiParams));
    }
  };
    // get the label for tabs
    const getTabLabel = (userLevel: number) => {
      let tabLabel = '';
      if (rolesByOrgTypeData.length > 0) {
        const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
        tabLabel = element.length === 1 ? element[0].Name : '';
      } else {
        tabLabel = '';
      }
      return tabLabel;
    };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.Competencies" />
        </h3>
      </div>
      <Row className="justify-content-between">
        <Col className="d-flex align-items-center content-sub-header">
          {/* <h6 className="m-0 me-3">
            <FormattedMessage id="SubHd.CompetenciesSelected" />
            {getTabLabel(RoleTypeIds.APP_USER)}(s)
          </h6> */}
          <Col xl={4} lg={4} md={4} className="form-sm max-w-140">
            <Select options={selectDays} placeholder={useIntlMessages('PH.Select')} value={dateFilter} onChange={(event: any) => handleDateFilter(event)} />
          </Col>
          <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />} burgerButtonClassName="ms-2">
            <div id="filter-wrapper" className="filter-main">
              <h4 className="mb-5">
                <FormattedMessage id="Hd.Filter" />
              </h4>
              <Col className="form-sm">
                <Select label="Tags" options={tags} placeholder="Select Tags" onChange={(e: any) => e} isMulti />
              </Col>
              <Row className="mt-4">
                <Col>
                  <Button variant="outline-primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                    <FormattedMessage id="Button.ClearFilter" />
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                    <FormattedMessage id="Button.ApplyFilters" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Filter>
        </Col>
        <Col md="auto" className="d-flex align-items-center">
          <Col className="w-180px form-sm me-2">
            <Select
              label={useIntlActionMessages('Label.OrgType')}
              options={organizationTypeOptions}
              value={organizationTypeValue}
              name="OrganizationTypeId"
              placeholder={useIntlMessages('PH.Select')}
              onChange={(e: any) => handleOrgTypeChange(e)}
            />
          </Col>
          {selectedOrganization && selectedOrganization?.length > 0 && selectedOrganization[0] && (
            <Col md="auto" className="d-flex align-items-center">
              <div className="custom-berger-menu me-3">
                <Filter
                  isOpen={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => setOpen(false)}
                  width={450}
                  right
                  pageWrapId={'filter-wrapper'}
                  outerContainerId={'outer-container'}
                  customCrossIcon={<img src={Close} />}
                  customBurgerIcon={
                    <div className="org-selected">
                      <div className="org-img-container">
                        <img
                          src={selectedOrganization && selectedOrganization?.length > 0 && selectedOrganization[0]?.Logo ? BUCKET_URL + selectedOrganization[0]?.Logo : Default}
                          alt="Organization Logo"
                          className="thumbnail-image"
                        />
                      </div>
                      <div className="org-details max-w-140">
                        <h6>{selectedOrganization && selectedOrganization?.length > 0 ? selectedOrganization[0]?.Name : 'No Organization Selected'}</h6>
                        <small>{selectedOrganization && selectedOrganization?.length > 0 ? selectedOrganization[0]?.Address : ''}</small>
                      </div>
                    </div>
                  }
                >
                  <div id="filter-wrapper" className="filter-main">
                    <h4 className="mb-5">
                      <FormattedMessage id="Hd.Organization" />
                    </h4>
                    {organisations.map((org: any, index: number) => (
                      <ListCard
                        key={index}
                        image={org?.Logo ? BUCKET_URL + org.Logo : Default}
                        label={org?.Name}
                        sublabel={org?.Address}
                        selected={org?.selected}
                        handleClick={() => handleOrganisationSelect(index)}
                      />
                    ))}
                  </div>
                </Filter>
              </div>
            </Col>
          )}
          <div className="pt-7">
            <Button variant="primary" size="sm" onClick={() => navigate('/addcompetencies')}>
              <FormattedMessage id="Button.AddCompetency" />
            </Button>
          </div>
        </Col>
      </Row>
      <Col className="mb-4">
        {dateFilter?.value === 'Custom_Range' && (
          <Col xl={3} md={5}>
            <div className="custom-calendar form-sm">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleCustomDateRange}
                isClearable={true}
                className="form-control"
                placeholderText="Select Range"
                onFocus={(e) => e.target.blur()}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
          </Col>
        )}
      </Col>
      <div className="dashboard-tiles-main competencies-tiles-main">
        <Row>
          {competenciesTopListApiSuccess &&
            competenciesTopListData &&
            competenciesTopListData.map((competency: any, index: number) => (
              <Col key={index} xl lg={4} md={4}>
                <div className={tileClasses[index % tileClasses?.length]}>
                  <div className="dash-tile-bg">
                    <h2>{competency?.Count}</h2>
                    <div className="d-flex">
                      <span>{competency?.CompetencyName}</span>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
      <Accordion defaultActiveKey="0">
        {competenciesListApiSuccess && competenciesListData?.Competencies?.length > 0 ? (
          competenciesListData?.Competencies?.map((competency: any, index: any) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Button
                variant="dark"
                size="sm"
                className="icon-btn edit-btn more-dropdown"
                onClick={() => ShowEditConfirmPopup(competency?.CompetencyID, competency?.OptionTotCount)}
              >
                <Edit />
              </Button>
              <Button
                variant="dark"
                size="sm"
                className="icon-btn delete-btn"
                onClick={() => ShowDeleteConfirmPopup(competency?.CompetencyID, competency?.OrganizationId, competency?.OptionTotCount)}
              >
                <Delete />
              </Button>
              <Accordion.Header>
                <h6>{competency?.CompentancyName}</h6>
              </Accordion.Header>
              <Accordion.Body>
                {competency?.Options?.map((option: any, optionIndex: any) => (
                  <CompetenciesCard key={optionIndex} count={option?.competency_option_count.toString()} competencies={option?.competencyoption} />
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))
        ) : (
          <div className="content-sub content-area-padding border-top text-center p-4">
            <h6>{NodataText}</h6>
          </div>
        )}
      </Accordion>
      {competenciesListApiSuccess && competenciesListData?.Competencies?.length > 0 && competenciesListData.TotalCount > PAGE_SIZE && (
        <Pagination
          pageSize={PAGE_SIZE}
          totalitems={competenciesListData?.TotalCount}
          pageLimit={PAGE_SIZE}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          currentPage={currentPage - 1}
          prevPage={-1}
          itemsDisplayed={competenciesListData?.Competencies?.length}
        />
      )}
      <DeleteConfirmPopup
        show={isShowDeleteConfirmPopup}
        handleClose={() => setIsShowDeleteConfirmPopup(false)}
        onCancel={() => setIsShowDeleteConfirmPopup(false)}
        onOkay={() => handleDeleteCompetencyClick()}
        title={useIntlActionMessages('Text.DeleteConfirmation.Competency')}
        content={useIntlActionMessages('Text.DeleteConfirmation.Competency.Desc')}
      />
      {competenciesListApiLoading && <Loader />}
    </>
  );
};

export default CompetenciesList;
