/**
 * @file   src\store\actions\organizationActions.ts
 * @brief  This file is responsible for creating organization based api call.
 * @date   JAN, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { OrganizationApi, CommonApis, GoalApis } from '../../utils/apiUrls';
import {
  IOrganisationForm,
  IListOrganisationForm,
  IDeleteOrganizationApiParams,
  IDeleteCategoryApiParams,
  IViewOrganizationInfoApiParams,
  IUploadFilePathUpdateParams,
  IListGoalTaskParams,
  IuploadFilePathUpdateMultipleParams,
  IDeleteTaskEventParams,
  IListGoalResourceParams,
  IDeleteGoalResourceParams,
  IGetUsersByGoalIdAPIRequest,
  IEventDetailsRequest,
  ITaskDetailsRequest,
  IEditTaskRequest,
} from '../../interfaces/OrganisationInterface';
import { IFinishGoalParams } from '../../interfaces/ResourceInterface';

// Api call to save organization
export const saveOrganization = createAsyncThunk('/saveorganization', async (request: IOrganisationForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${OrganizationApi.SAVE_ORGANIZATION}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to update organization data
export const updateOrganization = createAsyncThunk('/updateorganization', async (request: IOrganisationForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${OrganizationApi.UPDATE_ORGANIZATION}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to get list of organizations
export const getOrganizationList = createAsyncThunk('/organizationlist', async (request: IListOrganisationForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${OrganizationApi.LIST_ORGANIZATIONS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to delete organization
export const deleteOrganization = createAsyncThunk('/deleteorganization', async (request: IDeleteOrganizationApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${OrganizationApi.DELETE_ORGANIZATION}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to delete category in organization
export const deleteCategory = createAsyncThunk('/deleteCategory', async (request: IDeleteCategoryApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${OrganizationApi.DELETE_CATEGORY}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to get organization details
export const getOrganizationInfo = createAsyncThunk('/organizationInfo', async (request: IViewOrganizationInfoApiParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${OrganizationApi.ORGANIZATION_INFO}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get all states
export const getStates = createAsyncThunk('/getStates', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${CommonApis.STATES}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to get all cities in a state
export const getCities = createAsyncThunk('/getCities', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CommonApis.CITIES}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to Upload file to bucket
export const uploadFile = createAsyncThunk('/uploadFile', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CommonApis.FILE_UPLOAD}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to save uploaded file data
export const uploadFilePathUpdate = createAsyncThunk('/uploadFilePath', async (request: IUploadFilePathUpdateParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CommonApis.FILE_UPLOAD_PATH_UPDATE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to save uploaded file data
export const uploadFilePathUpdateMultiple = createAsyncThunk('/uploadFilePathUpdateMultiple', async (request: IuploadFilePathUpdateMultipleParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CommonApis.FILE_UPLOAD_PATH_UPDATE_MULTIPLE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to save uploaded file data
export const subscriptionPlans = createAsyncThunk('/subscriptionPlans', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${OrganizationApi.SUBSCRIPTION_PLANS}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get category, subcategory detail based on organization
export const getCategoryList = createAsyncThunk('/categoryList', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CommonApis.CATEGORY_LIST}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to add goal step 1
export const addGoalStepOne = createAsyncThunk('/addGoalStepOne', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.ADD_GOAL}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// action to call add event in goal API
export const addGoalEvent = createAsyncThunk('/addGoalEventStepThree', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.ADD_GOAL_EVENT}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// action to call add event in goal API
export const addGoalTask = createAsyncThunk('/addGoalTaskStepThree', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.ADD_GOAL_TASK}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get goal list
export const getGoalList = createAsyncThunk('/getGoalList', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.GET_GOAL_LIST}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get tasks and event based on goal id
export const getEventsTasks = createAsyncThunk('/listeventstasks', async (request: IListGoalTaskParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.LIST_EVENTS_TASK}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get  subcategory detail based on organization
export const getSubCategoryList = createAsyncThunk('/subcategoryList', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${CommonApis.SUB_CATEGORY_LIST}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to delete task or event in a goal
export const deleteEventsTasks = createAsyncThunk('/deleteeventstasks', async (request: IDeleteTaskEventParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${GoalApis.DELETE_EVENT_TASK}/${request.id}/${request.Type}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to add kudos and finish goal in step 4
export const addGoalFinish = createAsyncThunk('/addGoalFinish', async (request: IFinishGoalParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.ADD_GOAL_FINISH}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get resource list based on goal id
export const getGoalResource = createAsyncThunk('/listgoalresources', async (request: IListGoalResourceParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.LIST_GOAL_RESOURCE}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to delete resource in a goal
export const deleteGoalResource = createAsyncThunk('/deleteresource', async (request: IDeleteGoalResourceParams, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${GoalApis.DELETE_GOAL_RESOURCE}`, { data: request });
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get goal detail
export const getGoalDetail = createAsyncThunk('/getGoalDetail', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${GoalApis.GET_GOAL_DETAIL}/${request.goalId}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to update goal
export const updateGoal = createAsyncThunk('/updateGoal', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.UPDATE_GOAL}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get assigned users to goal
export const getUsersByGoalId = createAsyncThunk('/getUsersByGoalId', async (request: IGetUsersByGoalIdAPIRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.GET_USERS_BY_GOAL_ID}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// API call to get event details
export const getEventDetails = createAsyncThunk('/getEventDetails', async (request: IEventDetailsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.GET_EVENT_DETAILS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// API call to get task details
export const getTaskDetails = createAsyncThunk('/getTaskDetails', async (request: ITaskDetailsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.GET_TASK_DETAILS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to edit event details
export const editEventDetails = createAsyncThunk('/editEventDetails', async (request: IEventDetailsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.EDIT_EVENT}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to edit task details
export const editTaskDetails = createAsyncThunk('/editTaskDetails', async (request: IEditTaskRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${GoalApis.EDIT_TASK}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to delete goal
export const deleteGoal = createAsyncThunk('/deleteGoal', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.delete(`${GoalApis.DELETE_GOAL}/${request.goalId}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
