/**
 * @file   src\validations\userSchema.ts
 * @brief  This file is responsible for defining User validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';
import { EMAIL_REGEX, PASSWORD_REGEX, PHONE_NUMBER_REGEX } from '../utils/constants';

// User Form Validation Schema
export const USER_SCHEMA = yup.object({
  EmailId: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('Form.UserEmail.Required')),
  OrganizationTypeId: yup.number().notOneOf([0], useIntlActionMessages('Form.OrgType.Required')).required(useIntlActionMessages('Form.OrgType.Required')),
  RoleId: yup.number().notOneOf([0], useIntlActionMessages('Form.UserRole.Required')).required(useIntlActionMessages('Form.UserRole.Required')),
  OrganizationID: yup.number().notOneOf([0], useIntlActionMessages('Form.SelectOrganizationName.Required')).required(useIntlActionMessages('Form.SelectOrganizationName.Required')),
});
// System admin form validation schema.
export const CREATE_SYSTEM_ADMIN_SCHEMA = yup.object({
  AdminPassword: yup.string().trim().required(useIntlActionMessages('Form.Password.Required')).matches(PASSWORD_REGEX, useIntlActionMessages('Form.Password.Criteria')),
  AdminEmailId: yup
    .string()
    .trim()
    .matches(EMAIL_REGEX, useIntlActionMessages('Form.Email.Valid'))
    .max(200, useIntlActionMessages('Form.Email.MaxLength'))
    .required(useIntlActionMessages('LoginForm.Email.Required')),
  phoneNo: yup.string().required(useIntlActionMessages('Form.Phoneno.Required')).matches(PHONE_NUMBER_REGEX, useIntlActionMessages('Form.Phoneno.Required')),
});
// User bulk upload Validation Schema
export const USER_UPLOAD_SCHEMA = yup.object({
  OrganizationTypeID: yup.number().notOneOf([0], useIntlActionMessages('Form.OrgType.Required')).required(useIntlActionMessages('Form.OrgType.Required')),
  UserRoleID: yup.number().notOneOf([0], useIntlActionMessages('Form.UserRole.Required')).required(useIntlActionMessages('Form.UserRole.Required')),
  OrganizationID: yup.string().required(useIntlActionMessages('Form.SelectOrganizationName.Required')),
});