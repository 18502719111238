/**
 * @file   src\store\slices\profileSlice.ts
 * @brief  This file is responsible for creating teacher profile based slices to call actions and state management.
 * @date   MAY, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { createSlice } from '@reduxjs/toolkit';
import { ITeacherProfile } from '../../interfaces/ProfileInterface';
import {
  createTeacherProfile,
  createUserAccount,
  verifyOtpCode,
  validateToken,
  getAvatarsList,
  sendOtpToPhone,
  listSubscriptionPlans,
  addSubScriptionPayment,
  getBillingHistory,
  getCardsList,
  cancelSubscriptionPlan,
  addPaymentMethod,
} from '../actions/profileActions';
// Profile slice default state
const profileDefaultState: ITeacherProfile = {
  createProfileApiData: {},
  createProfileApiResponseCode: 0,
  createProfileApiResponseMessage: '',
  createProfileApiLoading: false,
  createProfileApiSuccess: false,
  createAccountApiData: {},
  createAccountApiResponseCode: 0,
  createAccountApiResponseMessage: '',
  createAccountApiLoading: false,
  createAccountApiSuccess: false,
  verifyOtpApiData: {},
  verifyOtpApiResponseCode: 0,
  verifyOtpApiResponseMessage: '',
  verifyOtpApiLoading: false,
  verifyOtpApiSuccess: false,
  validateTokenApiData: {},
  validateTokenApiResponseCode: 0,
  validateTokenApiResponseMessage: '',
  validateTokenApiLoading: false,
  validateTokenApiSuccess: false,
  getAvatarsApiData: {},
  getAvatarsApiLoading: false,
  getAvatarsApiResponseCode: 0,
  getAvatarsApiResponseMessage: '',
  getAvatarsApiSuccess: false,
  phoneOtpApiLoading: false,
  phoneOtpApiSuccess: false,
  phoneOtpApiData: {},
  phoneOtpApiResponseMessage: '',
  phoneOtpApiResponseCode: 0,
  getSubscriptionPlansLoading: false,
  getSubscriptionPlansSuccess: false,
  getSubscriptionPlansData: {},
  getSubscriptionPlansResponseCode: 0,
  getSubscriptionPlansResponseMessage: '',
  addSubscriptionPaymentApiLoading: false,
  addSubscriptionPaymentApiData: {},
  addSubscriptionPaymentApiSuccess: false,
  addSubscriptionPaymentApiResponseCode: 0,
  addSubscriptionPaymentApiResponseMessage: '',
  getCardsListApiLoading: false,
  getCardsListApiSuccess: false,
  getCardsListApiData: {},
  getCardsListApiResponseCode: 0,
  getCardsListApiResponseMessage: '',
  getBillingHistoryApiLoading: false,
  getBillingHistoryApiSuccess: false,
  getBillingHistoryApiData: {},
  getBillingHistoryApiResponseCode: 0,
  getBillingHistoryApiResponseMessage: '',
  cancelSubscriptionApiLoading: false,
  cancelSubscriptionApiData: {},
  cancelSubscriptionApiSuccess: false,
  cancelSubscriptionApiResponseCode: 0,
  cancelSubscriptionApiResponseMessage: '',
  profileForm: {},
  profileImgData: {},
  currentPlan: '',
  addPaymentMethodApiLoading: false,
  addPaymentMethodApiSuccess: false,
  addPaymentMethodApiData: {},
  addPaymentMethodApiResponseCode: 0,
  addPaymentMethodApiResponseMessage: '',
};
// teacher profile creation slice
const profileSlice = createSlice({
  name: 'profile',
  initialState: profileDefaultState,
  reducers: {
    resetProfileCreation: (state) => {
      state.createProfileApiData = {};
      state.createProfileApiResponseCode = 0;
      state.createProfileApiResponseMessage = '';
      state.createProfileApiLoading = false;
      state.createProfileApiSuccess = false;
      state.getAvatarsApiData = {};
      state.getAvatarsApiLoading = false;
      state.getAvatarsApiResponseCode = 0;
      state.getAvatarsApiResponseMessage = '';
      state.getAvatarsApiSuccess = false;
      state.phoneOtpApiData = {};
      state.phoneOtpApiLoading = false;
      state.phoneOtpApiResponseCode = 0;
      state.phoneOtpApiResponseMessage = '';
      state.phoneOtpApiSuccess = false;
    },
    resetCreateAccount: (state) => {
      state.createAccountApiData = {};
      state.createAccountApiLoading = false;
      state.createAccountApiResponseCode = 0;
      state.createAccountApiResponseMessage = '';
      state.createAccountApiSuccess = false;
    },
    resetOptVerify: (state) => {
      state.verifyOtpApiLoading = false;
      state.verifyOtpApiData = {};
      state.verifyOtpApiResponseCode = 0;
      state.verifyOtpApiResponseMessage = '';
      state.verifyOtpApiSuccess = false;
      state.phoneOtpApiData = {};
      state.phoneOtpApiLoading = false;
      state.phoneOtpApiResponseCode = 0;
      state.phoneOtpApiResponseMessage = '';
      state.phoneOtpApiSuccess = false;
    },
    addProfileForm: (state, action) => {
      state.profileForm = action.payload;
    },
    addProfileImgDatas: (state, action) => {
      state.profileImgData = action.payload;
    },
    resetSubscriptionPayment: (state) => {
      state.addSubscriptionPaymentApiLoading = false;
      state.addSubscriptionPaymentApiData = {};
      state.addSubscriptionPaymentApiSuccess = false;
      state.addSubscriptionPaymentApiResponseCode = 0;
      state.addSubscriptionPaymentApiResponseMessage = '';
    },
    resetCardsBillingHistory: (state) => {
      state.getCardsListApiLoading = false;
      state.getCardsListApiSuccess = false;
      state.getCardsListApiData = {};
      state.getCardsListApiResponseCode = 0;
      state.getCardsListApiResponseMessage = '';
      state.getBillingHistoryApiLoading = false;
      state.getBillingHistoryApiSuccess = false;
      state.getBillingHistoryApiData = {};
      state.getBillingHistoryApiResponseCode = 0;
      state.getBillingHistoryApiResponseMessage = '';
    },
    saveCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    resetSubscriptionList: (state) => {
      state.getSubscriptionPlansLoading = false;
      state.getSubscriptionPlansSuccess = false;
      state.getSubscriptionPlansData = {};
      state.getSubscriptionPlansResponseCode = 0;
      state.getSubscriptionPlansResponseMessage = '';
    },
    resetAddPaymentMethod: (state) => {
      state.addPaymentMethodApiLoading = false;
      state.addPaymentMethodApiSuccess = false;
      state.addPaymentMethodApiData = {};
      state.addPaymentMethodApiResponseCode = 0;
      state.addPaymentMethodApiResponseMessage = '';
    },
  },
  extraReducers(builder) {
    // create profile api starts
    builder.addCase(createTeacherProfile.pending, (state) => {
      state.createProfileApiLoading = true;
      state.createProfileApiSuccess = false;
      state.createProfileApiResponseCode = 0;
    });
    // create profile api success
    builder.addCase(createTeacherProfile.fulfilled, (state, action: any) => {
      state.createProfileApiLoading = false;
      state.createProfileApiSuccess = true;
      state.createProfileApiResponseCode = action.payload.ResponseCode;
      state.createProfileApiResponseMessage = action.payload.ResponseMessage;
      state.createProfileApiData = action.payload.ResponseData;
    });
    // create profile api failure
    builder.addCase(createTeacherProfile.rejected, (state, action: any) => {
      state.createProfileApiLoading = false;
      state.createProfileApiSuccess = false;
      state.createProfileApiResponseCode = action.payload.ResponseCode;
      state.createProfileApiResponseMessage = action.payload.ResponseMessage;
    });
    // create account api starts
    builder.addCase(createUserAccount.pending, (state) => {
      state.createAccountApiLoading = true;
      state.createAccountApiSuccess = false;
      state.createAccountApiResponseCode = 0;
    });
    // create account api success
    builder.addCase(createUserAccount.fulfilled, (state, action: any) => {
      state.createAccountApiLoading = false;
      state.createAccountApiSuccess = true;
      state.createAccountApiResponseCode = action.payload.ResponseCode;
      state.createAccountApiResponseMessage = action.payload.ResponseMessage;
      state.createAccountApiData = action.payload.ResponseData;
    });
    // create account api failure
    builder.addCase(createUserAccount.rejected, (state, action: any) => {
      state.createAccountApiLoading = false;
      state.createAccountApiSuccess = false;
      state.createAccountApiResponseCode = action.payload.ResponseCode;
      state.createAccountApiResponseMessage = action.payload.ResponseMessage;
    });
    // verify otp api starts
    builder.addCase(verifyOtpCode.pending, (state) => {
      state.verifyOtpApiLoading = true;
      state.verifyOtpApiSuccess = false;
      state.verifyOtpApiResponseCode = 0;
    });
    // verify otp api success
    builder.addCase(verifyOtpCode.fulfilled, (state, action: any) => {
      state.verifyOtpApiLoading = false;
      state.verifyOtpApiSuccess = true;
      state.verifyOtpApiResponseCode = action.payload.ResponseCode;
      state.verifyOtpApiResponseMessage = action.payload.ResponseMessage;
      state.verifyOtpApiData = action.payload.ResponseData;
    });
    // verify otp api failure
    builder.addCase(verifyOtpCode.rejected, (state, action: any) => {
      state.verifyOtpApiLoading = false;
      state.verifyOtpApiSuccess = false;
      state.verifyOtpApiResponseCode = action.payload.ResponseCode;
      state.verifyOtpApiResponseMessage = action.payload.ResponseMessage;
    });
    builder.addCase(validateToken.pending, (state) => {
      state.validateTokenApiLoading = true;
      state.validateTokenApiSuccess = false;
      state.validateTokenApiResponseCode = 0;
    });
    // verify otp api success
    builder.addCase(validateToken.fulfilled, (state, action: any) => {
      state.validateTokenApiLoading = false;
      state.validateTokenApiSuccess = true;
      state.validateTokenApiResponseCode = action.payload.ResponseCode;
      state.validateTokenApiResponseMessage = action.payload.ResponseMessage;
      state.validateTokenApiData = action.payload.ResponseData;
    });
    // verify otp api failure
    builder.addCase(validateToken.rejected, (state, action: any) => {
      state.validateTokenApiLoading = false;
      state.validateTokenApiSuccess = false;
      state.validateTokenApiResponseCode = action.payload !== undefined ? action.payload.ResponseCode : 3013;
      state.validateTokenApiResponseMessage = action.payload !== undefined ? action.payload.ResponseMessage : '';
    });
    // get avatars list api starts
    builder.addCase(getAvatarsList.pending, (state) => {
      state.getAvatarsApiLoading = true;
      state.getAvatarsApiSuccess = false;
      state.getAvatarsApiResponseCode = 0;
    });
    // get avatars list api success
    builder.addCase(getAvatarsList.fulfilled, (state, action: any) => {
      state.getAvatarsApiLoading = false;
      state.getAvatarsApiSuccess = true;
      state.getAvatarsApiResponseCode = action.payload.ResponseCode;
      state.getAvatarsApiResponseMessage = action.payload.ResponseMessage;
      state.getAvatarsApiData = action.payload.ResponseData;
    });
    // get avatars list api failure
    builder.addCase(getAvatarsList.rejected, (state, action: any) => {
      state.getAvatarsApiLoading = false;
      state.getAvatarsApiSuccess = false;
      state.getAvatarsApiResponseCode = action.payload.ResponseCode;
      state.getAvatarsApiResponseMessage = action.payload.ResponseMessage;
    });
    // send phone otp  api starts
    builder.addCase(sendOtpToPhone.pending, (state) => {
      state.phoneOtpApiLoading = true;
      state.phoneOtpApiSuccess = false;
      state.phoneOtpApiResponseCode = 0;
    });
    // send phone otp  api success
    builder.addCase(sendOtpToPhone.fulfilled, (state, action: any) => {
      state.phoneOtpApiLoading = false;
      state.phoneOtpApiSuccess = true;
      state.phoneOtpApiResponseCode = action.payload.ResponseCode;
      state.phoneOtpApiResponseMessage = action.payload.ResponseMessage;
      state.phoneOtpApiData = action.payload.ResponseData;
    });
    // send phone otp api failure
    builder.addCase(sendOtpToPhone.rejected, (state, action: any) => {
      state.phoneOtpApiLoading = false;
      state.phoneOtpApiSuccess = false;
      state.phoneOtpApiResponseCode = action.payload.ResponseCode;
      state.phoneOtpApiResponseMessage = action.payload.ResponseMessage;
    });
    // send phone otp  api starts
    builder.addCase(listSubscriptionPlans.pending, (state) => {
      state.getSubscriptionPlansLoading = true;
      state.getSubscriptionPlansSuccess = false;
      state.getSubscriptionPlansResponseCode = 0;
    });
    // send phone otp  api success
    builder.addCase(listSubscriptionPlans.fulfilled, (state, action: any) => {
      state.getSubscriptionPlansLoading = false;
      state.getSubscriptionPlansSuccess = true;
      state.getSubscriptionPlansResponseCode = action.payload.ResponseCode;
      state.getSubscriptionPlansResponseMessage = action.payload.ResponseMessage;
      state.getSubscriptionPlansData = action.payload.ResponseData;
    });
    // send phone otp api failure
    builder.addCase(listSubscriptionPlans.rejected, (state, action: any) => {
      state.getSubscriptionPlansLoading = false;
      state.getSubscriptionPlansSuccess = false;
      state.getSubscriptionPlansResponseCode = action.payload.ResponseCode;
      state.getSubscriptionPlansResponseMessage = action.payload.ResponseMessage;
    });
    // send phone otp  api starts
    builder.addCase(addSubScriptionPayment.pending, (state) => {
      state.addSubscriptionPaymentApiLoading = true;
      state.addSubscriptionPaymentApiSuccess = false;
      state.addSubscriptionPaymentApiResponseCode = 0;
    });
    // send phone otp  api success
    builder.addCase(addSubScriptionPayment.fulfilled, (state, action: any) => {
      state.addSubscriptionPaymentApiLoading = false;
      state.addSubscriptionPaymentApiSuccess = true;
      state.addSubscriptionPaymentApiResponseCode = action.payload.ResponseCode;
      state.addSubscriptionPaymentApiResponseMessage = action.payload.ResponseMessage;
      state.addSubscriptionPaymentApiData = action.payload.ResponseData;
    });
    // send phone otp api failure
    builder.addCase(addSubScriptionPayment.rejected, (state, action: any) => {
      state.addSubscriptionPaymentApiLoading = false;
      state.addSubscriptionPaymentApiSuccess = false;
      state.addSubscriptionPaymentApiResponseCode = action.payload.ResponseCode;
      state.addSubscriptionPaymentApiResponseMessage = action.payload.ResponseMessage;
    });
    // get cards list  api starts
    builder.addCase(getCardsList.pending, (state) => {
      state.getCardsListApiLoading = true;
      state.getCardsListApiSuccess = false;
      state.getCardsListApiResponseCode = 0;
    });
    //  get cards list  api success
    builder.addCase(getCardsList.fulfilled, (state, action: any) => {
      state.getCardsListApiLoading = false;
      state.getCardsListApiSuccess = true;
      state.getCardsListApiResponseCode = action.payload.ResponseCode;
      state.getCardsListApiResponseMessage = action.payload.ResponseMessage;
      state.getCardsListApiData = action.payload.ResponseData;
    });
    //  get cards list api failure
    builder.addCase(getCardsList.rejected, (state, action: any) => {
      state.getCardsListApiLoading = false;
      state.getCardsListApiSuccess = false;
      state.getCardsListApiResponseCode = action.payload.ResponseCode;
      state.getCardsListApiResponseMessage = action.payload.ResponseMessage;
    });
    // get billing history   api starts
    builder.addCase(getBillingHistory.pending, (state) => {
      state.getBillingHistoryApiLoading = true;
      state.getBillingHistoryApiSuccess = false;
      state.getBillingHistoryApiResponseCode = 0;
    });
    //  get billing history  api success
    builder.addCase(getBillingHistory.fulfilled, (state, action: any) => {
      state.getBillingHistoryApiLoading = false;
      state.getBillingHistoryApiSuccess = true;
      state.getBillingHistoryApiResponseCode = action.payload.ResponseCode;
      state.getBillingHistoryApiResponseMessage = action.payload.ResponseMessage;
      state.getBillingHistoryApiData = action.payload.ResponseData;
    });
    //  get billing history api failure
    builder.addCase(getBillingHistory.rejected, (state, action: any) => {
      state.getBillingHistoryApiLoading = false;
      state.getBillingHistoryApiSuccess = false;
      state.getBillingHistoryApiResponseCode = action.payload.ResponseCode;
      state.getBillingHistoryApiResponseMessage = action.payload.ResponseMessage;
    });
    // cancel subscription api starts
    builder.addCase(cancelSubscriptionPlan.pending, (state) => {
      state.cancelSubscriptionApiLoading = true;
      state.cancelSubscriptionApiSuccess = false;
      state.cancelSubscriptionApiResponseCode = 0;
    });
    //  cancel subscription api success
    builder.addCase(cancelSubscriptionPlan.fulfilled, (state, action: any) => {
      state.cancelSubscriptionApiLoading = false;
      state.cancelSubscriptionApiSuccess = true;
      state.cancelSubscriptionApiResponseCode = action.payload.ResponseCode;
      state.cancelSubscriptionApiResponseMessage = action.payload.ResponseMessage;
      state.cancelSubscriptionApiData = action.payload.ResponseData;
    });
    //  cancel subscription api failure
    builder.addCase(cancelSubscriptionPlan.rejected, (state, action: any) => {
      state.cancelSubscriptionApiLoading = false;
      state.cancelSubscriptionApiSuccess = false;
      state.cancelSubscriptionApiResponseCode = action.payload.ResponseCode;
      state.cancelSubscriptionApiResponseMessage = action.payload.ResponseMessage;
    });
    // add payment method api starts
    builder.addCase(addPaymentMethod.pending, (state) => {
      state.addPaymentMethodApiLoading = true;
      state.addPaymentMethodApiSuccess = false;
      state.addPaymentMethodApiResponseCode = 0;
    });
    //  add payment method api success
    builder.addCase(addPaymentMethod.fulfilled, (state, action: any) => {
      state.addPaymentMethodApiLoading = false;
      state.addPaymentMethodApiSuccess = true;
      state.addPaymentMethodApiResponseCode = action.payload.ResponseCode;
      state.addPaymentMethodApiResponseMessage = action.payload.ResponseMessage;
      state.addPaymentMethodApiData = action.payload.ResponseData;
    });
    //  add payment method api failure
    builder.addCase(addPaymentMethod.rejected, (state, action: any) => {
      state.addPaymentMethodApiLoading = false;
      state.addPaymentMethodApiSuccess = false;
      state.addPaymentMethodApiResponseCode = action.payload.ResponseCode;
      state.addPaymentMethodApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});
// Export actions
export const {
  resetProfileCreation,
  resetCreateAccount,
  resetOptVerify,
  addProfileForm,
  addProfileImgDatas,
  resetCardsBillingHistory,
  resetSubscriptionPayment,
  saveCurrentPlan,
  resetSubscriptionList,
  resetAddPaymentMethod,
} = profileSlice.actions;
// Export reducer.
export default profileSlice.reducer;
