/**
 * @file   src\containers\Login.tsx
 * @brief  Landing Home page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../assets/css/TeachHomeLand.scss';
import BgTeacherHome from '../assets/img/graphics-bg-teacger.png';
import Logo from '../assets/img/Logo.png';
import Linesimg from '../assets/img/lines-img.png';
import { Link, useNavigate } from 'react-router-dom';

const TeacherLandHome = () => {
    const navigate = useNavigate();
    const date = new Date();

    return (
        <>
            <Container fluid className='HomeMainLanding'>
                <Row >
                    <Col className='sec-left d-flex align-items-center justify-content-center'>
                        <img src={BgTeacherHome} alt="" />
                    </Col>
                    <Col>
                        <div className='p-5 d-flex justify-content-end align-items-center'>
                            <Link to="#" className="text-center text-white p-3" onClick={()=>window.alert('Not Implemented')}>How it works</Link>
                            <Link to="#" className="text-center text-white  p-3" onClick={()=>window.alert('Not Implemented')}>Contact Admin</Link>
                            <Button variant="primary" type="submit" className='px-3' onClick={()=>navigate('/signin')}>Sign In</Button>
                        </div>
                        <div className='mt-5 sce-rgt-2'>
                            <img src={Logo} alt="" />
                            <div className='d-flex align-items-center mt-5'>
                                <div className='p-3'><img src={Linesimg} alt="" /></div>
                                <div><p className='welcome'>Welcome to</p>
                                    <h1>MirrorIM</h1>
                                    <p>The complete solution for your organization!</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <p className='t-copyright d-flex align-items-center'>Copyright © {date.getFullYear()} MirrorIM</p>
            </Container>
        </>
    )
}

export default TeacherLandHome;