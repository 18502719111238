/**
 * @file   src\containers\organizations\SurveyStep2.tsx
 * @brief  Survey add page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect, useState } from 'react';
import '../../assets/css/Survey.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, checkValidFile, getFileTypeByExtension, useIntlActionMessages, bytesToSize, getFileTypeNumber } from '../../utils/helper';
import { Button, Col } from 'react-bootstrap';
import Input from '../../components/MAInput';
import AddIcon from '../../assets/img/icon/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { ISection } from '../../interfaces/SurveyInterface';
import SurveySections from '../../components/SurveySections';
import { getAnswerTypeList, addSurveyForm, getSurveyFormDetails, updateSurveyForm, deleteSurveyFormAttachment } from '../../store/actions/surveyAction';
import Loader from '../../components/Loader';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import { MessageToaster } from '../../utils/ToastUtil';
import { uploadFile } from '../../store/actions/organizationActions';
import { resetAddSurveyForm, resetSurveyFormDetail, resetDeleteFormAttachment, resetUpdateSurveyForm } from '../../store/slices/surveySlice';
import { NAME_REGEX } from '../../utils/constants';
import { NumberValues, RoleTypeIds } from '../../utils/enums';
import { resetUploadFile } from '../../store/slices/organizationSlice';

const defaultSection: { FormSections: ISection[] } = {
  FormSections: [
    {
      FormSectionId: 0,
      FormAnswerTypeId: 0,
      SelectedOption: {
        label: '',
        value: 0,
      },
      FormAnswerType: '',
      FormAttachment: '',
      FormAttachmentType: '',
      FormAttachmentTypeNumber: 0,
      FormAttachmentDisplayName: '',
      FormAttachmentSize: '',
      FormQuestion: '',
      Options: [
        {
          optionId: '',
          optionValue: '',
        },
      ],
      isRequired: 0,
      errors: [
        {
          key: '',
          errorMessage: '',
        },
      ],
    },
  ],
};

const initialOption = {
  optionId: '',
  optionValue: '',
};
const initialSection = {
  FormSectionId: 0,
  FormAnswerTypeId: 0,
  SelectedOption: {
    label: '',
    value: 0,
  },
  FormAnswerType: '',
  FormAttachment: '',
  FormAttachmentType: '',
  FormAttachmentDisplayName: '',
  FormAttachmentSize: '',
  FormQuestion: '',
  Options: [
    {
      optionId: '',
      optionValue: '',
    },
  ],
  isRequired: 0,
  errors: [
    {
      key: '',
      errorMessage: '',
    },
  ],
};

const SurveyAddForm = (props: any) => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  // Create route param object to access the route parameters.
  const params = useParams();

  // Initialize language variables.
  const ImageUploadFileError = useIntlActionMessages('Image.Upload.FileError');
  const ImageUploadSuccess = useIntlActionMessages('Image.Upload.success');

  // Access redux state variables.
  const {
    answerTypeApiData,
    answerTypeApiLoading,
    answerTypeApiSuccess,
    createSurveyApiData,
    addSurveyFormApiLoading,
    addSurveyFormApiResponseMessage,
    addSurveyFormApiSuccess,
    surveyFormDetailApiData,
    surveyFormDetailApiLoading,
    surveyFormDetailApiResponseCode,
    surveyFormDetailApiResponseMessage,
    surveyFormDetailApiSuccess,
    deleteSurveyFormAttachmentApiLoading,
    updateSurveyFormApiLoading,
    updateSurveyFormApiData,
    updateSurveyFormApiResponseCode,
    updateSurveyFormApiResponseMessage,
    updateSurveyFormApiSuccess,
  } = useAppSelector((state: RootState) => state.survey);

  const {
    uploadFileApiData,
    uploadFileApiLoading,
    uploadFileApiSuccess,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseCode,
    uploadFilePathUpdateResponseMessage,
    uploadFilePathUpdateMultipleApiLoading,
    uploadFilePathUpdateMultipleApiSuccess,
    uploadFilePathUpdateMultipleResponseCode,
    uploadFilePathUpdateMultipleResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);
  const {
    rolesByOrgTypeData,
  } = useAppSelector((state: RootState) => state.organizationType);

  // Initialize component state variables.
  const [sectionList, setSectionList] = useState<{ FormSections: ISection[] }>(defaultSection);
  const [formName, setFormName] = useState<string>('');
  const [answerTypeOptions, setAnswerTypeOptions] = useState<Array<any>>([]);
  const [fileUploadBegin, setFileUploadBegin] = useState<boolean>(false);
  const [formNameError, setFormNameError] = useState<string>('');
  const [formSubmit, setFormSubmit] = useState<boolean>(false);
  const [upImageAttachment, setUpImageAttachment] = useState<string | null>(null);
  const [upImageAttachmentDetails, setUpImageAttachmentDetails] = useState<any>({});

  useEffect(() => {
    dispatch(getAnswerTypeList({}));
    if (params?.formId) {
      dispatch(getSurveyFormDetails({ SurveyFormId: parseInt(params?.formId) }));
    }
    window.scrollTo(0, 0);
    const listener = (event: any) => {
      if (event.keyCode === 13 && event.target.nodeName != 'TEXTAREA') {
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      dispatch(resetAddSurveyForm());
      dispatch(resetSurveyFormDetail());
      dispatch(resetDeleteFormAttachment());
      dispatch(resetUpdateSurveyForm());
      document.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    if (answerTypeApiSuccess && answerTypeApiData?.length > 0) {
      // set data to org type dropdown
      const answerTypeOptions = answerTypeApiData.map(
        (type: any): ISelectOptionsNumber => ({
          label: type.TypeTitle,
          value: type.Id,
        }),
      );
      setAnswerTypeOptions(answerTypeOptions);
    }
  }, [answerTypeApiLoading]);
  useEffect(() => {
    if (addSurveyFormApiSuccess) {
      toast.toastSuccess(addSurveyFormApiResponseMessage);
      // props.onApiResponse(1);
      navigate('/assignsurvey', { state: { surveyId: params?.surveyId, step: 1 } });
    } else if (!addSurveyFormApiSuccess && addSurveyFormApiResponseMessage) {
      toast.toastError(addSurveyFormApiResponseMessage);
    }
  }, [addSurveyFormApiLoading]);
  useEffect(() => {
    if (updateSurveyFormApiSuccess) {
      toast.toastSuccess(updateSurveyFormApiResponseMessage);
      // props.onApiResponse(1);
      navigate('/assignsurvey', { state: { surveyId: params?.surveyId, step: 1 } });
    } else if (!updateSurveyFormApiSuccess && updateSurveyFormApiResponseMessage) {
      toast.toastError(updateSurveyFormApiResponseMessage);
    }
  }, [updateSurveyFormApiLoading]);

  useEffect(() => {
    if (surveyFormDetailApiSuccess && surveyFormDetailApiData !== null) {
      const { Sections } = surveyFormDetailApiData;
      setFormName(surveyFormDetailApiData.FormTitle);
      const mappedSections: ISection[] = Sections.map((section: any) => ({
        FormSectionId: section.FormQuestionId, // Assuming FormQuestionId can be used as FormSectionId
        FormAnswerTypeId: section.FormAnswerTypeId,
        SelectedOption: answerTypeOptions.find((obj) => obj.value == section.FormAnswerTypeId),
        // {
        //   label: answerTypeOptions.find((obj) => obj.value == section.FormAnswerTypeId),
        //   value: section.FormAnswerTypeId,
        // },

        FormAnswerType: section.FormAnswerType,
        FormAttachment: section.FormAttachment,
        FormAttachmentType: '', // This value is not provided in the API response
        FormAttachmentTypeNumber: 0, // This value is not provided in the API response
        FormAttachmentDisplayName: section.FormAttachmentDisplayName,
        FormAttachmentSize: '', // This value is not provided in the API response
        FormQuestion: section.FormQuestion,
        Options: section.Options.map((option: any) => ({
          optionId: option.optionId,
          optionValue: option.optionValue,
        })),
        isRequired: section.IsRequired ? 1 : 0, // Convert boolean to number
        errors: [], // No errors provided in the API response
      }));

      setSectionList({ FormSections: mappedSections });
    }
  }, [surveyFormDetailApiLoading]);

  // useEffect(() => {
  //   if (surveyFormDetailApiSuccess && surveyFormDetailApiData !== null) {
  //     const FormSections = surveyFormDetailApiData.sections.map((section: any, index: number) => ({
  //       FormSectionId: index, // Assuming the ID is based on the index for simplicity
  //       FormAnswerTypeId: section.FormAnswerTypeId,
  //       FormAnswerType: section.FormAnswerType,
  //       FormAttachment: section.FormAttachment,
  //       FormAttachmentType: '', // You need to determine the attachment type
  //       FormAttachmentTypeNumber: 0, // You need to determine the attachment type number
  //       FormAttachmentDisplayName: section.FormAttachmentDisplayName,
  //       FormAttachmentSize: '', // You need to determine the attachment size
  //       FormQuestion: section.FormQuestion,
  //       Options: section.Options.map((option: any) => ({
  //         optionId: option.OptionId,
  //         optionValue: option.OptionValue,
  //       })),
  //       isRequired: section.IsRequired ? 1 : 0, // Convert boolean to 1 or 0
  //       errors: [
  //         {
  //           key: '',
  //           errorMessage: '',
  //         },
  //       ],
  //     }));
  //     setSectionList(FormSections);
  //   }
  // }, [surveyFormDetailApiLoading]);

  // Handle the upload path api response
  useEffect(() => {
    const submitUploadsAndSubsequentActions = async () => {
      if (uploadFileResponseCode > 0 && uploadFileApiSuccess) {
        uploadAttachmentFileToBucket();
        dispatch(resetUploadFile());
      }
    };
    submitUploadsAndSubsequentActions();
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  // Put file to bucket after getting the path
  const uploadAttachmentFileToBucket = async () => {
    const uploadURL = uploadFileApiData;
    if (uploadURL) {
      // PUT request: upload file to S3
      const result = await fetch(uploadURL, {
        method: 'PUT',
        body: upImageAttachment,
      });
      if (result.status == 200) {
        setFileUploadBegin(false);
        toast.toastSuccess(ImageUploadSuccess);
      } else {
        setFileUploadBegin(false);
        toast.toastError('Failed to Upload.');
      }
    }
  };

  // Add section
  const handleAddSection = () => {
    setSectionList((prevState: any) => ({
      ...prevState,
      FormSections: [...prevState.FormSections, initialSection],
    }));
  };

  // Add form
  const handleAddForm = () => {
    // navigate('/assignsurvey')
    setFormSubmit(true);
    if (validateSections()) {
      const apiData = {
        FormTitle: formName,
        SurveyId: params?.surveyId ? parseInt(params?.surveyId) : 0,
        FormSections: sectionList.FormSections,
        SurveyFormId: params?.formId ? parseInt(params?.formId) : 0,
      };
      if (params?.formId) {
        dispatch(updateSurveyForm(apiData));
      } else {
        dispatch(addSurveyForm(apiData));
      }
      setFormSubmit(false);
    }
  };

  // Add section
  const handleRemoveSection = (sectionIndex: number) => {
    let newSections = sectionList.FormSections;
    newSections = newSections?.filter((item: any, index: number) => index != sectionIndex);
    setSectionList(() => ({
      FormSections: newSections,
    }));
  };
  // Add section
  const handleFormnameChange = (formName: string) => {
    if (formName) {
      setFormNameError('');
    }
    setFormName(formName);
  };
  // Add question, answer type change
  const handleInputChange = (event: any, sectionIndex: number, type: string) => {
    if (type == 'Question') {
      setSectionList((prevSectionList) => ({
        ...prevSectionList,
        FormSections: prevSectionList.FormSections.map((section, index) =>
          index === sectionIndex // Update the object at the specified index
            ? {
                ...section,
                FormQuestion: event.target.value,
              }
            : section,
        ),
      }));
    } else if (type == 'AnswerType') {
      setSectionList((prevSectionList) => ({
        ...prevSectionList,
        FormSections: prevSectionList.FormSections.map((section, index) =>
          index === sectionIndex // Update the object at the specified index
            ? {
                ...section,
                FormAnswerTypeId: parseInt(event.value),
                SelectedOption: {
                  label: event.label,
                  value: event.value,
                },
              }
            : section,
        ),
      }));
    } else if (type == 'isRequired') {
      setSectionList((prevSectionList) => ({
        ...prevSectionList,
        FormSections: prevSectionList.FormSections.map((section, index) =>
          index === sectionIndex // Update the object at the specified index
            ? {
                ...section,
                isRequired: event.target.checked ? 1 : 0,
              }
            : section,
        ),
      }));
    }
    if (formSubmit) {
      validateSections();
    }
  };

  // Handle options add
  const handleAddOption = (sectionIndex: number) => {
    setSectionList((prevSectionList) => ({
      ...prevSectionList,
      FormSections: prevSectionList.FormSections.map((section, index) =>
        index === sectionIndex // Update the object at the specified index
          ? {
              ...section,
              Options: [...section.Options, initialOption],
            }
          : section,
      ),
    }));
  };
  // Handle options remove
  const handleRemoveOption = (sectionIndex: number, optionIndex: number) => {
    setSectionList((prevSections) => {
      const newSections = {
        ...prevSections,
        FormSections: prevSections.FormSections.map((section, index) =>
          index === sectionIndex
            ? {
                ...section,
                Options: section.Options.filter((_, i) => i !== optionIndex),
              }
            : section,
        ),
      };
      return newSections;
    });
  };
  const handleOptionChange = (sectionIndex: number, optionIndex: number, optionId: string, value: string) => {
    setSectionList((prevSections) => {
      const newSections = {
        ...prevSections,
        FormSections: prevSections.FormSections.map((section, index) =>
          index === sectionIndex
            ? {
                ...section,
                Options: section.Options.map((option, optindex) =>
                  optindex === optionIndex ? { ...option, optionValue: value, optionId: `sec${index}_op${optionIndex}` } : option,
                ),
              }
            : section,
        ),
      };
      return newSections;
    });
  };

  const removeFormAttachment = (sectionIndex: number, FormSectionId: number) => {
    if (params?.surveyId && params?.formId) {
      const apiDeleteParams = {
        FormSessionId: FormSectionId,
        SurveyFormId: parseInt(params?.formId),
        SurveyId: parseInt(params?.surveyId),
      };
      dispatch(deleteSurveyFormAttachment(apiDeleteParams));
    }

    // Remove the FormAttachment value at the specific index
    setSectionList((prevSectionList) => ({
      ...prevSectionList,
      FormSections: prevSectionList.FormSections.map((section, index) =>
        index === sectionIndex
          ? {
              ...section,
              FormAttachment: '',
              FormAttachmentDisplayName: '',
              FormAttachmentSize: '',
            }
          : section,
      ),
    }));
  };

  const uploadQuestionFile = async (e: any, sectionIndex: number) => {
    setFileUploadBegin(true);
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const lastDotIndex = file?.name?.lastIndexOf('.');
      const fileNameWithoutExtension = file?.name?.slice(0, lastDotIndex);
      const fileExtension = file?.name?.slice(lastDotIndex + 1);
      const modifiedFileName = fileNameWithoutExtension + new Date().getTime() + '.' + fileExtension;
      const fileTypeValue = getFileTypeByExtension(fileExtension.toLowerCase());
      const fileType = { value: fileTypeValue };
      const fileTypeNumber = getFileTypeNumber(fileType);
      // replacing special characters with underscores
      const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
      const isValid = checkValidFile(formattedFilename, fileTypeValue, file.size);
      if (isValid) {
        // Update the FormAttachment and FormAttachmentDisplayName
        setUpImageAttachment(e.target.files[0]);
        setSectionList((prevSectionList) => ({
          ...prevSectionList,
          FormSections: prevSectionList.FormSections.map((section, index) =>
            index === sectionIndex
              ? {
                  ...section,
                  FormAttachment: 'survey_answers/' + formattedFilename,
                  FormAttachmentDisplayName: file.name,
                  FormAttachmentSize: bytesToSize(file.size),
                  FormAttachmentType: fileTypeValue,
                  FormAttachmentTypeNumber: fileTypeNumber,
                }
              : section,
          ),
        }));
        const fileInfo = {
          name: formattedFilename,
          displayName: file.name,
          type: fileExtension,
          size: file.size,
          fileType: fileTypeValue,
        };
        setUpImageAttachmentDetails(fileInfo);
        const formData = new FormData();
        formData.append('UploadExtension', fileExtension);
        formData.append('UploadObjectKey', formattedFilename);
        formData.append('UploadType', 'survey_answer');
        dispatch(uploadFile(formData));
      } else {
        toast.toastError(ImageUploadFileError);
        setFileUploadBegin(false);
        // if (fileUploadRef.current) {
        //   fileUploadRef.current.value = '';
        // }
      }
    } else {
      setFileUploadBegin(false);
    }
  };

  const validateSections = () => {
    let allSectionsValid = true;

    if (!formName) {
      setFormNameError('Enter Title of Form.');
    }

    sectionList.FormSections.forEach((section, sectionIndex) => {
      const errors: { key: string; errorMessage: string }[] = [];
      if (!section.FormQuestion) {
        errors.push({ key: 'FormQuestion', errorMessage: 'Enter Question.' });
      }
      if (section.FormAnswerTypeId == 2 || section.FormAnswerTypeId == 5) {
        section.Options.forEach((option, optionIndex) => {
          if (!option.optionValue) {
            errors.push({ key: `Option${optionIndex}`, errorMessage: `Enter Option ${optionIndex + 1}.` });
          }
        });
        if (section.Options.length < 2) {
          errors.push({ key: `Option0`, errorMessage: `Enter at least two options.` });
        }
      }
      // Check for empty or 0 values in other fields
      if (!section.FormAnswerTypeId) {
        errors.push({ key: 'FormAnswerType', errorMessage: 'Select Answer Type.' });
      }
      setSectionList((prevSectionList) => ({
        ...prevSectionList,
        FormSections: prevSectionList.FormSections.map((section, index) =>
          index === sectionIndex
            ? {
                ...section,
                errors: errors,
              }
            : section,
        ),
      }));

      if (errors.length > 0) {
        allSectionsValid = false;
      }
    });
    return allSectionsValid;
  };
   // get the label for tabs
 const getAppUserLabel = (userLevel: number) => {
  let tabLabel = '';
  if (rolesByOrgTypeData.length > 0) {
    const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
    tabLabel = element.length === 1 ? element[0].Name : '';
  } else {
    tabLabel = '';
  }
  return tabLabel;
};
  return (
    <>
      <div className="content-sub">
        <div className="content-area-padding">
          <div className="d-flex justify-content-center my-4">
            <Col xl={6} lg={7}>
              <div className="text-center mb-5">
                <h3>
                  <FormattedMessage id="Hd.AddForm" />
                </h3>
                <p>
                  <FormattedMessage id="Label.CreateQuestion" />{getAppUserLabel(RoleTypeIds.APP_USER)}{"."}
                </p>
              </div>
              <Col>
                <Input
                  label={useIntlMessages('Label.TitleForm')}
                  id="Goal"
                  name="NameSurvey"
                  type="text"
                  placeholder={useIntlMessages('PH.EnterTitle')}
                  maxLength={NumberValues.NUM_100}
                  value={formName}
                  onChange={(event: any) => handleFormnameChange(event.target.value)}
                  errorMessage={formNameError ? formNameError : ''}
                  pattern={NAME_REGEX}
                  autoFocus={true}
                />
              </Col>

              <SurveySections
                formSections={sectionList.FormSections}
                handleRemoveSection={handleRemoveSection}
                handleInputChange={handleInputChange}
                answerTypeOptions={answerTypeOptions}
                uploadQuestionFile={uploadQuestionFile}
                removeFormAttachment={removeFormAttachment}
                handleAddOption={handleAddOption}
                handleRemoveOption={handleRemoveOption}
                handleOptionChange={handleOptionChange}
              />

              <div className="d-flex justify-content-end">
                <Button variant="secondary" onClick={() => handleAddSection()}>
                  <AddIcon /> <FormattedMessage id="Hd.AddSection" />
                </Button>
              </div>
            </Col>
          </div>
          <div className="attachment-list d-flex justify-content-center mt-5">
            <Col xl={10}>
              <div className="btn-container d-flex justify-content-end my-4">
                <Button variant="outline-primary" onClick={() => navigate('/assignsurvey', { state: { surveyId: params?.surveyId ? parseInt(params?.surveyId) : 0, step: 0 } })}>
                  <FormattedMessage id="Button.Cancel" />
                </Button>
                <Button variant="primary" onClick={() => handleAddForm()}>
                  <FormattedMessage id="Button.AddtoSurvey" />
                </Button>
              </div>
            </Col>
          </div>
        </div>
      </div>
      {(answerTypeApiLoading ||
        fileUploadBegin ||
        addSurveyFormApiLoading ||
        fileUploadBegin ||
        surveyFormDetailApiLoading ||
        deleteSurveyFormAttachmentApiLoading ||
        updateSurveyFormApiLoading) && <Loader />}
    </>
  );
};
export default SurveyAddForm;
