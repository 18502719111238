/**
 * @file   src\config\axiosConfig.ts
 * @brief  This file is responsible for axios client configurations.
 * @date   DEC, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import axios from 'axios';
import { STATUS_401, AUTH_NOT_REQUIRED_URLS, REFRESH_TOKEN_URLS, ADMIN_API_URL, STATUS_400 } from '../utils/constants';
const REACT_APP_API_KEY = '$2a$12$EASChu/o0cpX6JUn.SFvnO/bWIZKwnTswABAJD7erjWf5btMRBjlq';
import { MessageToaster } from '../utils/ToastUtil';
import { AuthApis } from '../utils/apiUrls';
import { getFromLocalStorage, setItemLocalStorage } from '../utils/helper';

const toastObj = new MessageToaster();
// Get the site URL from a variable or a configuration file
const siteUrl = window.location.origin || 'http://localhost:3000';

// Craete axios instance.
const axiosClient = axios.create({
  baseURL: ADMIN_API_URL,
});

// Set axios headers.
const headers: any = {};
headers['Access-Control-Allow-Origin'] = '*';
headers['Access-Control-Allow-Headers'] = '*';
headers['Access-Control-Allow-Credentials'] = true;
headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
headers['Content-Type'] = 'application/json';
headers.Accept = 'application/json';
headers['Cache-Control'] = 'no-cache';

axiosClient.defaults.headers = headers;

// Renew access token using refresh token.
export const renewToken = async () => {
  try {
    const { data } = await axiosClient.post(AuthApis.REFRESH_TOKEN);
    return data;
  } catch (error: any) {
    return Promise.reject(error?.message);
  }
};

// Handle interceptor request.
axiosClient.interceptors.request.use(
  async (config: any) => {
    const storageData = getFromLocalStorage('MI_USR_DATA');
    const userData = storageData ? storageData : {};
    const apiPath = config.url;
    if (!AUTH_NOT_REQUIRED_URLS.includes(apiPath)) {
      if (REFRESH_TOKEN_URLS.includes(apiPath)) {
        config.headers['Authorization'] = userData ? 'Bearer ' + userData.RefreshToken : '';
      } else {
        const expirydate = userData ? userData.AccessTokenExpiry : null;
        const expiryTime = expirydate ? new Date(expirydate).getTime() - 60000 : 0;
        if (Date.now() >= expiryTime) {
          const res = await renewToken();
          const newToken = res?.ResponseData?.AccessToken || '';
          const newTokenExpiry = res?.ResponseData?.AccessTokenExpiry || '';
          const newRefreshToken = res?.ResponseData?.RefreshToken || '';
          if (!newToken || !newTokenExpiry || !newRefreshToken) {
            window.location.href = siteUrl;
          }
          userData.AccessToken = newToken;
          userData.AccessTokenExpiry = newTokenExpiry;
          userData.RefreshToken = newRefreshToken;
          setItemLocalStorage('MI_USR_DATA', userData);
        }
        config.headers['Authorization'] = userData ? 'Bearer ' + userData.AccessToken : '';
      }
    }
    config.headers['X-API-Key'] = REACT_APP_API_KEY;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Handle interceptor response.
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === STATUS_401) {
      toastObj.toastError('Session Expired');
      window.location.href = siteUrl;
    }
    //  else if (error.response?.status === STATUS_400) {
    //   if (error.response?.data?.ResponseCode === 3006) {
    //     toastObj.toastError('Something went wrong..Please try again..');
    //   }
    // }
    return Promise.reject(error.response);
  },
);

// Export default methods.
export default {
  get: axiosClient.get,
  post: axiosClient.post,
  put: axiosClient.put,
  patch: axiosClient.patch,
  delete: axiosClient.delete,
};
