/**
 * @file   src\containers\organizations\AssignSurvey.tsx
 * @brief  Survey add page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useState, useEffect, createContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Breadcrumb } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Orgimg from '../../assets/img/Org-image.png';
import { Stepper, Step } from 'react-form-stepper';
import Step1 from './SurveyStep1';
import Step2 from './SurveyStep2';
import { getFromLocalStorage } from '../../utils/helper';
import { BUCKET_URL } from '../../utils/constants';
import Default from '../../assets/img/default.jpg';

const AssignSurvey = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  const orgDetail = getFromLocalStorage('ORG_DETAIL');
  const surveyContext = createContext('');
  // Component states
  const [activeStep, setActiveStep] = useState(0);
  const [activeSurveyId, setActiveSurveyId] = useState<string>('');

  useEffect(() => {
    if (location?.state?.surveyId) {
      setActiveSurveyId(location?.state?.surveyId);
    }
    if (location?.state?.step) {
      setActiveStep(location?.state?.step);
    }
    window.scrollTo(0, 0);
  }, []);

  // Save action response event activated from steps.
  const onApiResponse = (page: number) => {
    setActiveStep(page);
  };
  // Save action response event activated from steps.
  const addSurveyId = (id: string) => {
    setActiveSurveyId(id);
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Button.AssignSurvey" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/activities')}>
              <FormattedMessage id="Hd.Activities" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Button.AssignSurvey" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="mb-4">
        <div className="org-selected">
          <img className="thumbnail-image" src={orgDetail?.ImageURL ? BUCKET_URL + orgDetail?.ImageURL : Default} alt="user pic" />
          <div>
            <h6 className="mb-1">{orgDetail?.Name}</h6>
            <small>{orgDetail?.Address}</small>
          </div>
        </div>
      </div>
      <div className="content-sub">
        <div className="content-area-padding">
          <Col xl={5} className="m-auto mb-4">
            <Stepper connectorStateColors activeStep={activeStep} className="from-stepper">
              {/* <Step onClick={() => setActiveStep(0)}/> */}
              <Step onClick={() => setActiveStep(0)} />
              <Step onClick={() => setActiveStep(1)} />
            </Stepper>
          </Col>
          {activeStep === 0 && (
            <Step1 surveyId={location?.state?.surveyId} onApiResponse={(page: number) => onApiResponse(page)} setSurveyId={addSurveyId} orgId={orgDetail?.OrganizationId} />
          )}
          {activeStep === 1 && <Step2 surveyId={activeSurveyId} onApiResponse={(page: number) => onApiResponse(page)} />}
        </div>
      </div>
    </>
  );
};
export default AssignSurvey;
