/**
 * @file   src\containers\organizations\UserAdd.tsx
 * @brief  User add page.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from 'react';
import '../../assets/css/org.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, useIntlActionMessages } from '../../utils/helper';
import { Button, Col, Tabs, Tab, Table } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import AddIcon from '../../assets/img/icon/Add';
import Delete from '../../assets/img/icon/Delete';
import Edit from '../../assets/img/icon/Edit';
import { IUserForm } from '../../interfaces/UserInterface';
import {
  getOrganizationTypeDropDownList,
  getUserRolesByOrgType,
  getOrganizationNameByOrgType,
  getUserTagsByOrgType,
  getCategoryDetailsById,
} from '../../store/actions/organizationTypeActions';
import { addSingleUser } from '../../store/actions/userActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { ISelectOptionsNumber, ISelectOptionsString, ISelectOption } from '../../interfaces/GeneralInterface';
import { USER_SCHEMA } from '../../validations/userSchema';
import { validateForm } from '../../utils/formValidation';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetUserAdd } from '../../store/slices/userSlice';
import Loader from '../../components/Loader';
import { NumberValues, RoleTypeIds } from '../../utils/enums';
import SystemAdminAdd from './SystemAdminAdd';
import UserBulkUpload from './UserBulkUpload';
import CreatableSelect from 'react-select/creatable';
// Declare default params
const savedefaultRequestParams: IUserForm = {
  CategoryDetails: [
    {
      categoryid: 0,
      classid: [],
    },
  ],
  EmailId: '',
  OrganizationID: 0,
  OrganizationTypeId: 0,
  RoleId: 0,
  Tags: [],
};
const UserAdd = () => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();
  // Ref for  input element on the page
  const inputRef = useRef<any>();
  const updateButtonRef = useRef<any>();
  const containerRef = useRef<any>();
  // Access redux state variables
  const {
    organizationTypeDropDownData,
    organizationTypeDropDownSuccess,
    organizationTypeDropDownLoading,
    rolesByOrgTypeData,
    rolesByOrgTypeLoading,
    orgnameByOrgTypeData,
    orgnameByOrgTypeLoading,
    categoryDetailsByOrgIdLoading,
    categoryDetailsByOrgIdData,
    tagsByOrgTypeData,
    tagsByOrgTypeLoading,
  } = useAppSelector((state: RootState) => state.organizationType);
  const { addUserApiLoading, addUserApiSuccess, addUserApiResponseCode, addUserApiResponseMessage, addSystemAdminApiLoading } = useAppSelector((state: RootState) => state.user);
  // Initialize language variables.
  const categoryAlert = useIntlActionMessages('Submit.Mandatory.Category');
  const categoryValidationAlert = useIntlActionMessages('Select.Mandatory.Category');
  const categorySuccessAlert = useIntlActionMessages('Success.Add.Category');
  const categoryDeleteAlert = useIntlActionMessages('Success.Delete.Category');
  const categoryUpdateAlert = useIntlActionMessages('Success.Category');
  const updateBtn = useIntlActionMessages('text.Update');
  const selectText = useIntlActionMessages('text.Select');
  const addText = useIntlActionMessages('text.Add');
  const categoryText = useIntlActionMessages('Label.Categories');
  const subcategoryText = useIntlActionMessages('Label.SubCategories');
  const categoryLabel = useIntlActionMessages('Label.Category');
  // Initialize component state variables.
  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOptionsNumber | null>();
  const [selectedUser, setSelectedUser] = useState<ISelectOptionsNumber[] | null>([]);
  const [selectedUserCheck, setSelectedUserCheck] = useState<number>(0);
  const [orgName, setOrgName] = useState<ISelectOptionsNumber[] | null>([]);
  const [userForm, setUserForm] = useState<IUserForm>(savedefaultRequestParams);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber>();
  const [errorFields, setErrorFields] = useState<any>({});
  const [userRoles, setUserRoles] = useState<ISelectOptionsNumber[] | null>([]);
  const [category, setCategory] = useState<ISelectOptionsNumber[] | null>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOption>({ label: selectText || 'Select', value: '' });
  const [selectedClasses, setSelectedClasses] = useState<ISelectOptionsString[]>([]);
  const [classes, setClasses] = useState<ISelectOptionsString[]>([]);
  const [newCategory, setNewCategory] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<ISelectOption[]>([]);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);

  // Populate organization details when coming from organization page
  useEffect(() => {
    if (location?.state) {
      const { organizationId, OrganizationTypeId, Name, OrganizationType } = location.state;
      if (OrganizationTypeId) {
        setOrganizationTypeValue({ label: OrganizationType, value: OrganizationTypeId });
        dispatch(getUserRolesByOrgType({ OrganizationTypeId }));
      }
      if (organizationId) {
        setSelectedOrganization({ label: Name, value: organizationId });
        dispatch(getCategoryDetailsById({ OrganizationId: organizationId }));
      }
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        OrganizationTypeId: OrganizationTypeId,
        OrganizationID: organizationId,
      }));
    }
  }, [location.state]);
  // Show message after form submit, success/failure
  useEffect(() => {
    if (addUserApiResponseCode === 4030 && addUserApiSuccess) {
      toast.toastSuccess(addUserApiResponseMessage);
      navigate('/manageuser');
      dispatch(resetUserAdd());
    } else if (addUserApiResponseCode === 4029 && addUserApiResponseMessage) {
      toast.toastError(addUserApiResponseMessage);
      dispatch(resetUserAdd());
    }
  }, [addUserApiLoading, addUserApiResponseCode, addUserApiSuccess, addUserApiResponseMessage]);
  // Reset the form after creating user
  useEffect(() => {
    return () => {
      dispatch(resetUserAdd());
    };
  }, []);
  // Set user form based on user role
  useEffect(() => {
    if (selectedUserCheck !== 2) {
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        CategoryDetails: savedefaultRequestParams.CategoryDetails,
      }));
    } else {
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        CategoryDetails: [],
      }));
    }
  }, [selectedUserCheck]);
  // Prevent enter key press
  useEffect(() => {
    dispatch(getOrganizationTypeDropDownList({}));
    const listener = (event: any) => {
      if (event.keyCode === NumberValues.NUM_13 && event.target.nodeName != 'TEXTAREA') {
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  // Get user roles by organization type
  useEffect(() => {
    if (organizationTypeValue) {
      if (rolesByOrgTypeData && rolesByOrgTypeData.length > 0) {
        // Filter out roles with RoleTypeID equal to 7
        const filteredRolesData = rolesByOrgTypeData.filter((role: any) => role.RoleTypeID !== RoleTypeIds.SYSTEM_ADMIN);
        const rolesData = filteredRolesData.map(
          (role: any): ISelectOptionsNumber => ({
            label: role.Name,
            value: role.RoleId,
          }),
        );
        setUserRoles(rolesData);
        setSelectedUser([]);
      } else {
        // If ResponseData is null, update userRoles state with null
        setUserRoles([]);
        setSelectedUser([]);
        setSelectedUserCheck(0);
      }
    }
  }, [rolesByOrgTypeLoading]);

  // Get tags by organization type
  useEffect(() => {
    if (organizationTypeValue && tagsByOrgTypeData && tagsByOrgTypeData.Tags) {
      const options = tagsByOrgTypeData.Tags.map(
        (tag: string): ISelectOption => ({
          value: tag,
          label: tag.charAt(0).toUpperCase() + tag.slice(1),
        }),
      );
      setTagOptions(options);
    } else {
      setTagOptions([]);
    }
  }, [tagsByOrgTypeLoading, organizationTypeValue, tagsByOrgTypeData]);

  // Get organization details by organization type
  useEffect(() => {
    if (organizationTypeValue) {
      if (orgnameByOrgTypeData && orgnameByOrgTypeData.length > 0) {
        const organizationData = orgnameByOrgTypeData.map(
          (organization: any): ISelectOptionsNumber => ({
            label: organization.Name,
            value: organization.ID,
          }),
        );
        setOrgName(organizationData);
        setSelectedOrganization(null);
      } else {
        // If ResponseData is null, update orgName state with null
        setOrgName([]);
        setSelectedOrganization(null);
      }
    }
  }, [orgnameByOrgTypeLoading]);
  // Get category details by organization
  useEffect(() => {
    if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length > 0) {
      const categoryData = categoryDetailsByOrgIdData.map(
        (category: any): ISelectOptionsNumber => ({
          label: category.CategoryName,
          value: category.CategoryId,
        }),
      );
      setCategory(categoryData);
    } else {
      // If ResponseData is null, update category state with null
      setCategory([]);
      setSelectedCategory({ label: selectText || 'Select', value: '' });
    }
  }, [categoryDetailsByOrgIdLoading, selectText]);
  // Get organization type
  useEffect(() => {
    if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
      const orgTypeOptions = organizationTypeDropDownData.map(
        (orgtype: any): ISelectOptionsNumber => ({
          label: orgtype.orgTypeName,
          value: orgtype.orgTypeId,
        }),
      );
      setOrganizationTypeOptions([...orgTypeOptions]);
    }
  }, [organizationTypeDropDownLoading]);
  // Add event listener for Enter key press
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        // Check if all required fields are filled
        if (isFormValid()) {
          event.preventDefault();
          onSubmit();
        }
      }
    };
    const isFormValid = () => {
      return Object.values(errorFields).every((error) => !error);
    };
    const currentContainer = containerRef?.current;
    if (currentContainer) {
      currentContainer.addEventListener('keydown', handleKeyPress);
    }
    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [errorFields]);

  // Get user roles and organization name on organization type change
  const handleOrgTypeChange = async (event: any) => {
    const selectedOrgTypeId = parseInt(event.value);
    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      OrganizationTypeId: selectedOrgTypeId,
      RoleId: 0,
      OrganizationID: 0,
    }));
    setOrganizationTypeValue(event);
    dispatch(
      getUserRolesByOrgType({
        OrganizationTypeId: selectedOrgTypeId,
      }),
    );
    dispatch(
      getOrganizationNameByOrgType({
        OrganizationTypeId: selectedOrgTypeId,
      }),
    );
    dispatch(
      getUserTagsByOrgType({
        OrganizationTypeId: selectedOrgTypeId,
        searchText: '',
      }),
    );
    const validateObj = {
      ...userForm,
      OrganizationTypeId: selectedOrgTypeId,
      RoleId: 0,
      OrganizationID: 0,
    };
    const errorResult = await validateForm(validateObj, USER_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Handle selection change
  const handleSelectionChange = async (event: any, type: string) => {
    const selectedValue = event.value;
    const selectedUser = parseInt(event.value);
    switch (type) {
      case 'role': {
        setSelectedUser(event.value);
        const selectedRole = rolesByOrgTypeData.find((role: any) => role.RoleId === selectedUser);
        // Check if the selected role exists and its RoleType is equal to student
        const selectedUserCheckValue = selectedRole && selectedRole.RoleTypeID === RoleTypeIds.APP_USER ? 2 : 0;
        setSelectedUserCheck(selectedUserCheckValue);
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          RoleId: selectedValue,
        }));
        setSelectedUser(event);
        const validateRoleObj = {
          ...userForm,
          RoleId: selectedValue,
        };
        const roleErrorResult = await validateForm(validateRoleObj, USER_SCHEMA, errorFields);
        setErrorFields(roleErrorResult);
        break;
      }
      case 'organization': {
        setSelectedOrganization(event.value);
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          OrganizationID: selectedValue,
        }));
        setSelectedOrganization(event);
        dispatch(
          getCategoryDetailsById({
            OrganizationId: selectedValue,
          }),
        );
        const validateOrgObj = {
          ...userForm,
          OrganizationID: selectedValue,
        };
        const orgErrorResult = await validateForm(validateOrgObj, USER_SCHEMA, errorFields);
        setErrorFields(orgErrorResult);
        break;
      }
      default:
        break;
    }
  };
  // Handle input field
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserForm((info: any) => ({
      ...info,
      [name]: value,
    }));
    const validateObj = {
      [name]: value,
    };
    const errorResult = await validateForm(validateObj, USER_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };
  // Handle form submit
  const onSubmit = async () => {
    const errorResult = await validateForm(userForm, USER_SCHEMA, errorFields);
    if (selectedUserCheck === 2) {
      // Check if both category and classes are selected
      if (newCategory.length === 0) {
        toast.toastError(categoryAlert);
        return;
      }
    }
    if (Object.keys(errorResult).length === 0) {
      dispatch(addSingleUser(userForm));
    } else {
      setErrorFields(errorResult);
      // If there are validation errors, focus on the input field
      const firstErrorField = Object.keys(errorResult)[0];
      if (firstErrorField && inputRef?.current) {
        inputRef?.current?.focus();
      }
    }
  };
  // Get sub categories based on category
  const handleCategoryChange = (event: any) => {
    const actualCategoryId = event.value;
    setSelectedCategory(event);
    const category = categoryDetailsByOrgIdData.find((category: any) => category.CategoryId === actualCategoryId);
    if (category) {
      const subcategories = category.Subcategory.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      }));
      setClasses(subcategories);
      setSelectedClasses([]);
    } else {
      setClasses([]);
    }
  };
  // Handle class chanage
  const handleClassChange = (event: any) => {
    setSelectedClasses(event);
  };
  // Submit category
  const onSubmitCategory = () => {
    if (selectedCategory.value === '' || selectedClasses.length === 0) {
      toast.toastError(categoryValidationAlert);
      return;
    }
    const updatedCategory = {
      CategoryId: parseInt(selectedCategory.value),
      CategoryName: selectedCategory.label,
      Subcategory: selectedClasses.map((classItem: any) => ({
        classid: classItem.value,
        classname: classItem.label,
      })),
    };
    const indexToUpdate = newCategory.findIndex((category: any) => category.CategoryId === updatedCategory.CategoryId);
    if (indexToUpdate !== -1) {
      // If the category already exists, update it
      const updatedNewCategory = [...newCategory];
      updatedNewCategory[indexToUpdate] = updatedCategory;
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        CategoryDetails: updatedNewCategory.map((category: any) => ({
          categoryid: category.CategoryId,
          classid: category.Subcategory.map((subCategory: any) => subCategory.classid),
        })),
      }));
      setNewCategory(updatedNewCategory);
      if (updateButtonRef.current) {
        updateButtonRef.current.textContent = addText;
      }
      const categoryData = categoryDetailsByOrgIdData.map(
        (category: any): ISelectOptionsNumber => ({
          label: category.CategoryName,
          value: category.CategoryId,
        }),
      );
      setCategory(categoryData);
      toast.toastSuccess(categoryUpdateAlert);
    } else {
      // If the category doesn't exist, add it as a new entry
      const updatedNewCategory = [...newCategory, updatedCategory];
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        CategoryDetails: updatedNewCategory.map((category: any) => ({
          categoryid: category.CategoryId,
          classid: category.Subcategory.map((subCategory: any) => subCategory.classid),
        })),
      }));
      setNewCategory(updatedNewCategory);
      const categoryData = categoryDetailsByOrgIdData.map(
        (category: any): ISelectOptionsNumber => ({
          label: category.CategoryName,
          value: category.CategoryId,
        }),
      );
      setCategory(categoryData);
      toast.toastSuccess(categorySuccessAlert);
    }
    setSelectedCategory({ label: selectText, value: '' });
    setClasses([]);
    setSelectedClasses([]);
  };
  // Delete category
  const onDeleteCategory = (index: number) => {
    setNewCategory((prevNewCategory: any) => {
      const updatedCategoryDetails = [...prevNewCategory];
      updatedCategoryDetails.splice(index, 1);
      return updatedCategoryDetails;
    });
    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      CategoryDetails: prevUserForm.CategoryDetails.filter((_: any, i: any) => i !== index),
    }));
    toast.toastSuccess(categoryDeleteAlert);
  };
  // Update category
  const onUpdateCategory = (index: number) => {
    const categoryToUpdate = newCategory[index];
    // Set the selected category and classes
    setSelectedCategory({ label: categoryToUpdate.CategoryName, value: categoryToUpdate.CategoryId });
    const updatedDisabledCategories = [
      {
        label: categoryToUpdate.CategoryName,
        value: categoryToUpdate.CategoryId,
        isDisabled: true, // Disable the selected category
      },
    ];
    setCategory(updatedDisabledCategories);
    setSelectedClasses(
      categoryToUpdate.Subcategory.map((subcategory: any) => ({
        label: subcategory.classname,
        value: subcategory.classid,
      })),
    );
    const categoryDetails = categoryDetailsByOrgIdData.find((category: any) => category.CategoryId.toString() === categoryToUpdate.CategoryId.toString());
    if (categoryDetails) {
      const categoryClasses = categoryDetails.Subcategory || [];
      const otherClasses = categoryClasses.filter((classItem: any) => {
        return !categoryToUpdate.Subcategory.some((selectedClass: any) => selectedClass.classid === classItem.classid);
      });
      const otherClassesOptions = otherClasses.map((classItem: any) => ({
        label: classItem.classname,
        value: classItem.classid,
      }));
      setClasses(otherClassesOptions);
    }
    // Change text of add button to update
    if (updateButtonRef.current) {
      updateButtonRef.current.textContent = updateBtn;
    }
  };
  // Function to handle cancel on tab key press
  const handleCancel: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === 'Enter') {
      navigate('/manageuser');
    }
  };
  // handle tag changes
  const handleTagChange = (tags: any) => {
    setSelectedTags(tags);
    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      Tags: tags.map((tag: any) => tag.value),
    }));
  };

  return (
    <>
      <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
        <h3>{useIntlActionMessages('Button.AddUser')}</h3>
        {location?.state?.parentPage == 'orgview' && (
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.Organization" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/vieworganization', { state: { organizationId: location.state.organizationId } })}>
              <FormattedMessage id="Hd.ViewOrganization" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Button.AddUser" />
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        {location?.state?.parentPage == 'manageuser' && (
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageuser')}>
              <FormattedMessage id="Hd.ManageUsers" />{' '}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Button.AddUser" />
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
      </div>
      <div className="content-sub" ref={containerRef}>
        <div className="content-area-padding">
          <div className="d-flex justify-content-center">
            <Col xl={6} lg={7} className="tab-secondary">
              <Tabs defaultActiveKey="SingleEntry" transition={false} id="noanim-tab-example" className="mb-5 mt-4">
                <Tab eventKey="SingleEntry" title="Single Entry">
                  <Select
                    label={useIntlMessages('Label.OrgType')}
                    options={organizationTypeOptions}
                    value={organizationTypeValue}
                    name="OrganizationTypeId"
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => handleOrgTypeChange(e)}
                    error={errorFields?.OrganizationTypeId}
                  />
                  <Select
                    label={useIntlMessages('Label.OrgName')}
                    options={orgName}
                    value={selectedOrganization}
                    name="OrganizationID"
                    id="OrganizationID"
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => handleSelectionChange(e, 'organization')}
                    error={errorFields?.OrganizationID}
                  />
                  <Select
                    label={useIntlMessages('Label.Userrole')}
                    options={userRoles}
                    value={selectedUser}
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => handleSelectionChange(e, 'role')}
                    name="RoleId"
                    id="RoleId"
                    error={errorFields?.RoleId}
                  />
                  <Input
                    autoFocus
                    ref={inputRef}
                    label={useIntlMessages('Label.EmailAddress')}
                    id="EmailId"
                    name="EmailId"
                    type="text"
                    placeholder={useIntlActionMessages('PH.Enter.Email')}
                    maxLength={NumberValues.NUM_100}
                    value={userForm.EmailId}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.EmailId}
                    autoComplete="new-password"
                  />
                  {selectedUserCheck == 2 && (
                    <>
                      <label className="form-label">{useIntlActionMessages('Text.AddTags')}</label>
                      <CreatableSelect isMulti options={tagOptions} name="Tags" id="Tags" className="tags-selector" onChange={handleTagChange} value={selectedTags} />
                      <small>{useIntlActionMessages('Text.HelpTextTags')}</small>
                    </>
                  )}
                  {selectedUserCheck == 2 && (
                    <div className="studentscategoryclass mt-4">
                      <Row>
                        <Col>
                          <Select
                            label={categoryLabel}
                            options={category}
                            value={selectedCategory}
                            placeholder={categoryText}
                            onChange={(e: any) => handleCategoryChange(e)}
                            className="select-category"
                          />
                        </Col>
                        <Col>
                          <Select label={subcategoryText} options={classes} value={selectedClasses} placeholder={selectText} onChange={(e: any) => handleClassChange(e)} isMulti />
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end btn-container mb-3">
                        <Button variant="dark" onClick={onSubmitCategory} className="icon-add">
                          <AddIcon />{' '}
                          <span className="icon-addtext" ref={updateButtonRef}>
                            {addText}
                          </span>
                        </Button>
                      </div>
                      {newCategory.length > 0 && (
                        <Table striped hover responsive>
                          <thead>
                            <tr>
                              <th>{categoryText}</th>
                              <th>{subcategoryText}</th>
                              <th className="w-100px"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {newCategory.map((category: any, index: number) => (
                              <tr key={index}>
                                <td>{category.CategoryName}</td>
                                <td>
                                  {category.Subcategory &&
                                    category.Subcategory.map((subcategory: any, subIndex: number) => (
                                      <span key={subIndex}>
                                        {subcategory.classname}
                                        {subIndex !== category.Subcategory.length - 1 && ', '}
                                      </span>
                                    ))}
                                </td>
                                <td>
                                  <Button variant="dark" size="sm" className="icon-btn me-2" onClick={() => onUpdateCategory(index)}>
                                    <Edit />
                                  </Button>
                                  <Button variant="dark" size="sm" className="icon-btn" onClick={() => onDeleteCategory(index)}>
                                    <Delete />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  )}
                  <Row className="mt-5 mb-5">
                    <Col>
                      <Button variant="outline-primary" className="w-100" onClick={() => navigate('/manageuser')} onKeyDown={handleCancel}>
                        {useIntlActionMessages('Button.Cancel')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        className="w-100"
                        onClick={onSubmit}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onSubmit();
                          }
                        }}
                      >
                        {useIntlActionMessages('Button.AddUser')}
                      </Button>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="BulkUpload" title={useIntlActionMessages('Label.BulkUpload')}>
                  <UserBulkUpload />
                </Tab>
                <Tab eventKey="SystemAdmin" title="System Admin">
                  <SystemAdminAdd />
                </Tab>
              </Tabs>
            </Col>
          </div>
        </div>
      </div>
      {(organizationTypeDropDownLoading || rolesByOrgTypeLoading || orgnameByOrgTypeLoading || categoryDetailsByOrgIdLoading || addUserApiLoading || addSystemAdminApiLoading) && (
        <Loader />
      )}
    </>
  );
};
export default UserAdd;
